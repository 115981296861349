<template>
  <div class="history-container bg-white rounded-lg" id="history-style">
    <div
      class="text-md tracking-tighter font-bold p-3 flex justify-between items-center drop-shadow-lg"
    >
      <span>Activity Overview</span>
      <img
        v-if="showPanel"
        @click="showPanel = !showPanel"
        src="@/assets/images/arrow-up.svg"
        alt="arrow-up"
      />
      <img
        v-if="!showPanel"
        @click="showPanel = !showPanel"
        src="@/assets/images/arrow-down.svg"
        alt="arrow-down"
      />
    </div>
    <template v-if="showPanel">
      <div class="pl-2 mb-2">
        <a
          class="[ p-2 ][ border-l-[1px] border-t-[1px] border-r-[1px] border-grey-20 ] rounded-lg mr-1"
          :class="{
            'border-b-[2px] border-b-orange-100 font-bold':
              panel === 'comments',
          }"
          @click="panel = 'comments'"
        >
          Comments
        </a>
        <a
          class="[ p-2 ][ border-l-[1px] border-t-[1px] border-r-[1px] border-grey-20 ] rounded-lg ml-1"
          :class="{
            'border-b-[2px] border-b-orange-100 font-bold':
              panel === 'auditLogs',
          }"
          @click="panel = 'auditLogs'"
        >
          Audit logs
        </a>
      </div>
      <hr />
      <div class="comments-section" v-if="panel === 'comments'">
        <div
          class="comments-inner p-3"
          v-for="comment in reversedArray"
          :key="comment._id"
        >
          <div class="user-image">
            <ImageIcon name="Trishant"></ImageIcon>
          </div>
          <div class="date-time mt-2">
            {{ moment(comment?.createdAt).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
          <div>
            <span class="font-bold">#{{ comment.index }}</span>
            {{ comment.comment.target?.selector[0]?.exact }}
          </div>
          <div class="user-name mt-2">
            <span class="font-bold">{{
              comment?.verifierId?.firstname +
              " " +
              comment?.verifierId?.lastname
            }}</span>
            {{ moment(comment?.createdAt).fromNow() }}
          </div>
          <div
            class="user-comment font-semibold"
            v-for="comm in comment.comment.body"
            :key="comm.value"
          >
            {{ comm.value }}
          </div>
          <hr class="mt-3" />
        </div>
      </div>
      <div class="comments-section" v-if="panel === 'auditLogs'">
        <div
          class="comments-inner p-3"
          v-for="(auditLog, index) in auditLogsData"
          :key="auditLog.createdAt"
        >
          <div class="user-image flex items-center gap-3">
            <ImageIcon :name="auditLog.verifierUserId.firstname"></ImageIcon>
            <span class="font-bold">
              {{ auditLog.indicator }}
            </span>
          </div>
          <div class="date-time mt-2">
            {{ moment(auditLog?.createdAt).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
          <div>
            <div>
              <span class="font-bold">#{{ auditLogsData.length - index }} </span
              >{{ auditLog?.question.slice(0, 30) + ".." }}
            </div>
          </div>
          <div class="user-name mt-2 mb-1">
            <span class="font-bold">{{
              auditLog?.verifierUserId?.firstname +
              " " +
              auditLog?.verifierUserId?.lastname
            }}</span>
            {{ moment(auditLog?.createdAt).fromNow() }}
          </div>
          <div v-if="Array.isArray(auditLog?.previousAnswer)">
            Changed from
            <ul
              style="list-style-type: disc; margin-left: 20px; font-size: 15px"
            >
              <li v-for="answer in auditLog?.previousAnswer" :key="answer">
                <span class="user-comment font-semibold text-[#FF3A21]">
                  {{ answer.slice(0, 30) + ".." }}
                </span>
              </li>
            </ul>
            To
            <ul
              style="list-style-type: disc; margin-left: 20px; font-size: 15px"
            >
              <li v-for="answer in auditLog?.updatedAnswer" :key="answer">
                <span class="user-comment font-semibold text-[#4C9F38]">
                  {{ answer.slice(0, 30) + ".." }}
                </span>
              </li>
            </ul>
          </div>
          <div v-else>
            Changed from
            <span class="user-comment font-semibold text-[#FF3A21]">
              {{
                Array.isArray(auditLog?.previousAnswer)
                  ? auditLog?.previousAnswer[0].slice(0, 25) + ".."
                  : auditLog?.previousAnswer
              }}
            </span>
            To
            <span class="user-comment font-semibold text-[#4C9F38]">
              {{
                Array.isArray(auditLog?.updatedAnswer)
                  ? auditLog?.updatedAnswer[0].slice(0, 30) + ".."
                  : auditLog?.updatedAnswer
              }}
            </span>
          </div>
          <hr class="mt-3" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ImageIcon from "@/components/ProfileIconComponent.vue";

export default {
  name: "VerifierHistorySection",
  data() {
    return {
      showPanel: false,
      panel: "comments",
    };
  },
  props: ["commentsData", "auditLogsData"],
  components: {
    ImageIcon,
  },
  computed: {
    reversedArray() {
      return this.commentsData.slice().reverse();
    },
  },
};
</script>
<style lang="scss" scoped>
.history-container {
  width: 320px;
  position: fixed;
  right: 0;
  top: 66px;
  overflow-y: auto;
  z-index: 999;

  // direction: rtl;
  .comments-section {
    height: calc(100vh - 66px);
    overflow-y: auto;

    // direction: ltr;
    .comments-inner {
      .user-image {
      }

      .date-time {
      }
    }

    .comments-inner:last-of-type {
      margin-bottom: 100px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.history-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

.history-container::-webkit-scrollbar {
  width: 10px;
  background-color: #ffff;
  position: relative;
  left: 0;
}

.history-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}
</style>
