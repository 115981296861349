<template>
  <div class="container mx-auto py-6">
    <h2 class="text-2xl font-bold mb-4 text-gray-800">2X {{ is2xChallenge ? 'Challenge' : 'Certification' }} Self-Assessments in Progress</h2>
    <div class="overflow-x-auto shadow-md rounded-lg">
      <div class="max-h-[65vh] overflow-y-auto">
        <table class="min-w-full bg-white divide-y divide-gray-200">
          <thead class="bg-[#201747] sticky top-0">
            <tr class="divide-x divide-gray-200">
              <th class="px-4 py-5 text-left text-xs font-medium text-white uppercase tracking-wider">
                Team Id
              </th>
              <th class="px-4 py-5 text-left text-xs font-medium text-white uppercase tracking-wider">
                Email Id
              </th>
              <th class="px-4 py-5 text-left text-xs font-medium text-white uppercase tracking-wider">
                Start Date
              </th>
              <th class="px-4 py-5 text-left text-xs font-medium text-white uppercase tracking-wider">
                Date of Certification
              </th>
              <th class="px-4 py-5 text-left text-xs font-medium text-white uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="item in certificationData?.certificationRequest" :key="item._id" class="hover:bg-gray-50 divide-x divide-gray-200">
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">{{ item.organizationId?.name }}</div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm font-medium text-gray-900">{{ item.equiloEmailId }}</div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">{{ formatDate(item.updatedAt) }}</div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-500">{{ item?.certificationDate ? formatDate(item?.certificationDate) : 'NA' }}</div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="getStatusClass(item.status)">
                  {{ statusMap[item.status] ? statusMap[item.status] : item.status }}
                </span>
              </td>
            </tr>
            <tr v-if="!certificationData.count" class="text-center">
              <td colspan="100%">No data found!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoXUserStatistics",
  data() {
    return {
        statusMap: {
        "In progress": "Self-Assessment in progress",
        "Full Survey Submitted": "Self-Assessment Submitted",
        "Min Survey Submitted": "Self-Assessment Submitted",
      },
    }
  },
  props: {
    certificationData: {
      type: [Array, Object, undefined],
      required: true,
    },
  },
  methods: {
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    getStatusClass(status) {
      switch (status) {
        case "New Request In Progress":
          return "bg-green-100 text-green-800";
        case "Certification Awarded":
          return "bg-green-100 text-green-800";
        case "Verification in Process":
          return "bg-yellow-100 text-yellow-800";
        case 'Full Survey Submitted':
          return 'bg-purple-100 text-purple-800';
        case 'Min Survey Submitted':
          return 'bg-purple-100 text-purple-800';
        default:
          return "bg-gray-100 text-gray-800";
      }
    },
  },
  computed: {
    is2xChallenge() {
      return this.$route.path.includes('2X-Challenge');
    }
  }
};
</script>
