<template>
  <div
    class="relative overflow-x-auto document-upload-table-section"
    v-if="filelist.length"
    v-cloak
  >
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead
        class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base"
      >
        <tr class="table-heading-row">
          <th scope="col" class="px-6 py-4 w-3/5">Document</th>
          <th scope="col" class="px-6 py-4 w-1/5">Date uploaded</th>
          <th scope="col" class="px-6 py-4 w-1/5">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="table-content-row dark:bg-gray-800 text-base"
          v-for="(file, i) in filelist"
          :key="i"
        >
          <td
            scope="row"
            class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-3/5"
            style="
              text-decoration: underline;
              text-decoration-thickness: 2px;
              text-decoration-color: #2c2e35;
            "
            @click="openPdfViewer(file)"
          >
            {{ file.name }}
          </td>
          <td
            class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/5"
          >
            {{ moment(file?.createdAt).format("DD MMMM, YYYY") }}
          </td>
          <td class="w-1/5">
            <div class="flex justify-around">
              <a
                :href="file.url"
                target="_blank"
                class="green-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
              >
                Download
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- PDF viewer modal -->
    <div
      v-if="showPdfModal"
      class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      @click.self="closePdfViewer"
    >
      <div
        class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-[85vh] overflow-auto relative"
      >
        <div
          class="absolute top-0 right-10 mt-2 mr-2 cursor-pointer text-gray-500 hover:text-gray-700 text-4xl"
          @click="closePdfViewer"
        >
          &times;
        </div>
        <h2 class="text-xl font-bold mb-4">{{ pdfData.title }}</h2>
        <vue-pdf-embed :source="pdfData.url" @progress="onProgress" />
        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md mt-4"
          @click="closePdfViewer"
        >
          Close
        </button>
      </div>
    </div>
    <!-- Reject application modal -->
    <DocumentRejectModal
      v-if="isDocumentModal"
      @close="(isDocumentModal = false), (selectedDocument = null)"
      :selectedDocument="selectedDocument"
    />
    <!-- Reject application modal -->
  </div>
</template>
<script>
import apiService from "@/helpers/apiServices.js";
import DocumentRejectModal from "@/components/2x-certification/documentRejectionModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "UploadDocumentsTable",
  data() {
    return {
      documentComment: "",
      isDocumentModal: false,
      selectedDocument: null,
      showPdfModal: false,
      pdfData: null,
    };
  },
  components: {
    DocumentRejectModal,
    VuePdfEmbed,
  },
  props: ["filelist"],
  methods: {
    updateDocumentStatus(file) {
      this.selectedDocument = file;
      apiService
        .putDocumentStatusByVerifier(
          this.documentComment,
          this.selectedDocument?._id,
          "Accepted"
        )
        .then(() => {
          this.$swal.fire({
            icon: "success",
            text: "Approved successfully!",
            showConfirmButton: true,
          });
          this.documentComment = "";
          this.selectedDocument = null;
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong!",
            showConfirmButton: true,
          });
          this.selectedDocument = null;
        });
    },
    openPdfViewer(file) {
      this.pdfData = { url: file.url, title: file.name };
      this.showPdfModal = true;
    },
    closePdfViewer() {
      this.showPdfModal = false;
      this.pdfData = null;
    },
    onProgress(progressData) {
      console.log(progressData);
    },
  },
  watch: {
    filelist(newVal) {
      console.log("Filelist changed", newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.document-upload-table-section {
  border-radius: 16px;
  border: 1px solid #e1e3e9;

  table {
    th,
    td {
      border: 1px solid #e1e3e9;
    }

    .table-heading-row {
      th {
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }

      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row {
      img {
        width: 24px;
        height: 24px;
      }

      td {
        border-bottom: none;
        height: 85px;
        padding: 22px 24px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2c2e35;

        .red-button {
          font-weight: bold;
          font-size: 14px;
          color: #ff3a21;
          border-style: solid;
          border-color: #ff3a21;
          border-width: 1px;
        }

        .green-button {
          font-weight: bold;
          font-size: 14px;
          color: #3f7e44;
          border-style: solid;
          border-color: #3f7e44;
          border-width: 1px;
        }
      }

      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }
    }
  }
}
</style>
