<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";
import TwoXThresholdAPI from "@/api/TwoXThresholdAPI";
import { saveAs } from 'file-saver';

export default {
  name: "my2xThresholdsCSV",
  components: { EquiloButton },
  setup() {
    const thresholdsAPI = new TwoXThresholdAPI();
    return { thresholdsAPI };
  },
  data() {
    return {
      isDownloading: false,
      csvData: null,
      errorCSV: "",
      usageTermsLink: "https://equilo-public-assets.s3.us-east-1.amazonaws.com/terms/Equilo+2X+Threshold+Tool+and+Data+Usage+Terms.pdf",
    };
  },
  methods: {
    async fetchCSVData() {
      this.isDownloading = true;
      try {
        const response = await this.thresholdsAPI.getCSV();
        this.csvData = response.data;
      } catch (error) {
        const self = this;
        this.errorCSV = "Sorry, something went wrong. Please try again later.";

        // Since the request failed, check if the response is a Blob
        if (error.response?.data instanceof Blob) {
          // Create a FileReader to read the Blob's textual content
          const reader = new FileReader();

          reader.onload = function(event) {
            const text = event.target.result;

            // If this text includes a message or you need to parse it as JSON
            try {
              const json = JSON.parse(text);
              self.errorCSV = json.message;

              // Redirect user if they don't have a purchase history
              // TODO: This can be replaced by a 404 status code from the API
              if (self.noPurchaseHistory) {
                setTimeout(() => {
                  window.location.href = '/#/2X-Ecosystem/Thresholds/paywall';
                }, 1000);
              }
            } catch (e) {
              console.error("Could not parse the response as JSON. Response text:", text);
            }
          };

          // Read the Blob as a text
          reader.readAsText(error.response?.data);
        }
      } finally {
        this.isDownloading = false;
      }
    },
    downloadCSV() {
      saveAs(this.csvData, 'my_2x_threshold.xlsx');
    }
  },
  mounted() {
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Criteria Thresholds", url: "/2X-Ecosystem/Thresholds" },
      { name: "CSV Download", url: "" },
    ]);
    this.fetchCSVData();
  },
  computed: {
    noPurchaseHistory() {
      return this.errorCSV === 'No purchase history found for csv';
    }
  }
};
</script>

<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="mt-5 text-lg">
        <h1
          class="text-6xl tracking-tighter font-medium print:text-7xl text-[#21191D]"
          style="max-width: 845px"
        >
          <span style="font-family: Work Sans">2X Criteria Thresholds</span>
        </h1>

        <div class="flex flex-col mt-5 gap-4">
          <div v-if="errorCSV">
            <p>{{ errorCSV }}. <span v-if="noPurchaseHistory">Redirecting you to the paywall...</span></p>
          </div>
          <div v-else class="flex flex-col gap-4">
            <p>Thank you for your purchase of the 2X Regional Data Set from Equilo! You can download your data using the link below.</p>
            <p class="font-bold">⚠️ Please note: This link allows one-time access only. Ensure you save the file securely after downloading.</p>
            <p>By downloading the data, you agree to the <a :href="usageTermsLink" target="_blank">Usage Terms</a>.</p>
            <br/>
          </div>
          <div class="w-32 mb-8">
            <EquiloButton
              @on-click="downloadCSV"
              :text="isDownloading ? 'Downloading...' : 'Download CSV'"
              :disabled="isDownloading || !!errorCSV"
            />
          </div>
          <div class="flex flex-col gap-4">
            <p>Need Assistance?</p>
            <p>If you have any issues with downloading or questions about the usage terms, feel free to contact us at
              <a href="mailto:example@example.com?subject=Hello%20there&body=This%20is%20the%20email%20body">support@equilo.io</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ecosystem-landing {
  background-color: #f4f1ed;
  font-family: Work Sans;
  color: #21191d;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 170px 0 0;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #21191d;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  a {
    color: #0077C8;
    text-decoration: underline;
  }
}
</style>
