<template>
  <div id="app" class="relative antialiased modal-vue">
    <div :class="{ flex: isChatOpen }">
      <template>
        <div
          :class="{
            '!w-[75%]': isChatOpen,
          }"
        >
          <template v-if="!hideHeaderFooter">
            <div
              v-if="this.user"
              class="h-16 fixed top-0 left-0 w-screen bg-white md:flex flex-row justify-center items-center border-b border-gray-200 shadow-xs no-print px-4 lg:px-0"
              :class="{
                'w-[75%] lg:px-4': isChatOpen,
              }"
              style="z-index: 8000; background-color: #201747"
            >
              <div
                class="container h-full flex flex-row justify-between items-center"
              >
                <div
                  class="flex flex-row items-center justify-start font-semibold w-10/12"
                >
                  <router-link
                    to="/"
                    class="flex-row flex items-center flex-shrink-0 mr-2 transition duration-150 text-xs capitalize homelink"
                  >
                    <img
                      src="@/assets/images/little-logo.png"
                      alt="little logo"
                      class="h-5 mr-5"
                    />
                    <p>Home</p>
                  </router-link>
                  <div
                    class="flex flex-row overflow-scroll mr-10 h-16 hidescrollbar"
                  >
                    <div
                      v-for="part in breadcrumbs"
                      v-bind:key="part.name"
                      class="flex flex-row items-center justify-start flex-shrink-0"
                    >
                      <div class="mr-2 text-white">
                        <svg
                          class="w-5 h-5"
                          version="1.1"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                          <g fill="none">
                            <path d="M0,0h24v24h-24Z"></path>
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                              d="M10,16l4,-4l-4,-4"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <router-link
                        :to="part.url"
                        class="mr-2 transition duration-150 flex-shrink-0 text-xs capitalize otherlink"
                      >
                        {{ part.name || "" }}
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="flex flex-row items-center justify-end font-semibold w-8/12"
                    v-if="
                      !isChatOpen &&
                      this.$route.path !== '/chatbot' &&
                      user &&
                      user.featureFlags.includes(FeatureFlags.CHATBOT)
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-10 h-10 sm:w-6 sm:h-6 mr-2 text-white ml-12"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <div
                      class="custom-input flex items-center bg-white rounded-2xl h-12 sm:h-8 text-[#201747] w-3/5"
                    >
                      <form
                        class="flex items-center w-full"
                        @submit.prevent="openChat"
                      >
                        <input
                          type="text"
                          placeholder="Have a question? Want an answer with a gender lens? Ask Jessie!"
                          v-model="chatMessage"
                          class="flex-1 border-none bg-transparent text-inherit p-2.5 pl-5 outline-none text-lg sm:text-xs"
                        />
                        <button
                          type="submit"
                          class="bg-transparent border-none text-inherit flex items-center cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-10 h-10 sm:w-6 sm:h-6 mr-2"
                          >
                            <path
                              d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
                            />
                          </svg>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-row items-center justify-end h-full relative w-5/12"
                >
                  <!--When uncomment download div, change w-3/12 to w-5/12-->
                  <div
                    class="relative flex flex-row items-center justify-center w-6/12 mr-4"
                    v-on:clickout="downloadPopup = false"
                  >
                    <button
                      style="background-color: #201747"
                      class="px-3 py-2 text-xs font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2"
                      v-if="
                        !this.$route.path.includes('gbv-risk') &&
                        !this.$route.path.includes('methodology') &&
                        !this.$route.path.includes('choose-country') &&
                        !this.$route.path.includes('choose-sector') &&
                        this.$route.path != '/gesi-analysis' &&
                        (this.$route.path.includes('gesi-analysis') ||
                          this.$route.path.includes('reports'))
                      "
                      @click="requestCSVData()"
                      id="downloadCSV"
                    >
                      <span>Request Data</span>
                    </button>
                    <button
                      style="background-color: #201747"
                      class="px-3 py-2 text-xs font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2"
                      v-if="
                        canDownload && !this.$route.path.includes('gbv-risk')
                      "
                      @click="
                        optionsPopup = false;
                        downloadPopup = !downloadPopup;
                      "
                      id="downloadpdf"
                    >
                      <!--button style="background-color: #201747;" class="px-3 py-2 text-xs font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white hover:text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2" id="downloadpdf"-->
                      <span>Download</span>
                      <svg
                        class="w-5 h-5"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                        <g
                          stroke-linecap="round"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linejoin="round"
                        >
                          <path d="M12,17v-14"></path>
                          <path
                            d="M21,17c0,2.209 -1.791,4 -4,4h-10c-2.209,0 -4,-1.791 -4,-4"
                          ></path>
                          <path d="M17,12l-5.001,5.001l-5,-5.001"></path>
                        </g>
                        <path fill="none" d="M0,0h24v24h-24Z"></path>
                      </svg>
                    </button>

                    <!--DOWNLOAD DROPDOWN-->
                    <transition name="fadeIn-faster" mode="out-in">
                      <div
                        class="absolute w-48 bg-white z-50 shadow-xl rounded-lg border border-gray-200 top-0 mt-10 overflow-hidden"
                        v-if="downloadPopup"
                      >
                        <div
                          class="w-full h-full flex flex-col justify-start items-start text-xs"
                        >
                          <button
                            v-on:clickout="downloadPopup1 = false"
                            v-if="
                              canDownload &&
                              !this.$route.path.includes('gbv-risk') &&
                              !this.$route.path.includes('gesi-analysis') &&
                              !this.$route.path.includes('reports')
                            "
                            @click="getCSVNew(iso2)"
                            class="w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 transition duration-200"
                            id="downloadpdf"
                            style="color: rgb(32, 23, 71)"
                          >
                            .CSV of data
                          </button>

                          <!-- @click="getPdf('domain', 'summary', iso2, 'taap', subItem)" -->
                          <button
                            v-if="href.indexOf('domain') > -1 && subItem"
                            style="cursor: not-allowed"
                            class="tooltip w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 transition duration-200 pointer-events-none"
                          >
                            summary - PDF
                            <span class="tooltiptext"
                              >Updated version coming soon!</span
                            >
                          </button>
                          <button
                            v-if="href.indexOf('theme') > -1 && subItem"
                            style="cursor: not-allowed"
                            class="tooltip w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 transition duration-200 pointer-events-none"
                          >
                            Summary - PDF
                            <span class="tooltiptext"
                              >Updated version coming soon!</span
                            >
                          </button>
                          <!-- hide this button as per this task (Hide the "Summary-PDF" Download button from GESI tool) -->
                          <button
                            v-if="false"
                            style="cursor: not-allowed"
                            class="tooltip w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 transition duration-200"
                          >
                            Summary - PDF
                            <span class="tooltiptext"
                              >Updated version coming soon!</span
                            >
                          </button>
                          <!-- bibliography button -->
                          <button
                            v-if="
                              bibliography &&
                              bibliography.country &&
                              (this.$route.path.includes('gesi-analysis') ||
                                this.$route.path.includes('domains') ||
                                this.$route.path.includes('themes'))
                            "
                            @click="exportBibliography()"
                            class="w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 transition duration-200"
                            id="downloadBibliography"
                            style="cursor: pointer; color: rgb(32, 23, 71)"
                          >
                            Sources Cited
                          </button>
                        </div>
                      </div>
                    </transition>
                  </div>
                  <button
                    v-if="
                      user.admin &&
                      user.organization.stripeCustomerId &&
                      user.organization.subscriptionStatus == 'Subscribed'
                    "
                    @click="redirectforcustomerportal()"
                    style="
                      background-color: #fe5000;
                      color: white !important;
                      cursor: pointer;
                    "
                    class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center"
                  >
                    <span style="text-align: center">Subscriptions</span>
                  </button>
                  <button
                    v-else-if="
                      user.admin &&
                      !user.organization.stripeCustomerId &&
                      user.organization.subscriptionStatus != 'Subscribed'
                    "
                    @click="redirecttoupgrade()"
                    style="background-color: #fe5000; color: white !important"
                    class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center"
                  >
                    <span>Upgrade</span>
                  </button>
                  <button
                    v-else-if="
                      user.admin &&
                      !user.organization.stripeCustomerId &&
                      user.organization.subscriptionStatus == 'Subscribed'
                    "
                    @click="redirectToEquilo()"
                    style="background-color: #fe5000; color: white !important"
                    class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center"
                  >
                    <span>Upgrade/Manage Billing</span>
                  </button>
                  <div class="relative" v-on:clickout="optionsPopup = false">
                    <button
                      class="ml-4 px-1 py-1 bg-gray-100 hover:bg-white flex flex-row items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none"
                      @click="optionsPopup = !optionsPopup"
                    >
                      <div class="rounded-full h-5 w-5 overflow-hidden">
                        <img
                          src="@/assets/images/profile7.png"
                          alt="ProfileIcon"
                          class="visibility-icon"
                        />
                      </div>
                    </button>

                    <!-- DROPDOWN-->
                    <transition name="fadeIn-faster" mode="out-in">
                      <div
                        ref="dropdown"
                        class="absolute bg-white z-50 shadow-xl rounded-lg border border-gray-200 top-0 mt-10 left-0 overflow-hidden"
                        v-if="optionsPopup"
                        style="width: 9rem; left: -125px"
                      >
                        <div
                          class="w-full h-full flex flex-col justify-start items-start text-xs"
                        >
                          <div
                            @click="goToMyAccount()"
                            class="cursor-pointer w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 hover:text-indigo-900 transition duration-200"
                          >
                            My Account
                          </div>
                          <logoutPage style="width: 100%"></logoutPage>
                        </div>
                      </div>
                    </transition>

                    <!-- PRICING DROPDOWN-->
                    <transition name="fadeIn-faster" mode="out-in">
                      <div
                        ref="dropdown"
                        v-if="pricingOptionPopup"
                        class="absolute bg-white z-50 shadow-xl rounded-lg border border-gray-200 top-0 mt-10 left-0 overflow-hidden"
                        style="width: 9rem; left: -125px"
                      ></div>
                    </transition>
                  </div>
                  <!-- Change Terms modal -->
                  <div data-modal="accept-terms" v-if="showTermsModal">
                    <div
                      class="fixed top-0 left-0 w-screen h-screen z-10 bg-white"
                      id="notLoaded"
                      v-if="applyTermsPreloader"
                    >
                      <page-loader></page-loader>
                    </div>
                    <div class="overlay"></div>
                    <div class="wr">
                      <div class="quote">{{ termsMessage }}</div>
                      <div
                        :class="
                          'download-links' +
                          ' ' +
                          (termsMessage === '' ? 'not-message' : '')
                        "
                      >
                        <a
                          :href="downloadTermsPdfUrl"
                          ref="download-link"
                          @click.prevent="downloadItem(termsName)"
                          >Download PDF version</a
                        >
                      </div>
                      <div class="content-wr" v-html="termsText"></div>
                      <div class="btn-wr">
                        <div class="btn white" @click="logout">Logout</div>
                        <div class="btn" @click="acceptTerms">Apply</div>
                      </div>
                    </div>
                  </div>
                  <!-- Language Modal -->
                  <div class="relative" v-on:clickout="showLangModal = false">
                    <button
                      class="tooltip px-1 py-1 bg-gray-100 hover:bg-white flex flex-row items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none ml-2"
                      @click="openLangModal()"
                    >
                      <span class="lang-tooltiptext notranslate">{{
                        user?.languagePreference
                      }}</span>
                      <div class="rounded-full h-5 w-5 overflow-hidden">
                        <img
                          src="@/assets/images/language-translation.svg"
                          alt="ProfileIcon"
                          class="visibility-icon"
                        />
                      </div>
                    </button>

                    <transition
                      name="fadeIn-faster"
                      mode="out-in"
                      v-if="showLangModal"
                    >
                      <div
                        class="modal absolute bg-white z-50 shadow-xl rounded-lg border border-gray-200 overflow-hidden"
                      >
                        <div
                          class="w-full h-full flex flex-col justify-start items-start text-xs"
                        >
                          <dd
                            class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 set-input-right"
                          >
                            <input
                              v-model="search_language"
                              type="text"
                              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full"
                              placeholder="Search here..."
                            />
                          </dd>
                        </div>
                        <div
                          class="w-full flex flex-col justify-start items-start text-xs"
                          :class="{ 'scroll-class': search_language == '' }"
                        >
                          <ul class="lang-modal notranslate">
                            <li
                              v-if="filteredLangList?.length == 0"
                              class="font-bold text-sm leading-7 font-medium text-center leading-7 font-medium text-gray-600 not-found-lang"
                            >
                              Not Found Matching Language
                            </li>
                            <li
                              v-for="(lang, i) in filteredLangList"
                              :key="i"
                              :value="lang"
                              @click="selectedLang(lang)"
                              v-else
                            >
                              <a
                                href="javascript:void(0);"
                                :class="{
                                  'font-bold': lang == user?.languagePreference,
                                }"
                                class="text-sm leading-7 font-medium gap-10 text-justify leading-7 font-medium text-gray-600"
                                style="display: flex"
                                >{{ lang }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <button
                    class="w-48 px-1 py-1 bg-gray-100 hover:bg-white flex items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none ml-2"
                    :class="{ 'bg-[#201747]': isChatOpen }"
                    v-if="
                      !isChatOpen &&
                      this.$route.path !== '/chatbot' &&
                      user &&
                      user.featureFlags.includes(FeatureFlags.CHATBOT)
                    "
                    @click="openChatModal()"
                  >
                    <div class="rounded-full h-5 w-5 overflow-hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                        :class="isChatOpen ? 'text-white' : 'text-[#201747]'"
                      >
                        <path
                          :fill="isChatOpen ? 'white' : 'black'"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                        />
                      </svg>
                    </div>
                    <div class="px-1 text-lg sm:text-xs font-semibold w-max">
                      Chat with Jessie
                    </div>
                  </button>
                  <router-link :to="{ name: 'homePage' }">
                    <button
                      class="tooltip mt-1 sm:mt-0.5 px-1 py-1 bg-gray-100 hover:bg-white flex flex-row items-center focus:shadow-outline-indigo justify-center rounded-lg transition duration-200 shadow border border-gray-300 focus:outline-none ml-2"
                      v-if="this.$route.path === '/chatbot'"
                    >
                      <span class="lang-tooltiptext notranslate w-[150px]"
                        >Back to Dashboard</span
                      >
                      <div
                        class="rounded-full h-7 w-7 sm:h-5 sm:w-5 overflow-hidden"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2.5"
                          stroke="currentColor"
                          class="w-7 h-7 sm:w-5 sm:h-5 text-[#201747]"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                          />
                        </svg>
                      </div>
                    </button>
                  </router-link>
                </div>
              </div>

              <!-- Right side floating bar -->
              <div
                @click="openLink()"
                v-if="
                  !(
                    this.$route.path == '/' ||
                    this.$route.path == '/tutorials' ||
                    this.$route.path == '/support-feedback' ||
                    this.$route.path == '/settings/account' ||
                    this.$route.path ==
                      '/2X-Ecosystem/technical-service-list' ||
                    this.$route.path == '/2X-Ecosystem/submitTSP' ||
                    this.$route.path == '/settings/gbv_api_settings' ||
                    this.$route.path == '/settings/gesi_api_settings' ||
                    this.$route.path ==
                      '/settings/twox_portfolio_api_settings' ||
                    this.$route.path == '/settings/roi_api_settings' ||
                    this.$route.path == '/gesi-upgrade'
                  )
                "
                class="feedback"
                :class="{ 'right-[25%]': isChatOpen }"
              >
                Feedback
              </div>
              <a
                href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                target="_blank"
                v-if="this.user && !(this.$route.path == '/gesi-upgrade')"
                class="support"
                :class="{ 'right-[25%]': isChatOpen }"
                >Support</a
              >
              <div
                @click="openHelpCenter()"
                class="help-center"
                :class="{ 'right-[25%]': isChatOpen }"
              >
                Help Center
              </div>
              <a
                v-bind:href="dataPrivacyUrl"
                target="_blank"
                v-if="this.user && displayDataPrivacy"
                class="data-privacy"
                :class="{ 'right-[25%]': isChatOpen }"
                >Data Privacy</a
              >
              <router-link
                to="/2X-Certification-Privacy-Notice"
                target="_blank"
                v-if="user && displayPrivacyNoticeForTwoX"
                class="data-privacy"
                >Privacy Notice
              </router-link>
              <!-- End -->
              <!-- Left side floating social icon bar -->
              <div class="left-social-icons-container" style="display: none">
                <!--need to add in bottom added display none-->
                <ul class="left-social-icons">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/equilo/"
                      target="_blank"
                      class="linkedin"
                      ><span>Linkedin</span>
                      <i class="entypo-linkedin"></i>
                      <img src="@/assets/images/linkedin.png" alt="linkedin"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/equilo.io"
                      target="_blank"
                      class="facebook"
                      ><span>Facebook</span><i class="entypo-facebook"></i>
                      <img
                        src="@/assets/images/facebook-icon.jpg"
                        alt="Facebook"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/equilo_io"
                      target="_blank"
                      class="twitter"
                      ><span>Twitter</span><i class="entypo-twitter"></i>
                      <img src="@/assets/images/twitter.png" alt="Twitter"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/equilo.io/?hl=en"
                      target="_blank"
                      class="instagram"
                      ><span>Instagram</span><i class="entypo-instagram"></i>
                      <img
                        src="@/assets/images/instagram-icon.png"
                        alt="Instagram"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="https://airtable.com/shryJ1jKTDYU9vQcG"
                      target="_blank"
                      class="subscribe"
                      ><span>Subscribe</span><i class="entypo-subscribe"></i>
                      <img
                        class="subscribe-email-image"
                        src="@/assets/images/Earth.png"
                        alt="Subscribe"
                    /></a>
                  </li>
                </ul>
              </div>
              <!-- End -->
            </div>
            <!--Hover Box-->
            <HoverBox :key="updateKey" />
            <!--Downloading-->
            <div
              class="fixed top-0 left-0 w-screen h-screen z-10 bg-white"
              id="notLoaded"
              v-if="downloading"
            >
              <page-loader></page-loader>
            </div>
          </template>
          <transition name="fadeQuick" mode="out-in">
            <router-view
              id="principal-route-view"
              class="body"
              :class="{ 'px-4': isChatOpen }"
              style="min-height: 100vh"
              v-if="!downloading"
            ></router-view>
          </transition>
          <template v-if="!hideHeaderFooter">
            <passwordPolicyModalComponent
              v-show="isPasswordPolicyModalVisible"
              @close="closeModal"
            >
            </passwordPolicyModalComponent>
            <!-- <div id="print-foot">
            </div> -->
            <translate-modal
              :languagePreference="user?.languagePreference"
            ></translate-modal>

            <!-- 2x Certification Disclaimer-->
            <twox-disclaimer
              v-if="show2xDisclaimer"
              :openTwoxDisclaimer="openTwoxDisclaimer"
              @setDisclaimer="setTwoXDisclaimer()"
            />
            <footerBar
              v-if="this.user"
              @open2xDisclaimer="open2xDisclaimerFromParent()"
            ></footerBar>
            <div
              class="fixed z-50 inset-0 overflow-y-auto csv_request_modal"
              v-if="isCSVRequestSuccess"
            >
              <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              >
                <div class="fixed inset-0 transition-opacity cursor-pointer">
                  <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                ></span
                >&#8203;
                <div
                  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                  style="max-width: 630px; width: 100%"
                >
                  <span
                    class="close"
                    @click="closeRequestModal()"
                    style="right: 12px"
                    >&times;</span
                  >
                  <div
                    class="Modal_Readmorestyle"
                    style="margin: 45px; padding: 0"
                  >
                    <div class="ModalInnerStyle" style="padding: 0">
                      <p style="font-weight: 600" class="">
                        Thank you for your request. We will share the requested
                        raw data with you via email shortly.
                      </p>
                      <button
                        style="background-color: #fe5000; color: white"
                        class="text-white px-4 py-4 text-medium gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-5"
                        @click="closeRequestModal()"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fixed z-50 inset-0 overflow-y-auto csv_request_modal"
              v-if="isCSVRequestFail"
            >
              <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              >
                <div class="fixed inset-0 transition-opacity cursor-pointer">
                  <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                ></span
                >&#8203;
                <div
                  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                  style="max-width: 630px; width: 100%"
                >
                  <span
                    class="close"
                    @click="closeRequestModal()"
                    style="right: 12px"
                    >&times;</span
                  >
                  <div
                    class="Modal_Readmorestyle"
                    style="margin: 45px; padding: 0"
                  >
                    <div class="ModalInnerStyle" style="padding: 0">
                      <p style="font-weight: 600" class="">
                        Thank you for your interest in accessing our data. We
                        value your enthusiasm for gender equality, however, in
                        adherence to the fair use principle, we regret to inform
                        you that you have reached today's download limit. If you
                        have any specific data requirements or questions, please
                        feel free to
                        <a
                          href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                          target="_blank"
                          style="color: #0077c8"
                        >
                          contact our support team</a
                        >
                        for further assistance.
                      </p>
                      <button
                        style="background-color: #fe5000; color: white"
                        class="text-white px-4 py-4 text-medium gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-5"
                        @click="closeRequestModal()"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div
          :class="{
            'w-3/12 h-full': isChatOpen,
          }"
          v-if="isChatOpen"
        >
          <chatbot />
        </div>
      </template>
    </div>
    <feedback-modal :isLink="isAirtableLink" @close="closeFeedBackModal" />

    <CookiePolicy :user="user" @cookie-policy-rejected="logout" />
  </div>
</template>

<script>
import axios from "axios";
import pageLoader from "@/components/page-loader.vue";
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import TranslateModal from "@/pages/backups/LangTranslator";
import backgroundImagePath from "@/assets/images/paper-stamp.jpg";
import footerBar from "@/components/footer.vue";
import passwordPolicyModalComponent from "@/components/passwordPolicyModal.vue";
import logoutPage from "@/pages/reports/logoutPage.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import HoverBox from "./HoverBox.vue";
import "./scss/reset.scss";
import FeedbackModal from "./components/feedback-modal.vue";
import TwoxDisclaimer from "./components/2xDisclaimer.vue";
import { useUserStore } from "@/stores/user";
import Sectors from "@/helpers/sectors";
import CookiePolicy from "@/components/CookiePolicy.vue";
import { analyticsService } from "@/services/analyticsService";
import chatbot from "@/components/chat/sideBarChatbot.vue";
import { useChatStore } from "./stores/chat";
import { mapState } from "pinia";
import { FeatureFlags } from "@/helpers/enums";

export default {
  name: "app",
  data() {
    return {
      chatMessage: "",
      showLangModal: false,
      search_language: "",
      listOfLanguage: [],
      loading: true,
      print: false,
      env,
      downloadPopup: false,
      downloadPopupCSV: false,
      showTermsModal: false,
      optionsPopup: false,
      pricingOptionPopup: false,
      href: null,
      iso2: null,
      subItem: null,
      downloading: false,
      applyTermsPreloader: false,
      subscription: true,
      backgroundImagePath,
      downloadurl: "",
      savetodrive: false,
      isPasswordPolicyModalVisible: false,
      displayDataPrivacy: false,
      dataPrivacyUrl: "/#/dataprivacy",
      termsText: "",
      downloadTermsPdfUrl: "",
      termsName: "",
      termsMessage: "",
      updateKey: 0,
      csvRequestFlag: "",
      isCSVRequestSuccess: false,
      isCSVRequestFail: false,
      isAirtableLink: null,
      user: null,
      openTwoxDisclaimer: false,
    };
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  methods: {
    openChat() {
      console.log("calling open chat..", this.chatMessage);
      if (this.chatMessage) {
        const chatStore = useChatStore();
        chatStore.messsage = this.chatMessage;
        this.chatMessage = "";
        chatStore.isChatOpen = true;
      }
    },
    openChatModal() {
      const chatStore = useChatStore();
      chatStore.isChatOpen = true;
    },
    setTwoXDisclaimer(value) {
      this.openTwoxDisclaimer = value;
    },
    open2xDisclaimerFromParent() {
      this.openTwoxDisclaimer = !this.openTwoxDisclaimer;
    },
    closeRequestModal() {
      this.isCSVRequestFail = false;
      this.isCSVRequestSuccess = false;
    },
    async requestCSVData() {
      const sector = document.location.href.split("/")[6];
      const url = `${env.gbvurl}/${this.$route.params.iso2}/${sector}`;
      try {
        const res = await axios.get(
          `${env.apiUrl}/gesi/csvRequestChek?url=${url}&tokenCode=${Cookies.get(
            "session"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.csvRequestFlag = res.data.data.access;
        this.isCSVRequestSuccess = this.csvRequestFlag;
        this.isCSVRequestFail = !this.csvRequestFlag;
      } catch (error) {
        console.error(error);
      }
    },
    async downloadItem(label) {
      const link = this.$refs["download-link"];
      const url = link.getAttribute("href");
      try {
        const response = await axios.get(url, { responseType: "blob" });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = label;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      } catch (error) {
        console.error(error);
      }
    },
    exportBibliography() {
      if (this.bibliography.data && this.bibliography.data.length) {
        const multiColumn =
          this.bibliography.country.length > 1
            ? ["Multiple Countries", this.bibliography.country.join(",")]
            : null;
        const column = ["Citation Number", "Source Text", "Source URL"];
        this.bibliography.data.unshift(column);
        if (multiColumn) {
          this.bibliography.data.unshift("", multiColumn);
        }
        const wb = XLSX.utils.book_new();
        const CSV = XLSX.utils.json_to_sheet(this.bibliography.data);
        this.deleteRow(CSV, 0);
        XLSX.utils.book_append_sheet(wb, CSV, "Bibliography");
        XLSX.writeFile(wb, "Sources Cited.XLSX");
      }
    },
    ec(r, c) {
      return XLSX.utils.encode_cell({ r, c });
    },
    deleteRow(ws, row_index) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = row_index; R < range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
        }
      }
      range.e.r--;
      ws["!ref"] = XLSX.utils.encode_range(range.s, range.e);
    },
    goToMyAccount() {
      if (this.$route.path !== "/settings/account") {
        this.$router.push({ path: "/settings/account" });
      }
      this.optionsPopup = false;
    },
    downloadCSV() {},
    openLangModal() {
      this.showLangModal = !this.showLangModal;
      this.search_language = "";
    },
    async selectedLang(lang) {
      this.user.languagePreference = lang;
      try {
        const res = await axios.put(
          `${env.apiUrl}/users/me`,
          {
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email,
            picture: this.profilePic,
            languagePreference: this.user?.languagePreference,
            consent: this.user.consent,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        if (res.data.ok) {
          if (this.$router.currentRoute.name === "settings") {
            document.location.reload();
          } else {
            this.user =
              this.userStore.user ||
              (await this.userStore.fetchUser(false, true));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async logout() {
      this.loading = true;
      const sessionID = Cookies.get("session");
      const deviceID = localStorage.getItem("deviceToken");
      try {
        await axios.post(
          `${env.apiUrl}/users/me/logout`,
          { sessionId: sessionID, deviceId: deviceID },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        if (!localStorage.getItem("isLogout")) {
          localStorage.setItem("isLogout", true);
        }
        this.$store.commit("logout");
        this.loading = false;
        this.optionsPopup = false;
      } catch (error) {
        console.error(error);
      }
    },
    async acceptTerms() {
      this.applyTermsPreloader = true;
      try {
        const response = await axios.patch(
          `${env.apiUrl}/users/update-terms/${this.user?._id}`,
          { termsAgreement: true },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.applyTermsPreloader = false;
        if (response.data.updatedData.termsAgreement === true) {
          this.showTermsModal = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCSVNew() {
      const sector = document.location.href.split("/")[6];
      const url = `${env.gbvurl}/${
        this.$route.params.iso2
      }/${sector}?token=${Cookies.get("session")}`;
      window.open(url, "_blank");
    },
    async getCSV(type, subtype, country) {
      this.downloading = true;
      const sector = document.location.href.split("/")[6];
      const email = this.$store.state.user.email;
      try {
        const res = await axios.get(
          `${
            env.apiUrl
          }/csvapi2/ai?country=${country}&sector=${sector}&emailId=${email
            .toString()
            .split("&")
            .join("___")}`
        );
        await axios.post(`${env.apiUrl}/csvapi2/processfurther`, {
          var_names: res.data.unique_var_names,
          sector,
          country,
          region: res.data.region,
        });
        this.downloading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getPdf(type, subtype, country, extra, extraContent) {
      const sector = document.location.href.split("/")[6];
      const email = this.$store.state.user.email;
      this.$swal.fire({
        icon: "success",
        text: "Your PDF is on the way. Please check your email inbox in a few minutes.",
        showConfirmButton: true,
      });
      const url = extra
        ? `${
            env.printUrl
          }/pdfapi/${type}/${subtype}?country=${country}&sector=${sector}&emailId=${email}&${extra}=${extraContent
            .toString()
            .split("&")
            .join("___")}`
        : `${env.printUrl}/pdfapi/${type}/${subtype}?country=${country}&sector=${sector}&emailId=${email}`;
      try {
        await axios.get(url, { responseType: "blob" });
      } catch (error) {
        console.error(error);
      }
    },
    openLink() {
      const path = this.$route.path;
      const airtableLinks = {
        "gbv-risk":
          "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        gbv: "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        true: "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        "gesi-analysis":
          "https://airtable.com/embed/shrZKKxEv31pKr31x?backgroundColor=purple",
        reports:
          "https://airtable.com/embed/shrZKKxEv31pKr31x?backgroundColor=purple",
        roi: "https://airtable.com/shrwLpbGTYawS4fYj",
        gbvh: "https://airtable.com/shrWy5dAIwfTnSBVx",
        GenderSmartTools: "https://airtable.com/shrWy5dAIwfTnSBVx",
        "2X-Challenge":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagW3hU7kOmL86LBF/form",
        "submit-deal":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagW3hU7kOmL86LBF/form",
        "investor-disclosures":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagW3hU7kOmL86LBF/form",
        "challenge-deals":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagW3hU7kOmL86LBF/form",
        "2X-Ecosystem/2X-Certification":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagyeAU9sz3w4i0VT/form",
        "2X-Challenge/2X-Challenge-Self-Assessment":
          "https://airtable.com/shrWy5dAIwfTnSBVx",
        "2X-Ecosystem/Thresholds":
          "https://airtable.com/embed/appXzBjEJbD3UkSil/pagyeAU9sz3w4i0VT/form",
        "2X-Ecosystem":
          "https://airtable.com/embed/apprV3rQn0Kmd1U3c/shrLs6ZA6tdZsyHsJ",
        supergirl:
          "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        "partner-directory":
          "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        "global-analysis":
          "https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple",
        "choose-country":
          "https://airtable.com/embed/shrZKKxEv31pKr31x?backgroundColor=purple",
      };
      const defaultLink = "https://airtable.com/shrWy5dAIwfTnSBVx";
      const link = Object.keys(airtableLinks).find((key) => path.includes(key));
      window.open(airtableLinks[link] || defaultLink, "_blank");
    },
    openHelpCenter() {
      const path = this.$route.path;
      const links = {
        "/2x-challenge": "https://help.equilo.io/en/category/2x-challenge",
        "/2x-certification":
          "https://help.equilo.io/en/category/Get-2x-certified",
        "/roi": "https://help.equilo.io/en/category/gender-roi",
        "/gesi-analysis":
          "https://help.equilo.io/en/category/GESI-Context-analysis",
        "/gbv-risk":
          "https://help.equilo.io/en/category/gbv-risk-score-for-financial-actors",
        "/ggsf":
          "https://help.equilo.io/en/category/ggsf-global-gender-smart-fund",
      };
      const defaultLink = "https://help.equilo.io/en";
      console.log("testing help center", path);
      let pathLower = path.toLowerCase();
      const link =
        Object.keys(links).find((key) => pathLower.includes(key)) || null;
      let finalLink = link ? links[link] : defaultLink;
      console.log("testing help center link", links[link]);
      window.open(finalLink, "_blank");
    },
    closeModal() {
      localStorage.setItem("passwordPolicyModal", true);
      this.isPasswordPolicyModalVisible = false;
    },
    closeFeedBackModal() {
      this.isAirtableLink = null;
    },
    openModal() {
      const excludedPaths = [
        "login",
        "signup",
        "invite",
        "reset-password",
        "not-allowed",
        "dataprivacy",
        "cookies-policy",
        "maintenance",
        "thresholds-global",
      ];
      if (
        !localStorage.getItem("passwordPolicyModal") &&
        !excludedPaths.some((path) => this.$route.path.includes(path))
      ) {
        this.isPasswordPolicyModalVisible = true;
      }
    },
    async planfamily() {
      try {
        const res = await axios.get(
          `${env.apiUrl}/subscriptions/get-product-family/`
        );
        return res.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async checkAndUpdateIsActive() {
      if (!localStorage.getItem("lastActive")) {
        try {
          const res = await axios.put(
            `${env.apiUrl}/users/update-last-active`,
            { currentTime: new Date() },
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
            }
          );
          localStorage.setItem("lastActive", res.data.updatedDate);
        } catch (error) {
          console.error(error);
        }
      } else {
        const currentTime = new Date().getTime();
        let updatedTime = new Date(
          localStorage.getItem("lastActive")
        ).getTime();
        updatedTime = new Date(updatedTime + 1 * 60 * 60 * 1000).getTime();
        if (updatedTime < currentTime) {
          try {
            const res = await axios.put(
              `${env.apiUrl}/users/update-last-active`,
              { currentTime: new Date() },
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("session")}`,
                },
              }
            );
            localStorage.setItem("lastActive", res.data.updatedDate);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    async redirectforcustomerportal() {
      try {
        const res = await axios.post(
          `${env.apiUrl}/payments/create-customer-portal-session`,
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        window.location.href = res.data.data.url;
      } catch (error) {
        console.error(error);
      }
    },
    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },
  },
  components: {
    pageLoader,
    footerBar,
    passwordPolicyModalComponent,
    logoutPage,
    TranslateModal,
    HoverBox,
    FeedbackModal,
    chatbot,
    TwoxDisclaimer,
    CookiePolicy,
  },
  computed: {
    FeatureFlags() {
      return FeatureFlags;
    },
    ...mapState(useChatStore, ["isChatOpen"]),
    displayPrivacyNoticeForTwoX() {
      return (
        this.$route.path.includes("/2X-Certification") &&
        !this.$route.path.includes("/2X-Certification-Privacy-Notice")
      );
    },
    bibliography() {
      return this.$store.state.bibliographyJson;
    },
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    },
    canDownload() {
      return this.$store.state.showDownloadOptions;
    },
    isFree() {
      return this.$store.state.isFree;
    },
    hideHeaderFooter() {
      return this.$route.meta.hideHeaderFooter || false;
    },
    filteredLangList() {
      if (this.search_language) {
        return this.listOfLanguage?.filter((item) =>
          this.search_language
            .toLowerCase()
            .split(" ")
            .every((v) => item.toLowerCase().includes(v))
        );
      } else {
        return this.listOfLanguage;
      }
    },
    shownavbar() {
      const excludedPaths = [
        "/login",
        "/reset-password",
        "invite",
        "print",
        "maintenance",
        "thresholds-global",
      ];
      return !excludedPaths.some((path) => this.$route.path.includes(path));
    },
    show2xDisclaimer() {
      return (
        this.$route.path.includes("2X-Certification") ||
        this.$route.path.includes("score-card")
      );
    },
  },
  watch: {
    $route() {
      this.$store.commit("resetBreadcrumbs");
      if (this.user?._id) {
        this.checkAndUpdateIsActive();
      }
      if (!localStorage.getItem("2x-disclaimer-accepted")) {
        this.openTwoxDisclaimer = true;
      }
      this.updateKey++;
      document.querySelector("#intersection-info").style.display = "none";
      this.href = document.location.href;

      if (window.pa && window.pa.pageview) {
        window.pa.pageview(); // pro analytics tracking.
      } else {
        console.error("Error. Missing PA lib.");
      }

      if (
        document.location.href &&
        document.location.href.indexOf("/gesi-analysis") > -1
      ) {
        this.iso2 = document.location.href
          .split("/gesi-analysis/")[1]
          .split("/")[0];

        if (document.location.href.indexOf("themes") > -1) {
          const theme = document.location.href.split("themes/")[1];
          if (theme) {
            this.subItem = theme;
          }
        } else if (document.location.href.indexOf("domains") > -1) {
          const domain = document.location.href.split("domains/")[1];
          if (domain) {
            this.subItem = domain;
          }
        } else {
          this.subItem = null;
        }
      }
      if (
        document.location.hash != "#/2X-Challenge/2X-Challenge-Self-Assessment"
      ) {
        sessionStorage.clear();
      }
    },
  },
  async created() {
    await this.userStore.fetchUser(false, true);
    this.user = this.userStore.user;
    if (!this.user?.termsAgreement || this.user?.termsAgreement !== true) {
      this.showTermsModal = true;
    }
    if (this.user !== null) {
      try {
        const response = await axios.get(
          env.apiUrl + "/users/get-terms/userTerms",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.termsText = response.data.terms.markup;
        this.downloadTermsPdfUrl = response.data.terms.downloadURL;
        this.termsName = response.data.terms.termsName;
        this.termsMessage = response.data.terms.message;
      } catch (error) {
        console.error(error);
      }
    }
    if (this.user?._id) {
      this.checkAndUpdateIsActive();
    }

    this.listOfLanguage = this.user?.languagePreferenceList;

    const excludedPaths = [
      "/maintenance",
      "/2X-Ecosystem/thresholds-global",
      "/login",
      "/signup",
      "/cookies-policy",
      "/set-password",
    ];
    if (
      !excludedPaths.some((path) => this.$route.path.includes(path)) &&
      !this.$route.path.includes("/reset-password")
    ) {
      this.$store.dispatch("fetchIPCodes");
    }

    if (
      location.protocol !== "https:" &&
      !document.location.href.includes("localhost")
    ) {
      //document.location.href = document.location.href.replace("http://","https://");
    }

    if (document.location.href.includes("print=true")) {
      this.print = true;
    }

    this.href = document.location.href;
    if (document.location.href.includes("/gesi-analysis")) {
      this.iso2 = document.location.href
        .split("/gesi-analysis/")[1]
        .split("/")[0];
      if (document.location.href.includes("themes")) {
        const theme = document.location.href.split("themes/")[1];
        if (theme) {
          this.subItem = theme;
        }
      } else if (document.location.href.includes("domains")) {
        const domain = document.location.href.split("domains/")[1];
        if (domain) {
          this.subItem = domain;
        }
      } else {
        this.subItem = null;
      }
    }

    if (!this.print) {
      if (!this.user || !this.user?._id) {
        const excludedPaths = [
          "password",
          "invite",
          "print",
          "login",
          "signup",
          "cookies-policy",
          "maintenance",
          "thresholds-global",
        ];
        if (!excludedPaths.some((path) => this.href.includes(path))) {
          this.$router.replace({ name: "login" });
          this.loading = false;
        } else {
          this.loading = false;
        }
      } else {
        this.$store.commit("setUser", this.user);
        this.loading = false;
      }
    }
    if (this.iso2 !== "EQ") {
      const sectorfordownload = document.location.href.split("/")[6];
      this.downloadUrl =
        env.gbvurl +
        "/" +
        this.iso2 +
        "/" +
        sectorfordownload +
        "?token=" +
        Cookies.get("session");
      this.savetodrive = true;
    }
    if (this.user && this.user.organization) {
      analyticsService.identify(this.user.id, {
        name: this.user.firstname.trim() + " " + this.user.lastname.trim(),
        email: this.user.email,
      });

      this.$gtag.event("teams", {
        page_name: this.$route.name,
        userId: this.user?._id,
        team_id: this.user?.organization?._id,
        organizationName: this.user?.organization?.name,
      });
    }
    let product = "";
    if (this.$route.path.includes("/roi")) {
      product = "Gender ROI™ Assessment";
    }
    if (
      this.$route.path.includes("/gesi-analysis") ||
      this.$route.path.includes("/themes") ||
      this.$route.path.includes("/domains") ||
      this.$route.path.includes("/what-works")
    ) {
      product = "GESI Contextual Analysis";
    }
    if (this.$route.path.includes("/global-analysis")) {
      product = "Global Analysis";
    }
    if (this.$route.path.includes("/gbv-risk")) {
      product = "GBV Risk Score";
    }
    if (this.$route.path.includes("/2X-Ecosystem")) {
      product = "2X Gender Smart Investment Ecosystem";
    }
    if (this.$route.path.includes("/supergirl")) {
      product = "Supergirl";
    }
    if (this.$route.path.includes("/pricings")) {
      product = "Pricing";
    }
    if (this.$route.path.includes("/settings")) {
      product = "Settings";
    }
    if (this.$route.path.includes("/GenderSmartTools")) {
      product = "Gender Smart Tools";
    }
    if (this.$route.path.includes("/gbvh")) {
      product = "GBVH Roadmap Assessment";
    }
    if (this.$route.path.includes("/partner-directory")) {
      product = "Partner Directory";
    }
    if (this.$route.path.includes("/tutorials")) {
      product = "Tutorials";
    }
    if (this.$route.path.includes("/support-feedback")) {
      product = "Support and Feedback";
    }
    if (this.user && this.user.organization) {
      this.$gtag.event("products", {
        page_name: this.$route.name,
        userId: this.user?._id,
        product_name: product,
        team_id: this.user?.organization?._id,
        organizationName: this.user.organization.name,
      });
    }
  },
  async mounted() {
    this.openModal();
    this.iso2 = this.$route.params.iso2;
    await this.$store.commit("setSectorsData", Sectors);
    // if we have previous url in local then redirect user to the page
    if (localStorage.getItem("previousURL")) {
      this.$router.push(localStorage.getItem("previousURL")).then(() => {
        // Remove previous url from local storage if user already on that page
        if (localStorage.getItem("previousURL") == this.$route.fullPath) {
          localStorage.removeItem("previousURL");
        }
      });
    }
    if (!localStorage.getItem("2x-disclaimer-accepted")) {
      this.openTwoxDisclaimer = true;
    }
  },
  updated() {
    if (
      this.$route.path.includes("2X-Ecosystem") ||
      this.$route.path.includes("roi")
    ) {
      this.displayDataPrivacy = true;
      if (
        this.$route.path.includes("gbvh") ||
        this.$route.path.includes("2XDirectory") ||
        this.$route.path.includes("co-investor-and-partner") ||
        this.$route.path.includes("deals") ||
        this.$route.path.includes("2X-Ecosystem/2X-Certification")
      ) {
        this.dataPrivacyUrl = "/#/investorsPrivacyPolicy";
      } else {
        this.dataPrivacyUrl = "/#/dataprivacy";
      }
    } else {
      this.displayDataPrivacy = false;
    }
  },

  beforeUnmount() {
    this.isAirtableLink = null;
  },
};
</script>

<style lang="scss">
.v-select-custom {
  input[type="search"] {
    border: none;
  }

  input[type="search"]:focus {
    border: none;
    --tw-ring-shadow: none;
  }
}

.v-select-custom:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

.csv_request_modal {
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    padding: 30px 0 10px 35px;
    position: relative;
    display: flex;

    .ModalInnerStyle {
      max-height: 630px;
      overflow: auto;
      background: #fff;
      padding-right: 30px;

      h1 {
        font-weight: bold;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
        text-align: center;
        padding-bottom: 30px;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        text-align: left;
        line-height: 18px;
        color: #1b1b1b;
      }

      button {
        margin: 30px auto 0 auto;
        order: none;
        padding: 15px 32px;
        font-size: 16px;
      }
    }

    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;

      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }
}

#downloadCSV {
  min-width: 112px;
  margin-right: 5px;
}

[data-modal="accept-terms"] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg-white {
    background-color: initial;
    backdrop-filter: blur(10px);
  }

  .quote {
    padding: 0 40px;
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 400;
    color: #201747;
    text-align: justify;
  }

  .download-links {
    margin-top: 34px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;

    a {
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 400;
      color: #0076bb;
    }

    a:nth-child(1) {
      margin-right: 40px;
    }
  }

  .download-links.not-message {
    margin-top: 40px;
  }

  .overlay {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
  }

  .wr {
    max-width: 824px;
    padding: 40px 0;
    background-color: #ffffff;
    position: relative;
    z-index: 4;
    height: 90%;
    width: 90%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 10px;

    .content-wr {
      > div {
        padding: 0 40px 40px 40px;
        border-bottom: 1px solid #f6f6f6;

        li {
          font-size: 16px;
          line-height: 19.5px;
          font-weight: 400;
          color: #201747;
          margin-top: 10px;
          text-align: justify;
        }

        > ol {
          > li {
            margin-top: 0;
          }

          > li:before {
            font-size: 18px;
            line-height: 21.94px;
          }

          h2 {
            margin-top: 20px;
          }

          p {
            margin-top: 10px;
          }

          > ol {
            margin-top: 10px;
          }
        }

        > p {
          margin-top: 20px;
          font-size: 18px;
          line-height: 21.94px;
          font-weight: 500;
          color: #201747;
          text-align: justify;
        }

        > h2 {
          margin-top: 40px;
        }
      }
    }

    .btn {
      width: 150px;
      padding: 15px 0;
      text-align: center;
      background-color: #201747;
      color: #ffffff;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 500;
    }

    .btn.white {
      background-color: #ffffff;
      border: 1px solid #201747;
      color: #201747;
    }

    .btn-wr {
      margin-top: 40px;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
    }

    h1 {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #201747;
      margin-top: 40px;
    }

    > p {
      font-size: 16px;
      line-height: 24px;
    }

    > ol {
      > li:before {
        font-size: 25px;
        color: #201747;
        font-weight: 500;
      }
    }

    h2 {
      font-size: 18px;
      line-height: 21.94px;
      font-weight: 500;
      color: #201747;
      display: inline-block;
    }

    ol {
      list-style: auto;
      counter-reset: item;
    }

    li {
      display: block;
    }

    li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      color: #201747;
      font-weight: 500;
    }
  }

  .wr::-webkit-scrollbar-thumb {
    background-color: rgba(32, 23, 71, 0.5);
    border: 4px solid transparent;
    border-radius: 30px;
    background-clip: padding-box;
  }

  .wr::-webkit-scrollbar {
    width: 20px;
  }
}

body {
  top: 0 !important;
}

.skiptranslate {
  display: none !important;
}

.hide-translator {
  height: 0px;
  width: 0px;
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}

.goog-text-highlight {
  background-color: transparent !important;
  box-shadow: none !important;
}

.left-social-icons {
  list-style: none;
  position: absolute;
  top: 25rem;
  left: 0%;
  transform: translateY(-50%);

  li {
    margin-left: 0;

    a {
      display: block;
      // margin-left: -2px;
      height: 60px;
      width: 60px;
      border-radius: 0 25px 25px 0;
      border: 2px solid #000;
      background: #fff;
      margin-bottom: 1em;
      transition: all 0.4s ease;
      color: #2980b9;
      text-decoration: none;
      line-height: 60px;
      position: relative;

      &:hover {
        cursor: pointer;
        width: 180px;
        color: #fff;

        span {
          left: 10px;
        }
      }

      span {
        padding: 0 30px 0 15px;
        position: absolute;
        left: -120px;
        transition: left 0.4s ease;
        font-size: 1.1rem;
      }

      i {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 2rem;
      }

      img {
        position: absolute;
        right: 8px;
        width: 45px;
        top: 5px;
        transition: left 0.4s ease;
      }

      .subscribe-email-image {
        position: absolute;
        right: 10px;
        width: 40px;
        top: 7px;
        transition: left 0.4s ease;
      }
    }

    .linkedin {
      background: #fff;
      border-color: rgb(32, 23, 71);
      color: rgb(32, 23, 71);

      &:hover {
        background: #fff;
        color: rgb(32, 23, 71);
      }
    }

    .facebook {
      background: #fff;
      border-color: #27ae60;
      color: #27ae60;

      &:hover {
        background: #fff;
        color: #27ae60;
      }
    }

    .twitter {
      background: #fff;
      border-color: #e67e22;
      color: #e67e22;

      &:hover {
        background: #fff;
        color: #e67e22;
      }
    }

    .instagram {
      background: #fff;
      border-color: #22a7f0;
      color: #22a7f0;

      &:hover {
        background: #fff;
        color: #22a7f0;
      }
    }

    .subscribe {
      background: #fff;
      border-color: #d2527f;
      color: #d2527f;

      &:hover {
        background: #fff;
        color: #d2527f;
      }
    }
  }
}
</style>
<style>
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

body:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: repeat-y;
  background-size: auto 0vh;
  background-image: url("https://api.equilo.io/LOGO'S/paper-stamp.jpg");
}

@media print {
  p {
    @apply leading-relaxed text-xs !important;
  }

  html {
    margin: 0.8cm 0.5cm;
  }

  body:before {
    background-size: auto 100vh;
  }
}

.hidescrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.fade-down-enter-active {
  transition: all 0.3s cubic-bezier(0.62, 0.01, 0.41, 1);
}

.fade-down-leave-active {
  transition: all 0.3s cubic-bezier(0.62, 0.01, 0.41, 1);
}

.fade-down-enter,
.fade-down-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(7px);
  opacity: 0;
}

.focus\:shadow-outline-indigo:focus {
  box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
}

.feedback {
  background-color: #6b787f;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: #46b8da;
  position: fixed;
  right: 0;
  /* bottom: 54%; */
  transform: rotate(-90deg);
  transform-origin: bottom right;
  top: 18em;
}

.feedback:hover {
  cursor: pointer;
  color: #40a9ff;
}

.support {
  background-color: rgb(0, 119, 200);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: rgb(0, 119, 200);
  position: fixed;
  right: 0;
  /* bottom: 325px; */
  transform: rotate(-90deg);
  transform-origin: bottom right;
  top: 29em;
}

.help-center {
  background-color: #5dd1c4;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: rgb(0, 119, 200);
  position: fixed;
  right: 0;
  /* bottom: 325px; */
  transform: rotate(-90deg);
  transform-origin: bottom right;
  top: 39em;
}

.help-center:hover {
  cursor: pointer;
  color: #40a9ff;
}

.data-privacy {
  background-color: rgb(151, 112, 21);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: rgb(255, 196, 57);
  position: fixed;
  right: 0;
  /* bottom: 80%; */
  transform: rotate(-90deg);
  transform-origin: bottom right;
  top: 5em;
}

/* .data-privacy:active {
  font-size: 20px;
  font-weight: 600;
} */

#mybutton {
  position: fixed;
  bottom: -4px;
  right: 10px;
}

/* ANT STEPS ICON */
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  width: 16px !important;
  height: 16px !important;
  top: -4px;
  background-color: #19486a !important;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  width: 16px !important;
  height: 16px !important;
  top: -4px;
  background-color: #19486a !important;
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  width: 16px !important;
  height: 16px !important;
  top: -4px;
  background-color: #fff !important;
  border: 1px solid #19486a !important;
}

.ant-steps-item-active
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  width: 16px !important;
  height: 16px !important;
  top: -4px !important;
  background-color: #fff !important;
  border: 3px solid #19486a !important;
}

.ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: #f2f2f2 !important;
  border-top: 1px dashed #201747 !important;
}

.ant-steps-item-container .ant-steps-item-tail::after {
  background-color: #f2f2f2 !important;
  border-top: 1px dashed #201747 !important;
  width: calc(100% - 25px) !important;
  margin-left: 18px !important;
}

.ant-steps-item-content {
  width: 150px !important;
}

/* ANT STEPS TITLE */

.ant-steps-item-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #717686;
}

.ant-steps-item-active .ant-steps-item-title {
  color: #545764 !important;
  font-weight: 400 !important;
}

.ant-steps-item-wait .ant-steps-item-title:hover {
  color: #545764 !important;
}

.ant-steps-item-container:hover .ant-steps-item-title {
  color: #545764 !important;
}

/* ANT STEPS DESCRIPTION */
.ant-steps-item-active .ant-steps-item-description {
  color: #19486a !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.ant-steps-item-wait .ant-steps-item-description {
  color: #19486a !important;
  font-size: 14px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.ant-steps-item-finish .ant-steps-item-description {
  color: #19486a !important;
  font-size: 14px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.ant-steps-item-wait .ant-steps-item-description:hover {
  color: #19486a !important;
}

.ant-steps-item-container:hover .ant-steps-item-description {
  color: #19486a !important;
}

/* ANT STEPS DISABLED - DESCRIPTION */
.ant-steps-item-disabled .ant-steps-item-description:hover {
  color: #8f8f8f !important;
  cursor: not-allowed;
}

.ant-steps-item-disabled
  .ant-steps-item-container:hover
  .ant-steps-item-description {
  color: #8f8f8f !important;
}

/* ANT STEPS DISABLED - TITLE */
.ant-steps-item-disabled .ant-steps-item-title:hover {
  color: #969696 !important;
  cursor: not-allowed;
}

.ant-steps-item-disabled .ant-steps-item-container:hover .ant-steps-item-title {
  color: #969696 !important;
}

.custom-text-style {
  font-weight: 900;
  text-decoration: underline;
}
</style>

<style scoped>
.modal-vue .modal {
  position: absolute !important;
  position: fixed;
  top: 47px;
  left: auto;
  right: 0;
  margin: auto;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 830px;
  padding: 2rem;
  -webkit-box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
  box-shadow: 0 10px 20px rgb(21 1 39 / 10%), 0 3px 6px rgb(21 1 39 / 4%),
    0 -3px 6px rgb(21 1 39 / 4%);
  background: #fff;
  box-sizing: content-box;
  z-index: 999;
  -webkit-transform: none;
  transform: none;
}

.lang-modal {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}

.set-input-right {
  position: relative;
  left: 75%;
  top: -15px;
}

.not-found-lang {
  width: 229px;
  position: relative;
  left: 155%;
}

.scroll-class {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 62px;
  top: -45px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.tooltip .lang-tooltiptext {
  visibility: hidden;
  /* width: 180px; */
  background-color: #6b787f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 9999;
  top: 34px;
  left: -34px;
}

.tooltip:hover .lang-tooltiptext {
  visibility: visible;
}

.tooltip .lang-tooltiptext::after {
  right: 95%;
}
</style>
