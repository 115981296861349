export const list = [
  {
    parent: "2X Criteria",
    icons: ["2xglobal"],
    parentCode: "EA",
    title: "2X Criteria Thresholds",
    code: "twoXC",
    description:
      "Self-assess if my organization meets global gender smart industry 2X Criteria Thresholds with country- and sector- benchmarking data.",
    isActive: true,
    isUpgrade: false,
    upgrade: "2X",
    isDemo: false,
    demo: "",
    isAccess: true,
    access: "",
    route: "/2X-Ecosystem/Thresholds",
    methodology: "/2X-Ecosystem/Thresholds",
    button: "Launch",
    api: "",
    actions: [
      {
        title: "2X Challenge",
        parentCode: "EA",
        code: "tcdq",
        icon: "2xglobal",
        shade: "#FFF8E0",
        border: "green",
        description: "2X Challenge Deal Qualification",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Challenge",
      },
      {
        title: "2X Matchmaking",
        icon: "folder",
        parentCode: "twoXC",
        code: "2xmm",
        shade: "#FFF8E0",
        border: "yellow",
        description: "Potential deals for you",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/2X-Matchmaking",
      },
      {
        title: "Investor Profile",
        parentCode: "twoXC",
        code: "investor-profile",
        icon: "idea",
        shade: "#FFF8E0",
        border: "red",
        description: "Investor Profile",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "2X Certification",
        icon: "graduation",
        parentCode: "twoXC",
        code: "g2xC",
        shade: "#E5FAFA",
        border: "green",
        description: "2X Certification",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/2X-Certification",
      },
      {
        title: "2X Verification",
        parentCode: "twoXC",
        code: "2xvp",
        icon: "users",
        shade: "#FFECE8",
        border: "red",
        description: "2X Verification Portal",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/admin",
      },
      {
        title: "2X Global Portal",
        parentCode: "twoXC",
        code: "2xgp",
        icon: "2xglobal",
        shade: "#FFECE8",
        border: "red",
        description: "2X Global Portal",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2x-global/Home",
      },
      {
        title: "Invite entity to 2X Portfolio",
        icon: "show",
        parentCode: "twoXC",
        code: "iet2xp",
        shade: "#EAF6FF",
        border: "green",
        description: "Invite entity to 2X Portfolio",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/2x-portfolio-setup",
      },
      {
        title: "2X Portfolio",
        icon: "show",
        parentCode: "twoXC",
        code: "2xPortfolio",
        shade: "#EAF6FF",
        border: "green",
        description: "2X Portfolio",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/2X-Certification/2X-Portfolio",
      },
      {
        title: "Upload Documents",
        icon: "show",
        parentCode: "twoXC",
        code: "udfv",
        shade: "#EAF6FF",
        border: "green",
        description: "Upload documents for verification",
        id: 0,
        isActive: false,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/upload-files",
      },
    ],
  },
  {
    parent: "Gender ROI™",
    icons: ["sweef"],
    parentCode: "GR",
    title: "Gender ROI™",
    code: "GR",
    description:
      "Use the Gender ROI™ to assess my organization's gender equality across all areas.",
    button: "Launch",
    isActive: true,
    isUpgrade: false,
    upgrade: "GR",
    isDemo: false,
    demo: "",
    isAccess: true,
    access: "",
    route: "/roi",
    methodology: "",
    api: "",
    actions: [
      {
        title: "View my Gender ROI™ results",
        parentCode: "GR",
        code: "vmgrr",
        icon: "2x",
        shade: "#EAF6FF",
        border: "blue",
        description: "Show my results",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/roi",
      },
      {
        title: "Invite an entity",
        parentCode: "GR",
        code: "ie2",
        icon: "invite",
        shade: "#EAF6FF",
        border: "blue",
        description: "Show the results of my 2X Assessments",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/roi",
      },
      {
        title: "View my favorite shared assessments",
        parentCode: "GR",
        code: "vmfsa2",
        icon: "assesments",
        shade: "#FFECE8",
        border: "red",
        description: "Show my saved assessments",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/roi/roiSharedAssessments",
      },
      {
        title: "Show my gender ROI™ portfolio",
        parentCode: "GR",
        code: "smgroi",
        icon: "show",
        shade: "#E5FAFA",
        border: "green",
        description: "View my Gender ROI™ portfolio",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/roi/roiPortfolio",
      },
      {
        title: "Investor Profile",
        parentCode: "GR",
        code: "investor-profile",
        icon: "idea",
        shade: "#FFF8E0",
        border: "red",
        description: "Investor Profile",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
    ],
    id: 0,
  },
  {
    parent: "GGSF",
    icons: ["ggsf"],
    parentCode: "GGSF",
    title: "GGSF",
    code: "GGSF",
    description:
      "Use the GGSF to assess my organization's gender equality across all areas.",
    button: "Launch",
    isActive: true,
    isUpgrade: false,
    upgrade: "GGSF",
    isDemo: false,
    demo: "",
    isAccess: true,
    access: "",
    route: "/ggsf",
    methodology: "",
    api: "",
    actions: [
      {
        title: "View Results",
        parentCode: "GGSF",
        code: "ggsfvr",
        icon: "show",
        shade: "#E5FAFA",
        border: "green",
        description: "Take GGSF Gender Assessment",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/ggsf",
      },
      {
        title: "Investor Profile",
        parentCode: "GGSF",
        code: "investor-profile",
        icon: "idea",
        shade: "#FFF8E0",
        border: "red",
        description: "Investor Profile",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "GGSF portfolio",
        parentCode: "GGSF",
        code: "ggsfp",
        icon: "show",
        shade: "#E5FAFA",
        border: "green",
        description: "View my GGSF portfolio",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/ggsf/ggsfPortfolio",
      },
    ],
    id: 0,
  },
  {
    parent: "Impact Compass",
    icons: [],
    parentCode: "IC",
    code: "IC",
    title: "Impact Compass",
    description:
      "This comprehensive solution for entities to measure and draw automated insights across impact, ESG, gender, climate, and finances.",
    button: "Set investor preferences",
    isActive: true,
    isUpgrade: false,
    upgrade: "",
    isDemo: true,
    demo: "/impact-compass/landing-page",
    isAccess: true,
    access: "",
    route: "/impact-compass/landing-page",
    methodology: "",
    api: "",
    actions: [
      {
        title: "Impact Compass investor dashboard",
        parentCode: "IC",
        code: "ic-investor-dashboard",
        icon: "idea",
        shade: "#FFF8E0",
        border: "yellow",
        description: "Impact Compass investor dashboard",
        id: 0,
        isActive: false,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "Investor Profile",
        parentCode: "IC",
        code: "investor-profile",
        icon: "idea",
        shade: "#FFF8E0",
        border: "red",
        description: "Investor Profile",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "Impact Compass investee dashboard",
        parentCode: "IC",
        code: "ic-investee-dashboard",
        icon: "users",
        shade: "#FFECE8",
        border: "red",
        description: "Impact Compass investee dashboard",
        id: 0,
        isActive: false,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "Sweef Capital IMM investor dashboard",
        parentCode: "IMM",
        code: "sweef-investor-dashboard",
        icon: "sweef",
        shade: "#FFF8E0",
        border: "yellow",
        description: "Sweef Capital IMM investor dashboard",
        id: 0,
        isActive: false,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
      {
        title: "Sweef Capital IMM investee dashboard",
        parentCode: "IMM",
        code: "sweef-investee-dashboard",
        icon: "sweef",
        shade: "#FFF8E0",
        border: "yellow",
        description: "Sweef Capital IMM investee dashboard",
        id: 0,
        isActive: false,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/",
      },
    ],
    id: 0,
  },
  // {
  //   parent: "Investor Profile",
  //   icons: [],
  //   parentCode: "IP",
  //   title: "Investor Profile",
  //   code: "IP",
  //   description:
  //     "Creating my investor profile is the first step to unlocking the benefits of tools for investors, including portfolio analytics for gender lens investment and impact measurement.",
  //   button: "Create investor profile",
  //   isActive: true,
  //   isUpgrade: false,
  //   upgrade: "IP",
  //   isDemo: false,
  //   demo: "",
  //   isAccess: true,
  //   access: "",
  //   route: "/2X-Ecosystem/create2Xprofile",
  //   methodology: "",
  //   api: "",
  //   actions: [
  // {
  //   title: "Directory of deals",
  //   icon: "compass",
  //   parentCode: "IP",
  //   code: "dod",
  //   shade: "#E5FAFA",
  //   border: "green",
  //   description:
  //     "Searchable directory of current and potential 2X investees",
  //   id: 0,
  //   isActive: true,
  //   isUpgrade: false,
  //   upgrade: "",
  //   isDemo: false,
  //   demo: "",
  //   isAccess: true,
  //   access: "",
  //   route: "/2X-Ecosystem/2XDirectory",
  // },
  // {
  //   title: "Directory of co-investors",
  //   parentCode: "IP",
  //   code: "doc",
  //   icon: "location",
  //   shade: "#EAF6FF",
  //   border: "blue",
  //   description:
  //     "Searchable directory to find 2X co-investors and partners",
  //   id: 0,
  //   isActive: true,
  //   isUpgrade: false,
  //   upgrade: "",
  //   isDemo: false,
  //   demo: "",
  //   isAccess: true,
  //   access: "",
  //   route: "/2X-Ecosystem/co-investor-and-partner",
  // },
  // {
  //   title: "Show my 2X portfolio",
  //   icon: "show",
  //   parentCode: "IP",
  //   code: "smy2p",
  //   shade: "#E5FAFA",
  //   border: "green",
  //   description: "View my 2X Portfolio",
  //   id: 0,
  //   isActive: true,
  //   isUpgrade: false,
  //   upgrade: "",
  //   isDemo: false,
  //   demo: "",
  //   isAccess: true,
  //   access: "",
  //   route: "/2X-Ecosystem/my-portfolio",
  // },
  // {
  //   title: "Submit a Deal",
  //   parentCode: "IP",
  //   code: "sad",
  //   icon: "show",
  //   shade: "#E5FAFA",
  //   border: "green",
  //   description: "Submit a Deal",
  //   id: 0,
  //   isActive: true,
  //   isUpgrade: false,
  //   upgrade: "",
  //   isDemo: false,
  //   demo: "",
  //   isAccess: true,
  //   access: "",
  //   route: "/2X-Ecosystem/submit-deal",
  // },
  //   ],
  //   id: 0,
  // },
  {
    parent: "GESI Contextual Analysis",
    icons: ["eq"],
    parentCode: "GESI",
    code: "EQ",
    title: "Country & Sector GESI Analysis",
    description:
      "For anyone to conduct a Gender Equality and Social Inclusion (GESI) analysis to understand the landscape for project planning, due diligence and advocacy efforts.",
    button: "Launch Analysis",
    isActive: true,
    isUpgrade: false,
    upgrade: "",
    isDemo: true,
    demo: "/gesi-analysis/EQ/AGRI_GENERL/snapshot",
    isAccess: true,
    access: "",
    route: "/gesi-analysis/choose-country",
    methodology: "/gesi-analysis/methodology",
    api: "",
    actions: [
      {
        title: "Global gender equality snapshot",
        parentCode: "EQ",
        code: "gges",
        icon: "global",
        shade: "#E5FAFA",
        border: "green",
        description: "Show the results of my 2X assessments",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/global-analysis",
      },
      {
        title: "Data Explorer",
        parentCode: "EQ",
        code: "de",
        icon: "global",
        shade: "#E5FAFA",
        border: "green",
        description: "Data Explorer",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/data-explorer",
      },
    ],
    id: 0,
  },
  {
    parent: "GBV Risk score",
    icons: ["eq"],
    parentCode: "GBV",
    code: "GBVA",
    title: "GBV Risk country analytics tool",
    description:
      "For financial system actors to integrate Gender Based Violence (GBV) into financial models to inform investment decisions.",
    button: "Launch",
    isActive: true,
    isUpgrade: false,
    upgrade: "",
    isDemo: true,
    demo: "/gbv-risk/EQ/INFR_GENERL/home",
    isAccess: true,
    access: "",
    route: "/gbv-risk/choose-country/true",
    methodology: "/gbv/methodology",
    api: "",
    actions: [
      {
        title: "Materiality of GBV in finance",
        parentCode: "GBVA",
        code: "mgbv",
        icon: "graduation",
        shade: "#E5FAFA",
        border: "green",
        description: "Tools for anyone who wants to understand GBV risks",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/gbv/materiality",
      },
      {
        title: "GBV risk models in financial models",
        icon: "chart",
        parentCode: "GBVA",
        code: "grnfm",
        shade: "#FFF8E0",
        border: "yellow",
        description: "GBV risk models in financial models",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/gbv/risk-models",
      },
      {
        title: "Global GBV risk heat map",
        parentCode: "GBVA",
        code: "grhm",
        icon: "risk",
        shade: "#FFECE8",
        border: "red",
        description: "Global GBV risk heat map",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/gbv/heat-map",
      },
    ],
    id: 0,
  },
  {
    parent: "Gender Smart Tools",
    icons: ["supergirl"],
    parentCode: "GST",
    code: "GIRL",
    title: "SuperGIRL",
    description:
      "Explore Equilo's Super Gender & Inclusion Resource Library (SuperGIRL) for a comprehensive review of Gender Equality and Social Inclusion (GESI) topics and geographies!",
    button: "Explore",
    isActive: true,
    isUpgrade: false,
    upgrade: "",
    isDemo: false,
    demo: "",
    isAccess: true,
    access: "",
    route: "/supergirl",
    methodology: "",
    api: "",
    actions: [
      {
        title: "Gender smart tools",
        parentCode: "GIRL",
        code: "gms",
        icon: "idea",
        shade: "#FFF8E0",
        border: "yellow",
        description: "Full list of Equilo’s gender smart tools",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/GenderSmartTools",
        extra: "Submit a tool",
        extraRoute: "/submitGenderSmartTools",
      },
      {
        title: "Partner directory",
        parentCode: "GIRL",
        code: "pd",
        icon: "users",
        shade: "#FFECE8",
        border: "red",
        description:
          "Utilize Equilo's Partner Directory for collaboration, insights, and networking.",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/partner-directory",
        extra: "Submit a New Partner",
        extraRoute: "/submitPartner",
      },
      {
        title: "Technical service provider",
        icon: "table",
        parentCode: "GIRL",
        code: "tsp",
        shade: "#EAF6FF",
        border: "blue",
        description: "Potential technical service providers that meet my needs",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/technical-service-list",
      },
      {
        title: "Join our TA service directory",
        icon: "document",
        parentCode: "GIRL",
        code: "JOTASD",
        shade: "#EAF6FF",
        border: "green",
        description: "TA service directory",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/2X-Ecosystem/submitTSP",
      },
      {
        title: "TA Service Provision",
        icon: "document",
        parentCode: "GIRL",
        code: "TASP",
        shade: "#EAF6FF",
        border: "green",
        description: "TA service Provision",
        id: 0,
        isActive: true,
        isUpgrade: false,
        upgrade: "",
        isDemo: false,
        demo: "",
        isAccess: true,
        access: "",
        route: "/ta-service-provision",
      },
    ],
    id: 0,
  },
];
