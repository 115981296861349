<template>
    <div>
    
      <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
      /> -->
      <div
          class="flex flex-col items-center justify-center w-full print:h-screen mt-20"
        >
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">

                  <h1 class="text-5xl tracking-tighter font-medium text-center print:text-7xl" >
                    Country & Sector GESI Analysis Methodology
                  </h1>

            </div>
          </div>
          
          <main>
          <div class="navsticky">
            <span>Scroll to...</span>
            <ol class="global_analysis_side_bar_list">
              <li>
                <button
                  @click="scrollTo('Structure')"
                  id="Structure1"
                  class="buttonlink"
                >
                  <span>Structure</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('Indicators')"
                  id="Indicators1"
                  class="buttonlink"
                >
                  <span>Indicators</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('Scoring')"
                  id="Scoring1"
                  class="buttonlink"
                >
                  <span>Scoring</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('gi-framework')"
                  id="gi-framework1"
                  class="buttonlink"
                >
                  <span>G&I Framework</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('Limits')"
                  id="Limits1"
                  class="buttonlink"
                >
                  <span>Limitations</span>
                </button>
              </li>
            </ol>
          </div>
        
      
    
        

        <!--TABLE-->
      <div class="flex flex-row">
        <div class="w-full">
            <div class="flex flex-col px-10 py-10 w-12/12" style="margin-right: 150px">
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  Equilo provides a detailed snapshot analysis of gender equality and social inclusion (GESI) in
                  all global countries. The data provided tells a story about the specific identity-based constraints and opportunities that individuals in a specific country face, using quantitative parity and empowerment scores that are contextualized with country- and theme-specific qualitative information. Information on the Equilo analytical structure, indicators, methodology and scoring, and limitations is provided below.
                  
                </p>
                <div class="flex flex-row mb-2 mt-10">
                    <img class="flex flex-col w-12 h-12 mr-2" src="@/assets/images/methodology/structure.png"/>
                    <h1 class="flex flex-col text-3xl leading-6 font-medium my-auto" id="Structure">Structure</h1>
                </div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    Equilo adopts the <a target="_blank" href="http://www.taapinclusion.org/" style="color: blue;">Transforming
                    Agency, Access, and Power (TAAP)</a> analytical framework, which emphasizes a universal,
                    intersectional, and
                    transformative approach and encompasses six key domains.
                </p>

                <a href="https://www.taapinclusion.org/" target="_blank" class="mt-5 mx-32"><img src="@/assets/images/methodology/taap-process.png"/></a>

                <div class="flex flex-col py-5">

                    <div class="flex flex-row border">
                        <div class="flex flex-row w-2/12 border p-1" style="color: #201747">
                            <b>TAAP Domain</b>
                        </div>
                        <div class="flex flex-row w-10/12 border p-1" style="color: #201747">
                            <b>Definition</b>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Access to and Control over Assets and Resources
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves being able to use the resources necessary to be a fully active and
                            productive social,
                            economic, and political participant in society. It includes access to resources, income,
                            services,
                            employment, information, and benefits. It addresses who owns and/or has access and the
                            capacity to use
                            productive resources—assets (land, housing), income, social benefits (social insurance,
                            pensions), public
                            services (health, water), technology and information.
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Human Dignity, Safety, and Wellness
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves the state or quality of being honored and respected, the ability of
                            individual
                            members
                            to live life free from stigma and discrimination, the ability to be free from harm or danger
                            and have
                            incidences of violence addressed and prevented, the ability to be protected and safeguarded
                            against those
                            harms. This domain also involves: the inclusion sensitivity of responses to conflict, crises
                            (including
                            natural disasters) and crimes; the different barriers and opportunities of included,
                            marginalized and
                            excluded identity groups to achieve their full potential in terms of lifestyle, mental,
                            emotional and
                            spiritual well-being; and the recognition of identity-specific needs.
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Knowledge, Beliefs and Perceptions, Cultural Norms
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves the knowledge accessible by different identity groups, the beliefs that
                            shape gender
                            identities and behavior, and perceptions that guide how people interpret aspects of their
                            lives
                            differently
                            depending on their social identity. It also includes beliefs and social norms about
                            different identity
                            groups’ acceptable behavior, how they are valued by society, and their capabilities.
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Laws, Policies, Regulations, and Institutional Practices
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves assessing how different identity groups are regarded and treated by
                            both customary
                            and
                            formal legal codes, safeguarding laws, and judicial systems. It encompasses access to legal
                            documentation
                            such as ID cards, voter registration, property titles, rights to inheritance, employment,
                            redress of
                            wrongs,
                            and representation. Laws include formal statutory laws and informal and customary legal
                            systems and
                            safeguarding laws. It involves identifying whether laws, policies, regulations and
                            institutional practices
                            contain explicit identity biases (provisions that treat included, marginalized, and excluded
                            identity
                            group
                            differently) or implicit identity-based biases (the different impacts of laws, policies,
                            regulations and
                            practices on included, marginalized and excluded groups because of different social
                            arrangements and
                            economic behavior).
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Power and Decision-Making
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves the ability and capacity to safeguard others, control resources, and to
                            make
                            autonomous
                            decisions free of coercion. Identity-based norms influence the extent to which individuals
                            can make and
                            act
                            on purchasing decisions; beliefs; one’s body; children; occupations; household affairs,
                            community or
                            state;
                            voting; legislation; entering into legal contracts; and associating with others.
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-2/12 border p-2 text-sm text-gray-600 font-medium">
                            Roles, Responsibilities, Participation, and Time Use
                        </div>
                        <div class="flex flex-row w-10/12 border p-2 text-gray-600">
                            This domain involves how behaviors and actions in life vary by identity group. This
                            encompasses
                            differences
                            in freedom of movement or autonomy to enable participation; types of activities; how they
                            engage in
                            development activities; and allocation and availability of time to participate. This
                            includes examining
                            what
                            different identity groups do in these spheres, including roles, responsibilities, and time
                            used during
                            paid
                            work, unpaid work (including in the home), and community service to get an accurate portrait
                            of how people
                            lead their lives and to anticipate potential constraints.
                        </div>
                    </div>
                </div>

                <div class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                        Across these TAAP domains, specific themes that represent key areas for gender equality and
                        social inclusion analysis were identified. These include:
                    </p>
                    <div class="flex flex-row px-10 py-3 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                        <div class="w-6/12 center-items">
                            <ul class="list-disc">
                                <li>Education & Literacy</li>
                                <li>Entrepreneurship</li>
                                <li>Financial Inclusion</li>
                                <li>Gender-Based Violence</li>
                                <li>HIV</li>
                                <li>Information & Communication Technology</li>
                                <li>Land & Property Ownership</li>
                            </ul>
                        </div>
                        <div class="w-6/12">
                            <ul class="list-disc">
                                <li>Nutrition & Food Security</li>
                                <li>Personal Agency</li>
                                <li>Political Participation & Leadership</li>
                                <li>Poverty</li>
                                <li>Sexual & Reproductive Health</li>
                                <li>Trafficking in Persons</li>
                                <li>Unpaid Care Work</li>
                                <li>Workforce</li>
                            </ul>
                        </div>
                    </div>

                  <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <b>In addition to these base country themes, each sector has 1-3 custom themes, as follows:</b>
                  </p>
                  <div class="flex flex-row px-10 py-3">
                    <div class="w-12/12 center-items">
                      <ul class="list-disc">
                        <li><b>Agriculture</b>: Production & Processing, Markets & Sales, and Climate Change</li>
                        <li><b>COVID-19</b>: Health Service Access & Quality and Business Impacts in place of the standardized Entrepreneurship</li>
                        <li><b>Economic Growth</b>: Climate Change, Enabling Infrastructure & Environment, Trade, and Workforce Health & Safety</li>
                        <li><b>Energy</b>: Energy Access & Quality and Environmental & Health Impacts</li>
                        <li><b>Environment</b>: Natural Resources, Natural Disasters, and Food Security & Ambient Health in place of the standardized Nutrition & Food Security.</li>
                        <li><b>Finance</b>: Credit Use & Access and Formal Banking Access 
                              (some Financial Inclusion theme indicators are allocated across the custom sector themes)</li>
                        <li><b>Health</b>: Health Service Access & Quality, Health Behaviors, and Health Outcomes. The SRH sub-sector does not include the base Sexual & Reproductive Health theme, given that these indicators are distributed within the new custom themes</li>
                        <li><b>Industry & Manufacturing</b>: Environmental & Health Impacts and Trade</li>
                        <li><b>Infrastructure</b>: Infrastructure Access & Quality, and Environmental & Health Impacts.</li>
                        <li><b>WASH</b>: Public Health and WASH Access Equity</li>
                      </ul>
                    </div>
                  </div>

                    <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                      These themes cut across two or more of the domains, resulting in 30+ unique TAAP
                      domain/theme intersections for analysis and scoring. For example, the Workforce
                      theme intersects with four TAAP domains (Knowledge & Beliefs, Roles &
                      Responsibilities, Power & Decision-Making, and Law & Policy), while the Political Participation
                      theme intersects with two TAAP domains (Knowledge & Beliefs and Power &
                      Decision-Making). Each intersection is populated with 1-8 discrete indicators, which are
                      used to calculate gender parity, women’s empowerment, and gender equality scores.
                      Although the TAAP domains are fixed, new themes and associated indicators will be created
                      in the future as data becomes more available.
                    </p>
                </div>
                <div class="flex flex-row mb-2 mt-10">
                    <img class="flex flex-col w-12 h-12 mr-2" src="@/assets/images/methodology/indicators.png"/>
                    <h1 class="flex flex-col text-3xl leading-6 font-medium my-auto" id="Indicators">Indicators</h1>
                </div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">The indicators were chosen according to the following criteria:
                </p>
                <ul class="list-disc px-5 py-2 text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify"><b>Alignment with international standards</b>: The chosen indicators
                        are based on
                        reputable, international sources that are recognized within the development community (e.g., UN
                        Sustainable
                        Development Goals indicators, UN Minimum Gender Indicators).
                    </li>
                    <li class="p-1 text-justify"><b>Data availability</b>: Equilo did not collect any primary data, but
                        rather
                        drew
                        upon existing internationally validated databases (e.g., Demographic and Health Surveys, World
                        Bank data,
                        ILO
                        data, UNESCO data) for quantitative country-level data. The indicators were selected according
                        to the
                        availability of standardized and disaggregated data.
                    </li>
                    <li class="p-1 text-justify"><b>Evidence base</b>: The Equilo Dash indicators are regularly used by
                        international and national experts to assess the state of gender equality in a country. A better
                        score for
                        each indicator is directly linked to better development outcomes for women and socially excluded
                        groups.
                    </li>
                    <li class="p-1 text-justify"><b>Unique dimension</b>: Each indicator measures a unique dimension of
                        gender
                        equality and social inclusion within the TAAP domain/theme intersection. No two indicators
                        measure the same
                        concept, so that the indicators collectively tell a story with no overlap to minimize bias.
                    </li>
                </ul>

                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  The following tables summarizes the 160 scored indicators within the intersection of TAAP
                  domains (header) and themes (left-hand column) that are used to calculate gender equality
                  scores. Additionally, there are 35 indicators that are unscored and not included in the
                  gender equality score, but are presented in the dashboard to provide context (denoted with
                  asterisks below). The primary data source is included in parentheses after the indicator
                  name, although due to limited data availability, some data points are sourced from alternative sources. All data points are cited within the dashboard to ensure transparency
                  and to allow the user to locate and explore the original data source. Many indicators
                  (“Country Base Indicators”) appear across all sectors, while sector-specific indicators have
                  been added to enhance the data story for any given sector. Not all sector-specific indicators
                  appear within each subsector in the application, or are sub-sector specific.
                </p>

                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer" @click="togglePanel('1')">
                        <h1 class="text-xl leading-6 font-medium">
                            Country Base Indicators
                        </h1>
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('1') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <!--<item :text="tip.text" :country="country" class="text-white"></item>-->
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    Access to Resources
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta"  filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector='BASE' type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Alpha) > 0), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE'  type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Beta) > 0), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Delta) > 0), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Gamma) > 0), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector='BASE' type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND(FIND('BASE', subsector_Zeta) > 0), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score.</p>
                        </div>
                    </transition>
                </div>

                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('2')">
                        
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/agriculture.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Agriculture Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('2') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                         <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                         <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                         <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                         <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Production & Processing
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Markets & Sales
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Markets & Sales'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Markets & Sales'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Markets & Sales'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Markets & Sales'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Markets & Sales'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Climate Change
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Production & Processing
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Production & Processing
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "AGRI" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "AGRI" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "AGRI" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Production & Processing'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('3')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/energy.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Energy Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                <transition name="slide-fade">
                
                    
                        <div v-if="openPanels.indexOf('3') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">    
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                        
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                        
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                        
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                        
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                        
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                            
                                     
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Energy Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Energy Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Energy Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENRG" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENRG" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENRG" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                            
                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                        
                    </transition>
                </div>
                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('4')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/infrastructure.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Infrastructure Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('4') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Infrastructure Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                 <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                    <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                        </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Infrastructure Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Infrastructure Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Infrastructure Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                        <div class="flex flex-row w-10/12 border p-2 ">
                                            <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "INFR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "INFR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "INFR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('5')">
                        
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/finance.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Finance Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('5') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                 <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                 <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Credit Use & Access
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                       Formal Banking Access
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Credit Use & Access
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                
                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Formal Banking Access
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Formal Banking Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Credit Use & Access
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Credit Use & Access'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "FNCE" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "FNCE" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "FNCE" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>

                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('6')">
                        
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/wash.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                WASH Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('6') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                       <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Public Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        WASH Access Equity
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Public Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Public Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        WASH Access Equity
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'WASH Access Equity'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Sexual & Reproductive Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Sexual & Reproductive Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "WASH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "WASH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "WASH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>

                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('7')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/health-equilo.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Health Sector Indicators
                            </h1>
                        </div>
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('7') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Behaviors
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Behaviors
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Outcomes
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Health Outcomes'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Health Outcomes'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Health Outcomes'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Health Outcomes'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Health Outcomes'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Behaviors
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Behaviors
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Health Behaviors'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "HLTH" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "HLTH" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "HLTH" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                 <!--New-->
                 <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('8')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/covid.png" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                COVID-19 Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('8') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Business Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Business Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Business Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Business Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Business Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Business Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Health Service Access & Quality
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Health Service Access & Quality'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "CVID" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "CVID" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "CVID" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                 <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('9')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/economic_growth.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Economic Growth Sector Indicators
                            </h1>
                        </div>
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('9') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Climate Change
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Enabling Infrastructure & Environment
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trade
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce Health & Safety
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Climate Change
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Enabling Infrastructure & Environment
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce Health & Safety
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Climate Change
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Climate Change'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce Health & Safety
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce Health & Safety'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>


                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Enabling Infrastructure & Environment
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Enabling Infrastructure & Environment'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ECON" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ECON" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ECON" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                 <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('10')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/environment_climate_action.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Environment Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('10') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Natural Resources
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Natural Disasters
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Natural Disasters
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Food Security & Ambient Health
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Food Security & Ambient Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Food Security & Ambient Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Food Security & Ambient Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Food Security & Ambient Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Food Security & Ambient Health'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Natural Resources
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Natural Resources'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Natural Disasters
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Natural Disasters'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "ENVR" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "ENVR" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "ENVR" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div>
                <div class="shadow overflow-hidden rounded-lg border border-indigo-200 mt-3 pb-i-avoid" v-scroll-reveal>
                    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                         @click="togglePanel('11')">
                        <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                            <img class="w-6 h-6" src="https://api.equilo.io/ICONS/Sectors/Sector icons/industry_manufacturing.svg" style="display:inline; white-space:nowrap;"/>
                        </div>
                        
                        <div class="flex flex-col w-11/12">
                            <h1 class="text-xl leading-6 font-medium">
                                Industry & Manufacturing Sector Indicators
                            </h1>
                        </div>
                        
                        <div style="margin-left: auto; cursor:pointer;" class="text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-chevron-right">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-if="openPanels.indexOf('11') > -1 || print" class="flex flex-col mt-5 px-10 pb-5">
                            <div class="flex flex-col py-5 text-gray-600 font-medium">
                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Access to Resources</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trade
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Trade'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Financial Inclusion
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Financial Inclusion'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Information & Communication Technology
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Information & Communication Technology'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Access to Resources'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Access to Resources'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Access to Resources'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Access to Resources'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b> Human Dignity</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Nutrition & Food Security
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Nutrition & Food Security'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Poverty
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Poverty'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Trafficking in Persons
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND({theme_Alpha} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Human Dignity'), AND({theme_Beta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Human Dignity'), AND({theme_Delta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Human Dignity'), AND({theme_Gamma} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Human Dignity'), AND({theme_Zeta} = 'Trafficking in Persons'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Knowledge & Beliefs</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Knowledge & Beliefs'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Knowledge & Beliefs'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Knowledge & Beliefs'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Knowledge & Beliefs'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Law & Policy</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        HIV
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'HIV'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Environmental & Health Impacts
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Environmental & Health Impacts'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Land & Property Ownership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Land & Property Ownership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Gender-Based Violence
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Gender-Based Violence'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Education & Literacy
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Education & Literacy'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Law & Policy'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Law & Policy'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Law & Policy'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Law & Policy'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Power and Decision-Making</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Entrepreneurship
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Entrepreneurship'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Personal Agency
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Personal Agency'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Political Participation & Leadership
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND({theme_Alpha} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Power & Decision-Making'), AND({theme_Beta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Power & Decision-Making'), AND({theme_Delta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Power & Decision-Making'), AND({theme_Gamma} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Power & Decision-Making'), AND({theme_Zeta} = 'Political Participation & Leadership'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row border border p-1 items-center">
                                    <b>Roles & Responsibilities</b>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Unpaid Care Work
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Unpaid Care Work'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                                <div class="flex flex-row">
                                    <div class="flex flex-row w-3/12 border p-2 text-sm">
                                        Workforce
                                    </div>
                                    <div class="flex flex-row w-10/12 border p-2 ">
                                        <ul class="list-disc px-5">
                                            <vue-airtable sector= "MANF" type="subsector_Alpha" filter="AND(AND({domain_Alpha} = 'Roles & Responsibilities'), AND({theme_Alpha} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Beta" filter="AND(AND({domain_Beta} = 'Roles & Responsibilities'), AND({theme_Beta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                             <vue-airtable sector= "MANF" type="subsector_Delta" filter="AND(AND({domain_Delta} = 'Roles & Responsibilities'), AND({theme_Delta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Gamma" filter="AND(AND({domain_Gamma} = 'Roles & Responsibilities'), AND({theme_Gamma} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                            <vue-airtable sector= "MANF" type="subsector_Zeta" filter="AND(AND({domain_Zeta} = 'Roles & Responsibilities'), AND({theme_Zeta} = 'Workforce'), AND({Country/Region} = 'Global'))"></vue-airtable>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <p class="text-gray-600">*Indicators marked with an asterisk are provided only for context and do not factor into the Gender
                                Equality Score</p>
                        </div>
                    </transition>
                </div> 
                <h1 class="text-xl leading-6 font-medium mt-10">Analysis Presentation</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">Equilo presents an overview analysis and thematic deep-dives to explore the data behind
                  the overview for each country:</p>
                <ul class="list-disc px-5 py-2 text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify"><b>Spider web</b>: A spider web on the landing page depicts the six TAAP domains and
                      15+ interspersed themes. Some themes appear in multiple TAAP domains because a
                      unique score is calculated for each specific TAAP domain/theme intersection. The
                      purpose of this is to analyze the “hot spots” of strengths and weaknesses across one
                      theme and illustrate potentially uneven progress. For example, a country may have
                      strong laws and policies on gender-based violence (GBV), resulting in a high score
                      within the Law & Policy domain, but there may be widespread social acceptance of
                      GBV, resulting in a low score within the Knowledge & Beliefs domain.
                    </li>
                    <img style="width: 70%" class="mx-auto mb-3" src="@/assets/images/methodology/spider-chart.png"/>
                    <li class="p-1 text-justify"><b>Top 5 areas for improvement</b>: This section highlights the top 5
                        theme scores that are furthest away from the value of 1 (perfect gender equality) in a given
                        country. This does not necessarily indicate that these are the top areas for priority, but
                        rather flags the areas with the biggest gaps. For example, there may be a smaller gap for sexual
                        and reproductive health than ICT access, but it may be more impactful to improve gender equality
                        overall by moving the needle on sexual and reproductive health, rather than closing the ICT gap.
                        Equilo in its nascency does not yet include predictive modeling to identify areas for highest
                        impact, but has plans to soon grow its capabilities to do just that.
                    </li>
                    <img style="width: 70%" class="mx-auto mb-3" src="@/assets/images/methodology/theme.png"/>
                </ul>

                <div class="flex flex-row mb-2 mt-10">
                    <img class="flex flex-col w-12 h-12 mr-2" src="@/assets/images/methodology/scoring.png"/>
                    <h1 class="flex flex-col text-3xl leading-6 font-medium my-auto" id="Scoring">Scoring</h1>
                </div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  Equilo uses a total of 160 selected indicators within its country-level base data to create two
                  scores (Gender Parity Score and Women’s Empowerment Score) for each TAAP/theme
                  intersection; these scores are combined into a weighted Gender Equality Score. Each sector
                  includes a number of sector-specific indicators that complement the base indicators:
                </p>
                <ul class="list-disc p-5 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  <li class="p-1 text-justify">The <b>agriculture sector</b> includes 42 additional scored indicators.</li>
                  <li class="p-1 text-justify">The <b>energy sector</b> includes 47 additional scored indicators.</li>
                  <li class="p-1 text-justify">The <b>infrastructure sector</b> includes 60 additional scored indicators</li>
                  <li class="p-1 text-justify">The <b>finance sector</b> includes 36 additional scored indicators</li>
                  <li class="p-1 text-justify">The <b>WASH sector</b> includes 37 additional scored indicators</li>
                  <li class="p-1 text-justify">The <b>health sector</b> includes 107 additional scored indicators</li>
                  <li class="p-1 text-justify">The <b>COVID sector</b> includes 67 additional scored indicators.</li>
                  <li class="p-1 text-justify">The <b>economic growth sector</b> includes 81 additional scored indicators.</li>
                  <li class="p-1 text-justify">The <b>environment sector</b> includes 56 additional scored indicators.</li>
                  <li class="p-1 text-justify">The <b>industry & manufacturing sector</b> includes 59 additional scored indicators</li>
                  
                </ul>
                
                <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  There is global recognition that there is room for improvement to strengthen the breadth
                  and quality of gender equality and social inclusion indicators and data, and organizations
                  such as <a style="color: blue;" href="https://data2x.org/" target="_blank">Data2X</a> are working to support this effort. As more and better data becomes
                  available, Equilo will continuously review and adapt its methodology, adding more indicators
                  globally and for each country and sector. Therefore, the strength, depth, and breadth of the
                  Equilo tool and its analysis are constantly improving as data evolves.
                </p>

                <h1 class="text-lg leading-6 font-medium mt-10">Gender Parity Score</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  The Gender Parity Score measures the difference, or gender gap, between women and men
                  on a particular indicator. For example, what percentage of women have completed
                  secondary school, compared to men?
                </p>
                <ul class="list-disc px-5 pt-5 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify">This difference is calculated at the indicator level. A score of 1 indicates perfect
                      gender parity while a score of 0 indicates complete non-parity where men are
                      completely benefiting, accessing, or completing something, whereas a score of 2
                      indicates the opposite where women are completely benefiting, accessing, or
                      completing something.
                    </li>
                    <li class="p-1 text-justify">In some instances, a higher percentage may have negative consequences for men
                      and women. For example, the percentage of men and women who are unemployed.
                      For these indicators, we invert the percentages before calculating gender parity.
                    </li>
                    <li class="p-1 text-justify">After all indicators are scored individually, they are weighted depending on their
                      power or influence within a TAAP/theme, then averaged to produce the Gender Parity
                      Score.
                    </li>
                </ul>

                <h1 class="text-lg leading-6 font-medium mt-10">Women’s Empowerment Score</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  The Women’s Empowerment Score measures the percentage of women and girls who are able to enjoy
                  universal
                  standards for resources, decision-making, rights, and dignity, independent of what men enjoy. <i>For
                        example,
                        what percentage of women have completed secondary school?</i>
                </p>

                <ul class="list-disc px-5 pt-5 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify">
                    Indicators that contribute to the Women’s Empowerment index are
                        selected to represent universal standards that all men and women should be able to enjoy. This includes
                        indicators like the completion of secondary education and literacy rate for Education & Literacy, or the
                        employment rate for Workforce. In a just world, 100% of people would be able to finish secondary
                        school, be literate, and be gainfully employed if they wish to be. Therefore, the Women’s Empowerment score
                        finds the difference between the actual percentages of women who have finished secondary school or are
                        literate, with a universal standard of 100%.
                    </li>
                    <li class="p-1 text-justify">A score of 1 indicates perfect women’s empowerment, and a score of 0
                        indicates
                        that
                        there is no evidence of women’s empowerment; all scores are between 0-1. Women’s empowerment
                        indicators are
                        scaled so that a score of 0 represents the worst values in the world.
                    </li>
                     <li style="list-style-type:none">
                        <ul>
                            <li class="p-1 text-justify" style="list-style-type:circle">
                                Additionally, as a common and established methodology to identify outliers, 
                                the <a style="color: blue;" href="https://online.stat.psu.edu/stat200/lesson/3/3.2" target="_blank">1.5IQR rule</a>
                                is used to determine the threshold for low outliers. As a 
                                reminder, the upper limit nearly always represents the universal standard 
                                (e.g. 100% literacy rate). If low outliers exist, all low outliers receive a 
                                women's empowerment score of 0, and all other values are scaled to that low 
                                outlier threshold (1.5IQR from the quartile). If low outliers don't exist, 
                                all values are scaled so that a women's empowerment score of 0 represents 
                                the worst value in the world. This methodology ensures that a single low outlier 
                                that is far away from most other values does not skew the scaling of empowerment 
                                scores for all other countries.
                            </li>
                        </ul>
                    </li>
                    <li class="p-1 text-justify">After all indicators are scored individually, they are weighted
                        depending on
                        their
                        power or influence within a TAAP/theme, then averaged to produce the Women’s Empowerment Score.
                    </li>
                </ul>

                <h1 class="text-lg leading-6 font-medium mt-10">Gender Equality Score</h1>
                <p class="text-justify text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  The Gender Equality Score is the weighted average of all individual indicators’ gender parity and
                  women’s empowerment scores within one TAAP/theme intersection, which is the score that
                  is presented as a particular point in the spider web.
                </p>

                <ul class="list-disc px-5 pt-5 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify">Women’s empowerment indicator scores are typically more heavily weighted than
                      gender parity indicator scores.</li>
                    <li class="p-1 text-justify">In some instances, there are only gender parity or women’s empowerment indicators
                      available for a particular TAAP/theme, in which case the gender equality score for
                      that TAAP/theme is based only on whichever type of score is available.
                    </li>

                </ul>

                <h1 class="text-lg leading-6 font-medium mt-10">Calculation of Regional and Global Averages</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  We use a weighted average to calculate regional and global scores, using a country’s
                  population as its weight. For example, to calculate the average Women’s Empowerment
                  Score across all global countries, we would multiply each individual country’s Women’s Empowerment
                  Score by its population, add them together, and divide by the sum of all country’s
                  populations. This method gives more weight to scores coming from more populous countries
                  and is used by UN Stats. Additionally, we use the World Bank
                  <a style="color: blue;" href="https://datahelpdesk.worldbank.org/knowledgebase/articles/906519-world-bank-country-and-lending-groups" target="_blank">country and lending group
                    classification</a> to define regions and income groups (FY21) and country population data from
                  the <a style="color: blue;" href="https://population.un.org/wup/DataQuery/" target="_blank">United Nations Population Division</a> (year 2020).
                </p>

                <h1 class="text-lg leading-6 font-medium mt-10">Missing Data</h1>

                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    One of the main criteria for selecting the specific indicators to be included in Equilo Dash was
                    data availability. We intentionally chose indicators that have data available for the majority of
                    global in order to create a dashboard with robust data across countries. Unfortunately, many of the
                    international databases that we draw upon do not provide data for all global countries, due to a number of
                    existing limitations.
                </p><br/>
                <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    Our primary goal with Equilo Dash is to provide the user with key insights about specific gender
                    equality and social inclusion issues within a given country. Thus, we conducted extensive research
                    and rigorously examined alternative data sources to fill in data gaps across countries. The
                    advantage of this approach is that users will be able to explore an interactive dashboard populated
                    with robust data, especially for many global countries or countries experiencing the serious
                    impacts of conflict or disaster that have limited capacity to provide data to the international
                    agencies we use as preferred data sources.
                </p><br/>
                <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    Our approach to fill in data gaps encompasses the following three options, in order of priority:
                </p>
                <ol style="list-style: decimal" class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify">Utilize an alternative source, such as a country-level study or
                        national statistics office data, to fill in data for the same indicator (referred to as an
                        “alternative proxy”)
                    </li>
                    <li class="p-1 text-justify">Utilize data from a similar indicator to be used as a proxy (also
                        referred to as an “alternative proxy”).
                    </li>
                    <li class="p-1 text-justify">Utilize a regional mean/median as a proxy (referred to as a “regional
                        proxy”).
                    </li>
                </ol>
                <br/>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">We recognize that alternative sources or proxies may not always represent the highest quality data
                    and have addressed this by creating a Power Score and Confidence Score for each TAAP/theme
                    intersection within a country. Country-level Gender Equality Scores that use alternative proxies
                    will be complemented by a lower Confidence Score, while those that use regional proxies will have
                    lower Power and Confidence Scores.</p>
                <h1 class="text-lg leading-6 font-medium mt-10">Power & Confidence Scores</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    Using missing data as a tool, we have calculated two scores to assess the strength (or weakness) of
                    a particular TAAP/theme intersection score:
                </p>
                <ul class="list-disc p-5 text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    <li class="p-1 text-justify"><b>Power Score: </b>
                        The Power Score measures the robustness of the Gender Equality
                      Score on a scale of 0-1. A higher Power Score indicates greater data availability,
                      including data from any year sourced from both internationally standardized and
                      alternative sources.
                    </li>
                    <li class="p-1 text-justify"><b>Confidence Score: </b> 
                        The Confidence Score measures the reliability of the Gender
                      Equality Score on a scale of 0-1. A higher Confidence Score indicates greater data
                      quality (i.e., data from internationally standardized sources, collected within the last
                      ten years) and comparability across countries.
                    </li>
                </ul>

                <div class="flex flex-row mb-2 mt-10">
                    <img class="flex flex-col w-12 h-12 mr-2" src="@/assets/images/methodology/gi-framework.png"/>
                    <h1 class="flex flex-col text-3xl leading-6 font-medium my-auto" id="gi-framework">Gender and Inclusion Framework for Actions</h1>
                </div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  The Gender and Inclusion (G&I) Framework supports all development, humanitarian, private, 
                  and financial system actors to assess and integrate G&I activities across three different 
                  levels: minimum compliance, empowerment, and transformation. All What Works within Equilo’s 
                  library are tagged according to these impact levels, with the goal of ensuring Minimum 
                  Compliance activities are systematically undertaken, while building upon this to Equilo 
                  adapts the <a style="color: blue;" href="https://medium.com/iced-facility/mainstreaming-gender-inclusion-in-infrastructure-programming-dc947055168f" target="_blank">Gender and Inclusion (G&I) Framework</a>,
                  originally developed by Caroline Moser (2016) and adapted by Moser with SDDirect for the 
                  DFID Infrastructure and Cities for Economic Development Programme (ICED). 
                </p>
                <img style="width: 100%" class="mx-auto mt-3" src="@/assets/images/methodology/actions.png"/>

                <h1 class="text-lg leading-6 font-medium mt-10">G&I Framework in Practice</h1>
                <div class="flex flex-col py-5">

                    <div class="flex flex-row border">
                        <div class="flex flex-row w-3/12 border p-1" style="color: #201747">
                            <b>Level</b>
                        </div>
                        <div class="flex flex-row w-9/12 border p-1" style="color: #201747">
                            <b>Actions to take</b>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-3/12 border p-2 text-sm text-gray-600 font-medium">
                            <div class="flex flex-col">
                                <b>Minimum Compliance</b>
                                <em>Actions that address basic needs of women and 
                                socially excluded groups, while DOING NO HARM</em>
                            </div>
                        </div>
                        <div class="flex flex-row w-9/12 border p-2 text-sm text-gray-600 font-medium">
                            <ul class="list-disc px-5">
                                <li class="p-1 text-justify">Undertake a <b>social and gender analysis & impact assessment</b> 
                                    to examine distributional impact of interventions. Questions to ask:
                                </li>
                                <li style="list-style-type:none">
                                    <ul>
                                        <li class="p-1 text-justify" style="list-style-type:circle">
                                            How are women and men, considering intersectional identities, impacted differently, 
                                            and relationships between them? Different barriers they face? Different needs? 
                                        </li>
                                        <li class="p-1 text-justify" style="list-style-type:circle">
                                            Who are the winners and losers from interventions? (E.g., Big business? 
                                            Better off? SMEs, informal sector? Women-owned enterprises? Poor people, IDPs, 
                                            socially excluded groups – consider sex, race, ethnicity, class, disability, 
                                            geography etc.)
                                        </li>
                                        <li class="p-1 text-justify" style="list-style-type:circle">
                                            What are the expected positive and negative impacts? 
                                        </li>
                                        <li class="p-1 text-justify" style="list-style-type:circle">
                                            How will the intervention/ program/ activity impact on the poorest and 
                                            most socially excluded?
                                        </li>
                                    </ul>
                                </li>
                                <li class="p-1 text-justify">
                                    <b>Monitor/evaluate</b> through gender-responsive log frames and sex-disaggregated data
                                </li>
                                <li class="p-1 text-justify">
                                    <b>Identify, monitor and mitigate risks</b>- use IFC performance standards on social and 
                                    environmental sustainability; good practice in infrastructure and investment programs 
                                    (standards for community/ stakeholder consultation, do no harm, labor standards, etc.)
                                </li>
                                <li class="p-1 text-justify">
                                    Consider <b>unintended consequences</b>; do not assume minimal impact of interventions
                                </li>
                                <li class="p-1 text-justify">
                                    Provide protection from reputational/ operational risks and ensures due diligence to promote protection and safeguards
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-3/12 border p-2 text-sm text-gray-600 font-medium">
                            <div class="flex flex-col">
                                <b>Empowerment</b>
                                <em>Actions that build assets, capabilities, and 
                                opportunities for women and socially excluded groups</em>
                            </div>
                        </div>
                        <div class="flex flex-row w-9/12 border p-2 text-sm text-gray-600 font-medium">
                            <ul class="list-disc px-5">
                                <li class="p-1 text-justify">
                                    Identify pathways to <b>increase the gender and inclusion impacts and benefits</b> for women 
                                    and socially excluded groups- direct and indirect
                                </li>
                                <li class="p-1 text-justify">
                                    Identify <b>opportunities</b> to promote youth employment, disability inclusion, 
                                    safeguarding against GBV and VAWG, and child exploitation
                                </li>
                                <li class="p-1 text-justify">
                                    Design to <b>broaden development impact</b> for more inclusive growth
                                </li>
                                <li class="p-1 text-justify">
                                    Identify ways to increase women’s assets & economic opportunities
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="flex flex-row w-3/12 border p-2 text-sm text-gray-600 font-medium">
                            <div class="flex flex-col">
                                <b>Transformation</b>
                                <em>Actions that address unequal power relations 
                                and seek institutional and societal change</em>
                            </div>
                        </div>
                        <div class="flex flex-row w-9/12 border p-2 text-sm text-gray-600 font-medium">
                            <ul class="list-disc px-5">
                                <li class="p-1 text-justify">
                                    Support institutions to integrate gender equality and social inclusion 
                                    into systems, processes, and operations
                                </li>
                                <li class="p-1 text-justify">
                                    Ensure inclusive representation on governance boards and decision-making bodies
                                </li>
                                <li class="p-1 text-justify">
                                    <b>Tackle systemic barriers</b> that prevent women & socially excluded groups’ 
                                    contribution to and benefits from economic growth
                                </li>
                                <li class="p-1 text-justify">
                                    Support policy, regulation, and legal changes
                                </li>
                                <li class="p-1 text-justify">
                                    <b>Social norm change</b> to increase women’s decision-making and agency, re-imagine 
                                    and put into practice equal power relations between women and men, reduce acceptance 
                                    of GBV and VAWG, reduce unpaid care burden and calibrate shared care duties between 
                                    women and men, shift harmful attitudes toward PWD, LGBTQIA+, and other populations etc.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                

                <div class="flex flex-row mb-2 mt-10">
                    <img class="flex flex-col w-12 h-12 mr-2" src="@/assets/images/methodology/limitations.png"/>
                    <h1 class="flex flex-col text-3xl leading-6 font-medium my-auto" id="Limits">Limitations</h1>
                </div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  In constructing the Equilo application, we faced a number of challenges related to the
                  availability of quantitative data, which limits our ability to provide a more comprehensive
                  picture of gender and social inequalities within a specific country, regionally, and globally.
                  Organizations such as <a style="color: blue;" href="https://data2x.org/" target="_blank">Data2X</a> are working to solve these data gaps. In the meantime, we
                  use what is available, and grow as data grows.
                </p>

                <h1 class="text-lg leading-6 font-medium mt-10">Lack of International Data Standards</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  Measuring inequalities within specific TAAP domains and themes is constrained by the lack
                  of international agreement on data standards and methodology for specific indicators.
                  Without this, it is difficult to compare data across countries due to differences in concepts,
                  definitions, and measurement. For example, there are no <a style="color: blue;" href="https://data2x.org/wp-content/uploads/2019/05/Data2X_MappingGenderDataGaps_FullReport.pdf" target="_blank">internationally agreed-upon
                  indicators</a> regarding the opportunity cost of paid work, employment mobility, and access to
                  child care. Despite the importance of these topics for gender equality and social inclusion,
                  this means that this data cannot be included in our Workforce and Unpaid Care
                  Work themes at this time.
                </p>

                <h1 class="text-lg leading-6 font-medium mt-10">Lack of Data Coverage across
                    Countries/Regular
                    Country Production</h1>

                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  Even when international data standards exist, gender and social data gaps persist due to
                  country-level data collection and production limitations, which may include financial
                  constraints, the capacity of national statistical offices or other relevant entities, or perceived
                  irrelevance of gender data/lack of political will. According to UN Women, only <a style="color: blue;" href="https://data.unwomen.org/data-portal" target="_blank">12 of the 53</a>
                  gender-specific Sustainable Development Goal indicators have data regularly produced.
                </p><br/>
                <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    In our initial review of gender data availability, we identified a number of valuable indicators,
                    but found that data does not yet exist for the majority of global countries and thus
                    excluded the indicators from our scoring. For example, within the Political Participation &
                    Leadership theme, we excluded “Proportion of population who believe decision-making is inclusive and
                    responsive (SDG indicator 16.7.2)” and within Gender-Based Violence, we excluded “Proportion of
                    population reporting having personally felt discriminated against or harassed in the previous 12
                    months on the basis of a ground of discrimination prohibited under international human rights law
                    (SDG indicator 10.3.1).” These would be powerful indicators to include within the analysis if more
                    data was available.
                </p>
                <br/>
                <p class="text-gray-600 w-full font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    When various indicators on a similar concept were identified, we chose the indicator with greater
                    data
                    availability. For example, despite the existence of UN SDG indicator 5.4.1, “Proportion of time
                    spend on
                    unpaid
                    care work, by sex, age, and location (%), we used the ILO indicator “Minutes per day spend on unpaid
                    care
                    work”
                    due to greater comparable global data availability.
                </p>


                <h1 class="text-lg leading-6 font-medium mt-10">Availability of Disaggregated and
                    Intersectional
                    Data</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    Some data was excluded from Equilo Dash due to the lack of sex-disaggregated data. For example, some
                    internationally standardized indicators are only collected at the household level and are not
                    disaggregated by
                    the sex of the household head. While this issue presents a challenge for obtaining comprehensive
                    gender
                    equality
                    data across themes, it is even more difficult to locate data consistently disaggregated by other
                    identity
                    characteristics, such as disability status, race, ethnicity, Indigenous status, migrant status,
                    religion, age,
                    income, education level, gender identity and expression, and sexual orientation, among others. This
                    means that
                    the TAAP/theme intersections currently scored in Equilo Dash focus exclusively on gender equality
                    and women’s
                    empowerment, which masks the urgent inequalities and identity-based constraints faced by individuals
                    that
                    belong
                    to socially excluded identities, especially those at the intersection of multiple socially excluded
                    identities.
                    Given the lack of standardized and disaggregated quantitative data, Equilo Dash includes
                    qualitative,
                    contextual
                    data that shines a light on these intersecting inequalities.
                </p>
                <br/>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                    In some countries, it is dangerous to collect data on individuals from socially excluded identities.
                    For
                    example, collecting data from LGBTQIA+ individuals may directly put them at harm in countries where
                    their
                    identities and day-to-day lives are criminalized. In practice, this means that standardized data on
                    LGBTQIA+
                    individuals is limited to legal protections (or lack thereof). In other countries, disability status
                    is
                    protected so that people living with disabilities do not have to disclose personal information, thus
                    avoiding
                    discrimination. While greater data availability is often a tool to make visible the specific
                    identity-based
                    challenges, opportunities, and experiences that specific groups face, data collection must be
                    informed by the
                    ethical imperative to “do no harm,” protecting populations that may face discrimination or
                    persecution if more
                    data is available. As responsibly collected data on socially excluded groups becomes more available,
                    we will
                    incorporate it into Equilo Dash.
                </p>

            </div>
        </div>

      </div>
      </main>
      </div>
    </div>
</template>

<script>
    import VueAirtable from '@/components/VueAirtable'
    import axios from 'axios';
    import env from '@/../env.js';

    export default {
        name: 'Overview',
        data: function () {
            return {
                openPanels: [],
                apiSubsectorUrl: 'https://api.airtable.com/v0/appXebWdllnMq540w/Subsector%20Codes?view=Grid%20view&fields%5B%5D=DisplayonMethodology&fields%5B%5D=Sub_sector_ID',
                apiKey: env.airTableAccessToken,
                allSubSectors:[],
                offset:'',
            }
        },
        components: {
            VueAirtable
        },
        methods: {
            togglePanel: function (panelid) {
                if (this.openPanels.indexOf(panelid) > -1) {
                    this.openPanels.splice(this.openPanels.indexOf(panelid), 1)
                } else {
                    this.openPanels.push(panelid);
                }
            },
            getParams:function(offset){
                const params = {
                    offset: offset
                };
                return params;
            },
            processRecords:function(records){
                records.forEach((value) => { 
                    if(value.fields.DisplayonMethodology){
                        this.allSubSectors.push(value.fields.Sub_sector_ID);
                    }
                })
            },
            scrollTo(id) {
              //document.getElementById(id).scrollIntoView();
              const element = document.getElementById(id);
              const offset = -100;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element.getBoundingClientRect().top;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition + offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
              var lis = document
                .getElementsByClassName("navsticky")[0]
                .getElementsByTagName("ol")[0]
                .getElementsByTagName("li");
              for (
                let i = 0;
                i <
                document.getElementsByTagName("ol")[0].getElementsByTagName("li")
                  .length;
                i++
              ) {
                lis[i].classList.remove("active");
              }
              document.getElementById(id + "1").parentElement.classList.add("active");
            },
        },
        async created() {
            this.$store.commit('downloadable', false);
            this.$store.commit("isFreeState", true);
            this.$store.commit('setBreadcrumbs', [
                {
                    'name': 'Methodology',
                    'url': '/gesi-analysis/methodology'
                }
            ])
            
            const headers = {
		'Authorization': `Bearer ${this.apiKey}`,
                'Content-Type': "application/json"
            };
            
            try {
                let offset = 0;    // no initial offset, explicitely set to 0
                var loopvar = true;
                while (loopvar) {
                    const params = this.getParams(offset);
                    await axios.get(this.apiSubsectorUrl, {params: params, headers: headers})
                        .then((response) => {
                            offset = response.data.offset;
                            this.processRecords(response.data.records);

                        });
                    if (typeof offset === 'undefined') {
                        break;
                    }
                }
            } catch (error) {
                console.error(error)
            }
    
//              await axios({
//                url: this.apiSubsectorUrl,
//                headers: {
//                  'Authorization': `Bearer ${this.apiKey}`
//                }
//              }).then((res) => {
//                  this.offset = res.offset;
//                  res.data.records.forEach((value) => { 
//                      if(value.fields.DisplayonMethodology){
//                          this.allSubSectors.push(value.fields.Sub_sector_ID);
//                      }
//                  })
//              });
//              
//              await axios.get(this.apiSubsectorUrl, {params: {offset: this.offset}, headers: {'Authorization': `Bearer ${this.apiKey}`}})
//                .then((res) => {
//                        res.data.records.forEach((value) => { 
//                            if(value.fields.DisplayonMethodology){
//                                this.allSubSectors.push(value.fields.Sub_sector_ID);
//                            }
//                        })
//                });

        }
    }
    import $ from "jquery";
    $(window)
      .scroll(function() {
        var scrollDistance = $(window).scrollTop();
        // Show/hide menu on scroll
        //if (scrollDistance >= 850) {
        //		$('nav').fadeIn("fast");
        //} else {
        //		$('nav').fadeOut("fast");
        //}

        // Assign active class to nav links while scolling
        $(".maincontent").each(function(i) {
          if ($(this).position().top <= scrollDistance) {
            var lis = document
              .getElementsByClassName("navsticky")[0]
              .getElementsByTagName("ol")[0]
              .getElementsByTagName("li");
            for (
              let i = 0;
              i <
              document.getElementsByTagName("ol")[0].getElementsByTagName("li")
                .length;
              i++
            ) {
              lis[i].classList.remove("active");
            }

            $(".navsticky ol li")
              .eq(i)
              .addClass("active");
          }
        });
      })
      .scroll();
</script>
<style>
  a {
      color: #0077C8;
  }
  /** page layout **/
  main {
    display: grid !important;
    grid-template-columns: 10em 1fr;
    max-width: 100em;
    width: 90%;
    margin: 0 auto;
  }
  /* 2. Make nav sticky */
  .navsticky {
    position: -webkit-sticky;
    position: sticky !important;
    top: 20em;
    align-self: start !important;
    margin-left: -17px;
    color: #201747;
  }

  .navsticky ol {
    border-left: 1px solid #efefef;
  }

  /* 3. ScrollSpy active styles (see JS tab for activation) */
  .navsticky li.active > button {
    color: #fe5000;
    font-weight: 500;
  }

  .navsticky button, .navsticky a {
    text-decoration: none;
    display: block;
    padding: 0.125rem 0;
    color: #79879C;
    transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
  }

  .navsticky button:hover,
  .navsticky button:focus {
    color: rgba(45, 55, 72, var(--text-opacity));
    border: none;
    outline: none;
  }
  @media only screen and (max-width: 768px) {
    .navsticky {
      display: none;
    }
    main {
      display: block !important;
    }
  }
</style>
