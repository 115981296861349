<template>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[60]"
  >
    <div class="bg-white w-full max-w-md p-6 rounded-lg shadow-lg" @click.stop>
      <h2 class="text-[1.2rem] font-semibold text-center mb-1">
        2X Challenge Self-Assessment Modules
      </h2>

      <div class="mb-4">
        <label class="block text-gray-900 text-base mb-3">
          To qualify under the 2X Challenge you are required to complete
          {{entityType == 'fund-manager' || entityType == 'financial-institution' ? 'three' : 'two'}}
          mandatory 2X Criteria:
        </label>
        <ul class="text-gray-900 ml-4 mb-3" style="list-style-type: disc;" v-if="entityType == 'financial-institution' && fiIndividualCompanyValue == 'Only individuals'">
          <li v-for="mod in challengeMandatoryModules" :key="mod">{{ mod }}</li>
        </ul>
        <ul class="text-gray-900 ml-4 mb-3" style="list-style-type: disc;" v-else>
          <li>Governance and Accountability</li>
          <li>Basic 2X ESG</li>
          <li v-if="entityType == 'fund-manager' || entityType == 'financial-institution'">Portfolio</li>
        </ul>
        <label class="block text-gray-900 text-base mb-3">
          You are also required to complete {{ entityType == 'fund-manager' || entityType == 'financial-institution' ?
          'one 2X Criteria at the organisation level' :
          'two 2X Criteria'}}.
          Please select which 2X Criteria you wish to provide data for:
        </label>
        <ul class="text-gray-900">
          <li
            v-for="module in ((entityType == 'financial-institution' && fiIndividualCompanyValue == 'Only individuals') ? challengeDimensionModules : surveyModules)"
            :key="module.surveyUrl"
            class="flex items-center mb-2 gap-2"
            :style="{ 'display': module.surveyUrl == '2x-commitment' ? 'block' : 'flex' }"
          >
            <div v-if="module.surveyUrl == '2x-commitment'" class="block text-gray-900 text-base mb-3 mt-3">
              <span v-if="modulesCountWithoutCommitment < requiredModule">
                {{ entityType == 'fund-manager' || entityType == 'financial-institution' ?
                'Funds/FIs need to meet one Criteria or make a commitment to one Criteria. Please make a commitment if no Criteria above are met.' :
                'Companies need to meet two Criteria, or meet one Criteria and make a commitment to a second. Please make a commitment' }}
                </span>
              <span v-else>
                {{ entityType == 'fund-manager' || entityType == 'financial-institution' ?
                'Funds/FIs need to meet 1 Criteria above, so it is optional to make an additional commitment.' :
                'Companies need to meet two Criteria so it is optional to make an additional commitment' }}
              </span>
            </div>
            <input
              type="checkbox"
              v-model="selectedModules"
              :value="module"
              :id="module.surveyUrl"
              @change="onCheckboxChange"
              class="h-5 w-5 text-blue-600 disabled:opacity-50 disabled:bg-gray-300 disabled:cursor-not-allowed"
              :class="{'pointer-events-none' : (modulesCountWithoutCommitment < requiredModule && module.surveyUrl == '2x-commitment') }"
            />
            <span>
              {{ module.name }}
            </span>
          </li>
        </ul>
      </div>

      <p v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</p>

      <div class="flex justify-end items-center mt-4 gap-4">
        <button
          @click="closeModal"
          class="bg-[#FF3A21] text-white py-2 px-4 rounded-md hover:bg-[#FF3A21]"
        >
          Cancel
        </button>
        <button
          @click="submitSelectedModules"
          class="bg-[#201747] text-white py-2 px-4 rounded-md hover:bg-[#191b45]"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"TwoxModuleSelectionModal",
  props: {
    visible: Boolean,
    surveyModules: Array,
    entityType: [String, null],
    fiIndividualCompanyValue: [String, null],
    challengeMandatoryModules: [Array, null, Object],
    challengeDimensionModules: [Array, null, Object],
  },
  data() {
    return {
      selectedModules: [],
      errorMessage: "",
      requiredModule: 0,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    submitSelectedModules() {
      if (this.selectedModules.length === 0) {
        this.errorMessage = "Please select at least one survey module.";
      } else {
        this.$emit("submit", this.selectedModules);
        this.errorMessage = "";
      }
    },
    async onCheckboxChange() {
      if(this.modulesCountWithoutCommitment < this.requiredModule && !this.selectedModules?.some(item => item?.surveyUrl == '2x-commitment')) {
        await this.selectedModules.push(this.surveyModules.filter(mod => mod.surveyUrl == '2x-commitment')[0]);
      }
    }
  },
  async mounted() {
    // By default selected commitment module
    if(this.entityType == 'fund-manager' || this.entityType == 'financial-institution') {
      this.requiredModule = 1;
    } else  {
      this.requiredModule = 2;
    }
    if(!this.selectedModules?.some(item => item?.surveyUrl == '2x-commitment')) {
      await this.selectedModules.push(this.surveyModules.filter(mod => mod.surveyUrl == '2x-commitment')[0]);
    }
  },
  computed: {
    modulesCountWithoutCommitment() {
      let count = 0;
      this.selectedModules.forEach(element => {
        if(element.surveyUrl != '2x-commitment') count++;
      })
      return count;
    }
  }
};
</script>
