<template>
  <div>
    <p
      class="font-sans text-2xl font-bold leading-[28px] text-left text-[#181818]"
    >
      Select all modules you want your portfolio companies to report on, and
      indicate the preferred frequency for receiving updated data. In addition,
      you may choose to fill out survey modules on behalf of your portfolio
      companies
    </p>
    <div class="mt-4 grid grid-rows-1 gap-1">
      <div
        v-for="(module, index) in modulesList"
        :key="module.title + index"
        :class="
          module.checked
            ? 'w-[616px] p-6 rounded-[8px] border border-[#2177c1] gap-4 mb-5'
            : 'w-[616px] p-6 rounded-[8px]  border-[#9ea4ba] border-[1px] gap-4 mb-5'
        "
      >
        <div class="flex justify-between">
          <div class="flex justify-between">
            <p
              class="font-sans text-[20px] font-normal leading-[28px] text-left text-[#2c2e35] font-semibold"
            >
              {{ module.title }}
            </p>
            <div class="w-4 h-4 ml-1.5 relative inline-block group">
              <img src="./../../../assets/images/IMM/info-filled.svg" />
              <span
                class="hidden group-hover:block bg-[#2c2e35] text-white absolute z-10 left-[-237px] top-[38px] w-[480px] p-4 rounded-md gap-4 font-sans text-base font-normal leading-6 tracking-normal text-center"
              >
                {{ module.hoverText }}
              </span>
            </div>
          </div>
          <input
            type="checkbox"
            :id="module.title"
            v-model="module.checked"
            @click="moduleChecked(index)"
            class=""
          />
        </div>
        <div class="mt-4" v-if="module.checked">
          Choose preferred reporting frequency for this survey module
        </div>
        <div class="flex gap-6" v-if="module.checked">
          <div
            class="flex gap-2 mt-2"
            v-for="frequency in frequencyOptions"
            :key="frequency"
          >
            <input
              type="radio"
              :id="module.title + '_' + frequency"
              :value="frequency"
              v-model="module.frequency"
              @change="frequencyChecked(module, frequency)"
            />
            <label :for="frequency">{{ frequency }}</label>
          </div>
        </div>
        <div class="mt-4" v-if="module.checked">
          Who should complete this survey module?
          <div class="flex gap-2 mt-2">
            <input
              type="radio"
              :id="module.title + '_portfolio'"
              value="portfolio"
              v-model="module.completedBy"
              @change="updateCompletedBy(module, 'portfolio')"
            />
            <label :for="module.title + '_portfolio'"
              >My portfolio companies</label
            >
            <input
              type="radio"
              :id="module.title + '_myself'"
              value="investor"
              v-model="module.completedBy"
              @change="updateCompletedBy(module, 'investor')"
            />
            <label :for="module.title + '_myself'">Myself</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step2",
  props: ["fieldValue"],
  data() {
    return {
      selectedFrequency: "",
      frequencyOptions: ["Monthly", "Quarterly", "Annually"],
      modulesList: [
        {
          title: "2X Certification Self-Assessment",
          hoverText:
            "Assess a portfolio company's actions across a comprehensive set of Dimensions and Themes that build on " +
            "the 2X Criteria and move beyond. If an entity meets the minimum 2X Criteria and is 2X eligible, " +
            "it may proceed with 2X Certification which includes verification of the self-reported data through " +
            "third-party verifiers. Please note 2X Certification is optional.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
        {
          title: "Gender ROI™",
          hoverText:
            "Conduct a gender analysis of a portfolio company's leadership, workforce, value chain, and society where they operate.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
        {
          title: "Impact",
          hoverText:
            "Assess the long-term effects or benefits brought about for the intended beneficiaries or stakeholders through the portfolio company's daily business. This includes impacts on people and the natural environment.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
        {
          title: "ESG",
          hoverText:
            "Assess a portfolio company's management of  environmental, social and governance risks and opportunities. This includes  assessment of environmental and social impacts, as well as how governance structures can be amended to maximize stakeholder well-being.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
        {
          title: "Climate",
          hoverText:
            "Assess a portfolio company's strategies to address and mitigate climate change, including a commitment to diminish its greenhouse gas emissions, embrace sustainable practices, and allocate resources toward environmentally friendly, renewable, or low-carbon products and services.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
        {
          title: "Finance",
          hoverText:
            "Assess a portfolio company's overall standing in categories such as assets, liabilities, equity, expenses, revenue,and overall profitability.",
          checked: false,
          isOpened: false,
          frequency: "",
          completedBy: "",
        },
      ],
      preferencesArray: [],
    };
  },
  mounted() {
    if (this.fieldValue && this.fieldValue.preferencesArray) {
      this.modulesList.forEach((module) => {
        const foundModule = this.fieldValue.preferencesArray.find(
          (item) => item.title === module.title
        );
        if (foundModule) {
          module.checked = true;
          module.frequency = foundModule.frequency;
          module.completedBy = foundModule.completedBy;
        }
      });
      this.preferencesArray = this.fieldValue.preferencesArray;
      this.$emit("input-typed", {
        isStepActive: true,
        preferencesArray: this.preferencesArray,
      });
    }
  },
  methods: {
    moduleChecked(index) {
      const module = this.modulesList[index];
      module.checked = !module.checked;
      if (!module.checked) {
        module.frequency = "";
        const existingIndex = this.preferencesArray.findIndex(
          (item) => item.title === module.title
        );
        if (existingIndex !== -1) {
          this.preferencesArray.splice(existingIndex, 1);
        }
      }
      this.emitValue(false);
    },
    frequencyChecked(module, frequency) {
      const existingIndex = this.preferencesArray.findIndex(
        (item) => item.title === module.title
      );
      if (existingIndex !== -1) {
        this.preferencesArray[existingIndex].frequency = frequency;
      } else {
        this.preferencesArray.push({
          title: module.title,
          frequency: frequency,
          completedBy: module.completedBy,
        });
      }
      this.updateEmitValue();
    },
    updateCompletedBy(module, completedBy) {
      const existingIndex = this.preferencesArray.findIndex(
        (item) => item.title === module.title
      );
      if (existingIndex !== -1) {
        this.preferencesArray[existingIndex].completedBy = completedBy;
      } else {
        this.preferencesArray.push({
          title: module.title,
          frequency: module.frequency,
          completedBy: completedBy,
        });
      }
      this.updateEmitValue();
    },
    updateEmitValue() {
      const hasEmpty = this.preferencesArray.some(
        (cert) =>
          cert.title == "" || cert.frequency == "" || cert.completedBy == ""
      );
      this.emitValue(!hasEmpty);
    },
    resetState() {
      this.selectedFrequency = "";
      this.preferencesArray = [];
    },
    emitValue(value) {
      this.$emit("input-typed", {
        isStepActive: value,
        preferencesArray: this.preferencesArray,
      });
    },
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>
