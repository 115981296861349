<template>
  <div class="static assementPageStyle" id="contentForm">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        key="load"
        class="container"
        v-if="endofcreated && allROIData && allROIData.length"
      >
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <img
              v-if="
                roiCompanyDetails.logo &&
                roiCompanyDetails.logo[0] &&
                roiCompanyDetails.logo[0].url
              "
              class="h-16 print:h-auto print:w-64"
              :src="roiCompanyDetails.logo[0].url"
              style="height: 10rem"
            />
            <h1
              class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl"
            >
              <span v-if="roiCompanyDetails && roiCompanyDetails.member2xc">{{
                roiCompanyDetails.member2xc + "'s"
              }}</span>
              Gender ROI™ Portfolio
            </h1>
          </div>
          <div class="powerd_invite-wrapper">
            <div class="powerdby_logo_wrapper">
              <div class="Poweredby_logoImg">
                <span class="powerd-logo">Powered by:</span>
                <img
                  src="@/assets/images/roi/sweef-capital.png"
                  alt="background-image"
                  style="height: 50px"
                />
                <img
                  src="@/assets/images/logo-navy.png"
                  alt="background-image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex my-10">
          <div
            class="portfolio_toggle flex w-2/4"
            v-if="toggleOptions && toggleOptions.length"
          >
            <div v-for="(tog, index) in toggleOptions" :key="'toggle' + index">
              <div
                class="flex mr-1"
                style=""
                :class="
                  tog.isSelected ? 'selected-toggle' : 'unselected-toggle'
                "
                v-if="tog.isDisplay"
                @click="switchPortfolioData(tog)"
              >
                <span style="margin: auto">{{ tog.status }}</span>
              </div>
            </div>
          </div>
          <div
            class="favorite-shared-button-section w-2/4"
            v-if="isToggleEnable"
          >
            <p>
              <span
                class="text-2xl tracking-tighter font-medium text-center print:text-7xl ml-5"
                >Company</span
              >
              <label class="toggleSwitch large">
                <input
                  type="checkbox"
                  id="favChecked"
                  @click="togglePortfolio()"
                />
                <span>
                  <span></span>
                  <span></span>
                </span>
                <a></a>
              </label>
              <span
                class="text-2xl tracking-tighter font-medium text-center print:text-7xl ml-4"
                >Funds/FIs</span
              >
            </p>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in">
          <div
            id="loaded"
            v-if="
              endofcreated &&
              roiData.investedCompaniesList &&
              toggleOptionLength > 0
            "
            key="content"
          >
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper">
              <div
                class="most_recent_message_section"
                id="mostRecentMessageSection"
              >
                <span
                  class="close"
                  style="top: -35px !important; right: -18px !important"
                  @click="closeMostRecentSurveyMessageSection()"
                  >&times;</span
                >
                <span class="">
                  This Portfolio Aggregate Gender ROI™ score is based on
                  {{ roiCompletedCompaniesTotal }}
                  {{
                    investor
                      ? roiCompletedCompaniesTotal > 1
                        ? "Funds/FIs"
                        : "Fund/FI"
                      : roiCompletedCompaniesTotal > 1
                      ? "portfolio companies"
                      : "portfolio company"
                  }}
                  who have fully completed the survey out of a total of
                  {{ roiInvestedCompaniesCount }}
                  {{
                    investor
                      ? roiInvestedCompaniesCount > 1
                        ? "Funds/FIs"
                        : "Fund/FI"
                      : roiInvestedCompaniesCount > 1
                      ? "portfolio companies"
                      : "portfolio company"
                  }}.</span
                >
              </div>
              <div class="OverviewBlock flex">
                <div class="OverviewBlock_left_side mr-5">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h2 style="width: 1780px">
                        {{ roiCompanyDetails.member2xc + "'s" }} Overview
                      </h2>
                      <span></span>
                    </div>
                    <ul>
                      <li v-if="roiCompanyDetails.organizationType">
                        <span
                          >Enterprise Type:
                          <b> {{ roiOrganizationtype }} </b></span
                        >
                      </li>
                      <li v-if="roiCompanyDetails.countryHqText">
                        <span
                          >Headquarters:
                          <b>{{ roiCompanyDetails.countryHqText }}</b></span
                        >
                      </li>
                      <li>
                        <span>Sector: <b>Financial Services</b></span>
                      </li>
                      <li v-if="roiSectors">
                        <span
                          >Sectors of Interest: <b>{{ roiSectors }}</b></span
                        >
                      </li>
                      <li v-if="roiCompanyDetails.countryHeadquartersText">
                        <span
                          >Headquarters:
                          <b>{{
                            roiCompanyDetails.countryHeadquartersText
                          }}</b></span
                        >
                      </li>
                      <li
                        v-if="roiInvestedCompanies && roiInvestedCompaniesCount"
                        class="cursor-pointer"
                        :key="updateKey"
                      >
                        <a>
                          <span @click="scrollToElement()"
                            >Number of
                            {{ investor ? "Funds/FIs" : "Companies" }} invested
                            in :
                            <b>{{
                              roiInvestedCompanies && roiInvestedCompaniesCount
                                ? roiInvestedCompaniesCount
                                : "NA"
                            }}</b></span
                          >
                        </a>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <span
                      class="flex"
                      style="align-items: center"
                      id="learnMoreAbout"
                    >
                      <h3 class="font-semibold">
                        Learn about the Gender ROI™ Assessment
                      </h3>
                      <img
                        class="cursor-pointer"
                        src="@/assets/images/plus-sign-new.svg"
                        style="height: 20px"
                        @click="learnMoreAboutModal = true"
                      />
                    </span>
                    <div
                      class="flex"
                      v-if="isPageRefreshed"
                      :key="updateKeyAfterRefresh"
                    >
                      <div class="mt-5" v-if="csvData" id="DownloadCSVBtn">
                        <div class="DownloadBtn">
                          <div class="tooltip">
                            <div @click="checkDownloadCSVPermission()">
                              <p style="cursor: pointer; margin-top: 3px">
                                <img
                                  src="../../assets/images/DownloadBtn.png"
                                  alt=""
                                />
                                Download XLSX
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-5 ml-10"
                        id="DownloadPDFBtn"
                        v-if="isPageRefreshed"
                        :key="updateKeyAfterRefresh"
                      >
                        <div class="DownloadBtn">
                          <div class="tooltip">
                            <div @click="checkDownloadPDFPermission()">
                              <p style="cursor: pointer; margin-top: 3px">
                                <img
                                  src="../../assets/images/DownloadBtn.png"
                                  alt=""
                                />
                                <span :key="downloadPDF">{{
                                  downloadPDF
                                    ? "Downloading..."
                                    : "Download PDF"
                                }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="OverviewBlock_right_side ml-5" :key="updateKey">
                  <div class="gender_roi_score">
                    <div class="OverviewTitle">
                      <h2 style="width: 1962px">
                        Portfolio Aggregate Gender ROI™ Score
                      </h2>
                      <span></span>
                    </div>
                    <div class="mb-8">
                      <h1 class="font-bold text-5xl">{{ genderRoiScore }}</h1>
                    </div>
                    <div class="gender_roi_score_chart">
                      <k-progress
                        style="
                          width: 100%;
                          margin: 0 !important;
                          position: relative;
                          top: 7px;
                        "
                        :percent="((genderRoiScore * 100) / 6) | mathOnlyRound"
                        :show-text="false"
                        color="#2BD4C4"
                      >
                      </k-progress>
                      <img
                        src="@/assets/images/roi/chart-bar.png"
                        style="position: relative; width: 100%"
                      />
                    </div>
                    <div class="finance_average_score mt-5" v-if="investor">
                      <div class="OverviewTitle">
                        <h2 style="width: 700px" class="cursor-pointer">
                          Financial Processes, Products, & Services
                        </h2>
                      </div>
                      <div class="mb-8">
                        <h1 class="font-bold text-5xl">
                          {{ averageFinanceScore }}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spiderchart_main_container mt-10 mb-10">
                <div class="OverviewTitle">
                  <h2 style="width: 1300px">
                    Portfolio Aggregate Pillar Assessment Results
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content flex">
                  <!-- <div class="left_side">
                    <template v-if="portfolioSwitchTog == 'Portfolio'">
                      <div class="flex" v-if="roiData.investedCompaniesList">
                        <div class="orange-dot mr-5"></div>
                        <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                      </div>
                      <div class="flex dot_with_text" v-if="(allROIData.length > 1 && oldRoiData && oldRoiData.investedCompaniesList && oldRoiData.investedCompaniesList.length > 0)">
                        <div class="blue-dot mr-5"></div>
                        <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                            allROIData[1].companyDetails[0].financialReportingYear
                        }}</span>
                        <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First
                          Historical Year</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex">
                        <div class="orange-dot mr-5"></div>
                        <span class="font-bold">Current</span>
                      </div>
                    </template>
                  </div> -->
                  <div class="right_side">
                    <div class="spiderchartContent" id="chart">
                      <div class="relative" :key="updateKey">
                        <spiderchart-multiple
                          v-if="
                            allROIData.length > 1 &&
                            oldRoiData &&
                            oldRoiData.investedCompaniesList &&
                            oldRoiData.investedCompaniesList.length > 0
                          "
                          :paddingL="40"
                          :paddingR="40"
                          :paddingT="40"
                          :paddingB="40"
                          :financialYear="
                            portfolioSwitchTog == 'Portfolio'
                              ? allROIData[0].companyDetails[0]
                                  .financialReportingYear
                              : 'Current'
                          "
                          :financialYear1="
                            portfolioSwitchTog == 'Portfolio'
                              ? allROIData[1].companyDetails[0]
                                  .financialReportingYear
                              : 'Current'
                          "
                          :details="roiData.roiPortfolioData"
                          :detailsOld="oldRoiData.roiPortfolioData"
                          benchmarkLabel="Gender ROI™ Benchmark"
                          benchmarkText="This line represents the average score of all users who responded to the Gender ROI™ Assessment. This benchmark allows your entity to compare its performance against the collective results of all ROI assessments."
                          class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%"
                        >
                        </spiderchart-multiple>
                        <spiderchart
                          v-else
                          :paddingL="40"
                          :paddingR="40"
                          :paddingT="40"
                          :paddingB="40"
                          :financialYear="
                            portfolioSwitchTog == 'Portfolio'
                              ? allROIData[0].companyDetails[0]
                                  .financialReportingYear
                              : 'Current'
                          "
                          :details="roiData.roiPortfolioData"
                          benchmarkLabel="Gender ROI™ Benchmark"
                          benchmarkText="This line represents the average score of all users who responded to the Gender ROI™ Assessment. This benchmark allows your entity to compare its performance against the collective results of all ROI assessments."
                          class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%"
                        ></spiderchart>
                        <template>
                          <!--Opportunity-->
                          <div class="Ind_Inv_Icon opportunity-icon" id="2uur">
                            <img
                              src="../../assets/images/roi/opportunity.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #2bb900"> Opportunity </span>
                          </div>

                          <!--Inclusion-->
                          <div class="Ind_Inv_Icon inclusion-icon" id="8uur">
                            <img
                              src="../../assets/images/roi/inclusion.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #00a269"> Inclusion </span>
                          </div>

                          <!--Resilince-->
                          <div class="Ind_Inv_Icon resilince-icon" id="10uur">
                            <img
                              src="../../assets/images/roi/resilience.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #0068cd"> Resilience </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Domain Assessment Results -->
              <div class="domain_assessment_results_section">
                <div class="OverviewTitle">
                  <h2 style="width: 600px">Domain Assessment Results</h2>
                  <span></span>
                </div>
                <div class="domain_assessment_results_container flex">
                  <div class="spiderchart_container">
                    <div class="spiderchart_content">
                      <div class="left_side">
                        <template v-if="portfolioSwitchTog == 'Portfolio'">
                          <div
                            class="flex"
                            v-if="roiData.investedCompaniesList"
                          >
                            <div class="orange-dot mr-5"></div>
                            <span class="font-bold">{{
                              allROIData[0].companyDetails[0]
                                .financialReportingYear
                            }}</span>
                          </div>
                          <div
                            class="flex dot_with_text"
                            v-if="
                              allROIData.length > 1 &&
                              oldRoiData &&
                              oldRoiData.investedCompaniesList &&
                              oldRoiData.investedCompaniesList.length > 0
                            "
                          >
                            <div class="blue-dot mr-5"></div>
                            <span
                              class="font-bold"
                              v-if="
                                allROIData[1].companyDetails[0]
                                  .financialReportingYear
                              "
                              >{{
                                allROIData[1].companyDetails[0]
                                  .financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                allROIData[1].companyDetails[0]
                                  .financialReportingYear == ''
                              "
                              >First Historical Year</span
                            >
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex">
                            <div class="orange-dot mr-5"></div>
                            <span class="font-bold">Current</span>
                          </div>
                        </template>
                      </div>
                      <div class="" style="width: 100%">
                        <div class="spiderchartContent" id="chart">
                          <div class="relative" :key="updateKey">
                            <domain-spiderchart-multiple
                              v-if="
                                allROIData.length > 1 &&
                                oldRoiData &&
                                oldRoiData.investedCompaniesList &&
                                oldRoiData.investedCompaniesList.length > 0
                              "
                              :paddingL="40"
                              :paddingR="40"
                              :paddingT="40"
                              :paddingB="40"
                              :details="roiData.domainAssesmnet"
                              :detailsOld="oldRoiData.domainAssesmnet"
                              class="overflow-visible"
                              style="
                                position: relative;
                                height: 100%;
                                width: 100%;
                              "
                            />
                            <domain-spiderchart
                              v-else
                              :paddingL="40"
                              :paddingR="40"
                              :paddingT="40"
                              :paddingB="40"
                              :details="roiData.domainAssesmnet"
                              class="overflow-visible"
                              style="
                                position: relative;
                                height: 100%;
                                width: 100%;
                              "
                            ></domain-spiderchart>
                            <template>
                              <!--Leadership-->
                              <div
                                class="Ind_Inv_Icon leadership-icon"
                                id="2uur"
                              >
                                <img
                                  src="../../assets/images/leadership.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Leadership </span>
                              </div>
                              <!--Workforce-->
                              <div
                                class="Ind_Inv_Icon workforce-icon"
                                id="2uur"
                              >
                                <img
                                  src="../../assets/images/workforce.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Workforce </span>
                              </div>

                              <!--Value Chain-->
                              <div
                                class="Ind_Inv_Icon value-chain-icon"
                                id="8uur"
                              >
                                <img
                                  src="../../assets/images/value_chain.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Value Chain </span>
                              </div>

                              <!--Society-->
                              <div class="Ind_Inv_Icon society-icon" id="10uur">
                                <img
                                  src="../../assets/images/society.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Society </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gender_roi_domain">
                    <div class="mb-5">
                      <h1 class="font-bold text-2xl">Gender ROI™ Domain</h1>
                    </div>
                    <div class="gender_roi_domain_taabs_container">
                      <div
                        class="flex my-4 items-center"
                        v-for="(dom, leadIndex) in roiData.domainAssesmnet"
                        :key="'Leadership' + leadIndex"
                        v-show="dom.name == 'Leadership'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            Math.round(
                              ((Number(dom.BonusScore) +
                                Number(dom.ThresholdScore)) /
                                6) *
                                100
                            )
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img
                          class="mx-4"
                          src="@/assets/images/leadership.svg"
                        />
                        <h2 class="font-bold text-xl">Leadership</h2>
                      </div>
                      <div
                        class="flex my-4 items-center"
                        v-for="(dom, workIndex) in roiData.domainAssesmnet"
                        :key="'Workforce' + workIndex"
                        v-show="dom.name == 'Workforce'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            Math.round(
                              ((Number(dom.BonusScore) +
                                Number(dom.ThresholdScore)) /
                                6) *
                                100
                            )
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img class="mx-4" src="@/assets/images/workforce.svg" />
                        <h2 class="font-bold text-xl">Workforce</h2>
                      </div>
                      <div
                        class="flex my-4 items-center"
                        v-for="(dom, chainIndex) in roiData.domainAssesmnet"
                        :key="'Value' + chainIndex"
                        v-show="dom.name == 'Value Chains'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            Math.round(
                              ((Number(dom.BonusScore) +
                                Number(dom.ThresholdScore)) /
                                6) *
                                100
                            )
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img
                          class="mx-4"
                          src="@/assets/images/value_chain.svg"
                        />
                        <h2 class="font-bold text-xl">Value Chain</h2>
                      </div>
                      <div
                        class="flex my-4 items-center"
                        v-for="(dom, societyIndex) in roiData.domainAssesmnet"
                        :key="'Society' + societyIndex"
                        v-show="dom.name == 'Society'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            Math.round(
                              ((Number(dom.BonusScore) +
                                Number(dom.ThresholdScore)) /
                                6) *
                                100
                            )
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img class="mx-4" src="@/assets/images/society.svg" />
                        <h2 class="font-bold text-xl">Society</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parameters -->
              <div class="parameters_block" :key="updateKey">
                <div class="parameters_section_first">
                  <div class="OverviewTitle">
                    <h2 style="width: 1000px">
                      Portfolio Aggregate Parameter Scores
                    </h2>
                    <span></span>
                    <!-- <div class="InviteBtn" style="width: 400px; margin: 0 20px">
                      <div class="tooltip TooltipImgText">
                        <button class="
                            text-white text-sm
                            font-medium
                            pointer-events-none
                          ">
                          View Detailed Assessment
                        </button>
                        <span class="tooltiptext" style="
                            width: 200px;
                            left: 118px;
                            top: 114%;
                            height: auto;
                          ">
                          Coming Soon!
                        </span>
                      </div>
                    </div>
                    <div class="InviteBtn" style="width: 300px">
                      <div class="tooltip TooltipImgText">
                        <button class="
                            text-white text-sm
                            font-medium
                            pointer-events-none
                          ">
                          View Action Plan
                        </button>
                        <span class="tooltiptext" style="
                            width: 200px;
                            left: -108px;
                            top: 114%;
                            height: auto;
                          ">
                          Coming Soon!
                        </span>
                      </div>
                    </div> -->
                  </div>
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/resilience.png" />
                          <h3 class="font-bold text-2xl">Resilience</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.resiliencePercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                          <!-- <vue-circle :progress="Math.round(roiData.resiliencePercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                          <!-- <vue-ellipse-progress :thickness="6" fontSize="12px" :size="50" :color="gradient" :progress="Math.round(roiData.resiliencePercentage * 100)">
                            <span slot="legend-value">%</span>
                          </vue-ellipse-progress> -->
                        </div>
                        <div
                          class="flex right_side_flex"
                          style="font-size: 13px"
                        >
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                          <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div
                              class="flex"
                              v-if="roiData.investedCompaniesList"
                            >
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{
                                allROIData[0].companyDetails[0]
                                  .financialReportingYear
                              }}</span>
                            </div>
                            <div
                              class="flex dot_with_text"
                              v-if="
                                allROIData.length > 1 &&
                                oldRoiData &&
                                oldRoiData.investedCompaniesList &&
                                oldRoiData.investedCompaniesList.length > 0
                              "
                            >
                              <div class="blue-dot mr-5"></div>
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                "
                                >{{
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                }}</span
                              >
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear == ''
                                "
                                >First Historical Year</span
                              >
                            </div>
                          </template>
                          <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                          </template>

                          <!-- <div class="flex dot_with_text">
                          <div class="blue-dot mr-2"></div>
                          <span class="font-bold">Benchmark</span>
                        </div> -->
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section" :key="updateKey">
                      <RoiProgressBarsSectionMultiple
                        v-if="
                          allROIData.length > 1 &&
                          oldRoiData &&
                          oldRoiData.investedCompaniesList &&
                          oldRoiData.investedCompaniesList.length > 0
                        "
                        :scoreData="roiData.roiPortfolioData.Resilience"
                        :scoreDataOld="oldRoiData.roiPortfolioData.Resilience"
                        :roiData="roiData"
                      />
                      <RoiProgressBarsSection
                        v-else
                        :scoreData="roiData.roiPortfolioData.Resilience"
                      />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/opportunity.svg" />
                          <h3 class="font-bold text-2xl">Opportunity</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.opportunityPercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                          <!-- <vue-circle :progress="Math.round(roiData.opportunityPercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                          <!-- <vue-ellipse-progress :thickness="6" fontSize="12px" :size="50" :color="gradient" :progress="Math.round(roiData.opportunityPercentage * 100)">
                            <span slot="legend-value">%</span>
                          </vue-ellipse-progress> -->
                        </div>
                        <div
                          class="flex right_side_flex"
                          style="font-size: 13px"
                        >
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                          <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div
                              class="flex"
                              v-if="roiData.investedCompaniesList"
                            >
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{
                                allROIData[0].companyDetails[0]
                                  .financialReportingYear
                              }}</span>
                            </div>
                            <div
                              class="flex dot_with_text"
                              v-if="
                                allROIData.length > 1 &&
                                oldRoiData &&
                                oldRoiData.investedCompaniesList &&
                                oldRoiData.investedCompaniesList.length > 0
                              "
                            >
                              <div class="blue-dot mr-5"></div>
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                "
                                >{{
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                }}</span
                              >
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear == ''
                                "
                                >First Historical Year</span
                              >
                            </div>
                          </template>
                          <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                          </template>

                          <!-- <div class="flex dot_with_text">
                          <div class="blue-dot mr-2"></div>
                          <span class="font-bold">Benchmark</span>
                        </div> -->
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section" :key="updateKey">
                      <RoiProgressBarsSectionMultiple
                        v-if="
                          allROIData.length > 1 &&
                          oldRoiData &&
                          oldRoiData.investedCompaniesList &&
                          oldRoiData.investedCompaniesList.length > 0
                        "
                        :scoreData="roiData.roiPortfolioData.Opportunities"
                        :scoreDataOld="
                          oldRoiData.roiPortfolioData.Opportunities
                        "
                        :roiData="roiData"
                      />
                      <RoiProgressBarsSection
                        v-else
                        :scoreData="roiData.roiPortfolioData.Opportunities"
                      />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/inclusion.svg" />
                          <h3 class="font-bold text-2xl">Inclusion</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.inclusionPercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                          <!-- <vue-circle :progress="Math.round(roiData.inclusionPercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                          <!-- <vue-ellipse-progress :thickness="6" fontSize="12px" :size="50" :color="gradient" :progress="Math.round(roiData.inclusionPercentage * 100)">
                            <span slot="legend-value">%</span>
                          </vue-ellipse-progress> -->
                        </div>
                        <div
                          class="flex right_side_flex"
                          style="font-size: 13px"
                        >
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                          <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div
                              class="flex"
                              v-if="roiData.investedCompaniesList"
                            >
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{
                                allROIData[0].companyDetails[0]
                                  .financialReportingYear
                              }}</span>
                            </div>
                            <div
                              class="flex dot_with_text"
                              v-if="
                                allROIData.length > 1 &&
                                oldRoiData &&
                                oldRoiData.investedCompaniesList &&
                                oldRoiData.investedCompaniesList.length > 0
                              "
                            >
                              <div class="blue-dot mr-5"></div>
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                "
                                >{{
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear
                                }}</span
                              >
                              <span
                                class="font-bold"
                                v-if="
                                  allROIData[1].companyDetails[0]
                                    .financialReportingYear == ''
                                "
                                >First Historical Year</span
                              >
                            </div>
                          </template>
                          <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                          </template>
                          <!-- <div class="flex dot_with_text">
                          <div class="blue-dot mr-2"></div>
                          <span class="font-bold">Benchmark</span>
                        </div> -->
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section" :key="updateKey">
                      <RoiProgressBarsSectionMultiple
                        v-if="
                          allROIData.length > 1 &&
                          oldRoiData &&
                          oldRoiData.investedCompaniesList &&
                          oldRoiData.investedCompaniesList.length > 0
                        "
                        :scoreData="roiData.roiPortfolioData.Inclusion"
                        :scoreDataOld="oldRoiData.roiPortfolioData.Inclusion"
                        :roiData="roiData"
                      />
                      <RoiProgressBarsSection
                        v-else
                        :scoreData="roiData.roiPortfolioData.Inclusion"
                      />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="investeed_companies mt-10"
                ref="investeedCompanies"
                v-if="roiInvestedCompanies && roiInvestedCompanies.length"
                :key="updateKey"
              >
                <div class="CompanyTitle">
                  Number of {{ investor ? "Funds/FIs" : "Companies" }} invested
                  in :
                  {{
                    roiInvestedCompanies && roiInvestedCompaniesCount
                      ? roiInvestedCompaniesCount
                      : "NA"
                  }}
                </div>
                <div class="companies_and_fund_table">
                  <table
                    class="divide-y divide-gray-200 bg-white w-full"
                    v-if="portfolioSwitchTog == 'Portfolio'"
                  >
                    <thead>
                      <tr>
                        <th class="px-3 py-2" style="width: 17rem">
                          <div class="flex">
                            <span>{{
                              investor ? "Funds/FIs" : "Companies"
                            }}</span>
                          </div>
                        </th>
                        <th class="px-3 py-2">
                          <div class="flex">
                            <span class="m-auto"> Gender ROI™ Score </span>
                          </div>
                        </th>
                        <th class="px-3 py-2">
                          <div class="flex">
                            <span class="m-auto">Quarter Completed </span>
                          </div>
                        </th>
                        <th class="px-3 py-2" style="width: 17rem">
                          <div class="flex">
                            <span class="m-auto"
                              >Time for a repeat assessment?</span
                            >
                          </div>
                        </th>
                        <th class="px-3 py-2">
                          <div class="flex">
                            <span class="m-auto"
                              >Progress of Current/Repeat Assessment</span
                            >
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr
                        v-for="(company, index) in roiInvestedCompanies"
                        :key="'company_fund' + index"
                      >
                        <!-- Funds/FIs -->
                        <td class="px-3 py-8">
                          <div class="flex image-center">
                            <img
                              class="organization-img"
                              :src="company.logo[0].url"
                              v-if="
                                company &&
                                company.logo &&
                                company.logo[0] &&
                                company.logo[0].url
                              "
                            />
                            <span
                              class="organization-name cursor-pointer"
                              v-if="company.percentage == '100'"
                              @click="redirectToCompany(company.recordId)"
                              >{{ company.organizationName }}</span
                            >
                            <span class="organization-name" v-else>{{
                              company.organizationName
                            }}</span>
                          </div>
                        </td>

                        <!-- Gender ROI™ Score -->
                        <td
                          class="px-3 py-8 center-text tooltipscore"
                          v-if="
                            company.roiScoresText &&
                            company.roiScoresText[0] &&
                            company.percentage == '100'
                          "
                        >
                          {{ company.roiScoresText[0] | toFixedTwo }}
                          <span
                            class="score_tooltiptext"
                            v-if="
                              company.currentQauter !=
                              company.financialReportingYear
                            "
                          >
                            This is the most recently completed score.
                          </span>
                        </td>
                        <td class="px-3 py-8 center-text" v-else>Incomplete</td>

                        <!-- Quarter Completed -->
                        <td class="px-3 py-8 center-text tooltipscore">
                          {{ company.financialReportingYear }}
                          <span class="score_tooltiptext">
                            This is the quarter in which the most recent Gender
                            ROI™ assessment was completed.
                          </span>
                        </td>

                        <!-- Time for a repeat assessment? -->
                        <td
                          class="blue-border-button px-3 py-8 center-text"
                          v-if="
                            company.currentQauter !=
                            company.financialReportingYear
                          "
                        >
                          <button
                            @click="
                              getRepeatRequestData(
                                company.currentQauter,
                                company.equiloEmailId,
                                company.team_id
                              )
                            "
                            v-if="company.showReapeatFlag === true"
                          >
                            Time to send a reminder!
                          </button>
                          <span v-else
                            >Waiting for the latest assessment. Request was sent
                            on {{ company.equiloEmailId }}.</span
                          >
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          <span>No- we are all set for now!</span>
                        </td>

                        <!-- Progress of Current/Repeat Assessment -->
                        <td
                          class="px-3 py-8 center-text tooltipscore"
                          v-if="
                            company.currentQauter !=
                            company.financialReportingYear
                          "
                        >
                          <!-- <template v-if="company.percentageOfCurrentQuatre < 100"> -->
                          <span
                            >{{
                              company.percentageOfCurrentQuatre == 0 ? (company.percentage | mathOnlyRound) : (company.percentageOfCurrentQuatre | mathOnlyRound)
                            }}%</span
                          >
                          <span class="score_tooltiptext">
                            This is the completion progress of the current
                            Gender ROI™ assessment in progress.
                          </span>
                          <!-- </template>
                          <img v-else src="@/assets/images/roi/smile-icon.png" height="50" width="50" style="display: block;margin: auto;"/> -->
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          <img
                            src="@/assets/images/roi/smile-icon.png"
                            height="50"
                            width="50"
                            style="display: block; margin: auto"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    class="divide-y divide-gray-200 bg-white w-full"
                    v-else
                  >
                    <thead>
                      <tr>
                        <th class="px-3 py-2" style="width: 17rem">
                          <div class="flex">
                            <span>{{
                              investor ? "Funds/FIs" : "Companies"
                            }}</span>
                          </div>
                        </th>
                        <th class="px-3 py-2">
                          <div class="flex">
                            <span class="m-auto"> Gender ROI™ Score </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr
                        v-for="(company, index) in roiInvestedCompanies"
                        :key="'company' + index"
                      >
                        <td class="px-3 py-8">
                          <div class="flex image-center">
                            <img
                              class="organization-img"
                              :src="company.logo[0].url"
                              v-if="
                                company &&
                                company.logo &&
                                company.logo[0] &&
                                company.logo[0].url
                              "
                            />
                            <span
                              class="organization-name cursor-pointer"
                              v-if="company.percentage == '100'"
                              @click="redirectToCompany(company.recordId)"
                              >{{ company.organizationName }}</span
                            >
                            <span class="organization-name" v-else>{{
                              company.organizationName
                            }}</span>
                          </div>
                        </td>
                        <td
                          class="px-3 py-8 center-text"
                          v-if="
                            company.roiScoresText &&
                            company.roiScoresText[0] &&
                            company.percentage == '100'
                          "
                        >
                          {{ company.roiScoresText[0] | toFixedTwo }}
                        </td>
                        <td class="px-3 py-8 center-text" v-else>Incomplete</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="DownloadBtn"
                  v-if="
                    roiInvestedCompanies &&
                    roiInvestedCompanies.length < roiInvestedCompaniesCount &&
                    roiInvestedCompanies.length > 0
                  "
                >
                  <div class="tooltip mt-10">
                    <div @click="loadMoreCompanies()">
                      <p style="cursor: pointer; margin-top: 3px">Load More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    class="h-16 print:h-auto print:w-64"
                    src="@/assets/images/2X-assessment/My2XAssessments3b.png"
                    alt=""
                  />
                  <h1
                    class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
                  >
                    No Data Found
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="container" v-else key="else_div">
        <!-- <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div class="flex flex-row items-center justify-center print:flex-col">
              <img class="h-16 print:h-auto print:w-64" src="@/assets/images/Writing-removebg.png" alt="" />
              <h1 class="
                  text-6xl
                  tracking-tighter
                  font-medium
                  text-center
                  print:text-7xl
                  ml-5
                ">
                No Data Found
              </h1>
            </div>
          </div>
        </div> -->
        <div class="flex flex-row justify-left items-center">
          <div class="flex flex-col item-center justify-left mt-10 ml-20">
            <div
              class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col"
            >
              Uh-oh! You have not given Equilo the data required to populate
              this portfolio!. You gave us the data but it seems missing?
            </div>
            <div
              class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
            >
              <a
                href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                class="text-white text-sm font-medium mr-3"
                style="width: max-content"
              >
                Contact support now
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Learn more About Modal -->
    <div class="fixed z-10 inset-0" v-if="learnMoreAboutModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 40% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
              >
                <div class="flex">
                  <h3
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 360px"
                  >
                    About the Gender ROI™ Assessment
                  </h3>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  id="style-1"
                >
                  <p>
                    The Sweef Capital Gender ROI™️ (Gender ROI™️) is a
                    diagnostic measurement and management tool that is designed
                    to support entrepreneurs, investment and development finance
                    practitioners to assess the gender equality and diversity
                    performance of companies, informing both practice and
                    performance in addressing inequalities, while highlighting
                    significant opportunities to improve outcomes for women and
                    girls.
                  </p>
                  <br />
                  <p>
                    The tool comprises of 24 quantitative and qualitative
                    indicators applied across the intersectionality of the
                    enterprise dimensions of Leadership, Workforce, Value
                    Chains, as well as through Society and Outcome Pillars of
                    Resilience, Opportunity, and Inclusion. The assessment
                    identifies gaps, determining appropriate actions and
                    interventions applied at the enterprise level which are
                    focused on delivering outcomes that support women’s economic
                    empowerment.
                  </p>
                  <br />
                  <p>
                    By applying different levers of change across the enterprise
                    dimensions targeting women’s economic and social roles,
                    contributions, and constraints and vulnerabilities, women’s
                    economic empowerment is demonstrated through 3 Outcome
                    Pillars - Resilience, Opportunities and Inclusion.
                  </p>
                  <br />
                  <p>
                    These Outcome Pillars are goalposts for women’s economic
                    empowerment which involve addressing the need for women’s
                    participation in gainful employment and to be in a position
                    of influence and decision making, as well as ensuring equal
                    access to opportunities for women’s participation and
                    advancement, and safeguarding women’s well-being through
                    safety nets and protection, thereby addressing gender norms
                    and biases. These are also the key tenets in building strong
                    and resilient companies to meet the challenges of today's
                    dynamic business environment. Each stakeholder has a role to
                    play; they are mutually beneficial and should be seen as a
                    collective engine of sustainable and inclusive development.
                  </p>
                  <br />
                  <p>
                    To calculate Gender ROI™ scores across these 24 indicators,
                    there are 16 discrete survey modules that ask questions
                    across a range of topics. These surveys may be taken all at
                    once, or over a discrete period of time, filled by one or
                    more people as appropriate.
                  </p>
                  <br />
                  <p>
                    This tool is digitized via the Equilo platform, with the
                    following benefits for users coming soon (data will be
                    collected now, with the automated benefits below in beta
                    testing and coming soon!):
                  </p>
                  <br />
                  <ul style="list-style-type: disc">
                    <li class="flex">
                      <span class="mr-2">➜</span>
                      <p>
                        Eases data collection and reporting, with multiple
                        survey modules that can be completed by multiple people
                        within an organization at any time.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Automatically analyzes the reported data, displaying
                        results instantly on a personalized dashboard. Users
                        need to have an account created at app.equilo.io to
                        access their results.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Easily share dashboard and summary results or
                        downloadable .CSV files with others.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        View organizational results alongside a contextual
                        market-level analysis to better understand,
                        contextualize, and address gaps.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Data can be automatically aggregated for investors and
                        financial actors at portfolio levels when enterprises
                        complete this assessment across the portfolio.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Receive intelligently automated action plan based on
                        identified gaps.
                      </p>
                    </li>
                  </ul>
                  <br />
                  <p>
                    Module 1 must be completed first, but all other modules may
                    be completed in any order. Each survey module may be
                    completed by the same or a different person. The Assessment
                    is associated with the main user's email ID. This person may
                    choose, for each module, to complete the survey, or may
                    enter an email address to send a request to another person
                    to complete the specific module.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Learn more About Modal -->

    <!-- Complete Survey Modal -->
    <div class="fixed z-10 inset-0" v-if="completeSurveyModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 35% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
                style="width: 100%"
              >
                <div class="flex">
                  <h1
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 100%"
                  >
                    Who will complete this Survey?
                  </h1>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  style="height: auto; color: black; font-weight: 500"
                >
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="myself"
                    @change="isMyselfChecked()"
                  />Complete this module myself
                  <br />
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="other"
                    @change="isMyselfChecked()"
                  />Request a team member to complete this module
                </div>
                <div class="InviteBtn mt-5 mb-4" v-if="!isShareVisible">
                  <div>
                    <button
                      class="text-white text-sm font-medium"
                      @click="fillMyself"
                      :class="
                        isMyselfRadioChecked
                          ? ''
                          : 'pointer_event_none opacity_light'
                      "
                    >
                      Launch
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    class="EmailInvitemember mt-5 mb-4 flex"
                    v-if="isShareVisible"
                  >
                    <div class="EmailInput">
                      <input
                        type="email"
                        placeholder="Enter Email Address"
                        v-model="invitedUserEmail"
                      />
                    </div>
                    <div class="InviteBtn tooltip">
                      <div @click="onInviteClick">
                        <button class="text-white text-sm font-medium">
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Start Request Admin Modal For PDF/CSV-->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="requestAdminModalForPDFCSV"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close"
            @click="closeAdminRequestModal()"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle" style="margin: 45px; padding: 0">
            <div class="ModalInnerStyle" style="padding: 0">
              <p style="font-weight: 600" class="">
                Your organization do not have plan that allow this feature to
                access. Email your account administrator to upgrade and unlock.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-4 py-4 text-medium gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-5"
                @click="SendMailToAdmin()"
              >
                Email request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
    <!-- End Complete Survey Modal -->
    <div class="pdfexportbtn">
      <roiPortfolio-print
        v-if="endofcreated && roiData"
        :investorFromParent="investor"
        @pageRefreshed="pageRefreshed"
        :portfolioSwitchTog="portfolioSwitchTog"
        :userFromParent="user"
        :roiDataFromParent="roiData"
        :oldRoiDataFromParent="oldRoiData"
        :roiInvestedCompaniesFromParent="roiInvestedCompanies"
        :roiInvestedCompaniesCountFromParent="roiInvestedCompaniesCount"
        :roiCompanyDetailsFromParent="roiCompanyDetails"
        :allROIDataFromParent="allROIData"
        id="roi_portfolio_print"
        :key="updateKey"
      />
    </div>
  </div>
</template>
<script>
import env from "@/../env";
import axios from "axios";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import RoiProgressBarsSection from "@/components/roiPortfolioProgressBarSection.vue";
import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/roiPortfolioSpiderchart.vue";
import spiderchartMultiple from "@/components/charts/roiPortfolioSpiderchartMultiple.vue";
import domainSpiderchart from "@/components/charts/roiDomainSpiderchart.vue";
import domainSpiderchartMultiple from "@/components/charts/roiDomainSpiderchartMultiple.vue";
import roiPortfolioPrint from "@/pages/reports/roiPortfolio-print.vue";
import RoiProgressBarsSectionMultiple from "@/components/roiPortfolioProgressBarSectionMultiple.vue";
import * as XLSX from "xlsx-js-style";
import html2pdf from "html2pdf.js";

export default {
  name: "roiAssessment",
  data: function () {
    return {
      percentValue: "80%",
      user: [],
      roiData: [],
      oldRoiData: [],
      endofcreated: false,
      invitedUserEmail: "",
      learnMoreAboutModal: false,
      selectedSurvey: [],
      completeSurveyModal: false,
      isShareVisible: false,
      isMyselfRadioChecked: false,
      isOtherRadioChecked: false,
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 10,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      json_data: [],
      csvData: [],
      roiCompanyDetails: [],
      roiInvestedCompanies: [],
      roiInvestedCompaniesCount: [],
      roiInvestedCompaniesTotalCount: 0,
      roiCompletedCompaniesTotal: 0,
      repeatRequestData: [],
      companyHeader: [
        {
          title: this.investor ? "Funds/FIs" : "Companies",
          // orderBy: 'organizationName',
          // defalutOrderTypeASC: true
        },
        {
          title: "Gender ROI™ Score ",
          // orderBy: 'percentShares',
          // defalutOrderTypeASC: false
        },
      ],
      country: [],
      limit: 5,
      downloadPDF: false,
      latestSurveyDateTime: "",
      fill1: { gradient: ["#0077C8", "#2BD4C4"] },
      fill2: { gradient: ["#FE5000", "#FAE053"] },
      allROIData: [],
      toggleOptions: [
        {
          status: "Portfolio",
          isSelected: true,
          isDisplay: false,
        },
        {
          status: "Alumni",
          isSelected: false,
          isDisplay: false,
        },
        {
          status: "Pipeline",
          isSelected: false,
          isDisplay: false,
        },
      ],
      investor: false,
      allPortfolioData: [],
      allOldPortfolioData: [],
      portfolioSwitchTog: "Portfolio",
      updateKey: 0,
      isPageRefreshed: false,
      updateKeyAfterRefresh: 0,
      toggleOptionLength: 0,
      isToggleEnable: true,
      gradient: {
        radial: false,
        colors: [
          {
            color: "#2BD4C4",
            offset: 10,
            opacity: "1",
          },
          {
            color: "#0077C8",
            offset: 90,
            opacity: "0.6",
          },
        ],
      },
      requestAdminModalForPDFCSV: false,
      emailBody: {},
    };
  },
  components: {
    pageLoader,
    spiderchart,
    RoiProgressBarsSection,
    domainSpiderchart,
    roiPortfolioPrint,
    RoiProgressBarsSectionMultiple,
    spiderchartMultiple,
    domainSpiderchartMultiple,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    if (this.user.accessROIPortfolio == false) {
      this.$router.push({ path: "/roi" });
    }

    await this.getRoiData();
    if (this.roiData.investedCompaniesList) {
      await this.getCompaniesData();
    }
    this.getCSVData();
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Gender ROI™",
        url: "/roi",
      },
      {
        name: "Gender ROI™ Portfolio",
        url: "/roi/roiPortfolio",
      },
    ]);
    this.endofcreated = true;
  },
  methods: {
    closeMostRecentSurveyMessageSection() {
      document.getElementById("mostRecentMessageSection").style.display =
        "none";
    },
    pageRefreshed(val) {
      this.updateKeyAfterRefresh++;
      this.isPageRefreshed = val;
      this.updateKeyAfterRefresh--;
    },
    async switchPortfolioData(tog) {
      this.updateKey++;
      this.portfolioSwitchTog = tog.status;

      this.roiData = [];
      this.oldRoiData = [];

      this.toggleOptions.forEach((data) => {
        data.isSelected = false;
      });
      let index = "";
      this.allPortfolioData.forEach((data, i) => {
        if (data.portfolioCompanyStatus == tog.status) {
          index = i;
        }
      });
      tog.isSelected = true;
      this.roiData = await this.allPortfolioData[index];
      // await this.filterROIData(); // while switching portfolio status
      if (this.allROIData.length > 1) {
        this.oldRoiData = await this.allOldPortfolioData[index];
        // await this.filterOldROIData();
      }
      if (this.roiData.investedCompaniesList) {
        await this.getCompaniesData();
      }
    },

    async togglePortfolio() {
      this.updateKey++;
      this.roiData = [];
      this.oldRoiData = [];
      this.allPortfolioData = [];
      this.allOldPortfolioData = [];
      this.toggleOptionLength = 0;

      document.getElementById("favChecked").checked
        ? (this.investor = true)
        : (this.investor = false);
      if (this.investor == true) {
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Portfolio") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Alumni") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Pipeline") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });

        // for old roi data
        if (this.allROIData.length > 1) {
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Fund") {
              if (data[1].portfolioCompanyStatus == "Portfolio") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Fund") {
              if (data[1].portfolioCompanyStatus == "Alumni") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Fund") {
              if (data[1].portfolioCompanyStatus == "Pipeline") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
        }

        let index = "";
        this.toggleOptions.forEach((data, i) => {
          data.isDisplay = false;
          if (data.isSelected) {
            index = i;
          }
        });

        this.allPortfolioData.forEach((data) => {
          this.toggleOptions.map((item) => {
            if (
              data.investedCompaniesList &&
              data.investedCompaniesList.length
            ) {
              if (data.portfolioCompanyStatus == item.status) {
                item.isDisplay = true;
                this.toggleOptionLength++;
              }
            }
          });
        });
        if (this.toggleOptionLength == 3) {
          this.roiData = this.allPortfolioData[index];
          if (this.allROIData.length > 1) {
            this.oldRoiData = this.allOldPortfolioData[index];
          }
        } else {
          this.toggleOptions.forEach((data) => {
            this.allPortfolioData.forEach((item) => {
              if (
                data.isSelected &&
                data.isDisplay &&
                data.status == item.portfolioCompanyStatus
              ) {
                this.roiData = item;
              } else {
                data.isSelected = false;
                if (
                  this.allPortfolioData[0].investedCompaniesList &&
                  this.allPortfolioData[0].investedCompaniesList.length
                ) {
                  this.toggleOptions[0].isSelected = true;
                  this.roiData = this.allPortfolioData[0];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[0];
                  }
                } else if (
                  this.allPortfolioData[1] &&
                  this.allPortfolioData[1].investedCompaniesList &&
                  this.allPortfolioData[1].investedCompaniesList.length
                ) {
                  this.toggleOptions[1].isSelected = true;
                  this.roiData = this.allPortfolioData[1];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[1];
                  }
                } else {
                  this.toggleOptions[2].isSelected = true;
                  this.roiData = this.allPortfolioData[2];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[2];
                  }
                }
              }
            });
            if (this.allROIData.length > 1) {
              this.allOldPortfolioData.forEach((item) => {
                if (
                  data.isSelected &&
                  data.status == item.portfolioCompanyStatus
                ) {
                  this.oldRoiData = item;
                }
              });
            }
          });
        }
        this.portfolioSwitchTog = this.roiData.portfolioCompanyStatus;
        // this.filterROIData(); // while toggling if type is fund

        // if (this.allROIData.length > 1) {
        //   this.filterOldROIData();
        // }
      }

      // for company
      if (this.investor == false) {
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Company") {
            if (data[1].portfolioCompanyStatus == "Portfolio") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Company") {
            if (data[1].portfolioCompanyStatus == "Alumni") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[0]).forEach((data) => {
          if (data[1].type == "Company") {
            if (data[1].portfolioCompanyStatus == "Pipeline") {
              this.allPortfolioData.push(data[1]);
            }
          }
        });
        // for old roi data

        if (this.allROIData.length > 1) {
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Portfolio") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Alumni") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[1]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Pipeline") {
                this.allOldPortfolioData.push(data[1]);
              }
            }
          });
        }
        let index = "";
        this.toggleOptions.forEach((data, i) => {
          data.isDisplay = false;
          if (data.isSelected) {
            index = i;
          }
        });
        this.allPortfolioData.forEach((data) => {
          this.toggleOptions.map((item) => {
            if (
              data.investedCompaniesList &&
              data.investedCompaniesList.length
            ) {
              if (data.portfolioCompanyStatus == item.status) {
                item.isDisplay = true;
                this.toggleOptionLength++;
              }
            }
          });
        });
        if (this.toggleOptionLength == 3) {
          this.roiData = this.allPortfolioData[index];
          if (this.allROIData.length > 1) {
            this.oldRoiData = this.allOldPortfolioData[index];
          }
        } else {
          this.toggleOptions.forEach((data) => {
            this.allPortfolioData.forEach((item) => {
              if (
                data.isSelected &&
                data.isDisplay &&
                data.status == item.portfolioCompanyStatus
              ) {
                this.roiData = item;
              } else {
                data.isSelected = false;
                if (
                  this.allPortfolioData[0].investedCompaniesList &&
                  this.allPortfolioData[0].investedCompaniesList.length
                ) {
                  this.toggleOptions[0].isSelected = true;
                  this.roiData = this.allPortfolioData[0];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[0];
                  }
                } else if (
                  this.allPortfolioData[1] &&
                  this.allPortfolioData[1].investedCompaniesList &&
                  this.allPortfolioData[1].investedCompaniesList.length
                ) {
                  this.toggleOptions[1].isSelected = true;
                  this.roiData = this.allPortfolioData[1];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[1];
                  }
                } else {
                  this.toggleOptions[2].isSelected = true;
                  this.roiData = this.allPortfolioData[2];
                  if (this.allROIData.length > 1) {
                    this.oldRoiData = this.allOldPortfolioData[2];
                  }
                }
              }
            });
            if (this.allROIData.length > 1) {
              this.allOldPortfolioData.forEach((item) => {
                if (
                  data.isSelected &&
                  data.status == item.portfolioCompanyStatus
                ) {
                  this.oldRoiData = item;
                }
              });
            }
          });
        }
        this.portfolioSwitchTog = this.roiData.portfolioCompanyStatus;
        // this.filterROIData(); // while toggling if type is company

        // if (this.allROIData.length > 1) {
        //   this.filterOldROIData();
        // }
      }
      this.roiCompletedCompaniesTotal = 0;
      document.getElementById("mostRecentMessageSection").style.display =
        "none";
      setTimeout(function () {
        document.getElementById("mostRecentMessageSection").style.display =
          "block";
      }, 1000);
      if (this.roiData.investedCompaniesList) {
        await this.getCompaniesData();
      }
    },
    scrollToElement() {
      const el = this.$refs.investeedCompanies;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    loadMoreCompanies() {
      this.limit = this.limit + 5;
      this.getCompaniesData();
    },
    SendMailToAdmin() {
      axios
        .post(
          env.apiUrl + "/assesment2x/gesi-contextual-request-to-admin",
          this.emailBody,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.closeAdminRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAdminRequestModal() {
      this.requestAdminModalForPDFCSV = false;
    },
    checkUserIsAdminOrNot(paramName) {
      if (this.user.admin) {
        if (
          this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectforcustomerportal();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus != "Subscribed"
        )
          this.redirecttoupgrade();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectToEquilo();
      } else {
        this.emailBody = {
          productName: paramName,
          product: paramName,
        };
        this.requestAdminModalForPDFCSV = true;
      }
    },
    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    redirectforcustomerportal() {
      axios
        .post(
          env.apiUrl + "/payments/create-customer-portal-session",
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          window.location.href = res.data.data.url;
        });
    },
    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },

    checkDownloadPDFPermission() {
      if (this.user.organization && this.user.organization.roiPDF) {
        this.exportToPDF();
      } else {
        this.checkUserIsAdminOrNot("Gender ROI™ Portfolio PDF");
      }
    },

    async exportToPDF() {
      this.downloadPDF = true;
      var pagebreak = { before: ".before_page" };
      setTimeout(async () => {
        let element = document.querySelector("#roi_portfolio_print");
        await html2pdf()
          .set({
            margin: 1,
            filename: "ROIPortfolio.pdf",
            pagebreak: pagebreak,
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [55, 35],
            },
          })
          .from(element)
          .save();
        this.downloadPDF = false;
        return;
      }, 1000);
    },
    redirectToCompany(id) {
      let routeData = this.$router.resolve({
        name: "roiAssessment",
        query: {
          recordId: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    ec(r, c) {
      return XLSX.utils.encode_cell({ r: r, c: c });
    },
    deleteRow(ws, row_index) {
      var variable = XLSX.utils.decode_range(ws["!ref"]);
      for (var R = row_index; R < variable.e.r; ++R) {
        for (var C = variable.s.c; C <= variable.e.c; ++C) {
          ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
        }
      }
      variable.e.r--;
      ws["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
    },

    checkDownloadCSVPermission() {
      if (this.user.organization && this.user.organization.roiCsv) {
        this.onexport();
      } else {
        this.checkUserIsAdminOrNot("Gender ROI™ Portfolio CSV");
      }
    },

    onexport() {
      const type = this.investor ? "fund" : "company";
      const otherSector = this.csvData?.teamSurveyData?.find(
        (data) => data[0] === "Other Sector"
      );
      const otherSectorIndex = this.csvData?.teamSurveyData?.findIndex(
        (data) => data[0] === "Other Sector"
      );

      if (otherSectorIndex !== -1) {
        this.csvData?.teamSurveyData?.splice(otherSectorIndex, 1);
      }

      this.csvData?.teamSurveyData?.forEach((data) => {
        if (data[0] === "Sector") {
          data.forEach((item, i) => {
            if (i > 0 && item === "Other") {
              data[i] =
                this.checkFundorCompanies === "funds"
                  ? "Financial Services"
                  : otherSector?.[i];
            }
          });
        }
      });

      // function to apply styles to a cell
      const applyStyles = (cell, styles) => {
        cell.s = styles;
      };

      // define styles
      const headerStyle = {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { fgColor: { rgb: "4F81BD" } },
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
      };

      const dataStyle = {
        alignment: { horizontal: "left", vertical: "center", wrapText: true },
      };

      // function to create a styled sheet
      const createStyledSheet = (data) => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        const range = XLSX.utils.decode_range(ws["!ref"]);

        // apply styles to data rows and delete missing data
        for (let R = range.e.r; R >= range.s.r; --R) {
          const firstCell = ws[XLSX.utils.encode_cell({ r: R, c: range.s.c })];
          const firstCellValue = firstCell?.v ?? null;

          if (firstCellValue === null || firstCellValue === "") {
            // delete the row by shifting all rows above it down
            for (let C = range.s.c; C <= range.e.c; ++C) {
              ws[XLSX.utils.encode_cell({ r: R, c: C })] =
                ws[XLSX.utils.encode_cell({ r: R + 1, c: C })];
            }
            range.e.r--;
          } else {
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
              if (cell) {
                applyStyles(cell, dataStyle);
                if (
                  ["Inclusion", "Opportunities", "Resilience"].includes(cell.v)
                ) {
                  applyStyles(cell, headerStyle);
                }
              }
            }
          }
        }
        ws["!ref"] = XLSX.utils.encode_range(range.s, range.e);

        // set column widths
        ws["!cols"] = data[0].map((col) => ({
          wch: Math.min(Math.max(col.length, 75), 100),
        }));

        return ws;
      };

      const processData = (data, isScore = false) => {
        if (!data) return null;
        data.forEach((row) => {
          row.forEach((item, i) => {
            if (i > 0) {
              if (row[0] === "Sector") {
                if (this.checkFundorCompanies === "funds") {
                  row[i] = "Financial Services";
                }
                if (i === 1) {
                  data?.splice(10, 0, ["Sectors of Interest", this.roiSectors]);
                }
              } else if (
                isScore &&
                [
                  "Inclusion Percentage",
                  "Opportunity Percentage",
                  "Resilience Percentage",
                ].includes(row[0])
              ) {
                if (row[i] !== "") {
                  row[i] = Number(row[i]).toFixed(2) + "%";
                }
              }
            }
          });
        });
        return createStyledSheet(data);
      };

      const typePrefix = type === "company" ? "Company" : "Fund";
      const dataTypes = ["Portfolio", "Alumni", "Pipeline"];

      const wb = XLSX.utils.book_new();

      if (this.csvData.roiSurveyData) {
        const roiSurveySheet = processData(this.csvData.roiSurveyData);
        XLSX.utils.book_append_sheet(wb, roiSurveySheet, "ROI Survey");
      }

      if (this.csvData.teamScoreData) {
        const scoringSheet = processData(this.csvData.teamScoreData, true);
        XLSX.utils.book_append_sheet(wb, scoringSheet, "Scoring");
      }

      dataTypes.forEach((dataType) => {
        const surveySheet = processData(
          this.csvData[`${typePrefix}${dataType}SurveyData`]
        );
        if (surveySheet) {
          XLSX.utils.book_append_sheet(
            wb,
            surveySheet,
            `${typePrefix} ${dataType} Survey`
          );
        }

        const scoreSheet = processData(
          this.csvData[`${typePrefix}${dataType}ScoreData`],
          true
        );
        if (scoreSheet) {
          XLSX.utils.book_append_sheet(
            wb,
            scoreSheet,
            `${typePrefix} ${dataType} Score`
          );
        }

        if (this.csvData[`aggregate${typePrefix}${dataType}`]) {
          const aggregateSheet = processData(
            this.csvData[`aggregate${typePrefix}${dataType}`],
            true
          );
          if (aggregateSheet) {
            XLSX.utils.book_append_sheet(
              wb,
              aggregateSheet,
              `Aggregate ${typePrefix} ${dataType}`
            );
          }
        }
      });

      const fileName = `${
        this.roiCompanyDetails.member2xc || "ROI_Portfolio"
      }.xlsx`;
      XLSX.writeFile(wb, fileName);
    },
    filterCSVData(result) {
      var csvData = Object.values(result.data);
      if (csvData) {
        csvData.forEach((data) => {
          this.json_data.push(data);
        });
      }
    },
    getCSVData() {
      let url = "";
      if (this.$route.query.id) {
        url = "/roi/download-csv-portfolio?teamId=" + this.$route.query.id;
      } else {
        url = "/roi/download-csv-portfolio";
      }
      return axios
        .get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.csvData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.endofcreated = true;
        });
    },
    fillMyself() {
      this.redirectToSurvey(this.selectedSurvey);
    },
    isMyselfChecked() {
      if (document.getElementById("myself").checked == true) {
        this.isShareVisible = false;
        this.isMyselfRadioChecked = true;
      }
      if (document.getElementById("other").checked == true) {
        this.isOtherRadioChecked = true;
        this.isShareVisible = true;
      }
    },
    moduleClicked(event) {
      let modVal = event.target.alt.split("/")[1];
      if (event.target.alt.split("/")[0] == "edit-image-icon") {
        this.selectedSurvey = [];
        this.modules.forEach((data) => {
          if (data.module == modVal) {
            this.selectedSurvey = data;
          }
        });
        if (this.roiData.shareAccess) {
          Object.entries(this.roiData).forEach((item) => {
            if (item[0] == this.selectedSurvey.var) {
              if (item[1] == false) {
                this.completeSurveyModal = true;
              } else {
                this.redirectToSurvey(this.selectedSurvey);
              }
            }
          });
        } else {
          this.redirectToSurvey(this.selectedSurvey);
        }
      }
    },
    redirectToSurvey(data) {
      this.closePopup();
      let routeData = this.$router.resolve({
        name: "roiSurvey",
        params: { surveyId: data.module },
      });
      window.open(routeData.href, "_blank");
    },
    closePopup: function () {
      this.learnMoreAboutModal = false;
      this.completeSurveyModal = false;
      this.isShareVisible = false;
    },
    pageReload() {
      window.location.reload();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let body = {
          email: this.invitedUserEmail,
          surveyName: this.selectedSurvey.name,
          surveyUrl: "roi/survey/" + this.selectedSurvey.module,
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.status) {
          this.closePopup();
          this.$swal.fire({
            icon: "success",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/roi/shared-assessments", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async getRoiData() {
      let url = "";
      if (this.$route.query.id) {
        url = "/roi/investor-data?teamId=" + this.$route.query.id;
      } else {
        url = "/roi/investor-data";
      }

      return axios
        .get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.allROIData = res.data.data;
          this.roiCompanyDetails = res.data.data[0].companyDetails[0];
          this.allROIData.forEach((item) => {
            Object.entries(item).forEach((data) => {
              if (data[0].includes("Alumni")) {
                data[1]["portfolioCompanyStatus"] = "Alumni";
              }
              if (data[0].includes("Pipeline")) {
                data[1]["portfolioCompanyStatus"] = "Pipeline";
              }
              if (data[0].includes("Portfolio")) {
                data[1]["portfolioCompanyStatus"] = "Portfolio";
              }
              if (data[0].includes("Company")) {
                data[1]["type"] = "Company";
              }
              if (data[0].includes("Fund")) {
                data[1]["type"] = "Fund";
              }
            });
          });

          Object.entries(this.allROIData[0]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Portfolio") {
                this.allPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[0]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Alumni") {
                this.allPortfolioData.push(data[1]);
              }
            }
          });
          Object.entries(this.allROIData[0]).forEach((data) => {
            if (data[1].type == "Company") {
              if (data[1].portfolioCompanyStatus == "Pipeline") {
                this.allPortfolioData.push(data[1]);
              }
            }
          });
          let arr = [];
          this.allPortfolioData.forEach((data) => {
            if (data.investedCompaniesList) {
              arr.push(data);
            }
          });
          this.allPortfolioData = arr;

          this.allPortfolioData.forEach((data) => {
            this.toggleOptions.map((item) => {
              if (
                data.type == "Company" &&
                data.investedCompaniesList &&
                data.investedCompaniesList.length
              ) {
                if (data.portfolioCompanyStatus == item.status) {
                  item.isDisplay = true;
                  this.toggleOptionLength++;
                }
              }
            });
          });
          if (this.toggleOptionLength > 0 && this.allPortfolioData) {
            if (
              this.allPortfolioData[0] &&
              this.allPortfolioData[0].investedCompaniesList &&
              this.allPortfolioData[0].investedCompaniesList.length
            ) {
              this.roiData = this.allPortfolioData[0]; // if page opens first time always display Fund Portfolio data
            } else if (
              this.allPortfolioData[1] &&
              this.allPortfolioData[1].investedCompaniesList &&
              this.allPortfolioData[1].investedCompaniesList.length
            ) {
              this.roiData = this.allPortfolioData[1];
            } else {
              this.roiData = this.allPortfolioData[2];
            }

            this.toggleOptions.map((item) => {
              item.isSelected = false;
            });

            this.toggleOptions.map((item) => {
              if (this.roiData.portfolioCompanyStatus == item.status) {
                item.isSelected = true;
                this.portfolioSwitchTog = item.status;
              }
            });

            // await this.filterROIData(); // initially filter data

            if (this.allROIData.length > 1) {
              Object.entries(this.allROIData[1]).forEach((data) => {
                if (data[1].type == "Company") {
                  if (data[1].portfolioCompanyStatus == "Portfolio") {
                    this.allOldPortfolioData.push(data[1]);
                  }
                }
              });
              Object.entries(this.allROIData[1]).forEach((data) => {
                if (data[1].type == "Company") {
                  if (data[1].portfolioCompanyStatus == "Alumni") {
                    this.allOldPortfolioData.push(data[1]);
                  }
                }
              });
              Object.entries(this.allROIData[1]).forEach((data) => {
                if (data[1].type == "Company") {
                  if (data[1].portfolioCompanyStatus == "Pipeline") {
                    this.allOldPortfolioData.push(data[1]);
                  }
                }
              });
            }

            if (this.allROIData.length > 1) {
              this.allOldPortfolioData.forEach((data) => {
                if (
                  data.portfolioCompanyStatus ==
                  this.roiData.portfolioCompanyStatus
                ) {
                  this.oldRoiData = data;
                }
              });
              // await this.filterOldROIData();
            }
          }

          await this.checkForFundsToggle();
          if (this.toggleOptionLength == 0 || !this.allPortfolioData) {
            this.isToggleEnable = false;
            await this.loadDataForFunds();
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    async checkForFundsToggle() {
      let fundData = [];
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Portfolio") {
            fundData.push(data[1]);
          }
        }
      });
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Alumni") {
            fundData.push(data[1]);
          }
        }
      });
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Pipeline") {
            fundData.push(data[1]);
          }
        }
      });
      let arr = [];
      fundData.forEach((data) => {
        if (data.investedCompaniesList) {
          arr.push(data);
        }
      });
      let allFundData = [];
      allFundData = arr;

      let toggleOptionLength = 0;
      allFundData.forEach((data) => {
        this.toggleOptions.map((item) => {
          if (
            data.type == "Fund" &&
            data.investedCompaniesList &&
            data.investedCompaniesList.length
          ) {
            if (data.portfolioCompanyStatus == item.status) {
              // item.isDisplay = true;
              toggleOptionLength++;
            }
          }
        });
      });
      if (toggleOptionLength == 0) {
        this.isToggleEnable = false;
      }
    },
    async loadDataForFunds() {
      this.roiData = [];
      this.investor = true;
      this.allPortfolioData = [];
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Portfolio") {
            this.allPortfolioData.push(data[1]);
          }
        }
      });
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Alumni") {
            this.allPortfolioData.push(data[1]);
          }
        }
      });
      Object.entries(this.allROIData[0]).forEach((data) => {
        if (data[1].type == "Fund") {
          if (data[1].portfolioCompanyStatus == "Pipeline") {
            this.allPortfolioData.push(data[1]);
          }
        }
      });
      let arr = [];
      this.allPortfolioData.forEach((data) => {
        if (data.investedCompaniesList) {
          arr.push(data);
        }
      });
      this.allPortfolioData = arr;

      if (
        this.allPortfolioData[0].investedCompaniesList &&
        this.allPortfolioData[0].investedCompaniesList.length
      ) {
        this.roiData = this.allPortfolioData[0]; // if page opens first time always display Fund Portfolio data
      } else if (
        this.allPortfolioData[1] &&
        this.allPortfolioData[1].investedCompaniesList &&
        this.allPortfolioData[1].investedCompaniesList.length
      ) {
        this.roiData = this.allPortfolioData[1];
      } else {
        this.roiData = this.allPortfolioData[2];
      }

      this.toggleOptions.map((item) => {
        item.isSelected = false;
      });

      this.toggleOptions.map((item) => {
        if (this.roiData.portfolioCompanyStatus == item.status) {
          item.isSelected = true;
          this.portfolioSwitchTog = item.status;
        }
      });

      // await this.filterROIData(); //while checking for funds
      if (this.allROIData.length > 1) {
        Object.entries(this.allROIData[1]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Portfolio") {
              this.allOldPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[1]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Alumni") {
              this.allOldPortfolioData.push(data[1]);
            }
          }
        });
        Object.entries(this.allROIData[1]).forEach((data) => {
          if (data[1].type == "Fund") {
            if (data[1].portfolioCompanyStatus == "Pipeline") {
              this.allOldPortfolioData.push(data[1]);
            }
          }
        });
      }

      if (this.allROIData.length > 1) {
        this.oldRoiData = this.allOldPortfolioData[0];
        // await this.filterOldROIData();
      }

      this.allPortfolioData.forEach((data) => {
        this.toggleOptions.map((item) => {
          if (
            data.type == "Fund" &&
            data.investedCompaniesList &&
            data.investedCompaniesList.length
          ) {
            if (data.portfolioCompanyStatus == item.status) {
              item.isDisplay = true;
              this.toggleOptionLength++;
            }
          }
        });
      });
    },
    filterROIData() {
      let arr = [];
      if (this.roiData && this.roiData.roiPortfolioData) {
        this.roiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Opportunities") {
            arr.push(this.roiData.roiPortfolioData[i]);
          }
        });
        this.roiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Inclusion") {
            arr.push(this.roiData.roiPortfolioData[i]);
          }
        });
        this.roiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Resilience") {
            arr.push(this.roiData.roiPortfolioData[i]);
          }
        });
        this.roiData.roiPortfolioData = [];
        this.roiData.roiPortfolioData = arr;
      }
    },
    filterOldROIData() {
      let arr = [];
      if (
        this.allROIData.length > 1 &&
        this.oldRoiData &&
        this.oldRoiData.roiPortfolioData
      ) {
        this.oldRoiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Opportunities") {
            arr.push(this.oldRoiData.roiPortfolioData[i]);
          }
        });
        this.oldRoiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Inclusion") {
            arr.push(this.oldRoiData.roiPortfolioData[i]);
          }
        });
        this.oldRoiData.roiPortfolioData.forEach((data, i) => {
          if (Object.entries(data)[0][0] == "Resilience") {
            arr.push(this.oldRoiData.roiPortfolioData[i]);
          }
        });

        this.oldRoiData.roiPortfolioData = [];
        this.oldRoiData.roiPortfolioData = arr;
      }
    },
    getCompaniesData() {
      let recordIdList = [];
      recordIdList = this?.roiData?.investedCompaniesList;
      if (this.oldRoiData?.length) {
        recordIdList = [
          ...this?.roiData?.investedCompaniesList,
          ...this?.oldRoiData?.investedCompaniesList,
        ];
      }
      let body = { recordIdList: recordIdList };
      return axios
        .post(env.apiUrl + "/roi/investor-companies-data", body, {
          params: {
            limit: this.limit,
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.updateKey++;
          this.roiInvestedCompanies = res.data.investedCompanies;
          this.roiInvestedCompaniesCount = res.data.totalCount;
          this.roiInvestedCompaniesTotalCount = res.data.total;
          await this.getCompaniesTotalCount();
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    getCompaniesTotalCount() {
      let recordIdList = [];
      recordIdList = this?.roiData?.investedCompaniesList;
      if (this.oldRoiData?.length) {
        recordIdList = [
          ...this?.roiData?.investedCompaniesList,
          ...this?.oldRoiData?.investedCompaniesList,
        ];
      }
      // Filtering array if same recordId exist
      recordIdList = recordIdList.filter((item, index) => {
        return recordIdList.indexOf(item) === index;
      });
      let body = { recordIdList: recordIdList };
      return axios
        .post(
          env.apiUrl + "/roi/investor-companies-data-completed-modules",
          body,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.roiCompletedCompaniesTotal = res.data.totalCompletedSurveys;
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    getRepeatRequestData(currentQauter, equiloEmailId, team_id) {
      let body = {
        financialReportingYear: currentQauter,
        email: equiloEmailId,
        teamId: team_id,
      };
      return axios
        .post(env.apiUrl + "/roi/assessment-repeat-request", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          if (res.data.data.date) {
            this.$swal.fire({
              icon: "success",
              text: "Nice work! Your request has been sent. Check back to view progress",
              showConfirmButton: true,
            });

            await this.getCompaniesData();
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
  },
  computed: {
    roiOrganizationtype() {
      let value = this.roiCompanyDetails.organizationType;
      var data;
      if (this.roiCompanyDetails.organizationType.includes("Other")) {
        value = this.roiCompanyDetails.organizationType;
        value.forEach((item, i) => {
          if (item == "Other") {
            value.splice(i, 1);
          }
        });
        value.push(this.roiCompanyDetails.otherOrganizationType);
        data = Object.values(value).join(", ");
      } else {
        data = Object.values(this.roiCompanyDetails.organizationType).join(
          ", "
        );
      }
      return data;
    },
    roiSectors() {
      let value = this.roiCompanyDetails.sectorsOfInterest;
      var data;
      if (this.roiCompanyDetails.sectorsOfInterest.includes("Other")) {
        value = this.roiCompanyDetails.sectorsOfInterest;
        value.forEach((item, i) => {
          if (item == "Other") {
            value.splice(i, 1);
          }
        });
        value.push(this.roiCompanyDetails.otherSectors);
        data = Object.values(value).join(", ");
      } else {
        data = Object.values(this.roiCompanyDetails.sectorsOfInterest).join(
          ", "
        );
      }
      return data;
    },
    checkFundorCompanies() {
      let typeFlag = "";
      let companiesType = [
        "Corporate (large)",
        "Small or medium enterprise",
        "Micro-enterprise",
      ];
      companiesType.includes(this.roiCompanyDetails.organizationType)
        ? (typeFlag = "companies")
        : (typeFlag = "funds");
      return typeFlag;
    },
    domainWorkforcePercent() {
      let val = 0;
      this.roiData.domainAssesmnet.forEach((dom) => {
        if (dom.name == "Workforce") {
          val = Math.round(
            ((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6) * 100
          );
        }
      });
      return val;
    },
    genderRoiScore() {
      let val = "";
      if (this.roiData.genderRoiScore) {
        val = Number(this.roiData.genderRoiScore).toFixed(2);
      }
      if (
        this.roiData.genderRoiScore == 0 &&
        this.roiData.genderRoiScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.genderRoiScore == null ||
          this.roiData.genderRoiScore == "") &&
        this.roiData.genderRoiScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    averageFinanceScore() {
      let val = "";
      if (this.roiData.averageFinanceScore) {
        val = Number(this.roiData.averageFinanceScore).toFixed(2);
      }
      if (
        this.roiData.averageFinanceScore == 0 &&
        this.roiData.averageFinanceScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.averageFinanceScore == null ||
          this.roiData.averageFinanceScore == "") &&
        this.roiData.averageFinanceScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    companySize() {
      let state = "";
      let val = Math.round(Number(this.roiData.womworkforceTwo));
      if (val <= 10) {
        state = "Micro";
      } else if (val > 10 && val <= 250) {
        state = "SME";
      } else if (val > 250) {
        state = "Large";
      }
      return state;
    },
  },
};
</script>
<style lang="scss" scoped>
.favorite-shared-button-section {
  text-align: right;
}

/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 40px;
  }

  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }

  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }

  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }

  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }

  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }

  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }

  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }

  .toggleSwitch input:checked ~ span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }

  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }

  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }

  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }

  .toggleSwitch.large a {
    width: 29px;
    height: 28px;
    top: 0.8px;
  }

  .toggleSwitch.large > span {
    height: 34px;
    line-height: 35px;
  }

  .toggleSwitch.large input:checked ~ a {
    left: 46px;
  }

  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }

  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */
.selected-toggle {
  pointer-events: none;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-toggle {
  cursor: pointer;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  font-weight: 500;
}

.investeed_companies {
  padding: 40px;
  background: white;
  border-radius: 10px;

  .CompanyTitle {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #1b1b1b;
    margin-right: 17px;
    position: relative;
    margin-bottom: 30px;
  }

  .DownloadBtn {
    text-align: center;

    p {
      display: flex;
      align-items: center;
      border: 1px solid #1b1b1b;
      border-radius: 20px;
      color: #1b1b1b;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 11px 17px;
      transition: 0.3s ease;

      img {
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    a {
      display: flex;
      align-items: center;
      border: 1px solid #1b1b1b;
      border-radius: 20px;
      color: #1b1b1b;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 11px 10px;
      transition: 0.3s ease;

      img {
        margin-right: 8px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }

  .loadmore-btn {
    border-radius: 20px;
  }

  .companies_and_fund_table {
    table {
      border: 1px solid #e2e8f0;
      border-radius: 10px;

      thead {
        th {
          font-size: 13px;
          line-height: 16px;
          color: #9d9d9c;
          font-weight: 700;
        }
      }

      th,
      td {
        border: 1px solid #e9e9e9;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }
    }

    .image-center {
      align-items: center;
    }

    .organization-img {
      border-radius: 50% !important;
      margin: 0 !important;
      margin-right: 15px !important;
      height: 50px;
      width: 50px;
      object-fit: contain;
      min-width: 50px;
    }

    .organization-name {
      color: black;
      font-size: 15px;
      line-height: 16px;
      font-weight: 700;
    }

    .background-image {
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .center-text {
      text-align: center;
    }
  }
}

.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }

  a {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.k-progress {
  margin: 0 !important;
}

.grey-dot {
  width: 10px !important;
  height: 10px;
  background-color: #9d9d9c;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.orange-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fe5000;
  border-radius: 10px;
  top: 5px;
  position: relative;
}

.blue-dot {
  width: 10px !important;
  height: 10px;
  background-color: #201747;
  border-radius: 10px;
  top: 5px;
  position: relative;
}

.yellow-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fae053;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .bonus_score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -250px;
  top: 20px;
}

.tooltip:hover .bonus_score_tooltiptext {
  visibility: visible;
}

.tooltip .roi_score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  // left: -250px;
  // top: 20px;
}

.tooltip:hover .roi_score_tooltiptext {
  visibility: visible;
}

.tooltipscore .score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 300px;
  // bottom: 130px;
}

.tooltipscore:hover .score_tooltiptext {
  visibility: visible;
}

.tooltip .bonus_score_tooltiptext::after {
  right: 95%;
}

.tooltip .roi_score_tooltiptext::after {
  right: 95%;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.pointer_event_none {
  pointer-events: none;
}

.opacity_light {
  opacity: 0.5;
}

.learn_more_modal_text_container {
  h2 {
    width: 60%;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
    width: 50%;
    text-align: end;
    cursor: pointer;
  }

  .learn_more_modal_text {
    height: 68vh;

    ::-webkit-scrollbar {
      width: 14px;
    }

    input {
      accent-color: black;
    }
  }
}

.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .EmailInput {
    padding: auto 0;
    // border: 1px solid gainsboro;
    border-radius: 5px;
    color: black;

    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      height: 100%;

      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        // display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        max-width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;

        .invite-member-btn {
        }

        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        padding: auto 0;
        border: 1px solid gainsboro;
        border-radius: 5px;

        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          height: 100%;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .InviteBtn {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      opacity: 0.7;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }

    .tooltip .tooltiptext {
      font-family: "Montserrat", arial;
      padding: 20px;
      font-size: medium;
      letter-spacing: normal;
      visibility: hidden;
      width: 600px;
      background-color: #201747;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      left: 20px;
      top: -160px;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .tooltip .tooltiptext::after {
      right: 95%;
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;
    .most_recent_message_section {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 40px;
    }
    .OverviewBlock {
      // background: #ffffff;
      // border-radius: 10px;
      // padding: 0 40px 40px;
      // padding-top: 40px;
      // margin-bottom: 18px;
      .OverviewBlock_left_side {
        width: 47%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .equal_strength {
          li {
            margin: 0;
            padding: 5px 0;
          }
        }
      }

      .OverviewBlock_right_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .gender_roi_score {
          .gender_roi_score_chart {
            width: 100%;
          }
        }
      }

      .take_survey_container {
        .take_survey_inner {
          h2 {
            display: flex;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              object-fit: contain;
            }
          }

          width: 50%;
          padding: 5px;

          ul {
            margin-top: 20px;

            li {
              display: flex;
              font-size: 13px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: left;
              margin-left: 0;
              padding-bottom: 10px;

              .edit_button {
                width: 16px;
                height: 16px;
              }

              span {
                width: 70%;
              }

              img {
                height: 20px;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .grey_border {
        display: inline-block;
        border-bottom: 2px solid #d0d0d0;
        width: 100%;
        height: 2px;
        margin: 35px 0;
      }

      .progress_bar_container {
        h2 {
          margin: auto 20px auto 0;
          width: 40%;
        }

        .span_text {
          padding-left: 68px;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
    }

    .spiderchart_main_container {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;

      .spiderchart_content {
        .left_side {
          width: 27%;
        }

        .right_side {
          width: 100%;

          .spiderchartContent {
            // padding: 0 20px;
            // width: 85%;

            .chartjs-render-monitor {
              // margin-left: -33px;
              // height: 530px !important;
            }

            .resilince-icon {
              max-width: 200px;
              left: 200px;
              top: 50px;
            }

            .opportunity-icon {
              max-width: 200px;
              top: 50px;
              right: 200px;
            }

            .inclusion-icon {
              max-width: 200px;
              right: 47%;
              bottom: -50px;
            }

            .spiderchartContent {
              width: 60%;
            }

            .Ind_Inv_Icon {
              // z-index: 9999;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              cursor: pointer !important;

              img {
                // width: 30px !important;
                // height: 30px;
                object-fit: contain;
                margin-right: 0 !important;
                margin-top: 30px !important;
              }

              span {
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                margin-top: 6px;
                // margin-left: 20px !important;
              }
            }

            .Indirect_Investment {
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .domain_assessment_results_section {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;
      margin-bottom: 40px;

      .spiderchartContent {
        // padding: 0 20px;
        width: 100%;

        .chartjs-render-monitor {
          // margin-left: -33px;
          // height: 530px !important;
        }

        .workforce-icon {
          max-width: 200px;
          left: 50px;
          top: 39%;
        }

        .value-chain-icon {
          max-width: 200px;
          right: 44%;
          bottom: -16px;
        }

        .leadership-icon {
          max-width: 200px;
          top: -9%;
          right: 44%;
        }

        .society-icon {
          max-width: 200px;
          top: 39%;
          right: 50px;
        }

        .spiderchartContent {
          width: 60%;
        }

        .Ind_Inv_Icon {
          // z-index: 9999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          cursor: pointer !important;

          img {
            // width: 30px !important;
            // height: 30px;
            object-fit: contain;
            margin-right: 0 !important;
            margin-top: 30px !important;
          }

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            margin-top: 6px;
            // margin-left: 20px !important;
          }
        }

        .Indirect_Investment {
          top: 0;
          left: 0;
        }
      }

      .domain_assessment_results_container {
        // color: black;

        .gender_roi_domain_taabs_container {
          img {
            width: 35px;
          }
        }

        .spiderchart_container {
          width: 60%;
        }
      }
    }

    .parameters_block {
      background: #ffff;
      padding: 40px;

      .parameters_section_first {
        padding: 0 10px 60px 10px;
      }

      .parameters_section {
        padding: 40px 10px 60px 10px;
        border-top: 1px solid #d0d0d0;
      }

      .inner_parameter_block {
        .score_results_section {
          .parameter_percent {
            h3 {
              margin: auto 0;
              padding: 0 20px;
            }

            .right_side_flex {
              margin: auto 0 auto auto;

              .dot_with_text {
                padding: 0 15px;
              }
            }
          }
        }

        .progress_bar_section {
          padding: 2rem 0;

          .left_side_progress_bar {
            width: 50%;
          }

          .right_side_progress_bar {
            width: 50%;
          }

          .roi-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }

            .roi-progress-content--left {
              color: #1b1b1b;
            }

            .roi-progress-content--right {
              width: 60%;
              line-height: 1;

              .bar-div {
                span {
                  color: #646363;
                  width: 50px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 320px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }

  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);

        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

.pdfexportbtn {
  height: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}

.blue-border-button {
  button {
    border: 1px solid #1b1b1b;
    // height: 36px;
    color: #1b1b1b;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    // line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>
