<template>
    <div class="max-w-[75vw] mx-auto pt-24 pb-14">
        <div class="content-wr">
            <h1 class="uppercase text-center text-3xl font-bold pb-5">
                2X Criteria Threshold Tool and Data Usage Terms
            </h1>
            <div class="flex justify-end">
                <EquiloButton classes="[ h-12 px-10 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
                    text="Download PDF" @on-click="downloadPdf" />
            </div>
            <div>
                <h2 class="py-3 font-bold">
                    PLEASE READ THESE USAGE TERMS CAREFULL
                </h2>
                <p class="mb-2">
                    This is a legally binding agreement (“Agreement”) between you and us (Equilo Inc. of 1658 N
                    Milwaukee
                    Ave, Suite 100-4581, Chicago, Illinois 60647 US), permitting you to access and use the 2X Criteria
                    Thresholds Tool (the “Tool”, inclusive of its content and underlying benchmarking data (the “Data”).
                </p>
                <p class="mb-2">
                    By using the Tool or accessing the Data via 2X Global or Equilo license, you agree to and accept the
                    following terms (“Terms”), which will be binding on you and your employer, or any other organization
                    on
                    whose behalf you are acting, (“Employer”) if you are accessing and use the Tool or Data in the
                    course of
                    your employment or otherwise on their behalf.
                </p>
                <p class="mb-2">
                    If your Employer has entered into an agreement with us governing your use of the Service
                    (“Enterprise
                    Agreement”), then the terms of that agreement shall, in the event of a conflict with these Terms,
                    override
                    and supersede these Terms.
                </p>
                <p class="mb-2">
                    By not accepting the terms, you may not be granted access or use of this Tool, Data Set, or the
                    software
                    that we use to provide it.
                </p>
                <ol>
                    <li>
                        <h2 class="font-bold text-lg">1. Definitions</h2>
                        <div class="pl-5">
                            <p class="mb-2">
                                The following words and phrases shall have the following meaning when used in these
                                Terms unless the
                                context requires otherwise:
                            </p>
                            <ol>
                                <li>
                                    <strong>1.1 “Tool”: </strong> The 2X Criteria Thresholds Tool provided by Equilo,
                                    which allows users via a dashboard
                                    to assess an entity’s alignment with the 2X Criteria utilizing dynamic thresholds
                                    based on entity
                                    type, country, and industry.
                                </li>
                                <li>
                                    <strong>1.2. “Content”: </strong> any information that is made available to you by
                                    means of the Tool via the
                                    Dashboard.
                                </li>
                                <li>
                                    <strong>1.3. “Data”: </strong> Refers to the entire collection of 2X Criteria
                                    Threshold Benchmarks data made available
                                    through the 2X Criteria Threshold Tool, or licensed through a downloadable .CSV or
                                    Excel
                                    format.
                                </li>
                                <li>
                                    <strong>1.4. “Intellectual Property Rights”: </strong> any and all copyright, rights
                                    in inventions, patents, know-how,
                                    trade secrets, trademarks and trade names, service marks, design rights, rights in
                                    get-up,
                                    database rights and rights in data and all similar rights whether registered or
                                    unregistered,
                                    including any applications to protect or register such rights and all renewals and
                                    extensions of
                                    such rights or applications whether vested, contingent or future anywhere in the
                                    world.
                                </li>
                                <li>
                                    <strong>1.5. “Software”: </strong> : the software accessed by you in order to
                                    receive the Service (e.g. access the Tool,
                                    Content, or Data).
                                </li>
                                <li>
                                    <strong>1.6. “Subscriber/Subscription”: </strong> The mechanism by which a user
                                    accesses the Tool or Data Set
                                    through the Equilo application at app.equilo.io.
                                </li>
                                <li>
                                    <strong>1.7. “Service”: </strong> Services provided to access the Tool, Content, or
                                    Data via an “Enterprise
                                    Agreement”.
                                </li>
                                <li>
                                    <strong>1.8. “Automated Data Extraction”: </strong> The use of automated scripts,
                                    bots, or software to retrieve any
                                    amount of data without manual input.
                                </li>
                                <li>
                                    <strong>1.9. “Monetization”: </strong> : The act of generating revenue, such as by
                                    selling, sublicensing, or using the
                                    data for commercial gain.
                                </li>
                                <li>
                                    <strong>1.10. “Violation/s”: </strong> Any action that breaches the terms of this
                                    Fair Usage Policy, including but not
                                    limited to Excessive Usage, unauthorized sharing, or misuse of the Tool.
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h2>2. USE OF THE TOOL AND DATA</h2>
                        <ol class="pl-5">
                            <li>
                                <strong>2.1. </strong> Subject to you complying with your obligations under this
                                Agreement and your Employer
                                complying with its obligations under its Subscription, License, or Enterprise Agreement,
                                we will
                                make the Tool, Content, and Data available to you substantially in accordance with this
                                Agreement.
                            </li>
                            <li>
                                <strong>2.2. </strong> Except as set out in this Agreement:
                                <ol class="mt-2 ml-5">
                                    <li>
                                        <strong>2.2.1. </strong> You agree that your use of the Tool, Content, and Data
                                        is at your own risk and that the Tool
                                        and Data is provided to you on an "as is" basis;
                                    </li>
                                    <li>
                                        <strong>2.2.2. </strong> We do not represent or warrant that the use of the Tool
                                        or Data will be uninterrupted or
                                        error-free or that it will meet the Customer's specific requirements; and
                                    </li>
                                    <li>
                                        <strong>2.2.3. </strong> All conditions, warranties, or other terms which may be
                                        implied or incorporated into this
                                        Agreement in any way are excluded.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>2.3. </strong> We will not be liable for any delays, delivery failures, or any
                                other losses arising out of or in
                                connection with any defect in your technical environment or any third-party
                                infrastructure. You
                                shall be solely responsible for determining whether the Tool or Data or the information
                                generated
                                by it is accurate or sufficient for your purposes.
                            </li>
                            <li>
                                <strong>2.4. </strong> Where an Enterprise Agreement is in place, it is agreed that any
                                issues relating to Tool or Data
                                failures or defaults shall be subject to that agreement and you shall have no separate
                                claim or
                                right of action against us under this Agreement in respect of such issues.
                            </li>
                            <li>
                                <strong>2.5. </strong> With respect to the use of the Tool, Content, and Data you may:
                                <ol class="mt-2 ml-5">
                                    <li>
                                        <strong>2.5.1. </strong> Use the Tool and Content to input entity information
                                        into the Tool and receive automated
                                        feedback on the dashboard regarding the specific thresholds for the entity type,
                                        country,
                                        and industry, and if this self-assessment indicates alignment with the 2X
                                        Criteria. The
                                        Content and Data set may be used to support internal communications and
                                        decisions to
                                        proceed with 2X Challenge or 2X Certification assessment, or for internal
                                        informational
                                        purposes;
                                    </li>
                                    <li>
                                        <strong>2.5.2. </strong> Use displayed content, data, and benchmarks provided by
                                        the tool in dashboard format for
                                        internal research and analysis only, with indication benchmarking of a
                                        particular entity
                                        against the set 2X Criteria. The intended use of the Tool is to provide an
                                        indication to the
                                        user if their entity meets or exceeds the set 2X Threshold. The Tool is intended
                                        to be used
                                        as an indication that the entity may be an eligible candidate to proceed to 2X
                                        Certification or
                                        my qualify for 2X Challenge; and
                                    </li>
                                    <li>
                                        <strong>2.5.3. </strong> Use the Data set of underlying benchmarks in .CSV and
                                        Excel for internal institutional use
                                        only. The Data is only accessible through special license access provided to 2X
                                        Global
                                        sophisticated investors by 2X Global as part of 2X Global membership, or by
                                        purchasing
                                        once-off license access to the Data directly from Equilo. The licensing is valid
                                        for the
                                        duration of the validity of the benchmarks (approximately through 2029). The
                                        data set for
                                        these users may be integrated into internal data systems and
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>2.6. </strong> Except as set out in these Terms or as permitted by applicable
                                law, you must not:
                                <ol class="mt-2 ml-5">
                                    <li>
                                        <strong>2.6.1. </strong> Reproduce, publish, or share externally a
                                        replication of the Tool, Content, or Data that
                                        attempts to replicate Equilo’s functionality for audiences, entities, or
                                        users for any purpose
                                        outside of internal use per the terms in 2.5. This may not be done for any
                                        reason, neither for
                                        Monetization nor other purposes;
                                    </li>
                                    <li>
                                        <strong>2.6.2. </strong> Use the Content, Data, or Benchmarks in any external
                                        publications, marketing or
                                        advertising, unless the Content and Data is otherwise published as part of
                                        the entity’s 2X
                                        Certification Scorecard or as part of 2X Challenge Deal disclosures. This
                                        includes
                                        restrictions in marketing or claiming “2X Certification” or “2X Eligibility”
                                        or “2X Qualified”
                                        status, which is a false representation through use of this Tool and Content
                                        alone, and
                                        requires official 2X Challenge Deal Qualification or 2X Certification. This
                                        may not be done
                                        for any reason, neither for Monetization nor other purposes;
                                    </li>
                                    <li>
                                        <strong>2.6.3. </strong> Provide or otherwise make available the Software,
                                        Tool, Content, or Data in any form to
                                        any person without our prior written consent, unless explicitly agreed
                                        within an existing
                                        Enterprise Agreement or Partnership Agreement with Equilo. This includes any
                                        unauthorized use, distribution, or reproduction of the Data, or use to
                                        provide wholesale
                                        benchmarking for external audiences outside of the entity or the entity’s
                                        investees;
                                    </li>
                                    <li>
                                        <strong>2.6.4. </strong> Use the Software in a manner that: (i) accesses or
                                        uses any data or information beyond that
                                        which we permit; (ii) breaks or circumvents any of our technical,
                                        administrative or security
                                        measures; (iii) degrades the performance of our services, systems or
                                        networks; or (iv)
                                        transmits any viruses or other malicious programming that could damage,
                                        degrade,
                                        interfere with or intercept any of our services, systems or networks;
                                    </li>
                                    <li>
                                        <strong>2.6.5. </strong> Disassemble, decompile, reverse engineer or create
                                        derivative works based on the
                                        Software, Tool, Content, or Data and where the same is permitted by
                                        applicable law, any
                                        information obtained as a result must not be disclosed without our prior
                                        written consent;
                                    </li>
                                    <li>
                                        <strong>2.6.6. </strong> Utilize Automated Data Extraction to access Content
                                        or Data wholesale. Data scraping of
                                        the tool is prohibited (e.g. utilizing any software or tools to collect all
                                        data wholesale in part
                                        or whole). Consecutive use of the Tool in more than 30 combinations within
                                        one day will
                                        trigger a warning flag of potential abuse (manual or automated) of attempt
                                        at wholesale
                                        access to the data. Users who wish to access the data set must pay for the
                                        license fee to
                                        access the Data Set;
                                    </li>
                                    <li>
                                        <strong>2.6.7. </strong> Remove, obscure, or alter any copyright, trademark
                                        or other proprietary rights notices that
                                        may be affixed to or contained within the Software, Tool, Content, or Data;
                                    </li>
                                    <li>
                                        <strong>2.6.8. </strong> Copy, reproduce, publish, distribute, redistribute,
                                        broadcast, transmit, modify, adapt, edit,
                                        abstract, store, archive, display publicly or to third parties, sell,
                                        license, sublicense, lease,
                                        rent, assign, transfer, disclose (in each case whether or not for charge) or
                                        in any way
                                        commercially exploit any part of the Tool, Content, and/or Data Set, unless
                                        for official 2X
                                        Global purposes by licensed holders for the qualification of 2X Challenge
                                        Deals.
                                    </li>
                                    <li>
                                        <strong>2.6.9. </strong> Permit any use of any Content, Tool, or Software in
                                        any manner by any third party;
                                    </li>
                                    <li>
                                        <strong>2.6.10. </strong> Combine, merge or otherwise permit the Content,
                                        Tool, Software, or Data to become
                                        incorporated in any other application, program or service, or arrange or
                                        create derivative
                                        works based on it (in whole or in part); or
                                    </li>
                                    <li>
                                        <strong>2.6.11. </strong> Access or use the Content, Tool, Software, or Data to
                                        develop or support, or assist a
                                        third party to develop or support, any products or services in competition with
                                        the Software
                                        and/ or the Tool, Content, and Data provided by Equlio.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>2.7. </strong> Any breach by you of the restrictions set out in clause 2.6 will
                                be
                                deemed to constitute a material
                                breach of this Agreement by you.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>3. INTELLECTUAL PROPERTY RIGHTS</h2>
                        <ol class="pl-5">
                            <li>
                                <strong>3.1. </strong> All Intellectual Property Rights in and to the Content, Tool,
                                Software, and Data will belong to and
                                remain vested in us or our licensors. Except as expressly set out in this Agreement,
                                nothing in
                                this Agreement grants you any right, title, or interest in or to any Intellectual
                                Property Rights in
                                respect of the Tool, Content, Software, or Data. We are under no obligation to deliver
                                copies of
                                any software, content, or data comprised in the Services to you in connection with this
                                Agreement or the Services.
                            </li>
                            <li>
                                <strong>3.2. </strong> You acknowledge that certain components of the Content and
                                Software are subject to third-party
                                copyright and other Intellectual Property Rights which we will notify you of from time
                                to time, and
                                you agree to comply with the terms of all applicable third party licenses and shall not
                                do or omit
                                to do anything which may cause us to be in breach of them.
                            </li>
                            <li>
                                <strong>3.3. </strong> If you provide any feedback or suggestions about the Tool,
                                Content, Software or Data to us then
                                we may use that information without obligation to you and you assign all right title and
                                interest in
                                that information to us.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>4. TERM AND TERMINATION
                        </h2>
                        <ol class="pl-5">
                            <li>
                                <strong>4.1. </strong> This Agreement shall remain in effect during such period as we
                                may permit you to use the Tool
                                or Data through a licensed agreement.
                            </li>
                            <li>
                                <strong>4.2. </strong> Unless agreed otherwise in an Enterprise or License Agreement, we
                                may terminate this
                                Agreement immediately on written notice:
                                <ol class="mt-2 ml-5">
                                    <li>
                                        <strong>4.2.1. </strong> If your permission to use the Service or Software
                                        ceases; or
                                    </li>
                                    <li>
                                        <strong>4.2.2. </strong> If you commit a material breach of any term of this
                                        Agreement;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>4.3. </strong> On termination or expiry of this Agreement for any reason:
                                <ol class="mt-2 ml-5">
                                    <li>
                                        <strong>4.3.1. </strong> All licences granted to you under this Agreement will
                                        terminate immediately and the
                                        Customer will cease using the Content, Software, or Services immediately;
                                    </li>
                                    <li>
                                        <strong>4.3.2. </strong> You will, on request at our option, promptly delete,
                                        destroy, or return any Confidential
                                        Information and other items, including Data, (and copies of them) belonging to
                                        us; and
                                    </li>
                                    <li>
                                        <strong>4.3.3. </strong> Any rights, remedies, obligations or liabilities of the
                                        parties that have accrued up to the date
                                        of termination, including the right to claim damages in respect of any breach of
                                        this
                                        Agreement which existed at or before the date of termination or expiry will not
                                        be affected or
                                        prejudiced and such termination or expiry will not affect any provision of this
                                        Agreement that
                                        is expressly or by implication intended to continue beyond termination or
                                        expiry.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h2>5. LIMITATION OF LIABILITY</h2>
                        <ol class="pl-5">
                            <li>
                                <strong>5.1. </strong> Subject to the other provisions of this clause 2, we will not be
                                liable for any indirect, special,
                                punitive or consequential damages (including loss of data or service interruption)
                                arising out of
                                the provision, use of, or inability to use, the Tool, Content, Software, or Data,
                                regardless of the
                                basis of such claim and even if advised of the possibility of such damages.
                            </li>
                            <li>
                                <strong>5.2. </strong> Subject to any other provision of this Agreement each party's
                                liability to the other party will not
                                be limited in any way in respect of: death or personal injury caused by negligence;
                                fraud or
                                fraudulent misrepresentation; or any other losses which cannot be excluded or limited by
                                applicable law.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>
<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";
export default {
    name: "TwoXUserAgreement",
    components: {
        EquiloButton,
    },
    methods: {
        downloadPdf() {
            window.open(
                "https://storage.equilo.io/api-assets/Equilo2XThresholdBenchmarkToolandDataUsageTerms1.pdf",
                "_blank"
            );
        },
    },
    mounted() {
        this.$store.commit("setBreadcrumbs", [
            {
                name: "Settings",
                url: "/settings/account",
            },
            {
                name: "2x User Agreement",
                url: "/2x-user-agreement",
            },
        ]);
    },
};
</script>

<style scoped>
li {
    color: #333 !important;
    padding-bottom: 10px;
}

p {
    color: #333 !important;
}

h2 {
    font-size: 18px;
    font-weight: bold !important;
    margin-bottom: 8px;
    margin-top: 15px;
}
</style>