<template>
  <div v-if="status === 'completed' && noValue" class="flex items-center">
    <div
      class="flex text-white text-sm items-center px-3 py-2 font-bold my-5"
      style="border-radius: 20px; background-color: #ff501c"
    >
    <img src="@/assets/images/white-x.svg" />
      <span class="ml-2">{{ elegible ? altText : text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotElegibleBadge",
  props: {
    status: String,
    elegible: Boolean,
    text: String,
    altText: String,
    noValue: Boolean,
  },
};
</script>
