var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('h1',{staticClass:"text-3xl font-bold text-[#21191D]"},[_vm._v("Portfolio Surveys")]),(
      (!_vm.portfolioSurveys?.length || !_vm.portfolioCompanies?.length) && !_vm.loading
    )?_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"text-red-500"},[_vm._v(" No surveys or companies found ")])]):_c('div',{staticClass:"flex flex-col space-y-4"},[_c('label',{staticClass:"text-xl font-semibold text-[#21191D] mt-4",attrs:{"for":"company"}},[_vm._v("Select Company ")]),(_vm.portfolioCompanies?.length)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg w-1/6",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.company=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select Company")]),_vm._l((_vm.portfolioCompanies),function(company){return _c('option',{key:company._id,domProps:{"value":company}},[_vm._v(" "+_vm._s(company.name)+" ")])})],2):_vm._e(),(_vm.company.userId)?_c('div',[_c('span',{staticClass:"text-sm text-[#21191D]"},[_vm._v(" Click on any survey to start ")]),(_vm.loading)?_c('div',[_vm._v("Loading...")]):_c('div',{staticClass:"grid grid-cols-4 gap-10 mt-10"},_vm._l((_vm.portfolioSurveys),function(survey){return _c('div',{key:survey.id,staticClass:"relative group"},[_c('router-link',{staticClass:"px-4 py-4 relative flex items-center justify-center h-24",class:{
              'bg-[#191B45] text-white rounded-lg':
                survey.isEnabled && !survey.isCompleted,
              'bg-[#a5a5a5] text-gray-700 rounded-lg cursor-not-allowed opacity-50':
                !survey.isEnabled,
              'opacity-50 bg-green-300 text-white rounded-lg cursor-not-allowed':
                survey.isCompleted,
            },attrs:{"to":survey.isEnabled && !survey.isCompleted
                ? survey.id === 'gender-roi'
                  ? '/roi'
                  : survey.id === '2x'
                  ? '/2X-Challenge'
                  : survey.id.includes('rating')
                  ? `/impact-compass/surveys/${survey.id}?id=${_vm.company._id}&surveyId=${survey.id}`
                  : `/impact-compass/surveys/${survey.id}?id=${_vm.company._id}&tag=${_vm.entityType}`
                : '',"target":"_blank"}},[_c('button',{staticClass:"w-full h-full font-semibold",class:{
                'text-white': survey.isEnabled && !survey.isCompleted,
                'text-gray-700 cursor-not-allowed':
                  !survey.isEnabled || survey.isCompleted,
              },attrs:{"disabled":!survey.isEnabled || survey.isCompleted}},[_vm._v(" "+_vm._s(survey.name)+" ")]),(survey.isCompleted)?_c('div',{staticClass:"absolute right-2 top-1/2 transform -translate-y-1/2"},[_c('svg',{staticClass:"h-6 w-6 text-green-700",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 13l4 4L19 7"}})])]):_vm._e()]),(!survey.isEnabled)?_c('div',{staticClass:"absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.getDisabledTooltip(survey.id))+" ")]):_vm._e(),(survey.isCompleted)?_c('div',{staticClass:"absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"},[_vm._v(" Survey completed ")]):_vm._e()],1)}),0)]):_c('div',[_c('span',{staticClass:"text-sm text-red-500"},[_vm._v(" This company doesn't have a user id associated with it. Please contact support. ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }