<template>
  <div
    class="sticky-sidebar min-h-screen flex justify-center bg-gray-100 overflow-hidden sidebar-inner pt-16"
  >
    <div
      class="flex w-full overflow-x-auto p-2 text-sm"
      style="background-color: #201747; z-index: 10"
    >
      <ul class="flex flex-col w-full">
        <li class="my-px">
          <router-link to="/">
            <span
              class="flex font-bold text-lg text-gray-600 px-4 my-4 uppercase"
              >Equilo</span
            >
          </router-link>
        </li>

        <li class="my-px" style="margin-top: 20px">
          <span
            class="flex text-xs font-medium text-gray-600 px-4 mb-2 uppercase"
            >Account</span
          >
        </li>
        <li class="my-px">
          <router-link
            to="/settings/account"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path
                    d="M.125 0h23.75 -5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125v23.75 0c0 .0690356-.0559644.125-.125.125h-23.75 -5.46392e-09c-.0690356-3.01764e-09-.125-.0559644-.125-.125v-23.75l1.44329e-15 1.88745e-08c-1.04241e-08-.0690356.0559644-.125.125-.125Z"
                  ></path>
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  ></circle>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M15.611 16.5h-7.222l3.21351e-08 5.76428e-11c-.306842-.000550373-.55545-.249158-.556-.556v-.451l-4.87439e-08 1.62824e-05c.0038376-1.28341 1.04357-2.32271 2.32698-2.32602h3.68l1.38533e-07 3.5566e-10c1.28341.00329499 2.32315 1.04259 2.327 2.326v.451l-7.03082e-12 3.91971e-09c-.000550482.306836-.249148.55544-.555984.556Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12 11l-6.90787e-08-5.54223e-13c-1.24264-9.97932e-06-2.24999-1.00738-2.24998-2.25002 9.97932e-06-1.24264 1.00738-2.24999 2.25002-2.24998 1.23912 9.95105e-06 2.24501 1.00189 2.24996 2.241l9.15825e-09 2.9466e-06c.00386481 1.24374-1.00125 2.25512-2.24499 2.25899 -.00167056 5.19112e-06-.00334112 8.52337e-06-.00501169 9.99674e-06Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3">Account Settings</span>
          </router-link>
          <router-link
            to="/settings/changepassword"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path
                    d="M.125 0h23.75 -5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125v23.75 0c0 .0690356-.0559644.125-.125.125h-23.75 -5.46392e-09c-.0690356-3.01764e-09-.125-.0559644-.125-.125v-23.75l1.44329e-15 1.88745e-08c-1.04241e-08-.0690356.0559644-.125.125-.125Z"
                  ></path>
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  ></circle>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M15.611 16.5h-7.222l3.21351e-08 5.76428e-11c-.306842-.000550373-.55545-.249158-.556-.556v-.451l-4.87439e-08 1.62824e-05c.0038376-1.28341 1.04357-2.32271 2.32698-2.32602h3.68l1.38533e-07 3.5566e-10c1.28341.00329499 2.32315 1.04259 2.327 2.326v.451l-7.03082e-12 3.91971e-09c-.000550482.306836-.249148.55544-.555984.556Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12 11l-6.90787e-08-5.54223e-13c-1.24264-9.97932e-06-2.24999-1.00738-2.24998-2.25002 9.97932e-06-1.24264 1.00738-2.24999 2.25002-2.24998 1.23912 9.95105e-06 2.24501 1.00189 2.24996 2.241l9.15825e-09 2.9466e-06c.00386481 1.24374-1.00125 2.25512-2.24499 2.25899 -.00167056 5.19112e-06-.00334112 8.52337e-06-.00501169 9.99674e-06Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3 text-[.875rem]">Change Password</span>
          </router-link>
          <router-link
            to="/settings/my-investor-profile"
            v-if="user?.membership2xData"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path
                    d="M.125 0h23.75 -5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125v23.75 0c0 .0690356-.0559644.125-.125.125h-23.75 -5.46392e-09c-.0690356-3.01764e-09-.125-.0559644-.125-.125v-23.75l1.44329e-15 1.88745e-08c-1.04241e-08-.0690356.0559644-.125.125-.125Z"
                  ></path>
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  ></circle>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M15.611 16.5h-7.222l3.21351e-08 5.76428e-11c-.306842-.000550373-.55545-.249158-.556-.556v-.451l-4.87439e-08 1.62824e-05c.0038376-1.28341 1.04357-2.32271 2.32698-2.32602h3.68l1.38533e-07 3.5566e-10c1.28341.00329499 2.32315 1.04259 2.327 2.326v.451l-7.03082e-12 3.91971e-09c-.000550482.306836-.249148.55544-.555984.556Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12 11l-6.90787e-08-5.54223e-13c-1.24264-9.97932e-06-2.24999-1.00738-2.24998-2.25002 9.97932e-06-1.24264 1.00738-2.24999 2.25002-2.24998 1.23912 9.95105e-06 2.24501 1.00189 2.24996 2.241l9.15825e-09 2.9466e-06c.00386481 1.24374-1.00125 2.25512-2.24499 2.25899 -.00167056 5.19112e-06-.00334112 8.52337e-06-.00501169 9.99674e-06Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3">My Investor Profile</span>
          </router-link>

          <div
            class="tab0"
            v-if="
              user.organization &&
              user.organization.secretKey &&
              user.organization.productApiAccess
            "
          >
            <input type="checkbox" id="chck0" />
            <label class="tab-label max-w-[16rem] container" for="chck0">
              <span class="flex items-center justify-center text-lg">
                <svg
                  class="h-5 w-5"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path
                      d="M.125 0h23.75 -5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125v23.75 0c0 .0690356-.0559644.125-.125.125h-23.75 -5.46392e-09c-.0690356-3.01764e-09-.125-.0559644-.125-.125v-23.75l1.44329e-15 1.88745e-08c-1.04241e-08-.0690356.0559644-.125.125-.125Z"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    ></circle>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M15.611 16.5h-7.222l3.21351e-08 5.76428e-11c-.306842-.000550373-.55545-.249158-.556-.556v-.451l-4.87439e-08 1.62824e-05c.0038376-1.28341 1.04357-2.32271 2.32698-2.32602h3.68l1.38533e-07 3.5566e-10c1.28341.00329499 2.32315 1.04259 2.327 2.326v.451l-7.03082e-12 3.91971e-09c-.000550482.306836-.249148.55544-.555984.556Z"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 11l-6.90787e-08-5.54223e-13c-1.24264-9.97932e-06-2.24999-1.00738-2.24998-2.25002 9.97932e-06-1.24264 1.00738-2.24999 2.25002-2.24998 1.23912 9.95105e-06 2.24501 1.00189 2.24996 2.241l9.15825e-09 2.9466e-06c.00386481 1.24374-1.00125 2.25512-2.24499 2.25899 -.00167056 5.19112e-06-.00334112 8.52337e-06-.00501169 9.99674e-06Z"
                    ></path>
                  </g>
                </svg>
              </span>
              <span
                :style="
                  user.languagePreference == 'English'
                    ? 'margin-left: -59px;'
                    : 'margin-left: 3px'
                "
                >API Settings</span
              ></label
            >
            <div class="tab-content third-item" v-if="GBV">
              <router-link to="/settings/gbv_api_settings"
                >GBV API Settings</router-link
              >
            </div>
            <div class="tab-content third-item" v-if="GESI">
              <router-link to="/settings/gesi_api_settings"
                >GESI API Settings</router-link
              >
            </div>
            <div class="tab-content third-item" v-if="PORTFOLIO">
              <router-link to="/settings/twox_portfolio_api_settings"
                >2X Portfolio API Settings</router-link
              >
            </div>
            <div class="tab-content third-item" v-if="ROI">
              <router-link to="/settings/roi_api_settings"
                >Gender ROI™ Portfolio API Settings</router-link
              >
            </div>
            <div class="tab-content third-item" v-if="ROI_Assessment_API">
              <router-link to="/settings/roi_assessment_api_settings"
                >Gender ROI™ Assessment API Settings</router-link
              >
            </div>
            <div class="tab-content third-item" v-if="twox_Assessment_API">
              <router-link to="/settings/twox_assessment_api_settings"
                >2X Assessment API Settings</router-link
              >
            </div>
          </div>

          <div class="tab">
            <input type="checkbox" id="chck1" />
            <label class="tab-label max-w-[16rem] container" for="chck1">
              <span class="flex items-center justify-center text-lg">
                <svg
                  class="h-5 w-5"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path
                      d="M.125 0h23.75 -5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125v23.75 0c0 .0690356-.0559644.125-.125.125h-23.75 -5.46392e-09c-.0690356-3.01764e-09-.125-.0559644-.125-.125v-23.75l1.44329e-15 1.88745e-08c-1.04241e-08-.0690356.0559644-.125.125-.125Z"
                    ></path>
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    ></circle>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M15.611 16.5h-7.222l3.21351e-08 5.76428e-11c-.306842-.000550373-.55545-.249158-.556-.556v-.451l-4.87439e-08 1.62824e-05c.0038376-1.28341 1.04357-2.32271 2.32698-2.32602h3.68l1.38533e-07 3.5566e-10c1.28341.00329499 2.32315 1.04259 2.327 2.326v.451l-7.03082e-12 3.91971e-09c-.000550482.306836-.249148.55544-.555984.556Z"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 11l-6.90787e-08-5.54223e-13c-1.24264-9.97932e-06-2.24999-1.00738-2.24998-2.25002 9.97932e-06-1.24264 1.00738-2.24999 2.25002-2.24998 1.23912 9.95105e-06 2.24501 1.00189 2.24996 2.241l9.15825e-09 2.9466e-06c.00386481 1.24374-1.00125 2.25512-2.24499 2.25899 -.00167056 5.19112e-06-.00334112 8.52337e-06-.00501169 9.99674e-06Z"
                    ></path>
                  </g>
                </svg>
              </span>
              <span
                :style="
                  user.languagePreference == 'English'
                    ? 'margin-left: -20px;'
                    : 'margin-left: 3px'
                "
                >Shared by me</span
              ></label
            >
            <div class="tab-content third-item">
              <router-link to="/settings/AssessmentsSharedByMe"
                >2X Assessment</router-link
              >
            </div>
            <div class="tab-content third-item">
              <router-link to="/settings/gbvhSharedAssessmentByMe"
                >GBVH</router-link
              >
            </div>
          </div>
        </li>

        <li
          class="my-px"
          style="margin-top: 40px"
          v-if="user && user.admin && user.organization"
        >
          <span
            class="flex text-xs font-medium text-gray-500 px-4 mb-2 uppercase"
            >Team</span
          >
        </li>

        <li class="my-px" v-if="user && user.admin">
          <router-link
            to="/settings/team"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path d="M0,0h24v24h-24v-24Z"></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M18.364,5.636c3.515,3.515 3.515,9.213 0,12.728c-3.515,3.515 -9.213,3.515 -12.728,0c-3.515,-3.515 -3.515,-9.213 0,-12.728c3.515,-3.515 9.213,-3.515 12.728,-8.88178e-16"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M9.172,14.828c-1.562,-1.562 -1.562,-4.095 0,-5.657c1.562,-1.562 4.095,-1.562 5.657,0c1.562,1.562 1.562,4.095 0,5.657c-1.563,1.563 -4.095,1.563 -5.657,0"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M14.83,9.17l-2.83,2.83"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12,3v1.5"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12,21v-1.5"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M18.364,5.636l-1.061,1.061"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M5.636,18.364l1.061,-1.061"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M21,12h-1.5"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M3,12h1.5"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M18.364,18.364l-1.061,-1.061"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M5.636,5.636l1.061,1.061"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3">Team Settings</span>
          </router-link>
        </li>

        <li class="my-px" v-if="user && user.admin">
          <router-link
            to="/settings/members"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path d="M0,0h24v24h-24v-24Z"></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M9.225,6.452v0c0,-1.532 1.242,-2.775 2.775,-2.775v0c1.532,0 2.775,1.242 2.775,2.775v0c0,1.532 -1.242,2.775 -2.775,2.775v0c-1.533,-0.002 -2.775,-1.244 -2.775,-2.775Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M2.5,17.548v0c0,-1.532 1.242,-2.775 2.775,-2.775v0c1.532,0 2.775,1.242 2.775,2.775v0c-0.001,1.532 -1.242,2.775 -2.775,2.775v0c-1.533,3.55271e-15 -2.775,-1.242 -2.775,-2.775Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M15.951,17.548v0c0,-1.532 1.242,-2.775 2.775,-2.775v0c1.532,0 2.775,1.242 2.775,2.775v0c0,1.532 -1.242,2.775 -2.775,2.775v0c-1.534,3.55271e-15 -2.775,-1.242 -2.775,-2.775Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M8.49,11.92l-2.01,3.14"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M13.5,8.78l2.01,3.14"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M15.95,17.55h-3.95"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3">Team Members</span>
          </router-link>
        </li>

        <li class="my-px" style="margin-top: 40px">
          <span
            class="flex text-xs font-medium text-gray-500 px-4 mb-2 uppercase"
            >Resources</span
          >
        </li>

        <li class="my-px">
          <a
            href="mailto:hello@equilo.io"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g fill="none">
                  <path d="M0,0h24v24h-24v-24Z"></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M11.467,18.934v0c-4.124,0 -7.467,-3.343 -7.467,-7.467v0c0,-4.124 3.343,-7.467 7.467,-7.467v0c4.124,0 7.467,3.343 7.467,7.467v0c0,4.124 -3.343,7.467 -7.467,7.467Z"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M11.467,12.504v-0.207c0,-0.678 0.419,-1.045 0.839,-1.327c0.41,-0.276 0.821,-0.636 0.821,-1.3c0,-0.917 -0.743,-1.659 -1.659,-1.659c-0.916,0 -1.66,0.741 -1.66,1.658"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M11.466,14.843c-0.083,0 -0.15,0.067 -0.149,0.15c0,0.083 0.067,0.15 0.15,0.15c0.083,0 0.15,-0.067 0.15,-0.15c0,-0.083 -0.067,-0.15 -0.151,-0.15"
                  ></path>
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M20,20l-3.25,-3.25"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="ml-3">Help</span>
          </a>
        </li>
        <li class="my-px">
          <router-link
            to="/settings/demo-video"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </span>
            <span class="ml-3">Demo Video</span>
          </router-link>
        </li>
        <li class="my-px">
          <router-link
            to="/user-terms"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 10V10.5H14H19.5H20.5V19C20.5 19.3978 20.342 19.7794 20.0607 20.0607C19.7794 20.342 19.3978 20.5 19 20.5H5C4.60218 20.5 4.22064 20.342 3.93934 20.0607C3.65804 19.7794 3.5 19.3978 3.5 19V5C3.5 4.16614 4.16614 3.5 5 3.5H13.5V4.5V10ZM20.5 9.20711V10.2929L19.8536 9.64645L14.3536 4.14645L13.7071 3.5H14.7929L20.5 9.20711ZM5 4.5H4.5V5V19V19.5H5H19H19.5V19V12V11.5H19H12.5V5V4.5H12H5Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </span>
            <span class="ml-3">User Terms</span>
          </router-link>
        </li>
        <li class="my-px">
          <router-link
            to="/cookies-policy"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 10V10.5H14H19.5H20.5V19C20.5 19.3978 20.342 19.7794 20.0607 20.0607C19.7794 20.342 19.3978 20.5 19 20.5H5C4.60218 20.5 4.22064 20.342 3.93934 20.0607C3.65804 19.7794 3.5 19.3978 3.5 19V5C3.5 4.16614 4.16614 3.5 5 3.5H13.5V4.5V10ZM20.5 9.20711V10.2929L19.8536 9.64645L14.3536 4.14645L13.7071 3.5H14.7929L20.5 9.20711ZM5 4.5H4.5V5V19V19.5H5H19H19.5V19V12V11.5H19H12.5V5V4.5H12H5Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </span>
            <span class="ml-3">Cookie policy</span>
          </router-link>
        </li>
        <li class="my-px">
          <router-link
            to="/2x-user-agreement"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 10V10.5H14H19.5H20.5V19C20.5 19.3978 20.342 19.7794 20.0607 20.0607C19.7794 20.342 19.3978 20.5 19 20.5H5C4.60218 20.5 4.22064 20.342 3.93934 20.0607C3.65804 19.7794 3.5 19.3978 3.5 19V5C3.5 4.16614 4.16614 3.5 5 3.5H13.5V4.5V10ZM20.5 9.20711V10.2929L19.8536 9.64645L14.3536 4.14645L13.7071 3.5H14.7929L20.5 9.20711ZM5 4.5H4.5V5V19V19.5H5H19H19.5V19V12V11.5H19H12.5V5V4.5H12H5Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </span>
            <span class="ml-3">2x User Agreement</span>
          </router-link>
        </li>
        <li class="my-px">
          <router-link
            to="/2x-threshold-tool-agreement"
            class="flex flex-row items-center h-10 px-4 rounded-lg text-gray-200"
          >
            <span class="flex items-center justify-center text-lg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 10V10.5H14H19.5H20.5V19C20.5 19.3978 20.342 19.7794 20.0607 20.0607C19.7794 20.342 19.3978 20.5 19 20.5H5C4.60218 20.5 4.22064 20.342 3.93934 20.0607C3.65804 19.7794 3.5 19.3978 3.5 19V5C3.5 4.16614 4.16614 3.5 5 3.5H13.5V4.5V10ZM20.5 9.20711V10.2929L19.8536 9.64645L14.3536 4.14645L13.7071 3.5H14.7929L20.5 9.20711ZM5 4.5H4.5V5V19V19.5H5H19H19.5V19V12V11.5H19H12.5V5V4.5H12H5Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </span>
            <span class="ml-3">2X Criteria Threshold Tool/Data Usage Terms</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";

export default {
  name: "sitebar",
  data: function () {
    return {
      user: {},
      subscription: true,
      GBV: false,
      GESI: false,
      PORTFOLIO: false,
      ROI: false,
      ROI_Assessment_API: false,
      twox_Assessment_API: false,
    };
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  async created() {
    this.user = this.userStore.user;
    if (this.user.organization.productApiAccess) {
      let productApiAccess = this.user.organization.productApiAccess.split(",");
      productApiAccess.map((val) => {
        if (val == "GBV") {
          this.GBV = true;
        }
        if (val == "GESI") {
          this.GESI = true;
        }
        if (val == "PORTFOLIO") {
          this.PORTFOLIO = true;
        }
        if (val == "ROI") {
          this.ROI = true;
        }
        if (val == "ROI-Assessment-API") {
          this.ROI_Assessment_API = true;
        }
        if (val == "2x-Assessment-API") {
          this.twox_Assessment_API = true;
        }
      });
    }
  },
};
</script>

<style>
.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  height: 100vh;
  margin: 0 auto -142px;
}
.router-link-exact-active {
  color: #fe5000 !important;
}
.sidebar-inner {
  flex: 0 0 258px;
}
</style>
<style lang="scss" scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// Layout
.row {
  display: flex;
  .col {
    flex: 1;
    &:last-child {
      margin-left: 1em;
    }
  }
}
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 10px 1rem;
    background: #201747;
    font-weight: 400;
    cursor: pointer;
    /* Icon */
    &:hover {
      color: #40a9ff;
    }
    &::after {
      content: "\276F";
      width: 1em;
      // height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: #201747;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #201747;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.tab0 {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 10px 1rem;
    background: #201747;
    font-weight: 400;
    cursor: pointer;
    /* Icon */
    &:hover {
      color: #40a9ff;
    }
    &::after {
      content: "\276F";
      width: 1em;
      // height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: #201747;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #201747;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    color: #40a9ff;
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
    margin-left: auto;
    &:hover {
      color: #40a9ff;
      cursor: pointer;
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.container .third-item {
  margin-left: auto;
}
</style>
