<template>
  <transition name="fadeIn" mode="out-in">
    <div class="static ecosystem-landing">
      <div id="notLoaded" v-if="isSurveyLoading" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="container mx-auto mt-20 pt-10">
        <div class="PoweredbyTitle_logo border-b">
          <!-- <div class="PoweredbyContentWrepper">
              <img src="@/assets/images/2X-Global_logo_transparent.svg" alt="background-image"
                  style="height: 50px" />
          </div> -->
          <div class="container flex items-center">
            <div class="flex">
              <h1
                class="text-6xl tracking-tighter font-medium print:text-7xl"
                style="color: #201747; max-width: 845px"
              >
                <span style="font-family: Work Sans"> Finance</span>
              </h1>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end" class="mb-5">
            <div class="Poweredby_logoImg mt-2 flex items-center">
              <div class="powered-text flex">
                <span class="whitespace-nowrap">Powered by:</span>
              </div>
              <img
                class="-ml-6"
                src="@/assets/images/logo-navy.png"
                alt="background-image"
              />
            </div>
          </div>
        </div>

        <div class="mx-auto">
          <div class="mt-8">
            <div class="text-base text-[#201747] mb-4">
              Welcome to our Financial Module!<br />
              It is designed to help your company understand and improve its
              financial health. It covers everything from your assets,
              liabilities, and expenses to your revenue and overall
              profitability. Plus, it makes reporting to investors or the
              financial community a breeze.
            </div>
            <div class="text-base text-[#201747] mb-4">
              Here's how to get started:
              <ul class="list-decimal ml-6 font-medium text-black">
                <li class="text-base text-[#201747]">
                  <span class="font-bold">Download the Template:</span> Click on
                  "Download File" to get your hands on our structured Excel
                  template. This template is your key to submitting your
                  financial data accurately.
                </li>
                <li class="text-base text-[#201747]">
                  <span class="font-bold">Fill in the Tabs:</span> Inside the
                  Excel file, you'll find separate tabs for your income
                  statement (IS), balance sheet (BS), and cash flow (CF). You'll
                  need to fill out actuals, budget, revised forecast, and
                  audited data (if available). That means you'll have between
                  nine and twelve tabs to fill with data.
                </li>
                <li class="text-base text-[#201747]">
                  <span class="font-bold">Designate an Authorized User:</span>
                  Make sure a senior manager, company leader, or authorized
                  designee fills out the spreadsheet. They should be someone who
                  can authorize data sharing on behalf of your company.
                </li>
                <li class="text-base text-[#201747]">
                  <span class="font-bold">Review for Accuracy:</span> Before you
                  submit, double-check your financial data for accuracy. It's
                  crucial to ensure everything is correct before moving forward.
                </li>
                <li class="text-base text-[#201747]">
                  <span class="font-bold">Upload and Analyze:</span> Once your
                  Excel spreadsheet is filled out, come back to this page and
                  upload it. We'll take care of the rest! Your financial data
                  will be aggregated, analyzed, and displayed on your personal
                  IMM dashboard. The data will also be shared with your
                  investor.
                </li>
              </ul>
            </div>
            <button
              class="upload-documents-button rounded-lg"
              @click="saveFile()"
            >
              Download File
            </button>
            <div class="text-base text-[#201747] mt-3 -mb-2">
              Do you have questions or need assistance? Don't hesitate to reach
              out to our support team. We're here to help you every step of the
              way.
            </div>
          </div>
          <div
            class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-4"
          >
            <div
              class="congrats-box-doc p-5 mb-5 text-center mt-5"
              id="app"
              @dragover="dragover"
              @drop="drop"
            >
              <div class="flex w-full items-center justify-center text-center">
                <div>
                  <input
                    type="file"
                    multiple
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="handleFileChange($event)"
                    accept=".xls,.xlsx"
                    ref="file"
                  />
                  <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="center-button">
                      <span
                        class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                      >
                        Choose file
                      </span>
                    </div>
                    <p class="center-span-modal">Drag one or more files here</p>
                  </label>
                </div>
              </div>
            </div>
            <div class="container p-4 border rounded-lg my-5">
              <h2 class="text-gray-700 text-xl font-semibold truncate">
                Selected File
              </h2>
              <div class="flex flex-col justify-between h-28">
                <div>
                  <div
                    v-if="xlsFilelist.length > 0"
                    class="flex flex-col overflow-y-auto"
                  >
                    <div
                      v-for="(file, index) in xlsFilelist"
                      :key="index"
                      class="p-1 my-2 border-b flex justify-between items-center"
                    >
                      <h3 class="text-gray-700 text-sm font-medium truncate">
                        {{ file.name }}
                      </h3>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer mr-6"
                          @click="removeSelectedFile(index)"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-gray-500 text-sm font-medium py-1">
                    Select the Finance Excel file.
                  </div>
                </div>
                <div class="w-full flex justify-between items-center">
                  <div class="flex items-center gap-2">
                    <span>Currency for financial reporting is</span>
                    <select
                      id="currency"
                      name="currency"
                      class="imm-select"
                      v-model="clientCurrency"
                    >
                      <option
                        v-for="currency in currencyOptions"
                        :key="currency"
                        :value="currency"
                      >
                        {{ currency }}
                      </option>
                    </select>
                  </div>
                  <button
                    @click="showModalCurrency = true"
                    :disabled="isUploadButtonDisabled"
                    :class="{
                      'bg-gray-400 cursor-not-allowed text-white font-medium':
                        !isUploaded || isDocumentUploading,
                      'bg-[#3F7E44] text-white':
                        isUploaded && !isDocumentUploading,
                    }"
                    class="modal-send-button px-3 py-2 text-base gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                  >
                    <span class="flex items-center" v-if="isDocumentUploading"
                      >Uploading...</span
                    >
                    <span v-else>Upload</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-40 mb-10">
          <a
            href="/#/impact-compass/dashboard"
            class="upload-documents-button rounded-lg"
          >
            Back to Dashboard
          </a>
        </div>
      </div>
      <Modal v-if="showModalCurrency" @on-close="showModalCurrency = false">
        <div class="[ w-full h-52 ][ flex flex-col justify-between ]">
          <div class="flex flex-col justify-center gap-2">
            <h1>Uploading your Financial Data</h1>
            <p>
              By clicking "Upload" you confirm that your currency used for financial reporting is <span class="font-bold">{{currentClientCurrency}}</span>.<br/>
              If you want to change the currency, update it now
            </p>
            <div class="[ my-4 ][ flex flex-row justify-between ]">
              <select
                id="currency"
                name="currency"
                class="imm-select"
                v-model="clientCurrency"
                :disabled="isUpdatingPreferences"
              >
                <option
                  v-for="currency in currencyOptions"
                  :key="currency"
                  :value="currency"
                >
                  {{ currency }}
                </option>
              </select>

              <!-- Submit button -->
              <span v-if="isUpdatingPreferences">
                Updating your currency preference...
              </span>
              <button
                @click="handleOnUploadDocuments()"
                :disabled="isUploadButtonDisabled"
                :class="{
                  'bg-gray-400 cursor-not-allowed text-white font-medium':
                    isUploadButtonDisabled,
                  'bg-[#3F7E44] text-white': !isUploadButtonDisabled,
                }"
                class="modal-send-button px-3 py-2 text-base gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
              >
              <span class="flex items-center" v-if="isDocumentUploading"
              >Uploading...</span
              >
                <span v-else>Upload</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </transition>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";

const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import { saveAs } from "file-saver";
import ToolAPI from "@/api/ToolAPI";
import Modal from "@/components/basics/Modal.vue";
import UserAPI from "@/api/UserAPI";

export default {
  name: "IIMImpactSurvey",
  data() {
    return {
      surveyData: [],
      surveyJson: {},
      user: [],
      surveyId: "",
      answerAgain: false,
      userSurveyDataId: "",
      isComplete: false,
      entityChosen: "",
      isSurveyLoading: false,
      excelData: null,
      filelist: [],
      xlsFilelist: [],
      uploadedFileNames: [],
      isFileUpload: false,
      file: null,
      uploadProgress: 0,
      isDocumentUploaded: false,
      isDocumentUploading: false,
      isUploaded: false,
      isUpdatingPreferences: false,
      currentClientCurrency: "USD",
      clientCurrency: "USD",
      currencyOptions: ["USD"],
      showModalCurrency: false,
    };
  },
  components: {
    pageLoader,
    Modal,
  },
  async mounted() {
    // Breadcrumbs
    this.$store.commit('setBreadcrumbs', [
      {'name': 'IMM', 'url': '/impact-compass/dashboard'},
      {'name': 'Financials', 'url': '/impact-compass/finance-survey'},
    ]);

    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    // Get currency list
    await this.getCurrencyList();
    this.currentClientCurrency = this.user?.organization?.currencyPreference ?? "USD";
    this.clientCurrency = this.user?.organization?.currencyPreference ?? "USD";

    await this.fetchFile();
    const footerWrapper = document.getElementById("SITE_FOOTER_WRAPPER");
    if (footerWrapper) {
      footerWrapper.style.position = "fixed";
      footerWrapper.style.bottom = "0";
      footerWrapper.style.left = "0";
      footerWrapper.style.right = "0";
    }

    if (this.xlsFilelist.length) {
      this.isUploaded = true;
    } else {
      this.isUploaded = false;
    }
  },
  methods: {
    async getCurrencyList() {
      const toolAPI = new ToolAPI();
      try {
        const response = await toolAPI.getCurrencyList();
        const { data } = response;
        this.currencyOptions = data;
      } catch (error) {
        console.error("Error fetching currency list:", error);
      }
    },
    fetchFile() {
      return axios
        .get(env.apiUrl + "/imm/excel-template", {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.excelData = res.data;
        });
    },
    saveFile() {
      const organizationName = this.user.organization?.name?.split(" ").join("-") ?? "CompanyName";
      saveAs(this.excelData, `Compass_Financials_${organizationName}.xlsx`);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      console.log(event);
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.handleFileChange();
    },
    handleFileChange(event) {
      this.file = event?.currentTarget?.files?.[0];
      const files = this.$refs.file.files;
      const xlsFiles = [];

      for (let i = 0; i < files.length; i++) {
        const fileExtension = files[i].name.split(".").pop().toLowerCase();
        if (fileExtension === "xls" || fileExtension === "xlsx") {
          if (!this.uploadedFileNames.includes(files[i].name)) {
            this.uploadedFileNames.push(files[i].name);
            if (this.xlsFilelist.length > 0) {
              this.uploadedFileNames = this.uploadedFileNames.filter(
                (name) =>
                  !["xls", "xlsx"].includes(name.split(".").pop().toLowerCase())
              );
              this.xlsFilelist = [];
            }
            xlsFiles.push(files[i]);
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `Unsupported file type: ${files[i].name}. Please upload files with .xls, .xlsx extensions.`,
            showConfirmButton: true,
          });
        }
      }

      this.xlsFilelist.push(...xlsFiles);
      this.filelist = [...this.xlsFilelist];
      this.uploadedFileNames = this.filelist.map((item) => item.name);
      if (this.xlsFilelist.length) {
        this.isUploaded = true;
      } else {
        this.isUploaded = false;
      }
    },
    generateDocumentPresignURL() {
      if (this.uploadedFileNames.length > 0) {
        let body = {
          files: this.uploadedFileNames,
          urlType: "upload",
          module: "IMM_FINANCE_EXCEL",
        };

        axios
          .post(env.apiUrl + `/other/presignedUrl`, body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(async (res) => {
            const fileRes = res.data.data;
            if (fileRes.length) {
              await this.uploadFile(fileRes);
            }
          });
      }
    },
    async uploadFile(dataArray) {
      try {
        const xlsDataArray = dataArray.filter((data) =>
          this.xlsFilelist.some((file) => file.name === data.fileName)
        );

        const xlsUploadPromises = xlsDataArray.map(async (data) => {
          const preSignedURL = data.url;
          const xlsFilefile = this.xlsFilelist.find(
            (file) => file.name === data.fileName
          );

          if (xlsFilefile) {
            const uploadResponseXls = await axios.put(
              preSignedURL,
              xlsFilefile,
              {
                headers: {
                  "Content-Type": xlsFilefile.type,
                  "Access-Control-Allow-Origin": "*",
                },
                onUploadProgress: (progressEvent) => {
                  this.uploadProgress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                },
              }
            );

            if (uploadResponseXls.status === 200) {
              return { key: data.key, name: xlsFilefile.name };
            } else {
              throw new Error(
                `XLS File upload failed for ${xlsFilefile.name}: ${uploadResponseXls.statusText}`
              );
            }
          }
        });

        const xlsResults = await Promise.all(xlsUploadPromises);
        const combinedResults = [...xlsResults];
        this.addDocuments(combinedResults);
      } catch (error) {
        console.error("An error occurred during file upload:", error.message);
      }
    },
    addDocuments(data) {
      axios
        .post(env.apiUrl + `/imm/excel-upload`, data[0], {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.isDocumentUploaded = true;
          this.$swal.fire({
            icon: "success",
            text: `${
              res?.data?.message
                ? res?.data?.message
                : "Your financial data was successfully submitted!"
            }`,
            showConfirmButton: true,
            timer: 3000,
          });
          this.isDocumentUploading = false;
          setTimeout(() => {
            this.$router.push({ path: "/impact-compass/dashboard" });
          }, 4000);
          this.resetUploadMeta();
        })
        .catch((error) => {
          this.isDocumentUploading = false;
          let errorMessage = error.response?.data?.message ?? "An error occurred while uploading the file.";

          this.$swal.fire({
            icon: "error",
            text: errorMessage,
            showConfirmButton: true,
          });
          this.resetUploadMeta();
        });
    },
    resetUploadMeta() {
      this.filelist = [];
      this.xlsFilelist = [];
      this.uploadedFileNames = [];
      (this.file = null), (this.isUploaded = false);
    },
    removeSelectedFile(idx) {
      this.uploadedFileNames.splice(idx, 1);
      this.xlsFilelist.splice(idx, 1);
      this.filelist.splice(idx, 1);
      if (this.xlsFilelist.length) {
        this.isUploaded = true;
      } else {
        this.isUploaded = false;
      }
    },
    async fetchSurvey(tag) {
      try {
        this.isSurveyLoading = true;
        const {
          data: { data },
          status,
        } = await axios.get(`${env.apiUrl}/surveys/finance?tag=${tag}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        if (status == 200) {
          this.surveyJson = data.json;
          this.isSurveyLoading = false;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.isSurveyLoading = false;
      }
    },
    surveyComplete() {
      setTimeout(() => {
        this.$router.push({ path: "/impact-compass/landing-page/company" });
      }, "3000");
    },
    async updateUserPreferences() {
      if (this.user.currencyPreference !== this.clientCurrency) {
        this.isUpdatingPreferences = true;
        const userAPI = new UserAPI();
        await userAPI.updateTeamCurrencyPreference(this.clientCurrency);
        this.isUpdatingPreferences = false;
      }
    },
    async handleOnUploadDocuments() {
      this.isDocumentUploading = true;
      try {
        await this.updateUserPreferences();
        await this.generateDocumentPresignURL();
      } catch (error) {
        console.error(error);
      } finally {
        this.showModalCurrency = false;
      }
    },
  },
  computed: {
    isUploadButtonDisabled() {
      return (
        !this.isUploaded ||
        this.isDocumentUploading ||
        this.isUpdatingPreferences
      );
    },
  },
};
</script>
<style scoped lang="scss">
.ContentWrapper {
  margin-top: 10px;

  .SelectionBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 40px 20px;
    max-width: 1000px;

    .column-wrapper {
      flex-direction: column;
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 10px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  p {
    color: #717686;
  }
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #fff;
  color: #21191d !important;
  font-family: Work Sans !important;

  .congrats-box-doc {
    border: 1px solid #2177c1;
    border-radius: 16px;
    padding: 32px, 0px, 32px, 0px;
    background: #eaf6ff;
    border-style: dashed;
    height: 160px;
  }

  .center-button {
    position: relative;
    top: 27px;
  }

  .center-span-modal {
    position: relative;
    top: 35px;
    line-height: 20px;
    font-family: arial;
    color: #191b45;
  }

  .upload-documents-button {
    font-weight: bold;
    color: white;
    background: #2177c1;
    padding: 8px 16px 8px 16px;
  }

  .breadcrumbs-main-container {
    margin-top: 0;
    padding-top: 7em;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      width: 100%;
      display: flex;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .imm-select {
    height: 32px;
    width: 75px;
    padding-right: 25px;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border-radius: 8px;
    border: 1px solid #e1e3e9;
    gap: 8px;
    color: #2c2e35;
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  }
}
</style>
