<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <div style="height: 5rem"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div v-if="endofcreated" id="loaded" key="content">
        <main style="grid-template-columns: none !important">
          <div style="width: 100%; text-align: center">
            <section
              class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
            >
              <div id="jotform-box" class="relative" style="border: 1px solid #ccc">
                <Survey
                  :survey-data="surveyData"
                  :survey-json="surveyJson"
                  :user="user"
                  :survey-id="surveyId"
                  :answer-again="answerAgain"
                  :id="id"
                  :isComplete="isComplete"
                  :userType="userType"
                  :uuid="uuid"
                  @survyeEdited="survyeEdited"
                  @redirectToInvestorProfile="redirectToInvestorProfile"
                />
                <button
                  v-if="!isSetupPrefActive"
                  class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex justify-end items-center absolute bottom-[10px] right-[55px]"
                  style="font-size: 16px;"
                  @click="saveCurrentProgress"
                  :disabled="isCurrentButtonActive < 1"
                  :class="{
                    'opacity-50 cursor-not-allowed': isCurrentButtonActive < 1,
                  }"
                >
                  Save current progress
                </button>
              </div>
              <div class="bottom-section flex justify-between">
                <button
                  class="come-back"
                  @click="
                    $router.push({ path: '/impact-compass/imm-landing-page/investor' })
                  "
                >
                  Come back later
                </button>
                <button
                  class="set-up"
                  :class="{ 'not-active': !isSetupPrefActive }"
                  @click="$router.push({ path: '/impact-compass/setup-preferences' })"
                >
                  Set up Preferences
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import Survey from "@/components/surveys/survey.vue";
import { useUserStore } from "@/stores/user";
import apiService from "@/helpers/apiServices.js";

export default {
  name: "CreateInvestorProfile",
  data: function () {
    return {
      isSetupPrefActive: false,
      user: {},
      surveyData: null,
      surveyJson: {},
      loading: false,
      surveyId: "investor-profile",
      isComplete: false,
      answerAgain: false,
      id: null,
      userType: null,
      endofcreated: false,
      isCurrentButtonActive: "",
      userSurveyData: [],
      uuid: "",
    };
  },
  components: {
    Survey,
  },
  async mounted() {
    const userStore = useUserStore();
    this.user = userStore.user;
    if(this.user?.membership2xData) {
      this.$router.push({path: `/2X-Ecosystem/2XC-Membership/${this.user?.organization?._id}`})
    }
    this.uuid = Date.now();
    this.surveyId = "investor-profile";
    this.isSurveyLoading = true;
    await apiService.fetchUserSurveys("All").then(async (response) => {
      this.userSurveyData = await response.data.data;
    });
    this.isSurveyLoading = false;
    this.checkAlreadySurveyData();
    await this.fetchSurvey();
    window.addEventListener("message", (message) => {
      if (message.data.action) {
        if (
          message.data.action.includes("submission") &
          message.data.action.includes("completed")
        ) {
          this.isSetupPrefActive = true;
        }
      }
    });
    this.endofcreated = true;
  },
  methods: {
    checkAlreadySurveyData() {
      if (this.userSurveyData) {
        this.surveyData = this.userSurveyData.find(
          (survey) =>
            survey.surveyId === this.surveyId
        );
      }
      // if survey has been answered already                                                                                                                                                                                                                                                                                      sComplete as true
      if (this.surveyData) {
        let surveyDataObject = {};
        this.surveyData.surveyResponse.forEach((survey) => {
          surveyDataObject[survey.questionId] = survey.answer;
        });
        this.surveyData = surveyDataObject;
      }
    },
    survyeEdited(val) {
      this.isCurrentButtonActive = val;
    },
    redirectToInvestorProfile() {
      this.isSetupPrefActive = true;
    },
    async fetchSurvey() {
      try {
        const {
          data: { data },
          status,
        } = await axios.get(`${env.apiUrl}/surveys/${this.surveyId}?tag=null`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        if (status == 200) {
          this.surveyJson = data.json;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.loading = false;
      }
    },
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-section {
  margin-top: 28px;
  .come-back {
    width: 300px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #191b45;
    color: #191b45;

    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .set-up {
    width: 300px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    gap: 8px;
    background: #191b45;
    color: #ffffff;

    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .not-active {
    background: #e1e3e9;
    pointer-events: none;
  }
}
</style>
