var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.values)?_c('div',_vm._l((_vm.values),function(value,k){return _c('div',{key:k},[(value.questions)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"inner-table"},[_c('tr',{staticClass:"table-heading-row"},[_c('th'),_vm._m(0,true),(
              _vm.$route.path.includes('/admin') &&
              value?.questions?.some(
                (data) => data?.surveyQType != 'Calculated'
              )
            )?_c('th'):_vm._e()]),_vm._l((value.questions),function(data){return _c('tr',{key:data.title,staticClass:"table-content-row"},[_c('td',[_c('span',[_vm._v(_vm._s(data.title))])]),_c('td',{staticClass:"text-center"},[(data.value == 'Yes')?_c('img',{attrs:{"src":require("@/assets/images/rightsign-2x-challenge.svg")}}):_vm._e(),(data.value == 'No')?_c('img',{attrs:{"src":require("@/assets/images/Crosscircle-2x-challenge.svg")}}):_vm._e()]),(
              _vm.$route.path.includes('/admin') &&
              data?.surveyQType != 'Calculated'
            )?_c('td',[_c('div',{staticClass:"flex gap-2 transition-all items-center duration-300 ease-in-out m-auto"},[_c('div',{staticClass:"flex items-center gap-3 justify-end"},[_c('img',{staticClass:"h-6 w-6 cursor-pointer",attrs:{"src":require("@/assets/images/edit-dark.svg"),"alt":"edit-icon"},on:{"click":function($event){return _vm.openEditModal(data)}}}),_c('verification-dropdown',{attrs:{"value":data}}),_c('div',[_c('MLVerifierValue',{attrs:{"value":data,"teamId":_vm.$route.query?.teamId}})],1)],1)])]):_vm._e()])})],2),(value.textForChartInfo)?_c('span',{staticClass:"rounded-md p-2 text-md text-center max-w-[25rem]",domProps:{"innerHTML":_vm._s(_vm.createInteractiveText(value))},on:{"click":_vm.handleSpanClick}}):_vm._e()]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("2024")])])
}]

export { render, staticRenderFns }