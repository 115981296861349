<template>
  <div class="flex flex-col items-center w-full pt-[2rem]">
    <div class="flex justify-between w-[90%] mb-[1.5rem]">
      <div class="arial text-sm transition-all">
        <div
          class="flex flex-col flex-wrap w-full montserrat text-[1rem] justify-end"
          v-if="isEditable"
        >
          <div class="flex">
            <div class="flex items-center">
              <span class="mr-[.5rem]">Click</span>
              <div
                class="toogle transition-all ease-in-out rounded-[8px] h-[2rem] w-[2rem] toogle--active"
              >
                <span class="transition-all ease-in-out"></span>
                <span class="transition-all ease-in-out"></span>
              </div>
              <span class="m-[.5rem]">near the tool to remove it. Click </span>
              <div
                class="toogle transition-all ease-in-out rounded-[8px] h-[2rem] w-[2rem]"
              >
                <span class="transition-all ease-in-out"></span>
                <span class="transition-all ease-in-out"></span>
              </div>
              <span class="m-[.5rem]">to add it back.</span>
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center">
              <span class="mr-[.5rem]">Hold</span>
              <div
                class="w-[1.9375rem] bg-[#E1E3E9] h-[1.9375rem] flex items-center justify-center rounded-[0.5rem]"
              >
                <img
                  src="@/assets/images/dashboard/drag.svg"
                  alt=""
                  class="h-[1.2rem] w-[1rem]"
                />
              </div>
              <span class="m-[.5rem]"
                >button and drag to change group’s order.
              </span>
            </div>
          </div>
          <div class="flex">
            <div class="flex items-center">
              <span class="mr-[.5rem]">Click on </span>
              <div
                class="w-[1.9375rem] bg-[#2177C1] h-[1.9375rem] flex items-center justify-center rounded-[0.5rem]"
              >
                <img
                  src="@/assets/images/dashboard/edit.svg"
                  alt=""
                  class="h-[1.2rem] w-[1rem]"
                />
              </div>
              <span class="m-[.5rem]">to save your changes </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-end mb-[.1rem]">
        <div
          class="active:translate-y-[1px] cursor-pointer mx-[.5rem] w-[6.4375rem] h-[2.5rem] bg-[#2177C1] rounded-[8px] flex items-center justify-center text-white arial font-bold text-base text-center"
          v-if="!isEditable"
          @click="startTour"
          v-track-click="{ elementName: 'Take a tour' }"
        >
          Take a tour
        </div>
        <div
          class="active:translate-y-[1px] cursor-pointer mx-[.5rem] flex items-center justify-center w-[6.4375rem] h-[2.5rem] bg-[#2177C1] rounded-[8px] w-full"
          @click="isEditable = !isEditable"
        >
          <img
            class="w-[1.2rem] h-[1.2rem] mr-[.5rem]"
            src="@/assets/images/dashboard/edit.svg"
            alt=""
          />
          <span
            class="flex items-center justify-center text-white arial font-bold text-base text-center"
          >
            {{ isEditable ? "Done" : "Edit" }}</span
          >
        </div>
      </div>
    </div>
    <draggable
      :list="board"
      :disabled="!isEditable"
      class="list-group w-[90%]"
      ghost-class="ghost"
      @start="dragging = true"
      @end="
        dragging = false;
        saveBoard();
      "
    >
      <div
        class="flex flex-col"
        v-for="(item, index) in board"
        :key="item.name + item.code"
      >
        <div
          class="flex gap-2 mb-[.2rem]"
          v-if="isEditable ? canTitleEdit(index) : canTitle(index)"
        >
          <div
            class="montserrat font-bold text-4xl text-black"
            :class="`${index > 0 ? '' : ''}`"
          >
            {{ item.parent }}
          </div>
          <div class="flex items-center" v-if="!isEditable">
            <img
              v-for="header in item.icons"
              :src="getImgUrl(header)"
              alt=""
              :key="header"
              class="mx-[.5rem] w-[5rem] h-[3rem]"
            />
          </div>
        </div>
        <div class="flex" v-if="isEditable || (!isEditable && item.isActive)">
          <div
            class="w-[1.4375rem] bg-[#E1E3E9] min-h-[22rem] my-[1rem] moving flex items-center justify-center"
            v-if="isEditable"
          >
            <img src="@/assets/images/dashboard/drag.svg" alt="" />
          </div>
          <div
            class="flex group my-[1rem] min-h-[22rem] rounded-r-[16px] relative pb-[1rem]"
            :style="
              (item.parent == '2X Criteria' &&
                item.actions.filter((data) => data.isActive).length > 4) ||
              item.parent == 'IC'
                ? 'width: 85rem;'
                : item.parent === 'GGSF' ||
                  item.parent === 'GESI Contextual Analysis' ||
                  (item.parent == '2X Criteria' &&
                    item.actions.filter((data) => data.isActive).length < 5)
                ? 'width: 65rem;'
                : ''
            "
            :class="`${isEditable ? 'bg-[#F4F6F9]' : 'bg-transparent'}`"
          >
            <div
              class="box flex flex-col mr-[2.5rem]"
              :class="`${isEditable ? '' : 'justify-between'}`"
              :id="item.code"
            >
              <div
                class="transition-all montserrat font-bold text-2xl text-black"
                :class="{
                  'mb-[1.3rem]': isEditable,
                  'text-[#717686]': isEditable && !item.isActive,
                }"
              >
                {{ item.title }}
              </div>
              <div class="arial text-base">{{ item.description }}</div>
              <div class="flex flex-col items-center" v-if="!isEditable">
                <button
                  type="button"
                  class="transition-all w-[24.625rem] min-h-[3rem] rounded-[8px] text-white bg-[#191B45] arial font-bold font-base active:translate-y-[1px] my-[.4rem]"
                  v-if="!item.isUpgrade && item.isAccess"
                  @click="$router.push(item.route)"
                  :ref="`button-${item.code}`"
                  v-track-click="{ elementName: item.title }"
                >
                  {{ item.button }}
                </button>

                <button
                  type="button"
                  class="transition-all w-[24.625rem] min-h-[3rem] rounded-[8px] text-white bg-[#191B45] arial font-bold font-base active:translate-y-[1px] flex items-center justify-center my-[.4rem]"
                  v-if="item.isUpgrade"
                  @click="handleUpgrade(item.upgrade)"
                  v-track-click="{ elementName: 'Upgrade Plan to Unlock' }"
                >
                  <img
                    src="@/assets/images/dashboard/lock.svg"
                    alt=""
                    class="mr-[.5rem]"
                  />
                  <span>Upgrade Plan to Unlock</span>
                </button>

                <button
                  type="button"
                  class="transition-all w-[24.625rem] min-h-[3rem] rounded-[8px] text-white bg-[#2177C1] arial font-bold font-base active:translate-y-[1px] my-[.4rem]"
                  v-if="item.isDemo && item.isUpgrade"
                  @click="$router.push(item.demo)"
                  v-track-click="{ elementName: 'Try Demo version' }"
                >
                  Try Demo version
                </button>
              </div>

              <div
                class="flex text-[#2177C1] arial font-bold"
                v-if="!isEditable"
              >
                <span
                  class="flex items-center mr-[1rem] cursor-pointer text-base"
                  v-if="item.api && item.title != '2X Criteria Thresholds'"
                  @click="$router.push(item.api)"
                  v-track-click="{ elementName: 'API Integration' }"
                  >&#60; > Api integration
                </span>
                <div
                  class="flex items-center cursor-pointer"
                  v-if="item.methodology"
                  @click="
                    item.methodology === '/2X-Ecosystem/Thresholds'
                      ? openBanchMarkBox()
                      : $router.push(item.methodology)
                  "
                  v-track-click="{ elementName: 'Methodology' }"
                >
                  <img
                    src="@/assets/images/dashboard/info.svg"
                    class="w-[1.25rem] h-[1.25rem] mr-[0.125rem]"
                  />
                  <span class="text-base mt-[1%]">Methodology</span>
                </div>
              </div>
            </div>
            <div class="flex flex-start flex-wrap grow basis-0 shrink-0">
              <div
                class="transition-all ease-in-out flex flex-col w-[15.50rem] h-[8.75rem] rounded-[16px] p-[1.25rem] border-[1px] mx-[0.75rem] cursor-pointer"
                v-for="action in item.actions"
                :class="`${isEditable ? '' : action.border} ${
                  isEditable ? 'mt-[1.75rem]' : 'mb-[.95rem]'
                } ${
                  isEditable ? '' : 'hover:border-[1.5px] hover:scale-[1.05]'
                } ${isEditable && action.isActive ? 'bg-white' : ''} ${
                  !isEditable && !action.isActive ? 'hidden' : ''
                } ${
                  action.code === '2xmm' &&
                  !(
                    user.sophisticatedInvestor &&
                    ($store.state.isDomainValid ||
                    isPremiumSubscriber ||
                    user?.access2x ||
                    isTwoXGlobalMember)
                  )
                    ? 'tooltip'
                    : ''
                }`"
                :key="`${item.code}-${action.code}`"
                :id="`${item.code}-${action.code}`"
                @click="
                  isEditable
                    ? ''
                    : action.isAccess
                    ? navigateToRoute(action.route)
                    : popUpsHandler(action.access)
                "
              >
                <div class="flex flex-row justify-between">
                  <div
                    class="w-[2rem] h-[2rem] flex items-center justify-center rounded-[32px] mb-[.8rem]"
                    :style="{ backgroundColor: action.shade }"
                    v-if="!isEditable"
                  >
                    <img
                      v-if="action.title === 'Take GGSF Gender Assessment'"
                      src="../../assets/images/roi/GGSF-Logo.png"
                      alt="icon"
                      :style="{ width: '100px', height: '20px' }"
                      class="ggfs-img"
                    />
                    <img
                      v-else
                      :src="getImgUrl(action.icon)"
                      alt="icon"
                      class="w-[1.1rem] h-[1.1rem]"
                    />
                  </div>
                  <div
                    v-if="
                      action.code === 'investor-profile' &&
                      showCompleteProfileTooltip
                    "
                    class="tooltip"
                  >
                    <img
                      src="@/assets/images/dashboard/info.svg"
                      class="w-[1.25rem] h-[1.25rem] mr-[0.125rem]"
                    />

                    <span class="completeInvestorProfileText">
                      <span class="header-style"
                        >Complete Investor Profile <br
                      /></span>
                      <span class="text-style">
                        Ensure your investor profile is finished to set up your
                        Portfolio
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  v-track-click="{ elementName: action.title }"
                  class="arial text-base"
                  :class="` ${isEditable ? 'font-bold' : ''} ${
                    !isEditable
                      ? 'text-[#2C2E35]'
                      : !action.isActive
                      ? 'text-[#717686]'
                      : 'text-[#2C2E35]'
                  }`"
                >
                  {{ action.title }}
                </div>
                <div
                  class="flex items-center mt-[0.4rem]"
                  v-if="!isEditable && action?.extra"
                  @click="$router.push(action.extraRoute)"
                  v-track-click="{ elementName: action.extra }"
                >
                  <span
                    class="text-[#2177C1] font-bold text-[0.875rem] mr-[0.4375rem]"
                  >
                    {{ action.extra }}
                  </span>
                  <img
                    class="w-[0.9rem] h-[0.5rem]"
                    src="@/assets/images/dashboard/arrow.svg"
                    alt="arrow"
                  />
                </div>
                <div
                  class="text-xs max-w-[89%] mt-[.4rem]"
                  v-if="isEditable"
                  v-track-click="{ elementName: action.description }"
                >
                  {{ action.description }}
                </div>
                <div
                  class="absolute bottom-[9%] right-[8%]"
                  v-track-click="{ elementName: 'Toggle' }"
                >
                  <div
                    class="toogle transition-all ease-in-out rounded-[8px] h-[2rem] w-[2rem]"
                    :class="`${action.isActive ? 'toogle--active' : ''}`"
                    @click="
                      action.isActive = !action.isActive;
                      configureTour();
                      saveBoard();
                    "
                    v-if="isEditable"
                  >
                    <span class="transition-all ease-in-out"></span>
                    <span class="transition-all ease-in-out"></span>
                  </div>
                </div>
                <div
                  v-if="
                    action.code === '2xmm' &&
                    !(
                      user.sophisticatedInvestor &&
                      ($store.state.isDomainValid || 
                      isPremiumSubscriber || 
                      isTwoXGlobalMember)
                    )
                  "
                  class="twox-matchmaking-hover-text cursor-default"
                  @click.stop=""
                >
                  <span
                    >You do not have access to this premium 2X Matchmaking
                    platform feature. To access this you must either be a 2X
                    Global Member and sophisticated investor, or you must be an
                    Equilo Premium subscriber and sophisticated investor,
                    accelerator, or network. Interested in this access?
                  </span>
                  <button
                    v-if="!is2xMatchAccessRequestSent"
                    class="certification-button px-4 py-2 rounded-md my-3"
                    @click.stop="sendRequestForMatchmakingAccess()"
                  >
                    Give me 2X Matchmaking Access
                  </button>
                  <button
                    v-else
                    class="certification-button px-3 py-2 rounded-md my-3 cursor-default"
                  >
                    Your Request is being processed
                  </button>
                </div>
              </div>
            </div>
            <div
              class="absolute bottom-[3%] right-[1%]"
              v-track-click="{ elementName: 'Toggle' }"
            >
              <div
                class="toogle transition-all ease-in-out rounded-[8px] h-[2rem] w-[2rem]"
                :class="`${item.isActive ? 'toogle--active' : ''}`"
                @click="
                  item.isActive = !item.isActive;
                  configureTour();
                  saveBoard();
                "
                v-if="isEditable"
              >
                <span class="transition-all ease-in-out"></span>
                <span class="transition-all ease-in-out"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <div key="1" class="get_certified z-[10000]" v-if="banchmarkIsOpen">
      <div class="flex">
        <div class="certify-content flex flex-col">
          <div class="flex justify-end">
            <div
              class="bg-[#fe5000] w-[1.4rem] h-[1.4rem] rounded-full text-white flex items-center justify-center text-[1.6rem] cursor-pointer"
              @click="banchmarkIsOpen = false"
            >
              &times;
            </div>
          </div>
          <div class="certify-title flex items-center justify-between">
            <h5
              class="text-2xl tracking-tighter font-medium print:text-7xl font-bold text-white"
            >
              2X Criteria Threshold Benchmarking Methodology
            </h5>
          </div>
          <div class="certify-info mt-4 font-medium text-sm text-white">
            <div>
              <span>
                The 2X Criteria Threshold minimum thresholds are determined by
                the 2X Criteria methodology set by 2X Global.
                The detailed methodology can be accessed
                <a
                  href="https://storage.equilo.io/api-assets/2X_Criteria_Thresholds_Methodology.pdf"
                  style="color: blue"
                  class="cursor-pointer"
                  target="_blank"
                  download="2X_Criteria_Thresholds_Methodology_27.06.24.pdf"
                >
                  here
                </a>
                .
              </span>
            </div>
            <div class="mt-2 mb-5">
              Do you want access to the full curated data set curated by Equilo?
              The data set is available via XLSX download or API.
              Simply click on <b>"Access the full data set"</b> below.
            </div>
          </div>
          <div class="certify-btn flex mt-4 mx-auto justify-evenly">
            <a
              class="text-white text-sm font-bold mr-2"
              style="border-radius: 50px; background-color: #fe5000"
              href="https://storage.equilo.io/api-assets/benchmark-threshold-2x.pdf"
              target="_blank"
              download
            >
              Download Data Sources
            </a>
            <button
              class="text-white text-sm font-bold mr-2"
              style="
                border-radius: 50px;
                background-color: #2bd4c4;
                height: 40px;
                padding: 0 50px;
                cursor: pointer;
              "
              @click="accessFullDataSet()"
            >
              Access the full data set
            </button>
            <button
              class="text-white text-sm font-bold"
              style="
                border-radius: 50px;
                background-color: #fe5000;
                color: white;
                height: 40px;
                padding: 0 50px;
                cursor: pointer;
              "
              @click="banchmarkIsOpen = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-if="isOpenTwoXThresholdPaywall"
      @on-close="isOpenTwoXThresholdPaywall = false"
    >
      <TwoXThresholdPaywall @on-cancel="isOpenTwoXThresholdPaywall = false" />
    </Modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import Users from "@/helpers/users";

import "driver.js/dist/driver.css";

import { canAccess } from "@/security/acl.js";

import { useUserStore } from "@/stores/user";
import apiService from "@/helpers/apiServices.js";

import { SUBSCRIPTION_TYPE } from "@/security/subscriptionTypes";

import { checkWhiteLabel } from "@/components/imm-components/white-label";

import Modal from "@/components/basics/Modal.vue";
import TwoXThresholdPaywall from "@/components/2XThresholdPaywall/index.vue";

export default {
  name: "menu-boarding",
  props: {
    user: Object,
    checkportfolioPermissionpopup: Function,
    checkportfolioPermissionpopupROI: Function,
    isTour: Boolean,
    tourSteps: Array,
    board: Array,
    configureTour: Function,
    createDrive: Function,
    driver: Object,
    handleRequestPermision: Function,
    isOpen: Boolean,
    setPilotRequestModal: Function,
    subscriptionDetails: [Object, Array],
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data: function () {
    return {
      enabled: true,
      dragging: false,
      isEditable: false,
      banchmarkIsOpen: false,
      lessThanIcon: "<=",
      gretarThanIcon: ">",
      currentPlanSubsricption: "",
      is2xMatchAccessRequestSent: false,
      status2xCertification: "",
      isOpenTwoXThresholdPaywall: false,
    };
  },
  components: {
    draggable,
    Modal,
    TwoXThresholdPaywall,
  },
  methods: {
    async fetch2xCertRequest() {
      await apiService
      .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
      .then(async (response) => {
        this.status2xCertification = response?.data?.data?.status;
      });
    },
    openBanchMarkBox() {
      this.banchmarkIsOpen = !this.banchmarkIsOpen;
    },
    accessFullDataSet() {
      this.banchmarkIsOpen = false;
      this.isOpenTwoXThresholdPaywall = true;

      /*
      // TODO: Check if this is needed, otherwise remove it
      return axios
        .post(
          env.apiUrl + "/other/customer-engagement",
          {
            dataRequested2x: true,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            text: err.response.data.error.message,
            showConfirmButton: true,
          });
        });
       */
    },
    saveBoard() {
      const simple = this.board.map((service) => {
        const actionSimple = service.actions.map((action) => {
          return { code: action.code, isActive: action.isActive };
        });
        return {
          code: service.code,
          isActive: service.isActive,
          actions: actionSimple,
        };
      });
      Users.updateDashboard(simple)
        .then()
        .catch((err) => {
          console.log(`Error saving board ${err}`);
        });
    },
    canTitle(index) {
      const currentCell = this.board[index];
      if (index === 0) return currentCell.isActive;

      const previousCell = this.board[index - 1];

      if (previousCell.isActive) {
        return (
          previousCell.parentCode !== currentCell.parentCode &&
          currentCell.isActive
        );
      }
      if (!previousCell.isActive) return currentCell.isActive;
      return false;
    },
    canTitleEdit(index) {
      if (index <= 0) {
        return true;
      }
      const currentParentCode = this.board[index].parentCode;
      const previousParentCode = this.board[index - 1].parentCode;

      return currentParentCode !== previousParentCode;
    },
    getImgUrl(icon) {
      if (icon) {
        try {
          return require(`../../assets/images/dashboard/${icon}.svg`);
        } catch (e) {
          console.error(`Failed to load icon: ${icon}`, e);
        }
      }
      // return a simple SVG as a data URI
      return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="100%" height="100%" fill="%23cccccc"/><text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" font-family="Arial" font-size="12" fill="%23000">Icon</text></svg>';
    },
    isActive() {
      return true;
    },
    handleUpgrade() {
      this.$router.push("/upgrade");
    },
    popUpsHandler(type) {
      if (type === "ROI") {
        this.checkportfolioPermissionpopupROI();
      }
      if (type === "IP") {
        this.checkportfolioPermissionpopup();
      }
      if (type === "DE") {
        this.handleUpgrade();
      }
    },
    navigateToRoute(route) {
      const certificationRoute = "/2X-Ecosystem/2X-Certification";
      const selfAssessmentRoute = "/2X-Challenge/2X-Challenge-Self-Assessment";
      // const towxEvaluation =
      //   "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment";
      const indicativeResultsRoute =
        "2X-Ecosystem/2X-Certification/IndicativeResults";
      const portfolioRoute = "/2X-Ecosystem/2X-Certification/2X-Portfolio";
      const upgradeRoute = "/upgrade";

      if (route === selfAssessmentRoute) {
        const destination = [
          "Full Survey Submitted",
          "Verification in Process",
        ].includes(this.status2xCertification)
          ? indicativeResultsRoute
          : "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment";
        this.$router.push(destination);
      } else if (route === certificationRoute) {
        // const query = { twoxRepeatAsses: "true" };
        // if (
        //   this.$store.state.certPageAccess.isFullSurveyFill &&
        //   !this.$store.state.certPageAccess.isUploadDocPage
        // ) {
        //   this.$router.push({ path: towxEvaluation, query });
        // } else {
          this.$router.push(certificationRoute);
        // }
      } else if (
        route === portfolioRoute &&
        this.userStore.subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM
      ) {
        // User tries to access Portfolio page without having a premium subscription
        this.$router.push(upgradeRoute);
      } else {
        this.$router.push(route);
      }
    },
    configBoard() {
      const userStore = this.userStore;

      for (let item of this.board) {
        if (item.code === "twoXC") {
          if (
            userStore?.user?.organization?.productApiAccess?.includes(
              "2x-Assessment-API"
            )
          ) {
            item.api = "/settings/twox_assessment_api_settings";
          }

          for (let child of item.actions) {
            if (child.code === "2xvp") {
              if (!userStore?.user?.isVerifier) {
                child.isAccess = false;
                child.isActive = false;
              }

              for (let child of item.actions) {
                if (child.code == "investor-profile") {
                  if (
                    userStore.user?.investor &&
                    userStore.user?.membership2xData
                  ) {
                    child.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
                  } else {
                    child.route = `/2X-Ecosystem/create2Xprofile`;
                  }
                }
              }
            }
            if (child.code === "2xgp") {
              if (
                userStore?.user?.hasOwnProperty("twoXGlobal") &&
                userStore?.user?.twoXGlobal.length === 0
              ) {
                child.isAccess = false;
                child.isActive = false;
              }
            }
            if (child.code === "2xmm") {
              if (
                !(
                  userStore?.user?.sophisticatedInvestor &&
                  (this.$store.state.isDomainValid || 
                  this.isPremiumSubscriber || 
                  this.isTwoXGlobalMember)
                )
              ) {
                child.isAccess = false;
              }
            }
            if (
              child.code === "2xPortfolio" &&
              userStore.subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM
            ) {
              child.isActive = false;
              child.isAccess = false;
            }
            if (child.code === 'iet2xp' && userStore.subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
              child.isAccess = false;
              child.isActive = false;
            }
            // Remove Take 2X Assessment button from home page
            if (
              child.code === "vm2ar" ||
              child.code === "t2as" ||
              child.code === "ie" ||
              child.code === "vmfsa"
            ) {
              child.isActive = false;
            }
          }
        }
        if (item.code === "GR") {
          if (userStore?.user?.innpact) {
            item.route = "/ggsf";
            item.isActive = false;
          }
          if (
            userStore?.user?.organization?.productApiAccess?.includes(
              "ROI-Assessment-API"
            )
          ) {
            item.api = "/settings/roi_assessment_api_settings";
          }
          for (let child of item.actions) {
            if (child.code == "investor-profile") {
              if (
                userStore.user?.investor &&
                userStore.user?.membership2xData
              ) {
                child.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
              } else {
                child.route = `/2X-Ecosystem/create2Xprofile`;
              }
            }
            if (child.code === "vmgrr") {
              if (userStore?.user?.innpact) {
                child.route = "/ggsf";
              }
            }
          }
        }

        if (item.code === "IP") {
          if (!userStore.user?.investor) {
            item.isActive = false;
          }
          if (userStore.user?.membership2xData && userStore.user?.investor) {
            item.button = "View investor profile";
            item.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
          }
          for (let child of item.actions) {
            if (child.code === "smgroi") {
              const roiRoute = "/roi/roiPortfolio";
              const orgAccess = canAccess(roiRoute, userStore.subscriptionType);
              if (userStore?.user?.accessROIportfolio && orgAccess) {
                child.route = roiRoute;
              }
              if (!userStore?.user?.accessROIportfolio && !orgAccess) {
                child.route = "/upgrade";
              }
              if (!userStore?.user?.accessROIportfolio && orgAccess) {
                child.route = "";
                child.isAccess = false;
                child.access = "ROI";
              }
            }

            if (child.code === "smy2p") {
              child.isActive = false;
              // It is causing confusion along pilot testers when they see 2X Assess.
              // Please hide it from Home Screen.
              // if (
              //   this.user?.accessPortfolio &&
              //   this.user?.organization?.portfolioAccess
              // ) {
              //   child.route = "/2X-Ecosystem/my-portfolio";
              // }
              // if (
              //   !this.user?.accessPortfolio &&
              //   !this.user?.organization?.portfolioAccess
              // ) {
              //   child.route = "/upgrade";
              // }
              // if (
              //   !this.user?.accessPortfolio &&
              //   this.user?.organization?.portfolioAccess
              // ) {
              //   child.route = "";
              //   child.isAccess = false;
              //   child.access = "IP";
              // }
            }

            if (child.code === "sad") {
              const sadRoute = "/submit-deal";
              // To submit a deal
              if (
                userStore?.user?.membership2xData &&
                (this.$store.state.isDomainValid ||
                userStore?.user?.access2x)
              ) {
                child.route = sadRoute;
              } else {
                child.route = "";
                child.isAccess = false;
                child.isActive = false;
                child.access = "IP";
              }
            }

            if (child.code === "ggsfvr") {
              const ggsfRoute = "/ggsf";
              if (userStore?.user?.innpact) {
                child.route = ggsfRoute;
              } else {
                child.route = "";
                child.isActive = false;
                child.access = "IP";
              }
            }
          }
        }

        if (item.code === "EQ") {
          if (
            userStore?.user?.organization?.productApiAccess?.includes("GESI")
          ) {
            item.api = "/settings/gesi_api_settings";
          }
          if (this.isOpen) {
            item.isUpgrade = true;
          }
          item?.actions.forEach((data) => {
            if (data.code == "de") {
              if (
                this?.currentPlanSubsricption?.subscription == "Equilo Pro" ||
                this?.currentPlanSubsricption?.subscription ==
                  "Equilo Premium" ||
                this?.currentPlanSubsricption?.subscription ==
                  "Equilo Deep Enterprise"
              ) {
                data.isUpgrade = false;
                data.isAccess = true;
                data.access = "DE";
                data.isActive = true;
              } else {
                data.isUpgrade = true;
                data.isAccess = false;
                data.access = "DE";
                data.isActive = true;
              }
            }
          });
        }
        if (item.code === "GBVA") {
          if (
            userStore?.user?.organization?.productApiAccess?.includes("GBV")
          ) {
            item.api = "/settings/gbv_api_settings";
          }
          if (this.isOpen) {
            item.isUpgrade = true;
            item?.actions.forEach((data) => {
              data.isUpgrade = true;
            });
          }
        }

        if (item.code === "GIRL") {
          for (let child of item.actions) {
            if (child.code === "JOTASD") {
              if (userStore?.user?.memberTA) {
                child.title = "View my TA profile";
                child.route = `/2X-Ecosystem/2XC-Membership/${userStore?.user?.memberTA}`;
              }
            }
          }
        }
        if (item.code === "GGSF") {
          if (!userStore?.user?.innpact) {
            item.isActive = false;
          }
          for (let child of item.actions) {
            if (child.code == "investor-profile") {
              if (
                userStore.user?.investor &&
                userStore.user?.membership2xData
              ) {
                child.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
              } else {
                child.route = `/2X-Ecosystem/create2Xprofile`;
              }
            }
          }
        }
        // IMM is now Impact Compass, so we need to update the code to IC
        if (item.code === "IC") {
          // Previously we were checking for IMM flag to enable this feature, but imm is 'visible' for everyone, but accessed only via 'imm' flag
          /* if (!userStore?.user?.featureFlags?.includes("imm")) {
            item.isActive = false;
          } */
          if (userStore?.user?.featureFlags?.includes("imm")) {
            item.parent = checkWhiteLabel(userStore.user.featureFlags).title;
            if (checkWhiteLabel(userStore.user.featureFlags).logo) {
              item.icons = [checkWhiteLabel(userStore.user.featureFlags).logo];
            }
            item.title = checkWhiteLabel(userStore.user.featureFlags).title;
            item.button = userStore.user.investor
              ? `Access ${
                  checkWhiteLabel(userStore.user.featureFlags).title
                } portfolio`
              : `Access ${
                  checkWhiteLabel(userStore.user.featureFlags).title
                } dashboard`;
            item.route = userStore.user.investor
              ? userStore?.user?.immInvestorPreference?.length
                ? "/impact-compass/dashboard"
                : "/impact-compass/landing-page"
              : userStore.user.hasCompanyProfile
              ? "/impact-compass/dashboard"
              : "/impact-compass/landing-page";
          }
          // Previously we were checking if the user is open to upgrade to enable this feature, but imm upgrade flow is different
          /* if (this.isOpen) {
            item.isUpgrade = true;
          } */
          if (!userStore?.user?.featureFlags?.includes("imm")) {
            item.isUpgrade = true;
          }

          // For SWEEF users
          // This might be over-engineered as we could skip the sweef data from the list and just replace it here
          if (userStore.user?.featureFlags?.includes("imm")) {
            if (userStore?.user?.featureFlags?.includes("imm-sweef")) {
              for (let child of item.actions) {
                switch (child.code) {
                  case "investor-profile":
                    if (
                      userStore.user?.investor &&
                      userStore.user?.membership2xData
                    ) {
                      child.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
                    } else if (userStore.user?.investor) {
                      child.route = `/2X-Ecosystem/create2Xprofile`;
                    } else {
                      child.isActive = false;
                    }
                    break;
                  case "sweef-investor-dashboard":
                    if (userStore?.user?.investor) {
                      child.isActive = true;
                      child.route = userStore?.user?.immInvestorPreference
                        ?.length
                        ? "/impact-compass/dashboard"
                        : "/impact-compass/landing-page";
                    } else {
                      child.isActive = false;
                    }
                    break;
                  case "sweef-investee-dashboard":
                    if (!userStore?.user?.investor) {
                      child.isActive = true;
                      child.route = "/impact-compass/landing-page";
                    } else {
                      child.isActive = false;
                    }
                    break;
                  case "ic-investor-dashboard":
                    child.isActive = false;
                    break;
                  case "ic-investee-dashboard":
                    child.isActive = false;
                    break;
                }
              }
            } else {
              for (let child of item.actions) {
                switch (child.code) {
                  case "sweef-investor-dashboard":
                    child.isActive = false;
                    break;
                  case "sweef-investee-dashboard":
                    child.isActive = false;
                    break;
                  case "investor-profile":
                    if (
                      userStore.user?.investor &&
                      userStore.user?.membership2xData
                    ) {
                      child.route = `2X-Ecosystem/2XC-Membership/${userStore?.user?.organization?._id}`;
                    } else if (userStore.user?.investor) {
                      child.route = `/2X-Ecosystem/create2Xprofile`;
                    } else {
                      child.isActive = false;
                    }
                    break;
                  case "ic-investor-dashboard":
                    if (userStore?.user?.investor) {
                      child.isActive = true;
                      child.route = userStore?.user?.immInvestorPreference
                        ?.length
                        ? "/impact-compass/dashboard"
                        : "/impact-compass/landing-page";
                    } else {
                      child.isActive = false;
                    }
                    break;
                  case "ic-investee-dashboard":
                    if (!userStore?.user?.investor) {
                      child.isActive = true;
                      child.route = "/impact-compass/landing-page";
                    } else {
                      child.isActive = false;
                    }
                    break;
                }
              }
            }
            // just disable all if imm access is false
          } else {
            for (let child of item.actions) {
              child.isActive = false;
            }
          }
        }
      }
    },
    startTour() {
      this.driver.drive();
    },
    sendRequestForMatchmakingAccess() {
      apiService
        .sendRequestForMatchmakingAccess()
        .then(() => {
          localStorage.setItem("2x-match-access-request-sent", true);
          this.is2xMatchAccessRequestSent = true;
          this.$swal.fire({
            icon: "success",
            text: "Your request has been sent!",
            showConfirmButton: true,
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            text: err.response.data.error || "Something went wrong!",
            showConfirmButton: true,
          });
        });
    },
  },
  computed: {
    showCompleteProfileTooltip() {
      return (
        this.userStore?.user?.membership2xData &&
        Object?.keys(this.userStore?.user?.membership2xData)?.length == 0
      );
    },
    isPremiumSubscriber() {
      return this.userStore?.subscriptionType === SUBSCRIPTION_TYPE.PREMIUM;
    },
    isTwoXGlobalMember() {
      return this.$store.state.user.hasOwnProperty("twoXGlobal") && this.$store.state.user.twoXGlobal.length !== 0;
    }
  },
  async mounted() {
    await this.$store.dispatch("fetchValidDomain");
    this.currentPlanSubsricption = this.subscriptionDetails;

    this.is2xMatchAccessRequestSent =
      localStorage.getItem("2x-match-access-request-sent") === "true"
        ? true
        : false;

    this.$nextTick(function () {
      this.configBoard();
      this.createDrive();
      if (this.isTour) this.driver.drive();
      if (this.$route.query.investorProfile) {
        if (this.$refs["button-IP"] && this.$refs["button-IP"][0]) {
          this.$refs["button-IP"][0].click();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.get_certified {
  background-color: #afafaf;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 30px;
  top: 20%;
  left: 150px;
  position: fixed;
  right: 0px;
  margin: auto;
  width: 50%;
  // height: 750px;
  // overflow-y: scroll;
  .certify-img {
    img {
      width: 51px;
      flex: 0 1 51px;
      max-width: 51px;
      margin-right: 20px;
    }
  }

  .certify-btn {
    a {
      background-color: #fe5000;
      border-radius: 20px;
      padding: 10px 50px;
      cursor: pointer;
    }
    a:hover {
      color: white;
    }
    button {
      background-color: #fe5000;
      border-radius: 20px;
      height: 40px;
      padding: 0 50px;
      cursor: pointer;
    }
  }
}
.montserrat {
  font-family: "Montserrat";
}

.arial {
  font-family: "Arial";
}

.group {
  width: 85rem;
}

.box {
  min-height: 22rem;
  flex: 0 0 34%;
  border-radius: 1.5rem;
  padding: 1.75rem;
  background-color: #f4f6f9;
}

.blue {
  border-color: #e1e3e9;

  &:hover {
    border-color: #eaf6ff;
    border-width: 2.5px;
  }
}

.red {
  border-color: #e1e3e9;

  &:hover {
    border-color: #ffece8;
    border-width: 2.5px;
  }
}

.yellow {
  border-color: #e1e3e9;

  &:hover {
    border-color: #fff8e0;
    border-width: 2.5px;
  }
}

.green {
  border-color: #e1e3e9;

  &:hover {
    border-color: #e5fafa;
    border-width: 2.5px;
  }
}

.moving {
  border-radius: 24px 0px 0px 24px;
  transform: translateX(10%);
}

.toogle {
  color: #ecf0f1;
  background: #191b45;
  border-radius: 0.5rem;
  border-color: #191b45;
  border-width: 1px;
  font-size: 1rem;
  position: relative;
  cursor: pointer;

  &--active {
    background: white;
  }
}

.toogle span {
  position: absolute;
  transition: 300ms;
  background: #191b45;
}

/* Create the "+" shape by positioning the spans absolutely */
.toogle span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
  background: white;
}

.toogle span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
  background: white;
}

/* Morph the shape when the .toogle is hovered over */
.toogle--active span:first-child {
  transform: rotate(90deg);
  background: #191b45;
}

.toogle--active span:last-child {
  left: 50%;
  right: 50%;
  background: #191b45;
}
.ggfs-img {
  min-width: 60px !important;
  min-height: 30px;
  object-fit: contain;
  margin-left: 25px;
}
</style>

<style lang="scss">
.my-custom-popover-class {
  background-color: #2c2e35;
  .driver-popover-title {
    color: #ffff;
    font-weight: bold;
    font-size: 1rem;
    font-family: Arial;
  }
  .driver-popover-description {
    color: #c9ccd6;
    font-weight: 400;
    font-size: 1rem;
    font-family: Arial;
  }

  .driver-popover-close-btn {
    color: white;
  }

  .driver-popover-prev-btn {
    background-color: #2c2e35;
    border: 1px solid white;
    color: #ffff;
    font-weight: bold;
    font-size: 1rem;
    font-family: Arial;
    opacity: 1;
    text-shadow: none;

    &:hover {
      background-color: #ffff;
      border: 1px solid #2c2e35;
      color: #2c2e35;
    }

    &:disabled {
      color: #a7a9b3;
      border: 1px solid #a7a9b3;
    }
  }

  .driver-popover-progress-text {
    color: #ffff;
    font-weight: bold;
  }

  .driver-popover-next-btn {
    background-color: #ffff;
    border: 1px solid #2c2e35;
    color: #2c2e35;
    font-weight: bold;
    font-size: 1rem;
    font-family: Arial;
    opacity: 1;
    text-shadow: none;

    &:hover {
      background-color: #2c2e35;
      border: 1px solid white;
      color: #ffff;
    }
  }

  .driver-popover-arrow {
    border: 5px solid #2c2e35;
  }

  .driver-popover-arrow-side-right {
    right: 100%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .text-style {
  font-family: "Montserrat", arial;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 1 !important;
}

.tooltip .header-style {
  font-family: "Montserrat", arial;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: normal;
  line-height: 1 !important;
}

.completeInvestorProfileText {
  padding: 15px;
  visibility: hidden;
  width: 300px;
  background-color: #19486a;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  top: -40px;
}

.tooltip:hover .completeInvestorProfileText {
  visibility: visible;
}

.tooltip .twox-matchmaking-hover-text {
  font-family: "Montserrat", arial;
  padding: 10px 15px;
  font-size: 15px;
  letter-spacing: normal;
  visibility: hidden;
  width: 450px !important;
  background-color: rgb(32, 23, 71) !important;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 9999;
  left: 101%;
  bottom: 0px;
}

.tooltip:hover .twox-matchmaking-hover-text {
  visibility: visible;
}

.tooltip .twox-matchmaking-hover-text::after {
  right: 95%;
}

.certification-button {
  font-weight: 500;
  color: white;
  background: #19486a;
  font-size: 16px;
}

.certification-button:hover {
  background: #1d557c;
}
</style>
