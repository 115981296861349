<template>
  <div class="container mx-auto p-4 dashboard">
    <div
      v-if="isLoading"
      class="absolute inset-0 flex justify-center items-center z-10"
    >
      <div
        class="w-10 h-10 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"
      ></div>
    </div>
    <div class="flex justify-between items-center pb-4 mb-6 border-b">
      <h1 class="text-3xl font-bold">2X Challenge Dashboard</h1>
      <div>
        <div>
          <ul
            class="relative flex flex-wrap items-center list-none rounded-2xl backdrop-blur-sm outline-none"
            data-tabs="tabs"
            role="list"
          >
            <li class="z-30 flex-1">
              <button
                class="w-full px-6 py-3 text-sm font-semibold rounded-l-2xl focus:outline-none"
                :class="
                  !isToggled
                    ? 'border-blue-400 border-2 text-blue-600 bg-blue-50'
                    : 'bg-transparent text-slate-600 border-2 border-blue-200'
                "
                @click="setToggle(false)"
                role="tab"
                :aria-selected="!isToggled"
              >
                <span class="relative z-10 whitespace-nowrap"
                  >Deal Submission</span
                >
              </button>
            </li>
            <li class="z-30 flex-1">
              <button
                class="w-full px-6 py-3 text-sm font-semibold rounded-r-2xl focus:outline-none"
                :class="
                  isToggled
                    ? 'border-blue-400 border-2 text-blue-600 bg-blue-50'
                    : 'bg-transparent text-slate-600 border-2 border-blue-200'
                "
                @click="setToggle(true)"
                role="tab"
                :aria-selected="isToggled"
              >
                <span class="relative z-10 whitespace-nowrap"
                  >Investor Disclosures</span
                >
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="toggleLabel == '2X Deal Submissions/Document Viewer'">
      <div class="mb-8">
        <twoxDealSubmissions
          :data="dealData.deals"
          :count="dealData.count"
          :page="dealData.page"
          @sort-deals-data="sortDealsData"
        />
        <EquiloPagination
          :total-items="parseInt(dealData.count)"
          :items-per-page="parseInt(dealData.pageSize)"
          :current-page="parseInt(dealData.page)"
          @page-change="changeDealPage"
        />
      </div>

      <div class="mb-8">
        <div class="flex justify-between items-center p-4">
          <h2 class="text-2xl font-semibold">Document Viewer</h2>
          <TwoxOrganizationSelect
            :organizations="teams"
            @organization-selected="fetchOrgDocuments"
          />
        </div>
        <TwoxDocumentViewer
          :files="documentsData.documents"
          :count="documentsData.count"
          :page="documentsData.page"
        />
        <EquiloPagination
          :total-items="parseInt(documentsData.count)"
          :items-per-page="parseInt(documentsData.pageSize)"
          :current-page="parseInt(documentsData.page)"
          @page-change="changeDocumentsage"
        />
      </div>

      <div class="mb-8">
        <div class="flex justify-between items-center p-4">
          <h2 class="text-2xl font-semibold">
            2X Challenge Self-Assessments Completed
          </h2>
          <TwoxOrganizationSelect
            :organizations="teams"
            @organization-selected="fetchOrgDocuments"
          />
        </div>
        <TwoxDocumentViewer
          :files="documentsData.documents"
          :count="documentsData.count"
          :page="documentsData.page"
        />
        <EquiloPagination
          :total-items="parseInt(documentsData.count)"
          :items-per-page="parseInt(documentsData.pageSize)"
          :current-page="parseInt(documentsData.page)"
          @page-change="changeDocumentsage"
        />
      </div>
    </div>
    <div v-if="toggleLabel == '2X Investor Profile Disclosures'" class="mb-8">
      <twoxInvestorProfileDisclosures
        :data="investorData.investorProfileDisclosure"
        :count="investorData.count"
        :page="investorData.page"
        @sort-investor-data="sortInvestorData"
      />
      <EquiloPagination
        :total-items="parseInt(investorData.count)"
        :items-per-page="parseInt(investorData.pageSize)"
        :current-page="parseInt(investorData.page)"
        @page-change="changeInvestorPage"
      />
    </div>
    <div class="flex gap-5 mt-8">
      <div
        @click="redirectToChallengeInProgress()"
        class="bg-white shadow-sm rounded-lg p-6 border border-gray-500 cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg text-gray-700 hover:text-[#0077C8] w-64"
      >
        <p class="font-bold">2X Challenge Self-Assessments in Progress</p>
      </div>
      <div
        @click="$router.replace({ path: '/2x-global/2X-Challenge' })"
        class="bg-white shadow-sm rounded-lg p-6 border border-gray-500 cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg text-gray-700 hover:text-[#0077C8] w-64"
      >
        <p class="font-bold">2X Challenge Self-Assessments Results</p>
      </div>
    </div>
  </div>
</template>
<script>
import twoxDealSubmissions from "@/components/2x-Global/2xDealSubmissions.vue";
import twoxInvestorProfileDisclosures from "@/components/2x-Global/2XInvestorProfileDisclosures.vue";
import EquiloPagination from "@/components/common/EquiloPagination.vue";
import TwoxDocumentViewer from "@/components/2x-Global/2xDocumentViewer.vue";
import TwoxOrganizationSelect from "@/components/2x-Global/2xOrgSelect.vue";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import { useUserStore } from "@/stores/user";

export default {
  name: "TwoXChallengeDealsAndDisclosures",
  components: {
    twoxDealSubmissions,
    twoxInvestorProfileDisclosures,
    EquiloPagination,
    TwoxDocumentViewer,
    TwoxOrganizationSelect,
  },
  data() {
    return {
      documents: [],
      teams: [],
      editingItem: null,
      isToggled: false,
      dealData: {
        count: 0,
        deals: [],
        page: 0,
        pageSize: 10,
      },
      investorData: {
        count: 0,
        investorProfileDisclosure: [],
        page: 0,
        pageSize: 10,
      },
      documentsData: {
        count: 0,
        documents: [],
        page: 0,
        pageSize: 10,
      },
      dealSortOrder: "ASC",
      invSortOrder: "ASC",
      isLoading: false,
    };
  },
  methods: {
    redirectToChallengeInProgress() {
      this.$router.replace({ name: 'TwoXChallengeInProgress'});
    },
    setToggle(value) {
      this.isToggled = value;
      this.updateToggle();
      if (!this.isToggled) {
        if (this.dealData.deals.length) {
          return;
        }
        this.fetchTwoxDealTable();
      } else {
        if (this.investorData.investorProfileDisclosure.length) {
          return;
        }
        this.fetchTwoxInvestorDealTable();
      }
    },
    updateToggle() {
      localStorage.setItem("viewToggle", this.isToggled.toString());
    },
    loadToggleState() {
      const savedState = localStorage.getItem("viewToggle");
      if (savedState !== null) {
        this.isToggled = savedState === "true";
      }
    },
    async fetchData(url, dataProperty) {
      this.isLoading = true;
      try {
        const response = await fetch(`${env.apiUrl}${url}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Something went wrong!");
        }
        this[dataProperty] = data.data;
        if (dataProperty === "dealData") {
          const { count, deals, page, pageSize } = this[dataProperty];
          Object.assign(this.dealData, { count, deals, page, pageSize });
        }
        if (dataProperty === "documents") {
          const { count, documents, page, pageSize } = this[dataProperty];
          Object.assign(this.documentsData, {
            count,
            documents,
            page,
            pageSize,
          });
        }
        if (dataProperty === "filterTeams") {
          this.teams = data.data;
        }
        if (dataProperty === "investorData") {
          const { count, investorProfileDisclosure, page, pageSize } =
            this[dataProperty];
          Object.assign(this.investorData, {
            count,
            investorProfileDisclosure,
            page,
            pageSize,
          });
        }
      } catch (error) {
        console.error("Error:", error.message);
        this.$swal.fire({
          icon: "error",
          text: error.message,
          showConfirmButton: true,
        });
        this.$emit("error", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    fetchTwoxDealTable(page = 1) {
      return this.fetchData(
        `/twox-global/deals?page=${page}&pageSize=${this.dealData.pageSize}&sort=${this.dealSortOrder}`,
        "dealData"
      );
    },
    fetchTwoxInvestorDealTable(page = 1) {
      return this.fetchData(
        `/twox-global/investor-profile-disclosure?page=${page}&pageSize=${this.investorData.pageSize}&sort=${this.invSortOrder}`,
        "investorData"
      );
    },
    fetchDocuments(page = 1) {
      return this.fetchData(
        `/twox-challenge/documents?page=${page}&pageSize=${this.documentsData.pageSize}`,
        "documents"
      );
    },
    fetchOrgDocuments(val, page = 1) {
      let url = `/twox-challenge/documents`;
      const params = new URLSearchParams();

      if (!val.type && !val.teamId) {
        return this.fetchDocuments();
      }

      params.append("page", page);
      params.append("pageSize", this.documentsData.pageSize);
      if (val.type) params.append("type", val.type);
      if (val.teamId) params.append("teamId", val.teamId);

      url += `?${params.toString()}`;

      return this.fetchData(url, "documents");
    },
    fetchTeams() {
      return this.fetchData(`/twox-challenge/filter-teams`, "filterTeams");
    },
    changeDealPage(page) {
      this.fetchTwoxDealTable(page);
    },
    changeInvestorPage(page) {
      this.fetchTwoxInvestorDealTable(page);
    },
    changeDocumentsage(page) {
      this.fetchDocuments(page);
    },
    createOrderedTableDataset(dealsdData) {
      const tableData = [];
      dealsdData.forEach((item) => {
        const rowData = {};
        Object.keys(this.expectedFields).forEach((key) => {
          const backendField = this.expectedFields[key];
          if (backendField in item) {
            rowData[key] = item[backendField];
          } else {
            rowData[key] = "";
          }
        });
        tableData.push(rowData);
      });
      return tableData;
    },
    sortInvestorData(sortOrder) {
      this.invSortOrder = sortOrder;
      this.fetchTwoxInvestorDealTable();
    },
    sortDealsData(sortOrder) {
      this.dealSortOrder = sortOrder;
      this.fetchTwoxDealTable();
    },
  },
  computed: {
    toggleLabel() {
      return this.isToggled
        ? "2X Investor Profile Disclosures"
        : "2X Deal Submissions/Document Viewer";
    },
    dealsTableDataset() {
      return this.createOrderedTableDataset(this.dealData.deals);
    },
  },
  async mounted() {
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Global Portal", url: "/2x-global/Home" },
      { name: "2X Challenge", url: "/2x-global/2X-Challenge" },
      {
        name: "2X Challenge Deals and Investor Disclosures",
        url: "/2x-global/2X-Challenge/deals-investor-disclosures",
      },
    ]);
    const userStore = useUserStore();
    if (
      userStore.user.hasOwnProperty("twoXGlobal") &&
      userStore.user.twoXGlobal.length === 0
    ) {
      await this.$router.push({ name: "homePage" });
    } else {
      this.loadToggleState();
      await this.fetchDocuments();
      await this.fetchTeams();
      if (!this.isToggled) {
        await this.fetchTwoxDealTable();
        await this.fetchTeams();
      } else {
        await this.fetchTwoxInvestorDealTable();
      }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.container {
  font-family: "Work Sans";
}
.dashboard {
  padding-top: 7em;
}
</style>
