<template>
  <div class="static assementPageStyle" id="contentForm">
    <transition name="fadeIn" mode="out-in">
      <div id="loaded" key="load" class="container" v-if="(endofcreated && roiData && roiData.investedCompaniesList)">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <img v-if="roiCompanyDetails.logo && roiCompanyDetails.logo[0] && roiCompanyDetails.logo[0].url"
              class="h-16 print:h-auto print:w-64" :src="roiCompanyDetails.logo[0].url" style="height: 10rem" crossorigin="anonymous"/>
            <h1 class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl mb-10">
              <span v-if="roiCompanyDetails && roiCompanyDetails.member2xc">{{ roiCompanyDetails.member2xc + "'s"
              }}</span>
              Gender ROI™ Portfolio
            </h1>
          </div>
        </div>
        <div class="flex my-10">
          <div class="portfolio_toggle flex w-2/4 ml-8" v-if="toggleOptions && toggleOptions.length">
            <div>
              <div class="flex mr-1 selected-toggle" style="">
                <span style="margin: auto;position: relative;">{{ portfolioSwitchTog }}</span>
              </div>
            </div>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in">
          <div id="loaded" key="content" v-if="(endofcreated && roiData && roiData.investedCompaniesList)">
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper">
              <div class="OverviewBlock flex" style="height:auto">
                <div class="OverviewBlock_left_side">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h2 style="width: 1000px">{{ roiCompanyDetails.member2xc + "'s" }} Overview</h2>
                      <span></span>
                    </div>
                    <ul>
                      <li v-if="roiCompanyDetails.organizationType">
                        <span>Enterprise Type:
                          <b> {{ roiOrganizationtype }} </b></span>
                      </li>
                      <li v-if="roiCompanyDetails.countryHqText">
                        <span>Headquarters:
                          <b>{{ roiCompanyDetails.countryHqText }}</b></span>
                      </li>
                      <li>
                        <span>Sector: <b>Financial Services</b></span>
                      </li>
                      <li v-if="roiSectors">
                        <span>Sectors of Interest: <b>{{ roiSectors }}</b></span>
                      </li>
                      <li v-if="roiCompanyDetails.countryHeadquartersText">
                        <span>Headquarters: <b>{{ roiCompanyDetails.countryHeadquartersText }}</b></span>
                      </li>
                      <li v-if="roiInvestedCompanies && roiInvestedCompaniesCount" class="cursor-pointer">
                        <a>
                          <span @click="scrollToElement()">Number of {{ investor ? 'Funds/FIs' : 'Companies' }} invested
                            in
                            : <b>{{
                                roiInvestedCompanies && roiInvestedCompaniesCount
                                  ? roiInvestedCompaniesCount
                                  : "NA"
                            }}</b></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="OverviewBlock_right_side">
                  <div class="gender_roi_score">
                    <div class="OverviewTitle">
                      <h2 style="width: 3000px">Portfolio Aggregate Gender ROI™ Score</h2>
                      <span></span>
                    </div>
                    <div class="mb-8">
                      <h1 class="font-bold text-5xl">{{ genderRoiScore }}</h1>
                    </div>
                    <div class="gender_roi_score_chart">
                      <k-progress style="width: 100%;margin: 0 !important;position: relative;top: 7px;"
                        :percent="((genderRoiScore * 100) / 6) | mathOnlyRound" :show-text="false" color="#2BD4C4">
                      </k-progress>
                      <img src="@/assets/images/roi/chart-bar.png" style="position: relative; width: 100%" />
                    </div>
                    <div class="finance_average_score mt-5" v-if="investorFromParent">
                      <div class="OverviewTitle">
                        <h2 style="width: 700px" class="cursor-pointer">Financial
                          Processes, Products, & Services</h2>
                      </div>
                      <div class="mb-8">
                        <h1 class="font-bold text-5xl">{{ averageFinanceScore }}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="spiderchart_main_container mb-10" style="">
                <div class="OverviewTitle">
                  <h2 style="width: 1300px">
                    Portfolio Aggregate Pillar Assessment Results
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content flex">
                  <!-- <div class="left_side">
                    <template v-if="portfolioSwitchTog == 'Portfolio'">
                      <div class="flex">
                        <div class="orange-dot mr-5"></div>
                        <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                      </div>
                      <div class="flex dot_with_text" v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)">
                        <div class="blue-dot mr-5"></div>
                        <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                            allROIData[1].companyDetails[0].financialReportingYear
                        }}</span>
                        <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First
                          Historical Year</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex">
                        <div class="orange-dot mr-5"></div>
                        <span class="font-bold">Current</span>
                      </div>
                    </template>
                  </div> -->
                  <div class="right_side">
                    <div class="spiderchartContent" id="chart">
                      <div class="relative">
                        <spiderchart-multiple v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)"
                          :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                          :financialYear="portfolioSwitchTog == 'Portfolio' ? allROIData[0].companyDetails[0].financialReportingYear : 'Current'"
                          :financialYear1="portfolioSwitchTog == 'Portfolio' ? allROIData[1].companyDetails[0].financialReportingYear : 'Current'"
                          :details="roiData.roiPortfolioData" :detailsOld="oldRoiData.roiPortfolioData"
                          class="overflow-visible" style="position: relative; height: 100%; width: 100%">
                        </spiderchart-multiple>
                        <spiderchart v-else :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                          :financialYear="portfolioSwitchTog == 'Portfolio' ? allROIData[0].companyDetails[0].financialReportingYear : 'Current'"
                          :details="roiData.roiPortfolioData" class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%"></spiderchart>
                        <template>
                          <!--Opportunity-->
                          <div class="Ind_Inv_Icon opportunity-icon" id="2uur">
                            <img src="../../assets/images/roi/opportunity.svg" alt="Indirect Investment" />
                            <span style="color: #2bb900"> Opportunity </span>
                          </div>

                          <!--Inclusion-->
                          <div class="Ind_Inv_Icon inclusion-icon" id="8uur">
                            <img src="../../assets/images/roi/inclusion.svg" alt="Indirect Investment" />
                            <span style="color: #00a269"> Inclusion </span>
                          </div>

                          <!--Resilince-->
                          <div class="Ind_Inv_Icon resilince-icon" id="10uur">
                            <img src="../../assets/images/roi/resilience.svg" alt="Indirect Investment" />
                            <span style="color: #0068cd"> Resilience </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Domain Assessment Results -->
              <div class="domain_assessment_results_section before_page" style="">
                <div class="OverviewTitle">
                  <h2 style="width: 600px">
                    Domain Assessment Results
                  </h2>
                  <span></span>
                </div>
                <div class="domain_assessment_results_container flex">
                  <div class="spiderchart_container">
                    <div class="spiderchart_content">
                      <div>
                        <div class="left_side">
                          <template v-if="portfolioSwitchTog == 'Portfolio'">
                              <div class="flex">
                                <div class="orange-dot mr-5"></div>
                                <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                              </div>
                              <div class="flex dot_with_text"
                                v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)">
                                <div class="blue-dot mr-5"></div>
                                <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                                    allROIData[1].companyDetails[0].financialReportingYear
                                }}</span>
                                <span class="font-bold"
                                  v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First
                                  Historical Year</span>
                              </div>
                          </template>
                          <template v-else>
                              <div class="flex">
                                <div class="orange-dot mr-5"></div>
                                <span class="font-bold">Current</span>
                              </div>
                          </template>
                        </div>
                      </div>
                      <div class="" style="width:100%">
                        <div class="spiderchartContent" id="chart">
                          <div class="relative">
                            <domain-spiderchart-multiple
                              v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)" :paddingL="40"
                              :paddingR="40" :paddingT="40" :paddingB="40" :details="roiData.domainAssesmnet"
                              :detailsOld="oldRoiData.domainAssesmnet" class="overflow-visible"
                              style="position: relative; height: 100%; width: 100%" />
                            <domain-spiderchart v-else :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                              :details="roiData.domainAssesmnet" class="overflow-visible"
                              style="position: relative; height: 100%; width: 100%"></domain-spiderchart>
                            <template>
                              <!--Workforce-->
                              <div class="Ind_Inv_Icon workforce-icon" id="2uur">
                                <img src="../../assets/images/workforce.svg" alt="Indirect Investment" />
                                <span style="color:black"> Workforce </span>
                              </div>
                              <!--Leadership-->
                              <div class="Ind_Inv_Icon leadership-icon" id="2uur">
                                <img src="../../assets/images/leadership.svg" alt="Indirect Investment" />
                                <span style="color:black"> Leadership </span>
                              </div>

                              <!--Value Chain-->
                              <div class="Ind_Inv_Icon value-chain-icon" id="8uur">
                                <img src="../../assets/images/value_chain.svg" alt="Indirect Investment" />
                                <span style="color:black"> Value Chain </span>
                              </div>

                              <!--Society-->
                              <div class="Ind_Inv_Icon society-icon" id="10uur">
                                <img src="../../assets/images/society.svg" alt="Indirect Investment" />
                                <span style="color:black"> Society </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gender_roi_domain">
                    <div class="mb-5">
                      <h1 class="font-bold text-2xl">Gender ROI™ Domain</h1>
                    </div>
                    <div class="gender_roi_domain_taabs_container">
                      <div class="flex my-4 items-center" v-for="(dom, domIndex) in roiData.domainAssesmnet" :key="'Leadership' + domIndex"
                        v-show="dom.name == 'Leadership'">
                        <!-- <vue-circle v-if="dom.name == 'Leadership'"
                                                :progress="Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)"
                                                :size="50" :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                                                :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                                                :show-percent="true">
                                              </vue-circle> -->
                        <progress-bar :options="options" :value="
                          Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/leadership.svg" />
                        <h2 class="font-bold text-xl">Leadership</h2>
                      </div>
                      <div class="flex my-4 items-center" v-for="(dom, domIndex) in roiData.domainAssesmnet"
                        :key="'Workforce' + domIndex" v-show="dom.name == 'Workforce'">
                        <!-- <vue-circle v-if="dom.name == 'Workforce'"
                          :progress="Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)"
                          :size="50" :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                          :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                          :show-percent="true">
                        </vue-circle> -->
                        <progress-bar :options="options" :value="
                          Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/workforce.svg" />
                        <h2 class="font-bold text-xl">Workforce</h2>
                      </div>
                      <div class="flex my-4 items-center" v-for="(dom, domIndex) in roiData.domainAssesmnet"
                        :key="'Value' + domIndex" v-show="dom.name == 'Value Chains'">
                        <!-- <vue-circle v-if="dom.name == 'Value Chains'"
                          :progress="Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)"
                          :size="50" :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                          :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                          :show-percent="true">
                        </vue-circle> -->
                        <progress-bar :options="options" :value="
                          Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/value_chain.svg" />
                        <h2 class="font-bold text-xl">Value Chain</h2>
                      </div>
                      <div class="flex my-4 items-center" v-for="(dom, domIndex) in roiData.domainAssesmnet"
                        :key="('Society' + domIndex)" v-show="dom.name == 'Society'">
                        <!-- <vue-circle v-if="dom.name == 'Society'"
                          :progress="Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)"
                          :size="50" :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                          :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                          :show-percent="true">
                        </vue-circle> -->
                        <progress-bar :options="options" :value="
                          Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/society.svg" />
                        <h2 class="font-bold text-xl">Society</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parameters -->
              <div class="parameters_block before_page">
                <div class="parameters_section_first">
                  <div class="OverviewTitle">
                    <h2 style="width: 1000px">Portfolio Aggregate Parameter Scores</h2>
                    <span></span>
                    <!-- <div class="InviteBtn" style="width: 400px; margin: 0 20px">
                      <div class="tooltip TooltipImgText">
                        <button class="
                            text-white text-sm
                            font-medium
                            pointer-events-none
                          ">
                          View Detailed Assessment
                        </button>
                        <span class="tooltiptext" style="
                            width: 200px;
                            left: 118px;
                            top: 114%;
                            height: auto;
                          ">
                          Coming Soon!
                        </span>
                      </div>
                    </div>
                    <div class="InviteBtn" style="width: 300px">
                      <div class="tooltip TooltipImgText">
                        <button class="
                            text-white text-sm
                            font-medium
                            pointer-events-none
                          ">
                          View Action Plan
                        </button>
                        <span class="tooltiptext" style="
                            width: 200px;
                            left: -108px;
                            top: 114%;
                            height: auto;
                          ">
                          Coming Soon!
                        </span>
                      </div>
                    </div> -->
                  </div>
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/resilience.png" />
                          <h3 class="font-bold text-2xl">Resilience</h3>
                          <progress-bar :options="options" :value="
                          (roiData.resiliencePercentage * 100)
                          | mathOnlyRound" style="width: 45px;height: 45px;line-height: 0;margin: auto 0;" />
                          <!-- <vue-circle :progress="Math.round(roiData.resiliencePercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                        </div>
                        <div class="flex right_side_flex" style="font-size: 13px">
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                        <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div class="flex">
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                            </div>
                            <div class="flex dot_with_text"
                              v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)">
                              <div class="blue-dot mr-5"></div>
                              <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                                  allROIData[1].companyDetails[0].financialReportingYear
                              }}</span>
                              <span class="font-bold"
                                v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First Historical
                                Year</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                        </template>
                          <!-- <div class="flex dot_with_text">
                          <div class="blue-dot mr-2"></div>
                          <span class="font-bold">Benchmark</span>
                        </div> -->
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold" style="bottom:0px;position: relative;">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <!-- <RoiProgressBarsSectionPrint :scoreData="roiData.roiPortfolioData.Resilience" /> -->
                      <RoiProgressBarsSectionMultiplePrint
                        v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)"
                        :scoreData="roiData.roiPortfolioData.Resilience"
                        :scoreDataOld="oldRoiData.roiPortfolioData.Resilience" :roiData="roiData" />
                      <RoiProgressBarsSectionPrint v-else :scoreData="roiData.roiPortfolioData.Resilience" />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/opportunity.svg" />
                          <h3 class="font-bold text-2xl">Opportunity</h3>
                          <progress-bar :options="options" :value="
                            (roiData.opportunityPercentage * 100)
                            | mathOnlyRound
                          " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                          <!-- <vue-circle :progress="Math.round(roiData.opportunityPercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                        </div>
                        <div class="flex right_side_flex" style="font-size: 13px">
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                        <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div class="flex">
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                            </div>
                            <div class="flex dot_with_text"
                              v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)">
                              <div class="blue-dot mr-5"></div>
                              <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                                  allROIData[1].companyDetails[0].financialReportingYear
                              }}</span>
                              <span class="font-bold"
                                v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First Historical
                                Year</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                        </template>
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold" style="bottom:0px;position: relative;">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSectionMultiplePrint
                        v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)"
                        :scoreData="roiData.roiPortfolioData.Opportunities"
                        :scoreDataOld="oldRoiData.roiPortfolioData.Opportunities" :roiData="roiData" />
                      <RoiProgressBarsSectionPrint v-else :scoreData="roiData.roiPortfolioData.Opportunities" />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/inclusion.svg" />
                          <h3 class="font-bold text-2xl">Inclusion</h3>
                          <progress-bar :options="options" :value="
                            (roiData.inclusionPercentage * 100)
                            | mathOnlyRound
                          " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                          <!-- <vue-circle :progress="Math.round(roiData.inclusionPercentage * 100)" :size="50"
                            :reverse="false" line-cap="round" :fill="fill1" empty-fill="rgba(0, 0, 0, .1)"
                            :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                            :show-percent="true">
                          </vue-circle> -->
                        </div>
                        <div class="flex right_side_flex" style="font-size: 13px">
                          <!-- <div class="flex dot_with_text">
                          <div class="grey-dot mr-2"></div>
                          <span class="font-bold">Previous Results 2021</span>
                        </div> -->
                        <template v-if="portfolioSwitchTog == 'Portfolio'">
                            <div class="flex">
                              <div class="orange-dot mr-5"></div>
                              <span class="font-bold">{{ allROIData[0].companyDetails[0].financialReportingYear }}</span>
                            </div>
                            <div class="flex dot_with_text"
                              v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)">
                              <div class="blue-dot mr-5"></div>
                              <span class="font-bold" v-if="allROIData[1].companyDetails[0].financialReportingYear">{{
                                  allROIData[1].companyDetails[0].financialReportingYear
                              }}</span>
                              <span class="font-bold"
                                v-if="allROIData[1].companyDetails[0].financialReportingYear == ''">First Historical
                                Year</span>
                            </div>
                        </template>
                         <template v-else>
                            <div class="flex">
                              <div class="orange-dot mr-2"></div>
                              <span class="font-bold">Current</span>
                            </div>
                        </template>
                          <!-- <div class="flex dot_with_text">
                          <div class="blue-dot mr-2"></div>
                          <span class="font-bold">Benchmark</span>
                        </div> -->
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold" style="bottom:0px;position: relative;">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSectionMultiplePrint
                        v-if="(allROIData.length > 1 && oldRoiData.investedCompaniesList.length > 0)"
                        :scoreData="roiData.roiPortfolioData.Inclusion"
                        :scoreDataOld="oldRoiData.roiPortfolioData.Inclusion" :roiData="roiData" />
                      <RoiProgressBarsSectionPrint v-else :scoreData="roiData.roiPortfolioData.Inclusion" />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Investeed Companies list -->
              <div class="investeed_companies mt-10" ref="investeedCompanies"
                v-if="roiInvestedCompanies && roiInvestedCompanies.length">
                <div class="CompanyTitle">
                  Number of {{ investor ? 'Funds/FIs' : 'Companies' }} invested in : {{
                      roiInvestedCompanies && roiInvestedCompaniesCount
                        ? roiInvestedCompaniesCount
                        : "NA"
                  }}
                </div>
                <div class="companies_and_fund_table">
                  <table class="divide-y divide-gray-200 bg-white w-full" v-if="portfolioSwitchTog == 'Portfolio'">
                    <thead>
                      <tr>
                        <th class="px-3 py-3" style="width: 17rem">
                          <div class="flex">
                            <span>{{ investor ? 'Funds/FIs' : 'Companies' }}</span>
                          </div>
                        </th>
                        <th class="px-3 py-3">
                          <div class="flex">
                            <span class="m-auto"> Gender ROI™ Score </span>
                          </div>
                        </th>
                        <th class="px-3 py-3">
                          <div class="flex">
                            <span class="m-auto">Quarter Completed </span>
                          </div>
                        </th>
                        <th class="px-3 py-3" style="width: 17rem">
                          <div class="flex">
                            <span class="m-auto">Time for a repeat assessment?</span>
                          </div>
                        </th>
                        <th class="px-3 py-3">
                          <div class="flex">
                            <span class="m-auto">Progress of Current/Repeat Assessment</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr v-for="(company, index) in roiInvestedCompanies" :key="'company' + index">
                        <!-- Funds/FIs -->
                        <td class="px-3 py-8">
                          <div class="flex image-center">
                            <img class="organization-img" :src="company.logo[0].url" v-if="company &&
                            company.logo &&
                            company.logo[0] &&
                            company.logo[0].url" crossorigin="anonymous"/>
                            <span class="organization-name cursor-pointer" v-if="company.percentage == '100'"
                              @click="redirectToCompany(company.recordId)">{{
                                  company.organizationName
                              }}</span>
                            <span class="organization-name" v-else>{{
                                company.organizationName
                            }}</span>
                          </div>
                        </td>
                        
                        <!-- Gender ROI™ Score -->
                        <td class="px-3 py-8 center-text"
                          v-if="company.roiScoresText && company.roiScoresText[0] && company.percentage == '100'">
                          {{ company.roiScoresText[0] | toFixedTwo }}
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          Incomplete
                        </td>
                        
                        <!-- Quarter Completed -->
                        <td class="px-3 py-8 center-text">
                           {{ company.financialReportingYear }}
                        </td>
                        
                        <!-- Time for a repeat assessment? -->
                        <td class="blue-border-button px-3 py-8 center-text" v-if="company.currentQauter != company.financialReportingYear">
                          <button v-if="company.showReapeatFlag === true"> Time to send a reminder! 
                          </button>
                          <span v-else>Waiting for the latest assessment. Request was sent on {{ company.equiloEmailId }}.</span>
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          <span>No- we are all set for now!</span>
                        </td>
                        
                        <!-- Progress of Current/Repeat Assessment -->
                        <td class="px-3 py-8 center-text" v-if="company.currentQauter != company.financialReportingYear">
                          <!-- <template v-if="company.percentageOfCurrentQuatre < 100"> -->
                            <span
                              >{{
                                company.percentageOfCurrentQuatre == 0 ? (company.percentage | mathOnlyRound) : (company.percentageOfCurrentQuatre | mathOnlyRound)
                              }}%</span
                            >
                          <!-- </template>
                          <img v-else src="@/assets/images/roi/smile-icon.png" height="50" width="50" style="display: block;margin: auto;"/> -->
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          <img src="@/assets/images/roi/smile-icon.png" height="50" width="50" style="display: block;margin: auto;"/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="divide-y divide-gray-200 bg-white w-full" v-else>
                    <thead>
                      <tr>
                        <th class="px-3 py-2" style="width: 17rem">
                          <div class="flex">
                            <span>{{ investor ? 'Funds/FIs' : 'Companies' }}</span>
                          </div>
                        </th>
                        <th class="px-3 py-2">
                          <div class="flex">
                            <span class="m-auto"> Gender ROI™ Score </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr v-for="(company, index) in roiInvestedCompanies" :key="index">
                        <td class="px-3 py-8">
                          <div class="flex image-center">
                            <img class="organization-img" :src="company.logo[0].url" v-if="company &&
                            company.logo &&
                            company.logo[0] &&
                            company.logo[0].url" crossorigin="anonymous"/>
                            <span class="organization-name cursor-pointer" v-if="company.percentage == '100'"
                              @click="redirectToCompany(company.recordId)">{{
                                  company.organizationName
                              }}</span>
                            <span class="organization-name" v-else>{{
                                company.organizationName
                            }}</span>
                          </div>
                        </td>
                        <td class="px-3 py-8 center-text"
                          v-if="company.roiScoresText && company.roiScoresText[0] && company.percentage == '100'">
                          {{ company.roiScoresText[0] | toFixedTwo }}
                        </td>
                        <td class="px-3 py-8 center-text" v-else>
                          Incomplete
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-else key="else_div">
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div class="flex flex-row items-center justify-center print:flex-col">
              <img class="h-16 print:h-auto print:w-64" src="@/assets/images/Writing-removebg.png" alt="" />
              <h1 class="
                  text-6xl
                  tracking-tighter
                  font-medium
                  text-center
                  print:text-7xl
                  ml-5
                ">
                No Data Found
              </h1>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import RoiProgressBarsSectionPrint from "@/components/roiPortfolioProgressBarsSection-print.vue";
import spiderchart from "@/components/charts/roiPortfolioSpiderchart.vue";
import spiderchartMultiple from "@/components/charts/roiPortfolioSpiderchartMultiple.vue";
import domainSpiderchart from "@/components/charts/roiDomainSpiderchart.vue";
import domainSpiderchartMultiple from "@/components/charts/roiDomainSpiderchartMultiple.vue";
import RoiProgressBarsSectionMultiplePrint from "@/components/roiPortfolioProgressBarsSectionMultiple-print.vue";

export default {
  name: "roiAssessment",
  data: function () {
    return {
      percentValue: "80%",
      user: [],
      roiData: [],
      oldRoiData: [],
      endofcreated: false,
      invitedUserEmail: "",
      learnMoreAboutModal: false,
      selectedSurvey: [],
      completeSurveyModal: false,
      isShareVisible: false,
      isMyselfRadioChecked: false,
      isOtherRadioChecked: false,
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 10,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      json_data: [],
      csvData: [],
      roiCompanyDetails: [],
      roiInvestedCompanies: [],
      roiInvestedCompaniesCount: [],
      companyHeader: [
        {
          title: this.investor ? 'Funds/FIs' : 'Companies',
          // orderBy: 'organizationName',
          // defalutOrderTypeASC: true
        },
        {
          title: 'Gender ROI™ Score ',
          // orderBy: 'percentShares',
          // defalutOrderTypeASC: false
        },
      ],
      country: [],
      downloadPDF: false,
      latestSurveyDateTime: '',
      fill1: { gradient: ["#0077C8", "#2BD4C4"] },
      fill2: { gradient: ["#FE5000", "#FAE053"] },
      allROIData: [],
      toggleOptions: [
        {
          status: 'Portfolio',
          isSelected: true,
        },
        {
          status: 'Alumni',
          isSelected: false,
        },
        {
          status: 'Pipeline',
          isSelected: false,
        },
      ],
      investor: false,
      allPortfolioData: [],
      allOldPortfolioData: [],
      updateKey: 0,
    }
  },
  props: {
    investorFromParent: { type: Boolean },
    portfolioSwitchTog: { type: String },
    requestSubmitDate: { type: String },
    userFromParent: [Array, Object],
    roiDataFromParent: [Array, Object],
    oldRoiDataFromParent: [Array, Object],
    roiInvestedCompaniesFromParent: [Array, Object],
    roiInvestedCompaniesCountFromParent: [Array, Object, Number, String],
    roiCompanyDetailsFromParent: [Array, Object],
    allROIDataFromParent: [Array, Object],
  },
  components: {
    spiderchart,
    RoiProgressBarsSectionPrint,
    domainSpiderchart,
    RoiProgressBarsSectionMultiplePrint,
    spiderchartMultiple,
    domainSpiderchartMultiple
  },
  async mounted() {
    await this.$emit('pageRefreshed', false)
    this.user = this.userFromParent;
    this.allROIData = this.allROIDataFromParent;
    this.investor = this.investorFromParent;
    this.roiData = this.roiDataFromParent;
    this.oldRoiData = this.oldRoiDataFromParent;
    this.roiCompanyDetails = this.roiCompanyDetailsFromParent
    this.roiInvestedCompanies = this.roiInvestedCompaniesFromParent;
    this.roiInvestedCompaniesCount = this.roiInvestedCompaniesCountFromParent;
    this.endofcreated = true;
    await this.$emit('pageRefreshed', true)
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.investeedCompanies;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    redirectToCompany(id) {
      let routeData = this.$router.resolve({
        name: "roiAssessment",
        query: {
          recordId: id,
        },
      });
      window.open(routeData.href, "_blank");
    }
  },
  computed: {
    roiOrganizationtype() {
      let value = this.roiCompanyDetails.organizationType;
      var data;
      if (this.roiCompanyDetails.organizationType.includes('Other')) {
        value = this.roiCompanyDetails.organizationType;
        value.forEach((item, i) => {
          if (item == 'Other') {
            value.splice(i, 1);
          }
        })
        value.push(this.roiCompanyDetails.otherOrganizationType);
        data = Object.values(value).join(", ");
      } else {
        data = Object.values(this.roiCompanyDetails.organizationType).join(", ");
      }
      return data;
    },
    roiSectors() {
      let value = this.roiCompanyDetails.sectorsOfInterest;
      var data;
      if (this.roiCompanyDetails.sectorsOfInterest.includes('Other')) {
        value = this.roiCompanyDetails.sectorsOfInterest;
        value.forEach((item, i) => {
          if (item == 'Other') {
            value.splice(i, 1);
          }
        })
        data = Object.values(value).join(", ");
      } else {
        data = Object.values(this.roiCompanyDetails.sectorsOfInterest).join(", ");
      }
      return data;
    },
    domainWorkforcePercent() {
      let val = 0;
      this.roiData.domainAssesmnet.forEach(dom => {
        if (dom.name == 'Workforce') {
          val = Math.round((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
        }
      })
      return val
    },
    genderRoiScore() {
      let val = '';
      if (this.roiData.genderRoiScore) {
        val = Number(this.roiData.genderRoiScore).toFixed(2);
      }
      if (this.roiData.genderRoiScore == 0 && this.roiData.genderRoiScore !== null) {
        val = 0;
      }
      if ((this.roiData.genderRoiScore == null || this.roiData.genderRoiScore == '') && this.roiData.genderRoiScore !== 0) {
        val = '';
      }
      return val;
    },
    averageFinanceScore() {
      let val = '';
      if (this.roiData.averageFinanceScore) {
        val = Number(this.roiData.averageFinanceScore).toFixed(2);
      }
      if (this.roiData.averageFinanceScore == 0 && this.roiData.averageFinanceScore !== null) {
        val = 0;
      }
      if ((this.roiData.averageFinanceScore == null || this.roiData.averageFinanceScore == '') && this.roiData.averageFinanceScore !== 0) {
        val = '';
      }
      return val;
    },
    companySize() {
      let state = "";
      let val = Math.round(Number(this.roiData.womworkforceTwo));
      if (val <= 10) {
        state = "Micro";
      } else if (val > 10 && val <= 250) {
        state = "SME";
      } else if (val > 250) {
        state = "Large";
      }
      return state;
    },
  },
};
</script>
<style lang="scss" scoped>
.selected-toggle {
  pointer-events: none;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-toggle {
  cursor: pointer;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  font-weight: 500;
}

.before_page {
  page-break-before: always;
}

.investeed_companies {
  padding: 40px;
  background: white;
  border-radius: 10px;

  .CompanyTitle {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #1b1b1b;
    margin-right: 17px;
    position: relative;
    margin-bottom: 30px;
  }

  .DownloadBtn {
    text-align: center;

    p {
      display: flex;
      align-items: center;
      border: 1px solid #1b1b1b;
      border-radius: 20px;
      color: #1b1b1b;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 11px 17px;
      transition: 0.3s ease;

      img {
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    a {
      display: flex;
      align-items: center;
      border: 1px solid #1b1b1b;
      border-radius: 20px;
      color: #1b1b1b;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 11px 10px;
      transition: 0.3s ease;

      img {
        margin-right: 8px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }

  .loadmore-btn {
    border-radius: 20px;
  }

  .companies_and_fund_table {
    table {
      border: 1px solid #e2e8f0;
      border-radius: 10px;

      thead {
        th {
          font-size: 13px;
          line-height: 16px;
          color: #9d9d9c;
          font-weight: 700;
        }
      }

      th,
      td {
        border: 1px solid #E9E9E9;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }
    }



    .image-center {
      align-items: center;
    }

    .organization-img {
      border-radius: 50% !important;
      margin: 0 !important;
      margin-right: 15px !important;
      height: 50px;
      width: 50px;
      object-fit: contain;
      min-width: 50px;
    }

    .organization-name {
      color: black;
      font-size: 15px;
      line-height: 16px;
      font-weight: 700;
    }

    .background-image {
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .center-text {
      text-align: center;
    }
  }
}


.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }

  a {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.k-progress {
  margin: 0 !important;
}

.grey-dot {
  width: 10px !important;
  height: 10px;
  background-color: #9d9d9c;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.orange-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fe5000;
  border-radius: 10px;
  top: 5px;
  position: relative;
}

.blue-dot {
  width: 10px !important;
  height: 10px;
  background-color: #201747;
  border-radius: 10px;
  top: 5px;
  position: relative;
}

.yellow-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fae053;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .bonus_score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -250px;
  top: 20px;
}

.tooltip:hover .bonus_score_tooltiptext {
  visibility: visible;
}

.tooltip .bonus_score_tooltiptext::after {
  right: 95%;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.pointer_event_none {
  pointer-events: none;
}

.opacity_light {
  opacity: 0.5;
}

.learn_more_modal_text_container {
  h2 {
    width: 60%;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
    width: 50%;
    text-align: end;
    cursor: pointer;
  }

  .learn_more_modal_text {
    height: 68vh;

    ::-webkit-scrollbar {
      width: 14px;
    }

    input {
      accent-color: black;
    }
  }
}

.assementPageStyle {
  background: #ffffff !important;

  .EmailInput {
    padding: auto 0;
    // border: 1px solid gainsboro;
    border-radius: 5px;
    color: black;

    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      height: 100%;

      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 100%;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        margin-left: 34px;
        // display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        max-width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;

        .invite-member-btn {}

        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        padding: auto 0;
        border: 1px solid gainsboro;
        border-radius: 5px;

        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          height: 100%;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .InviteBtn {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      opacity: 0.7;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }

    .tooltip .tooltiptext {
      font-family: "Montserrat", arial;
      padding: 20px;
      font-size: medium;
      letter-spacing: normal;
      visibility: hidden;
      width: 600px;
      background-color: #201747;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      left: 20px;
      top: -160px;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .tooltip .tooltiptext::after {
      right: 95%;
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;

    .OverviewBlock {

      // background: #ffffff;
      // border-radius: 10px;
      // padding: 0 40px 40px;
      // padding-top: 40px;
      // margin-bottom: 18px;
      .OverviewBlock_left_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .equal_strength {
          li {
            margin: 0;
            padding: 5px 0;
          }
        }
      }

      .OverviewBlock_right_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .gender_roi_score {
          .gender_roi_score_chart {
            width: 100%;
          }
        }
      }

      .take_survey_container {
        .take_survey_inner {
          h2 {
            display: flex;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              object-fit: contain;
            }
          }

          width: 50%;
          padding: 5px;

          ul {
            margin-top: 20px;

            li {
              display: flex;
              font-size: 13px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: left;
              margin-left: 0;
              padding-bottom: 10px;

              .edit_button {
                width: 16px;
                height: 16px;
              }

              span {
                width: 70%;
              }

              img {
                height: 20px;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .grey_border {
        display: inline-block;
        border-bottom: 2px solid #d0d0d0;
        width: 100%;
        height: 2px;
        margin: 35px 0;
      }

      .progress_bar_container {
        h2 {
          margin: auto 20px auto 0;
          width: 40%;
        }

        .span_text {
          padding-left: 68px;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
          // top: 15px;
          position: relative;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
    }

    .spiderchart_main_container {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;

      .spiderchart_content {
        .left_side {
          width: 27%;
        }

        .right_side {
          width: 100%;

          .spiderchartContent {
            // padding: 0 20px;
            // width: 85%;

            .chartjs-render-monitor {
              margin-left: -33px;
              // height: 530px !important;
            }

            .resilince-icon {
              max-width: 200px;
              left: 200px;
              top: 50px;
            }

            .opportunity-icon {
              max-width: 200px;
              top: 50px;
              right: 200px;
            }

            .inclusion-icon {
              max-width: 200px;
              right: 47%;
              bottom: -50px;
            }

            .spiderchartContent {
              width: 60%;
            }

            .Ind_Inv_Icon {
              // z-index: 9999;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              cursor: pointer !important;

              img {
                // width: 30px !important;
                // height: 30px;
                object-fit: contain;
                margin-right: 0 !important;
                margin-top: 30px !important;
              }

              span {
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                margin-top: 6px;
                // margin-left: 20px !important;
              }
            }

            .Indirect_Investment {
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .domain_assessment_results_section {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px 40px 80px 40px;
      margin-bottom: 40px;

      .spiderchartContent {
        // padding: 0 20px;
        width: 100%;

        .chartjs-render-monitor {
          margin-left: -33px;
          // height: 530px !important;
        }

        .workforce-icon {
          max-width: 200px;
          left: 50px;
          top: 39%;
        }

        .value-chain-icon {
          max-width: 200px;
          right: 43%;
          bottom: -16px;
        }

        .leadership-icon {
          max-width: 200px;
          top: -11%;
          right: 43%;
        }

        .society-icon {
          max-width: 200px;
          top: 39%;
          right: 50px;
        }

        .spiderchartContent {
          width: 60%;
        }

        .Ind_Inv_Icon {
          // z-index: 9999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          cursor: pointer !important;

          img {
            // width: 30px !important;
            // height: 30px;
            object-fit: contain;
            margin-right: 0 !important;
            margin-top: 30px !important;
          }

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            margin-top: 6px;
            // margin-left: 20px !important;
          }
        }

        .Indirect_Investment {
          top: 0;
          left: 0;
        }
      }

      .domain_assessment_results_container {
        // color: black;

        .gender_roi_domain_taabs_container {
          img {
            width: 35px;
          }
        }

        .spiderchart_container {
          width: 60%;
        }
      }
    }

    .parameters_block {
      background: #ffff;
      padding: 40px;

      .parameters_section_first {
        padding: 0 10px 60px 10px;
      }

      .parameters_section {
        padding: 40px 10px 60px 10px;
        border-top: 1px solid #d0d0d0;
      }

      .inner_parameter_block {
        .score_results_section {
          .parameter_percent {
            h3 {
              margin: auto 0;
              padding: 0 20px;
              position: relative;
              // bottom: 11px;
            }

            .right_side_flex {
              margin: auto 0 auto auto;

              .dot_with_text {
                padding: 0 15px;
              }
            }
          }
        }

        .progress_bar_section {
          padding: 2rem 0;

          .left_side_progress_bar {
            width: 50%;
          }

          .right_side_progress_bar {
            width: 50%;
          }

          .roi-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }

            .roi-progress-content--left {
              color: #1b1b1b;
            }

            .roi-progress-content--right {
              width: 60%;
              line-height: 1;

              .bar-div {
                span {
                  color: #646363;
                  width: 50px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 320px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
        // top: 15px;
        position: relative;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }

  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);

        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

.blue-border-button {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }
</style>