<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <div v-if="aggregatedView">
      <div class="flex flex-col gap-2">
        <div>
          <span class="text-2xl font-bold">{{ climateRating || 30 }}</span
          >/50
        </div>
        <span class="text-sm font-semibold text-gray-500">Climate Rating</span>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ scope1Emissions || 0 }}</span
        >kg CO2e
      </div>
      <span class="text-sm font-semibold text-gray-500">Scope 1 Emissions</span>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ scope2Emissions || 0 }}</span
        >kg CO2e
      </div>
      <span class="text-sm font-semibold text-gray-500">Scope 2 Emissions</span>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ scope3Emissions || 0 }}</span
        >kg CO2e
      </div>
      <span class="text-sm font-semibold text-gray-500">Scope 3 Emissions</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClimateModuleCardContent",
  props: {
    scope1Emissions: Number, // Source: CLIMATE_Metrics and Targets_9
    scope2Emissions: Number, // Source: CLIMATE_Metrics and Targets_10
    scope3Emissions: Number, // Source: CLIMATE_Metrics and Targets_14
    climateRating: Number, // Source: CLIMATE_Climate Rating_300
    aggregatedView: Boolean,
  },
  mounted() {
    // TODO: Add logic to calculate impact rating and energy emission
  },
};
</script>
