<template>
  <div class="pt-[7em] flex flex-col items-center">
    <div class="h-[85%]">
      <div class="flex justify-center items-center gap-4">
        <h1 class="text-3xl font-bold">Your Impact Compass settings</h1>
        <p class="text-md">{{ step }}/4</p>
      </div>
      <div class="mt-10 w-[600px] flex flex-col items-center justify-center">
        <div
          v-if="step === 0"
          class="flex flex-col items-center justify-center"
        >
          <h2 class="text-[#2c2e35] text-2xl font-bold mb-4 text-center">
            What do you intend to use the Impact Compass platform for?
          </h2>
          <p class="text-[#2c2e35] text-lg text-center mt-5 mb-10">
            The Impact Compass platform helps you unlock impactful investments.
            To get started, please specify whether your initial focus will be on
            evaluating existing portfolios/funds or on assessing potential deals
            and pipeline companies. Note that the difference between the two
            options is that for due diligence and deal assessments, inviting a
            representative from potential portfolio companies is optional,
            though available. Please be assured that you can adjust your
            preferences at any stage following the initial setup.
          </p>
          <div class="flex gap-4 mt-10 text-lg">
            <button
              @click="selectPurpose('portfolio')"
              class="bg-green-500 text-white py-2 px-6 rounded-md font-bold"
            >
              Analyzing an existing portfolio/fund
            </button>
            <button
              @click="selectPurpose('deal')"
              class="bg-[#4a90e2] text-white py-2 px-6 rounded-md font-bold"
            >
              Conducting due diligence and deal assessment
            </button>
          </div>
        </div>
        <component
          v-else
          :is="'Step' + step"
          :fieldValue="currentStepData.fieldValue"
          :portfolioName="portfolioName"
          @input-typed="handleInput"
          :purpose="purpose"
          :previousStep="previousStep"
        />
      </div>
    </div>
    <!-- NAVIGATION -->
    <div
      class="flex flex-col items-center justify-center w-full h-[120px] pt-2 border-t border-[#e1e3e9]"
      v-if="step !== 0 && purpose !== 'deal'"
    >
      <div v-if="step !== 4" class="flex flex-col items-center justify-center">
        <p class="text-lg mb-4 text-center">
          You can change your preferences at any time
        </p>
        <div class="flex gap-4">
          <button
            @click="previousStep()"
            class="bg-[#191b45] text-white py-2 px-6 rounded-md font-semibold"
          >
            Back
          </button>
          <button
            :class="{
              'bg-[#e1e3e9] cursor-not-allowed':
                !isNextButtonActive || !portfolioName,
            }"
            class="bg-[#191b45] text-white py-2 px-6 rounded-md font-semibold"
            @click="nextStep()"
            :disabled="!isNextButtonActive || !portfolioName"
          >
            Next Step
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center" v-if="step === 4">
        <p class="text-center text-lg">
          Are you ready to check out your portfolio company's aggregated
          assessment results in your customized dashboard?
        </p>
        <div class="flex gap-4 mt-4">
          <button
            @click="previousStep()"
            class="bg-[#191b45] text-white py-2 px-6 rounded-md font-semibold"
          >
            Back
          </button>
          <button
            @click="saveIMMPreferences"
            class="bg-green-500 text-white py-2 px-6 rounded-md font-semibold"
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Step1 from "@/components/imm-components/preferences-steps/step1.vue";
import Step2 from "@/components/imm-components/preferences-steps/step2.vue";
import Step3 from "@/components/imm-components/preferences-steps/step3.vue";
import Step4 from "@/components/imm-components/preferences-steps/step4.vue";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import { useUserStore } from "@/stores/user";

export default {
  name: "SetUpPreferences",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  data() {
    return {
      step: 0,
      isNextButtonActive: false,
      activeStep: "Step1",
      user: null,
      portfolioName: null,
      preferencesArray: [],
      selectedLevel: null,
      fieldValues: [
        { sectionName: "step 1", fieldValue: "" },
        { sectionName: "step 2", fieldValue: "" },
        { sectionName: "step 3", fieldValue: "" },
        { sectionName: "step 4", fieldValue: "", portfolioName: "" },
      ],
      purpose: null,
    };
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  computed: {
    currentStepData() {
      const stepData = this.fieldValues.find(
        (data) => data.sectionName === `step ${this.step}`
      );
      if (this.step === 4) {
        stepData.portfolioName = this.portfolioName;
      }
      return stepData;
    },
  },
  methods: {
    selectPurpose(purpose) {
      this.purpose = purpose;
      this.step = 1;
      if (purpose === "portfolio") {
        this.isNextButtonActive = true;
      }
    },
    previousStep() {
      this.step--;
      this.isNextButtonActive = true;
    },
    nextStep() {
      if (!this.portfolioName) {
        return;
      }
      if (this.purpose === "deal" && this.step === 3) {
        this.step = 4;
      } else {
        this.step++;
      }
      this.isNextButtonActive = false;
    },
    handleInput(value) {
      this.currentStepData.fieldValue = value;
      switch (this.step) {
        case 1:
          this.portfolioName = value.portfolioName;
          break;
        case 2:
          this.preferencesArray = value.preferencesArray.map((pref) => {
            const existingPref = this.preferencesArray.find(
              (item) => item.title === pref.title
            );
            return existingPref ? { ...existingPref, ...pref } : pref;
          });
          break;
        case 3:
          this.selectedLevel = value.selectedLevel;
          break;
      }

      this.isNextButtonActive = value.isStepActive;
    },
    async saveIMMPreferences() {
      const immInvestorPreference = {
        portfolioName: this.portfolioName,
        preferences: this.preferencesArray,
        level: this.selectedLevel,
      };

      const { status } = await axios.post(
        `${env.apiUrl}/imm-preferences`,
        immInvestorPreference,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      if (status === 200) {
        this.$router.push("/impact-compass/landing-page");
      }
    },
  },
  async mounted() {
    this.user = this.userStore.user;
    if (!this.user) {
      this.user = await this.userStore.fetchUser(false, true);
    }
    if (!this.user.investor) {
      this.$router.push("/impact-compass/landing-page");
    }
  },
};
</script>
