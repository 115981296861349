<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img src="@/assets/images/roi/sweef-large.png" alt="background-image" class="object-contain no-print"
        style="max-width: 200px; margin-top: 72px; margin-left: 285px" />
      <img src="@/assets/images/logo-navy.png" alt="background-image" class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px" />
    </div>
    <div style="height: 1rem"></div>
    <transition name="fadeIn" mode="out-in">
      <template>
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div id="loaded" v-if="endofcreated && roiData && roiData != ''" key="content">
          <main style="grid-template-columns: none !important">
            <div class="text-center">
              <h1 class="text-5xl font-semibold" style="width: 75%; margin: auto">
                {{ selectedSurvey.name }}
              </h1>
            </div>
            <section class="container mx-auto px-4 lg:px-0" style="margin-bottom: 2rem">
              <div class="shadow overflow-hidden rounded-lg my-1 mt-2 flex flex-col mx-auto"
                :style="'border-style: solid;width: 80%; border-width: 2px; border-color:' + '#201747'">
                <div class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer" :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' +
                  '#201747'
                  ">
                  <h3 @click="togglePanel('country-overview')" class="text-medium leading-6 font-medium"
                    :style="'margin:auto 0;color:' + '#201747'" style="width: 360px;">
                    About the Gender ROI™ Assessment
                  </h3>
                  <div :style="'margin-left: auto; cursor:pointer; color:'">
                    <span v-if="openPanels.indexOf('country-overview') > -1" @click="togglePanel('country-overview')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-down no-print">
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                    <span v-else @click="togglePanel('country-overview')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-right">
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </span>
                  </div>
                </div>
                <transition name="slide-fade">
                  <div v-if="openPanels.indexOf('country-overview') > -1" class="flex flex-col px-5 py-5">
                    <div class="text-gray-600 w-full">
                      <div class="p-2 mt-2">
                        <!-- <img src="@/assets/images/2X-assessment/Integrated_2X_Assess_Ecosystem_1.png"
                    style="border-radius: 20px" /> -->
                        <div class="
                          mt-3
                          text-center
                          sm:mt-0 sm:ml-4 sm:text-left
                          learn_more_modal_text_container
                        ">
                          <div class="flex">
                            <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-headline"
                              style="width: 360px;">
                              About the Gender ROI™ Assessment
                            </h3>
                          </div>
                          <div class="mt-5 overflow-y-auto learn_more_modal_text" id="style-1">
                            <p>
                              The Sweef Capital Gender ROI™️ (Gender ROI™️) is a
                              diagnostic measurement and management tool that is
                              designed to support entrepreneurs, investment and
                              development finance practitioners to assess the
                              gender equality and diversity performance of
                              companies, informing both practice and performance
                              in addressing inequalities, while highlighting
                              significant opportunities to improve outcomes for
                              women and girls.
                            </p>
                            <br />
                            <p>
                              The tool comprises of 24 quantitative and
                              qualitative indicators applied across the
                              intersectionality of the enterprise dimensions of
                              Leadership, Workforce, Value Chains, as well as
                              through Society and Outcome Pillars of Resilience,
                              Opportunity, and Inclusion. The assessment
                              identifies gaps, determining appropriate actions and
                              interventions applied at the enterprise level which
                              are focused on delivering outcomes that support
                              women’s economic empowerment.
                            </p>
                            <br />
                            <p>
                              By applying different levers of change across the
                              enterprise dimensions targeting women’s economic and
                              social roles, contributions, and constraints and
                              vulnerabilities, women’s economic empowerment is
                              demonstrated through 3 Outcome Pillars - Resilience,
                              Opportunities and Inclusion. These Outcome Pillars
                              are goalposts for women’s economic empowerment which
                              involve addressing the need for women’s
                              participation in gainful employment and to be in a
                              position of influence and decision making, as well
                              as ensuring equal access to opportunities for
                              women’s participation and advancement, and
                              safeguarding women’s well-being through safety nets
                              and protection, thereby addressing gender norms and
                              biases. These are also the key tenets in building
                              strong and resilient companies to meet the
                              challenges the dynamic business environment of
                              today. Each stakeholder has a role to play; they are
                              mutually beneficial and should be seen as a
                              collective engine of sustainable and inclusive
                              development.
                            </p>
                            <br />
                            <p>
                              To calculate Gender ROI™ scores across these 24
                              indicators, there are 16 discrete survey modules
                              that ask questions across a range of topics. These
                              surveys may be taken all at once, or over a discrete
                              period of time, filled by one or more people as
                              appropriate.
                            </p>
                            <br />
                            <p>
                              This tool is digitized via the Equilo platform, with
                              the following benefits for users coming soon (data
                              will be collected now, with the automated benefits
                              below in beta testing and coming soon!): * Eases
                              data collection and reporting, with multiple survey
                              modules that can be completed by multiple people
                              within an organization at any time.
                            </p>
                            <ul style="list-style-type: disc">
                              <li>
                                Eases data collection and reporting, with multiple
                                survey modules that can be completed by multiple
                                people within an organization at any time.
                              </li>
                              <li>
                                Automatically analyzes the reported data,
                                displaying results instantly on a personalized
                                dashboard. Users need to have an account created
                                at app.equilo.io to access their results.
                              </li>
                              <li>
                                Easily share dashboard and summary results or
                                downloadable .CSV files with others.
                              </li>
                              <li>
                                View organizational results alongside a contextual
                                market-level analysis to better understand,
                                contextualize, and address gaps.
                              </li>
                              <li>
                                Data can be automatically aggregated for investors
                                and financial actors at portfolio levels when
                                enterprises complete this assessment across the
                                portfolio.
                              </li>
                              <li>
                                Receive intelligently automated action plan based
                                on identified gaps.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <!-- <img src="@/assets/images/2X-assessment/Integrated_2X_Assess_Ecosystem_2.png"
                    style="border-radius: 20px" /> -->
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </section>
            <div style="width: 100%; text-align: center" class="mb-5">
              <section class="
                w-full
                container
                mx-auto
                px-4
                lg:px-0
                border-t border-b border-gray-300
                mb-5
              ">
                <Survey :survey-data="surveyData" :survey-json="surveyJson" :user="user" :surveyType="surveyType"
                  :survey-id="$route.params.surveyName" :answer-again="answerAgain"
                  :id="uuid === roiUserSurveyData.filter(data => data.surveyId === $route.params.surveyName)?.[0]?.uuid ? userSurveyDataId : null"
                  :isComplete="isComplete" :userType="$route.params.tag == 'base' ? null : $route.params.tag"
                  :skipFlags="['roi']" :uuid="uuid" @surveyComplete="surveyComplete"
                  @currentProgressSaved="currentProgressSaved" @survyeEdited="survyeEdited"
                  :COM_Commitment="COM_Commitment" :entityTypeForOldUsers="entityTypeForOldUsers"
                  :financialQuarter="roiData?.scoreData ? roiData.scoreData[0]?.financialReportingYear : null"
                  :key="updateKey" />
              </section>
              <div class="flex flex-row justify-end pb-5">
                <button
                  class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center float-right"
                  @click="saveCurrentProgress" :disabled="isCurrentButtonActive < 1" :class="{
                    'opacity-50 cursor-not-allowed': isCurrentButtonActive < 1,
                  }">
                  Save current progress
                </button>
              </div>
            </div>
          </main>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import env from "@/../env";
import axios from "axios";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import Survey from "@/components/surveys/survey.vue";
import apiService from '@/helpers/apiServices.js';
export default {
  name: "roiSurvey",
  data: function () {
    return {
      user: [],
      endofcreated: false,
      modules: [],
      selectedSurvey: [],
      openPanels: [],
      roiData: [],
      surveyData: null,
      surveyJson: {},
      answerAgain: false,
      userSurveyDataId: null,
      isComplete: false,
      current_module: 0,
      roiUserSurveyData: [],
      surveyType: { ggsf: false, roi: false },
      uuid: '',
      isCurrentButtonActive: "",
      COM_Commitment: [],
      thresholds: [],
      thresholdsData: [],
      workforceThreshold: "",
      srManagerThreshold: "",
      boardThreshold: "",
      entityTypeForOldUsers: "",
      updateKey: 0,
    };
  },
  components: {
    pageLoader,
    Survey,
  },
  methods: {
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
        });
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.roiData.ISO[0] &&
        this?.roiData?.sector
      ) {
        await this.getThresholdsData(
          this?.roiData?.ISO[0],
          this?.roiData?.sector
        );
      }
      this.industrySearchPlaceholder =
        this?.roiData?.sector;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.roiData.countryHqText) &&
          item.indicator == "Employees" &&
          (item.industry == this?.roiData?.sector ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.roiData.countryHqText) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this?.roiData?.sector ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.roiData.countryHqText) &&
          item.indicator == "Board Member" &&
          (item.industry == this?.roiData?.sector ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
            (item) =>
              item.industry == this?.roiData?.sector
          )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
            (item) =>
              item.industry == this?.roiData?.sector
          )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
            (item) =>
              item.industry == this?.roiData?.sector
          )?.[0]?.threshold;
      this.COM_Commitment = [
        this.workforceThreshold?.split('%')?.[0],
        this.srManagerThreshold?.split('%')?.[0],
        this.boardThreshold?.split('%')?.[0],
      ];
    },
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
    survyeEdited(val) {
      this.isCurrentButtonActive = val;
    },
    async getRoiUserSurvey() {
      try {
        const response = await apiService.fetchRoiUserSurvey('roi');
        this.roiUserSurveyData = response.data.data;

        if (!this.roiUserSurveyData || this.roiUserSurveyData.length === 0 || this.$route?.query?.repeat === 'true') {
          this.uuid = Date.now();
        } else {
          const roiOneData = this.roiUserSurveyData.find(data => data.surveyId === 'roi-one');
          this.uuid = roiOneData?.uuid || Date.now();
        }

        await this.checkAlreadySurveyData();
      } catch (error) {
        console.error('Error fetching data:', error);
        this.endofcreated = true;
      }
    },
    async checkAlreadySurveyData() {
      if (this.roiUserSurveyData) {
        this.surveyId = this.modules[this.current_module]?.surveyName;
        this.surveyData = this.roiUserSurveyData.find(
          (survey) => survey.surveyId === this.surveyId
        );
      }
      // if survey has been answered already, mark isComplete as true
      if (this.surveyData) {
        this.userSurveyDataId = this.surveyData?._id;
        this.isComplete = this.surveyData?.isComplete;

        // transform surveyData from array to object with key values
        let surveyDataObject = {};
        this.surveyData.surveyResponse.forEach((survey) => {
          surveyDataObject[survey.questionId] = survey.answer;
        });
        this.surveyData = surveyDataObject;
      }
      this.endofcreated = true;
    },
    surveyComplete() {
      this.isCurrentButtonActive = false;
      this.$router.push({ path: "/roi" });
    },
    async currentProgressSaved() {
      await this.getRoiUserSurvey();
      await this.updateKey++;
    },
    async fetchSurveyJson() {
      const {
        data: { data },
        status,
      } = await axios.get(
        `${env.apiUrl}/surveys/${this.$route.params.surveyName}?tag=${this.$route.params.tag === 'base' ? null : this.$route.params.tag}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      if (status == 200) {
        this.surveyJson = data.json;
        if (this.$route?.query?.repeat === 'true' && this.$route?.params?.surveyName == 'roi-one') {
          this.surveyJson?.pages.forEach(page => {
            page.elements.forEach(element => {
              if (element.name == "General3") {
                element.readOnly = true;
                if ((!this?.roiUserSurveyData || this?.roiUserSurveyData == [] || this?.roiUserSurveyData?.length == 0) && this.$route?.query?.repeat === 'true') {
                  this.entityTypeForOldUsers = this?.roiData?.organizationType; // assign entity type for old roi users
                }
              }
            });
          })
        }
      }
    },
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    getRoiData() {
      return axios
        .get(env.apiUrl + "/roi/flags", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.roiData = res.data.data;
        });
    },
  },
  computed: {
    checkFundorCompanies() {
      const typeFlag = this.roiData.organizationType == "On-lending facility" ? "Financial Institution" : this.roiData.organizationType
      return typeFlag;
    },
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.fetchSurveyJson();
    await this.getRoiData();
    if (this?.roiData && this?.roiData?.sector) {
      await this.calculateThreshold();
    }
    if (this.$route.path.includes('/roi')) {
      this.surveyType = { ggsf: false, roi: true };
    }
    this.modules = [
      {
        name: "Enterprise Intake Information",
        module: "1",
        surveyName: "roi-one",
        var: "certifiedThatAllResponsesTrueAndAccurate",
      },
      {
        name: "Financials",
        module: "2",
        surveyName: "roi-two",
        var: "modTwoComplete",
      },

      {
        name: "Employment, Leadership, and Pay Equity",
        module: "3",
        surveyName: "roi-three",
        var: "modThreeComplete",
      },
      {
        name: "Diversity in Recruitment",
        module: "4",
        surveyName: "roi-four",
        var: "modFourComplete",
      },

      {
        name: "Performance, Evaluation, and Progression",
        module: "5",
        surveyName: "roi-five",
        var: "modFiveComplete",
      },
      {
        name: "Professional Development, Networking, and Mentoring",
        module: "6",
        surveyName: "roi-six",
        var: "modSixComplete",
      },

      {
        name: "Gender Lens in Policies and Strategies",
        module: "7",
        surveyName: "roi-seven",
        var: "modSevenComplete",
      },
      {
        name: "Women Workforce Support Policies",
        module: "8",
        surveyName: "roi-eight",
        var: "modEightComplete",
      },
      {
        name: "Survivor-Centered Prevention and Response to GBVH",
        module: "9",
        surveyName: "roi-nine",
        var: "modNineComplete",
      },
      {
        name: "Inclusive Health Insurance Policies",
        module: "10",
        surveyName: "roi-ten",
        var: "modTenComplete",
      },
      {
        name: "Fair Treatment of Part-time or Temporary Workers",
        module: "11",
        surveyName: "roi-eleven",
        var: "modElevenComplete",
      },

      {
        name: "Gender-Responsive Procurement and Gender-disaggregated Data in Supply Chains",
        module: "12",
        surveyName: "roi-twelve",
        var: "modTwelveComplete",
      },
      {
        name: "Gendered Approach to Value Chains and Gender-Disaggregated Value Chain Data",
        module: "13",
        surveyName: "roi-thirteen",
        var: "modThirteenComplete",
      },

      {
        name: "Gender Equality Beyond the Work Place",
        module: "14",
        surveyName: "roi-fourteen",
        var: "modFourteenComplete",
      },
      {
        name: "Climate Risks",
        module: "15",
        surveyName: "roi-fifteen",
        var: "modFifteenComplete",
      },
      {
        name: "Community Engagement through CSR",
        module: "16",
        surveyName: "roi-sixteen",
        var: "modSixteenComplete",
      },
      {
        name: "Financial Products, Services, and Processes",
        module: "17",
        surveyName: "roi-seventeen",
        var: "modSeventeenComplete",
      },
    ]
    this.modules.forEach((data) => {
      if (data.surveyName == this.$route?.params?.surveyName) {
        this.current_module = parseInt(data.module) - 1;
      }
    });
    let moduleFlag = this.modules.filter(
      (data) => data.surveyName == this.$route.params.surveyName
    )[0].var;
    if(!this.$route?.query?.repeat) {
      if (moduleFlag == "certifiedThatAllResponsesTrueAndAccurate") {
        if (this.roiData[moduleFlag] == "Yes") {
          this.$router.push({ name: "notAllowed" });
        }
      } else {
        if (this.roiData[moduleFlag] || !this.roiData.editAccess) {
          this.$router.push({ name: "notAllowed" });
        }
      }
    }
    await this.getRoiUserSurvey();
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Gender ROI™",
        url: "/roi",
      },
      {
        name: "Survey",
        url: this.$route.params.surveyId ? "/roi/survey/" + this.$route.params.surveyId + '/' + this.$route.params.tag + '/' + this.$route.params.surveyName : "/roi",
      },
    ]);
  },
};
</script>
<style>
.next-button {
  font-weight: 700;
  font-size: 16px;
  color: white;
  background: #191b45;
}
</style>