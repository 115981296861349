<template>
  <div class="fixed z-50 inset-0 overflow-y-auto" id="dealsPrivacy">
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div class="fixed inset-0 transition-opacity cursor-pointer">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle
        "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style="max-width: 630px; width: 100%"
      >
        <div class="Modal_Readmorestyle">
          <div class="ModalInnerStyle">
            <h1
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              You do not have permission to access this page
            </h1>
            <button
              style="background-color: #fe5000; color: white !important"
              class="
                text-white
                px-3
                py-2
                text-xs
                gap-1
                font-bold
                rounded-lg
                text-white
                transition
                duration-200
                shadow
                flex flex-row
                items-center
                justify-center
                mr-4
                ml-4
              "
              @click="redirection()"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoXEcosystemRedirectPopup",
  data() {
    return {};
  },
  methods: {
    redirection() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}
span {
  cursor: pointer;
}
input {
  margin: 6px;
}
.Modal_Readmorestyle {
  padding: 30px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    margin: auto;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: center;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: 0px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
</style>