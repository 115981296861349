<template>
  <div class="my-[1rem]" :key="'updateListComponents' + updateKey">
    <div
      class="flex items-center gap-3 justify-end mb-2"
      v-if="$route.query.fromVerifier && info.surveyQType != 'Calculated'"
    >
      <img
        src="@/assets/images/edit-dark.svg"
        alt="edit-icon"
        class="h-6 w-6 cursor-pointer"
        @click="$emit('openEditModalFromCustomComponent', info)"
      />
      <VerificationDropdown :value="info" />
    </div>
    <h3 v-if="!clustered" class="font-bold max-w-[32rem]">
      {{ title }}
    </h3>
    <div class="flex" v-if="!noInfo">
      <div v-if="answer">
        {{ answer }}
      </div>
      <div v-else-if="answers.length">
        <ul class="list-disc ml-[2rem]" v-if="answers?.[0] == 'none' && title.includes('Requirements Willing to Commit to')">
          <li>
            <span>No commitments are made</span>
          </li>
        </ul>
        <ul class="list-disc ml-[2rem]" v-else>
          <li class="my-[.5rem]" v-for="ans in answers" :key="ans">
            {{ ans }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import VerificationDropdown from "../my2xCertificationFullAssessmentResult/verificationDropdown.vue";
export default {
  name: "list",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    clustered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "",
      answer: "",
      answers: [],
      noInfo: false,
      updateKey: 0,
    };
  },
  components: { VerificationDropdown },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.info && this.info.surveyQuestion) {
        this.title = this.info.title;
      }
      if (typeof this.info.value === "string") {
        this.answer = this.info.value;
      } else if (Array.isArray(this.info.value)) {
        this.answers = this.info.value;
      } else {
        this.noInfo = true;
      }
    },
  },
};
</script>
