<template>
  <div>
    <UnderConstructionBanner :is-visible="true" />
    <!--Menu-->
    <div class="mt-8">
      <div class="flex justify-between">
        <div class="flex gap-8 h-10">
          <div class="flex bg-[#f4f6f9] rounded-lg cursor-pointer">
            <div
              :class="[
                'px-3 py-[6px] text-sm font-arial',
                {
                  'bg-[#2177c1] rounded-lg text-white font-bold':
                    selectedFrequency === 'All',
                },
              ]"
              @click="selectedFrequency = 'All'"
            >
              All
            </div>
            <div
              :class="[
                'px-3 py-[6px] text-sm font-arial   ',
                {
                  'bg-[#2177c1] rounded-lg text-white font-bold':
                    selectedFrequency === 'Quarterly',
                },
              ]"
              @click="selectedFrequency = 'Quarterly'"
            >
              Quarters
            </div>
            <div
              :class="[
                'px-3 py-[6px] text-sm font-arial ',
                {
                  'bg-[#2177c1] rounded-lg text-white font-bold':
                    selectedFrequency === 'Annually',
                },
              ]"
              @click="selectedFrequency = 'Annually'"
            >
              Annual
            </div>
          </div>
          <div class="flex items-center">
            <select
              name="year"
              id="year"
              class="px-3 py-[6px] bg-[#f4f6f9] rounded-lg text-sm font-arial cursor-pointer focus:outline-none w-20"
            >
              <option value="2023">2024</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- header section -->
    <div
      class="bg-gray-100 rounded-xl overflow-hidden p-4 flex justify-between mt-4"
    >
      <div class="flex flex-col gap-2">
        <h2>{{ portfolioName }}</h2>
        <p class="target-goal">Portfolio description</p>
      </div>
      <div>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Performance
        </button>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Edit preferences
        </button>
      </div>
    </div>
    <!-- Modules -->
    <div class="grid grid-cols-2 gap-6 mt-10">
      <ModuleCard
        v-for="(card, index) in modules"
        :key="index"
        v-bind="card"
        :frequency="card.frequency"
        :title="card.title"
        :description="card.description"
        :aggregatedView="true"
        :aggregatedUrl="card.aggregatedUrl"
        :status2xCertification="status2xCertification"
      />
    </div>
  </div>
</template>

<script>
import ModuleCard from "../ModuleCard.vue";
import { moduleDescriptions, getAggregatedUrl } from "../../IMMhelpers";
import UnderConstructionBanner from "@/components/imm-components/UnderConstructionBanner.vue";

export default {
  name: "AggregatedPortfolio",
  components: { ModuleCard, UnderConstructionBanner },
  props: {
    portfolioName: {
      type: String,
      required: true,
    },
    investorPreferences: {
      type: Array,
      required: true,
    },
    status2xCertification: {
      type: String || null,
    },
  },
  data() {
    return {
      selectedFrequency: "All",
      selectedPortfolio: null,
      selectedYear: "2024",
      modules: [],
    };
  },
  async mounted() {
    this.modules = this.investorPreferences[0]?.preferences?.map((module) => {
      return {
        ...module,
        description: moduleDescriptions[module.title],
        aggregatedUrl: getAggregatedUrl(module.title, this.portfolioName),
      };
    });
  },
};
</script>
