<template>
  <div :key="$route.query.key" class="static ecosystem-landing">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="container mx-auto -mt-20 px-4 lg:px-0" v-if="endofcreated">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyContentWrepper">
            <img
              src="@/assets/images/2XG-Logo-2023.png"
              alt="background-image"
              style="height: 50px"
            />
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div class="Poweredby_logoImg mt-2">
              <div class="powered-text flex">
                <span>Powered by:</span>
              </div>
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
        </div>
        <div class="PoweredbyTitle_logo bg-image" style="padding: 0">
          <div class="PoweredbyContentWrepper bg-text-style">
            <div class="PoweredbyTitleWrepper mt-5">
              <h1
                class="text-6xl tracking-tighter font-medium print:text-7xl"
                style="max-width: 500px; color: #fff"
              >
                <span>
                  {{
                    this.$route.path.includes(
                      "2X-Certification-Self-Assessment"
                    )
                      ? "Welcome to the 2X Certification Self-Assessment"
                      : "Welcome to the 2X Challenge Self-Assessment"
                  }}
                </span>
              </h1>
            </div>
            <div
              class="flex flex-col mt-10 mx-auto items-center justify-center"
              style="max-width: 845px"
            >
              <p
                class="text-lg leading-7 font-medium gap-10 text-justify leading-7"
                style="color: #fff"
              >
                {{
                  this.$route.path.includes("2X-Certification-Self-Assessment")
                    ? "The 2X Certification Self-Assessment evaluates an entity's actions across a comprehensive set of Dimensions and Themes that build on the 2X Criteria and move beyond. It provides a comprehensive assessment of whether the requirements of 2X Certification are met and furthermore indicates which level of Good, Advanced, Best in Class the entity aligns with."
                    : "The 2X Challenge Self-Assessment is the starting point for the 2X Challenge and is an assessment indicating whether an entity aligns with the 2X Criteria"
                }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="isAssesmentModal" class="fixed z-10 inset-0 overflow-y-auto">
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 -mt-3 sm:align-middle sm:w-full max-w-2xl h-full max-h-[95vh] sm:p-4"
            >
              <div class="ModalInnerStyle">
                <div class="text-base">
                  <!-- You are now proceeding to the 2X Certification Self-Assessment to determine if you are Good, Advanced, or Best-in-class. Your survey modules will be unlocked allowing you to review and edit your previously submitted data. You'll also have the opportunity to respond to additional questions, delving even deeper into the 2X dimensions and themes. -->
                  You are now proceeding to the 2X Certification
                  Self-Assessment. If you have previously completed the 2X
                  Challenge Self-Assessment, your results will be pre-populated
                  and you will unlock further questions that make up the 2X
                  Certification Self-Assessment.
                </div>
                <button
                  @click="closeAssesModal()"
                  class="flex justify-center m-auto mt-4 bg-[#A21942] px-4 py-2 rounded-lg text-white font-semibold"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Selection Box Wrepper -->

        <div class="ContentWrapper w-12/12">
          <!-- CERTIFICATION PROCESS -->
          <div>
            <h1
              class="text-4xl tracking-tighter font-medium print:text-7xl mt-12 mb-4"
              style="max-width: 520px"
            >
              <span>
                {{
                  this.$route.path.includes("2X-Certification-Self-Assessment")
                    ? "Begin the 2X Certification Self-Assessment"
                    : "Begin the 2X Challenge Self-Assessment"
                }}
              </span>
            </h1>
            <div class="flex gap-2">
              <div class="flex flex-col mt-10 w-2/4">
                <div
                  class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-3/4"
                >
                  <span
                    v-if="
                      this.$route.path.includes(
                        '2X-Certification-Self-Assessment'
                      )
                    "
                  >
                    Start your journey with 2X Certification by completing the
                    2X Certification Self-Assessment. Simply take the
                    exploratory first steps to self-assess whether you meet the
                    minimum requirements of 2X Certification and at which level-
                    Good, Advanced, or Best-in-Class? You can view your
                    indicative results and then choose to move along to
                    third-party verification and request 2X Certification.
                    You'll also have a choice to Level-Up before third-party
                    verification and Certification with optional automated
                    insights to help you take actions to move up to meet minimum
                    requirements or advance to higher Certification Level.
                  </span>
                  <span v-else style="cursor: default; color: #21191d">
                    Navigate the 2X Challenge Self-Assessment modules organised
                    by each of the 2X Criteria to complete this assessment.
                    Meeting the 2X Self-Assessment is used to qualify for the 2X
                    Challenge.
                  </span>
                  <br /><br />
                  <div class="flex flex-col">
                    <p
                      v-if="
                        this.$route.path.includes(
                          '2X-Certification-Self-Assessment'
                        )
                      "
                      class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium"
                      style="cursor: default; color: #21191d"
                    >
                      Are you interested in discovering where your entity stands
                      against global gender-smart industry benchmarks before
                      starting your 2X Certification Self-Assessment? Dive into
                      the 2X Criteria Thresholds tool to unveil which thresholds
                      your entity meets!
                    </p>
                    <p
                      v-else
                      class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium"
                      style="cursor: default; color: #21191d"
                    >
                      Are you interested in discovering where your entity stands
                      against global gender-smart industry benchmarks before
                      starting your self-assessment? Dive into the 2X Criteria
                      Thresholds tool to unveil which thresholds your entity
                      meets!
                    </p>
                    <router-link
                      to="/2X-Ecosystem/Thresholds"
                      class="flex gap-2 items-center text-xs font-bold cursor-pointer mt-5"
                      style="color: #2177c1"
                      @click="see2XThresholdCriteria()"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                          fill="#2177C1"
                        />
                      </svg>
                      <span class="flex items-center justify-center h-full">
                        2X Criteria Thresholds</span
                      >
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="progress-steps-section w-2/4">
                <div class="outer">
                  <div class="progress">
                    <div class="right">
                      <div
                        v-if="
                          $route.path.includes('/2X-Challenge') ||
                          ($store.state.certPageAccess.isMinSurveyFill &&
                            !$store.state.certPageAccess.isFullSurveyFill)
                        "
                        :class="{ current: currentStep == 1 }"
                      >
                        <span class="font-bold"
                          >2X Challenge Self-Assessment</span
                        >
                        <br />
                        <span class="font-normal"
                          >2X Criteria are a global baseline standard for gender
                          finance and public good used by investors, financial
                          intermediaries and companies as well as other
                          stakeholders worldwide to assess and monitor
                          gender-lens investments.
                        </span>
                      </div>
                      <div
                        v-if="
                          $route.path.includes('/2X-Challenge') ||
                          ($store.state.certPageAccess.isMinSurveyFill &&
                            !$store.state.certPageAccess.isFullSurveyFill)
                        "
                        :class="{
                          current: currentStep == 2,
                          'hide-line': !this.$route.path.includes(
                            '2X-Certification-Self-Assessment'
                          ),
                        }"
                      >
                        <span class="font-bold">View 2X Criteria Results</span>
                        <br />
                        <span class="font-normal"
                          >After taking the 2X Challenge Self-Assessment, view
                          your indicative results to see whether you align with
                          the 2X Criteria
                        </span>
                      </div>
                      <div :class="{ current: currentStep == 3 }">
                        <span class="font-bold flex"
                          >2X Certification Self-Assessment
                          <span
                            v-if="
                              !this.$route.path.includes(
                                '2X-Certification-Self-Assessment'
                              )
                            "
                            class="flex gap-2 items-center text-xs font-bold cursor-pointer ml-2"
                            style="color: #2177c1"
                            @click="navigateFullAssesSurvey()"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                                fill="#2177C1"
                              />
                            </svg> </span
                        ></span>
                        <span class="font-normal">
                          Complete the 2X Certification Self-Assessment to see
                          whether your entity meets the requirements of 2X
                          Certification and which Certification level you align
                          with. <span v-if="entityChosen && $route.path.includes('2X-Certification-Self-Assessment')">
                            You can save your work and return later to
                          complete, and invite team members
                          <router-link to="/settings/members" class="text-blue-600 font-normal rounded border-blue-400">here</router-link>
                          to work collaboratively.</span>
                        </span>
                        <br />
                        <button v-if="entityChosen && $route.path.includes('2X-Certification-Self-Assessment')"
                          class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          @click="scrollToTarget">
                          <span class="">Get started now</span>
                        </button>
                        <br />
                      </div>
                      <div
                        v-if="
                          this.$route.path.includes(
                            '2X-Certification-Self-Assessment'
                          )
                        "
                        :class="{ current: currentStep == 4 }"
                      >
                        <span class="font-bold"
                          >View Indicative Results</span
                        >
                        <br />
                        <span class="font-normal"
                          >Once you’ve completed the 2X Certification Self-Assessment, you can view your indicative results. You may choose to <b>Level-Up</b> and view insights to help you meet minimum requirements or advance to a higher Certification level. You may repeat your assessment before proceeding.</span
                        >
                      </div>
                      <div :class="{ current: currentStep == 5 }">
                        <span class="font-bold">Third-Party Verification</span>
                        <br />
                        <span class="font-normal"
                          >If you choose to proceed with 2X Certification, you will select through the platform a third-party verifier, which is an independent professional services firm that is not an affiliate of
                          the Entity or any related Entity. Following verification of the Self-Assessment, a verification report is submitted by the Third-Party Verifier to the 2X Certification Platform for review by the 2X Certification Body.
                        </span>
                      </div>
                      <div :class="{ current: currentStep == 6 }">
                        <span class="font-bold">Issuance of the 2X Certificate</span>
                        <br />
                        <span class="font-normal"
                          >A 2X Certificate is issued by the 2X Certification Body to an Entity upon satisfactory completion of these steps following verification. A 2X Certificate will include the 2X Certification Level achieved with the relevant 2X Certification Seal; a scorecard which will be publicly disclosed on the 2X, Global Certification List.and details of the Third-Party Verifier. A 2X Certificate is valid for 2 years, following which an Entity must recertify to maintain their 2X Certification.</span
                        >
                      </div>
                      <!-- <div
                        :class="{ current: currentStep == 7 }"
                        v-track-click="{ elementName: 'Level up' }"
                      >
                        <span class="font-bold">Act to Level Up</span>
                        <br />
                        <span class="font-normal"
                          >Gain access to strategies that can take your entity
                          to the next level</span
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="targetRefs" id="test">
            <div class="flex items-center relative justify-between gap-5">
              <div class="flex items-center gap-5">
                <h1
                  class="text-3xl tracking-tighter font-medium"
                >
                  {{
                    this.$route.path.includes("2X-Certification-Self-Assessment")
                      ? "2X Certification Self-Assessment Modules"
                      : "2X Challenge Self-Assessment Modules"
                  }}
                </h1>
                <div class="relative container w-auto">
                  <div
                    class="ml-4 cursor-pointer"
                    @click="isTextVisible = !isTextVisible"
                  >
                    <svg
                      class="svgIcon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#19486A" />
                      <path
                        d="M11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div
                    v-if="isTextVisible"
                    class="px-8 py-4 bg-[#1F1747] left-0 text-white max-w-4xl min-w-[40rem] rounded-lg font-medium absolute z-50 top-0 right-0 ml-12 -translate-y-1"
                  >
                    <ul>
                      <li>
                        <p
                          class="text-med leading-7 gap-10 text-justify font-medium mt-4"
                        >
                          Begin the
                          <span class="font-extrabold text-[#dda63a]"
                            >{{
                              this.$route.path.includes(
                                "2X-Certification-Self-Assessment"
                              )
                                ? "2X Certification Self-Assessment"
                                : "2X Challenge Self-Assessment"
                            }}
                          </span>
                          by starting with the General Intake module. Once you've
                          reviewed and are ready to submit your data, simply click
                          <span class="font-extrabold text-[#dda63a]"
                            >Complete</span
                          >. This action will unlock the Governance &
                          Accountability module, followed by all other modules for
                          you.
                        </p>
                      </li>
                      <!-- <li><p class="text-med leading-7 gap-10 text-justify font-medium  mt-2"> Proceed to work through the remaining survey modules, where you'll be required to review your initial submissions. Additionally, you'll be asked additional survey questions that go deeper into the 2X dimensions and themes.  </p></li> -->
                      <li>
                        <p
                          class="text-med leading-7 gap-10 text-justify font-medium mt-2"
                        >
                          After completing all modules, click
                          <span class="font-extrabold text-[#dda63a]"
                            >Submit Responses</span
                          >
                          to access your
                          {{
                            this.$route.path.includes(
                              "2X-Certification-Self-Assessment"
                            )
                              ? "2X Certification Self-Assessment"
                              : "2X Challenge Self-Assessment"
                          }}
                          Results.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="isTextVisible"
                    class="fixed inset-0 transition-opacity z-20 cursor-pointer"
                  >
                    <div
                      @click="isTextVisible = false"
                      class="absolute inset-0 bg-white opacity-20"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="">
                <a
                  :href="downloadQuesLink"
                  :download="downloadQuesFileName"
                  target="_blank"
                  class="text-xl tracking-tighter font-medium rounded-lg border-2 px-4 py-3 block text-center"
                  :disabled="!entityChosen.length"
                >
                  <span class="pr-1">Download questions in advance</span>
                </a>
              </div>
            </div>
            <div>
              <SurveyDashboard :user="user" @entityChosen="setEntityType" />
            </div>
          </div>
        </div>
      </div>
      <!-- Submit deal Modal -->
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isSubmitDealModal">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen cursor-pointer"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 624px"
          >
            <div
              class="close cursor-pointer"
              @click="isSubmitDealModal = false"
              style="right: 8px"
            >
              &times;
            </div>
            <div class="Modal_Readmorestyle p-6 pr-3">
              <div class="ModalInnerStyle">
                <div class="heading-section flex justify-center items-center">
                  <h1
                    class="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Submit a Deal
                  </h1>
                </div>
                <div class="mt-7">
                  Select this option to complete a form with information
                  required for an individual deal. Once complete, you will be
                  prompted to invite the investee to directly submit required 2X
                  Criteria utilizing the 2X Challenge Self-Assessment tool. Once
                  complete by investor and investee, the deal will be reviewed
                  and published to the system.
                </div>
                <button
                  class="cancel-button flex justify-center m-auto mt-4"
                  @click="$router.push({ path: '/submit-deal' })"
                >
                  Submit one deal for qualification
                </button>
                <div class="mt-5">
                  <div>
                    Select this option to download an Excel template with all
                    the required information to qualify deals, including both
                    deal information and 2X Criteria data regarding each
                    individual investee. Once complete, upload the file to the
                    system. It will be reviewed and published to the public
                    database.
                  </div>
                  <button
                    class="cancel-button flex justify-center m-auto mt-4"
                    @click="
                      (isDocumentsModal = true), (isSubmitDealModal = false)
                    "
                  >
                    Bulk upload multiple deals
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--what documents upload modal -->
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isDocumentsModal">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 660px; width: 100%"
          >
            <span
              @click="isDocumentsModal = false"
              class="close z-10"
              style="right: 16px; top: 5px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle what-modal" style="display: block">
              <div class="ModalInnerStyle pr-6">
                <h2
                  class="text-2xl mb-6"
                  style="font-weight: 600; text-align: left; line-height: 32px"
                >
                  Documents
                </h2>
                <div>
                  <p class="content text-base">
                    Please upload any documentation that provides evidence of
                    any of your responses, including all responses to which you
                    responded "Yes", affirmed, or attested to during the 2X
                    basic ESG screen. These documents will be used by 2X
                    Global's accredited certification partner to review and
                    verify your assessment results. The more complete your
                    documentation is, the quicker the verification and review
                    process will be. The accredited certification partner will
                    follow-up as needed.
                  </p>
                  <div
                    class="congrats-box-doc p-5 mb-5 text-center mt-5"
                    id="app"
                    @dragover="dragover"
                    @drop="drop"
                  >
                    <div
                      class="flex w-full items-center justify-center text-center"
                    >
                      <div>
                        <input
                          type="file"
                          multiple
                          name="fields[assetsFieldHandle][]"
                          id="assetsFieldHandle"
                          class="w-px h-px opacity-0 overflow-hidden absolute"
                          @change="onChange"
                          ref="file"
                        />
                        <label
                          for="assetsFieldHandle"
                          class="block cursor-pointer"
                        >
                          <div class="center-button">
                            <span
                              class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                            >
                              Choose files
                            </span>
                          </div>

                          <p class="center-span-modal">
                            Drag one or more files here
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex mt-10 justify-between">
                  <button
                    @click="isDocumentsModal = false"
                    class="modal-link-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    @click="closeDocumentsModal()"
                    class="modal-send-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Request permission from 2X Global -->
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="requestTwoxGlobal">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span
              class="close cursor-pointer"
              @click="requestTwoxGlobal = false"
              style="right: 8px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle what-modal pr-2">
              <div class="ModalInnerStyle">
                <p class="font-semibold">
                  Before submitting deals to qualify for 2X Challenge, please
                  complete your
                  <a
                    class="font-bold"
                    href="https://app.equilo.io/#/2X-Ecosystem/create2Xprofile"
                    style="color: #0077c8"
                    >Investor Profile</a
                  >.
                </p>
                <button
                  style="background-color: #ff3a21; color: white"
                  class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-2"
                  @click="requestTwoxGlobal = false"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import SurveyDashboard from "./../../components/2XCertSurveyModulesDashboard.vue";
import apiService from "@/helpers/apiServices.js";

export default {
  name: "twoxSelfAssessment",
  data: function () {
    return {
      user: [],
      isSubmitDealModal: false,
      currentStep: 1,
      isDocumentsModal: false,
      completeInvestorModal: false,
      requestTwoxGlobal: false,
      endofcreated: false,
      isAssesmentModal: false,
      isTextVisible: false,
      entityChosen: "",
      status2xCertification: "",
    };
  },
  components: {
    pageLoader,
    SurveyDashboard,
  },
  methods: {
    scrollToTarget() {
      //   this.$nextTick(() => {
      const targetElement = this.$refs.targetRefs;
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    closeAssesModal() {
      this.isAssesmentModal = false;
    },
    setEntityType(entity) {
      this.entityChosen = entity;
    },
  },
  navigateFullAssesSurvey() {
    const queryParams = {};
    if (
      this.$route.query.twoxRepeatAsses &&
      this.$store.state.certPageAccess.isFullSurveyFill &&
      !this.$store.state.certPageAccess.isUploadDocPage
    ) {
      queryParams.twoxRepeatAsses = true;
    }
    this.$router.push({
      path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment",
      query: queryParams,
    });
    this.$router.go();
  },
  computed: {
    isCertificationSelfAssessment() {
      return this.$route.path.includes("/2X-Certification-Self-Assessment");
    },
    downloadQuesLink() {
      if (this.isCertificationSelfAssessment) {
        return this.entityChosen === "fund-manager"
          ? "https://storage.equilo.io/api-assets/2X_Certification_Detailed_indicator_questions.pdf"
          : "https://storage.equilo.io/api-assets/2X%20Certification_Detailed%20indicator%20questions_vCompanies_April%202024.pdf";
      }
      return "https://storage.equilo.io/api-assets/2X_Challenge_Self_Assessment_Questions.pdf";
    },
    downloadQuesFileName() {
      if (this.isCertificationSelfAssessment) {
        return this.entityChosen === "fund-manager"
          ? "Certification_Detailed_Questions_Fund.pdf"
          : "Certification_Detailed_Questions.pdf";
      }
      return "Challenge_Detailed_Questions.pdf";
    },
  },
  async mounted() {
    if (
      !localStorage.getItem("isAssesmentModal") &&
      this.$route.path.includes("2X-Certification-Self-Assessment") &&
      !this.$route.query.isRedirectBack
    ) {
      this.isAssesmentModal = true;
      localStorage.setItem("isAssesmentModal", "true");
    }
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await apiService
      .fetch2xCertRequest(
        this.$route.query?.teamId
          ? this.$route.query?.teamId
          : this.user?.organization?._id
      )
      .then(async (response) => {
        this.status2xCertification = response?.data?.data?.status;
      });
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    if (this.$route.path.includes("2X-Certification-Self-Assessment")) {
      this.currentStep = 3;
    } else {
      this.currentStep = 1;
    }
    if (this.$route.query.isRedirectBack) {
      const waitForElement = (selector) => {
        return new Promise((resolve) => {
          const observer = new MutationObserver((mutations) => {
            if (mutations.some((mutation) => mutation.addedNodes.length)) {
              const element = document.querySelector(selector);
              if (element) {
                observer.disconnect();
                resolve(element);
              }
            }
          });
          observer.observe(this.$el, { childList: true });
        });
      };
      waitForElement("#scrollSurvey")
        .then(() => {
          setTimeout(() => {
            this.scrollToTarget();
          }, 1500);
        })
        .catch((error) => {
          console.error("Failed to find element:", error);
        });
    }
    await this.$store.dispatch("fetchValidDomain");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    let baseQuery = this.$route.query.twoxRepeatAsses
      ? "?twoxRepeatAsses=true"
      : "";
    if (this.$route.path.includes("2X-Certification-Self-Assessment")) {
      this.$store.commit("setBreadcrumbs", [
        {
          name: "2X Certification",
          url: "/2X-Ecosystem/2X-Certification" + baseQuery,
        },
        {
          name: "2X Certification Self-Assessment",
          url:
            "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" +
            baseQuery,
        },
      ]);
    } else {
      this.$store.commit("setBreadcrumbs", [
        { name: "2X Challenge", url: "/2X-Challenge" + baseQuery },
        {
          name: "2X Challenge Self-Assessment",
          url: "/2X-Challenge/2X-Challenge-Self-Assessment" + baseQuery,
        },
      ]);
    }
    this.endofcreated = true;
  },
};
</script>
<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .progress-steps-section {
    color: #21191d;

    .outer {
      min-width: 20vw;
      flex: 1;
    }

    .progress {
      $gap: 20px;
      $line-height: 20px;
      $bullet-radius: 5px;
      $line-thick: 5px;
      $strip-color: #666461;
      $next-color: #c2beba;
      $current-color: #666461;
      $prev-color: #666461;

      display: inline-flex;
      height: 100%;
      padding: 5vh 10%;

      > div {
        display: flex;
        flex-direction: column;
        color: $prev-color;

        &.left {
          padding-right: $gap;
          text-align: right;

          // Line
          div {
            &:last-of-type:after {
              display: none;
            }

            &:after {
              content: "";
              background: fade_out($strip-color, 0.9); //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              right: -$gap;
              top: $line-height/2;
              height: 101%;
              width: 1px;
              transform: translateX(50%);
            }
          }
        }

        &.right {
          padding-left: $gap;

          div {
            span {
              color: #21191d;
            }

            button {
              span {
                color: #fff;
              }
            }

            &.prev {
              &:after {
                transition: none;
              }
            }

            &.current {
              color: $current-color;
              font-weight: bold;
              z-index: 1;

              &:before {
                background: $current-color;
                padding: 15px;
                transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
                z-index: 1;
              }

              &:last-child:after {
                height: 0%;
                transition: height 0.2s ease-out;
              }

              &:after {
                content: "";
                background: $next-color; //rgba(0, 0, 0, 0.6);
                border-radius: 2px;
                position: absolute;
                left: -40px;
                top: $line-height/2;
                height: 101%;
                width: $line-thick;
                transform: translateX(-50%);
                transition: height 0.2s ease;
              }

              ~ div {
                color: $next-color;

                &:before {
                  background: $next-color;
                  padding: 15px;
                }

                &:after {
                  content: "";
                  background: $next-color; //rgba(0, 0, 0, 0.6);
                  border-radius: 2px;
                  position: absolute;
                  left: -40px;
                  top: $line-height/2;
                  height: 101%;
                  width: $line-thick;
                  transform: translateX(-50%);
                  transition: height 0.2s ease;
                }

                &:last-child:after {
                  height: 0%;
                  transition: none;
                }
              }
            }

            // Dot
            &:before {
              content: "";
              background: $strip-color;
              padding: 15px;
              border-radius: 50%;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              transform: translateX(-50%) translateY(-50%);
              transition: padding 0.2s ease;
            }

            // Line
            &:after {
              content: "";
              background: $strip-color; //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              height: 101%;
              width: $line-thick;
              transform: translateX(-50%);
              transition: height 0.2s ease;
            }
          }
        }

        div {
          flex: 1;
          //outline: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          line-height: $line-height;
          cursor: default;
          min-height: 150px;

          &:last-of-type {
            flex: 0;
          }
        }
      }
    }

    .done.current {
      color: #62af0b !important;

      &:before {
        background: #62af0b !important;
      }
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 12px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .certification-button {
    font-weight: 500;
    color: white;
    background: #19486a;
    font-size: 16px;
  }
}

.asset_text {
  font-size: 12px;
  font-weight: bold;
  color: #1f1747;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image: linear-gradient(
        201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%
      ),
      url("./../../assets/images/2x-cert-intro-page-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191b45;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px 0 24px 24px;

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #3f7e44;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #191b45;
    }

    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #2177c1;
      // width: 150px;
      padding: 8px 16px 8px 16px;
    }

    .center-button {
      position: relative;
      top: 27px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

ul li {
  list-style: disc;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}

.hide-line::after {
  display: none;
}
</style>
