<template>
  <div class="flex flex-row w-full">
    <div
      class="w-4/12 flex flex-col items-center justify-center relative px-12"
      :style="'background-color:' + env.brandingColors.navy"
    >
      <!--Logo equilo-->
      <img
        class="absolute top-0 left-0 mt-10 ml-10 h-10"
        src="@/assets/images/logo.png"
        alt="logo equilo"
      />
      <div class="text-4xl font-medium" style="height: 50vh">
        <h2 class="tracking-tighter" style="color: #fff">
          Hacking gender equality &amp; social inclusion for a happier, more
          just world.
        </h2>
      </div>
    </div>
    <div class="w-8/12 bg-white flex flex-col items-center justify-center">
      <div
        class="flex items-center ml-auto mt-auto w-4/12 lg:w-4/12 custom-w-100 language-dropdown"
      >
        <h1 class="mr-3 font-medium" style="width: 50%">Language</h1>
        <div
          class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4 notranslate"
          style="margin-top: 0.5rem"
        >
          <v-select
            v-model="user.languagePreference"
            :options="LanguageOptions"
            @option:selected="selectLanguage($event)"
            class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full font-medium focus:outline-none focus:shadow-outline v-select-custom"
            placeholder="Select Language"
            label="name"
          ></v-select>
        </div>
      </div>
      <div class="w-8/12" style="margin-bottom: 143px">
        <div class="mb-10">
          <h1 class="text-5xl print:text-4xl font-bold tracking-tight">
            Create an Equilo account
          </h1>
        </div>
        <div class="flex flex-col justify-start">
          <h1 class="mt-3 font-medium">First name</h1>
          <input
            type="text"
            autocomplete="firstname"
            id="firstname"
            class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
            placeholder="First name"
            v-model="user.firstname"
          />

          <h1 class="mt-3 font-medium">Last name</h1>
          <input
            type="text"
            autocomplete="lastname"
            id="lastname"
            class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
            placeholder="Last name"
            v-model="user.lastname"
          />

          <h1 class="mt-3 font-medium">Organization name</h1>
          <input
            type="text"
            name="organisation"
            autocomplete="off"
            id="organisation"
            class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
            placeholder="Organization name"
            v-model="user.organization"
          />

          <div class="custom-indicator-div">
            <h1 class="mt-3 font-medium">Country</h1>
          </div>
          <div
            class="flex flex-col ml-auto mt-auto w-4/12 lg:w-3/12 custom-w-100 my-2"
          >
            <div
              class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4 notranslate"
              style="margin-top: 0.5rem"
            >
              <v-select
                v-model="user.country_id"
                :options="countryList"
                :reduce="(countryList) => countryList._id"
                class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full font-medium focus:outline-none focus:shadow-outline v-select-custom"
                placeholder="Select Country"
                label="name"
              ></v-select>
            </div>
          </div>

          <h1 class="mt-3 font-medium notranslate">E-mail</h1>
          <input
            type="email"
            name="email"
            autocomplete="email"
            id="email"
            class="notranslate border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
            placeholder="E-mail"
            v-model="user.email"
          />

          <h1 class="mt-3 font-medium">URL (optional)</h1>
          <input
            type="text"
            name="url"
            autocomplete="url"
            id="url"
            class="notranslate border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
            placeholder="URL"
            v-model="user.url"
          />

          <h1 class="mt-3 font-medium">
            Set a password
            <span class="tooltip TooltipImgText">
              <img
                src="../../assets/images/helpblack.png"
                alt="background-image"
              />
              <span class="tooltiptext">
                <p><strong>Password Policy Rules:</strong></p>
                <ul>
                  <li>- One lowercase letter required.</li>
                  <li>- One uppercase letter required.</li>
                  <li>- Minimum 8 characters required.</li>
                  <li>- Maximum 15 characters allowed.</li>
                  <li>- One number required.</li>
                  <li>- Password must not contain spaces.</li>
                  <li>
                    - One special character required. choose from: !@#$%^&*
                  </li>
                </ul>
              </span>
            </span>
          </h1>
          <div class="pwd-image">
            <span class="pwd-text" @click="showPassword = !showPassword">
              <img
                src="@/assets/images/pwd-eye.png"
                height="30"
                width="30"
                v-if="showPassword"
                style="cursor: pointer"
              />
              <img
                src="@/assets/images/eye-slash.svg"
                height="30"
                width="30"
                v-if="!showPassword"
                style="cursor: pointer"
              />
            </span>

            <input
              :type="showPassword ? 'text' : 'password'"
              class="notranslate border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm pwd-input"
              placeholder="Password"
              v-model="user.password"
            />
            <p v-for="(error, index) in errors" :key="index" style="color: red">
              {{ error }}
            </p>
          </div>

          <label class="flex items-center mb-4 mt-5">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              class="w-8 h-8 accept"
              v-model="equiloNewsletter"
            />
            <h1 for="default-checkbox" class="ml-3 font-medium">
              Sign-up for our newsletter to keep in touch and be the first to
              know when there are new product and feature releases
            </h1>
          </label>
          <div class="flex items-center mb-4 mt-5 terms-checkbox">
            <input
              type="checkbox"
              class="w-8 h-8 accept"
              :checked="termsAgreement"
              @change="showAcceptTermsModal('checkbox')"
            />
            <h1 class="ml-3 font-medium" @click="showAcceptTermsModal('text')">
              I accept user terms and conditions and agree with them to proceed
              using our platform
            </h1>
          </div>
          <div data-modal="accept-terms" v-if="acceptTermsModal">
            <div class="overlay" @click="closeAcceptTerms"></div>
            <div class="wr">
              <div class="close" @click="closeAcceptTerms">
                <img src="@/assets/images/close-icon.svg" alt="close" />
              </div>
              <div class="download-links">
                <a
                  href="https://storage.equilo.io/api-assets/User Terms.pdf"
                  target="_blank"
                  >Download PDF version</a
                >
              </div>
              <div class="content-wr" v-html="termsText"></div>
              <div class="btn-wr">
                <div class="btn white" @click="closeAcceptTerms">Cancel</div>
                <div class="btn" @click="acceptTerms">Apply</div>
              </div>
            </div>
          </div>
          <button
            @click="createAccount"
            :style="
              termsAgreement
                ? 'background-color: ' + env.brandingColors.teal
                : 'background-color: #b0b0b0'
            "
            :class="'w-full py-5 rounded-lg mt-10 text-white font-semibold'"
            style="font-size: 20px"
            :disabled="!termsAgreement"
          >
            Create account
          </button>
        </div>

        <div class="mt-5 text-gray-500">
          <p>
            Already have an account?
            <router-link
              to="/login"
              class="text-sm"
              :style="'color:' + env.brandingColors.blue"
              >Login</router-link
            >
          </p>
        </div>

        <div></div>
      </div>
    </div>
    <translate-modal
      :languagePreference="user.languagePreference"
    ></translate-modal>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import env from "@/../env.js";
const axios = require("axios");
import TranslateModal from "@/pages/backups/LangTranslator";
import Cookies from "@/helpers/cookies";
import { analyticsService } from "@/services/analyticsService";
import moment from "moment";

export default {
  name: "Signup",
  components: {
    TranslateModal,
  },
  data: function () {
    return {
      countryList: [],
      showPassword: false,
      organizationTypeList: [
        {
          name: "Impact Investors & financial actors",
        },
        {
          name: "Private sector companies of all sizes",
        },
        {
          name: "Working in international development & humanitarian field",
        },
        {
          name: "Other",
        },
        {
          name: "Academia & Research",
        },
      ],
      equiloNewsletter: true,
      termsAgreement: false,
      acceptTermsModal: false,
      termsAccepted: false,
      termsText: "",
      downloadTermsPdfUrl: "",
      termsName: "",
      LanguageOptions: [],
      orgList: [],
      user: {
        firstname: "",
        lastname: "",
        password: "",
        email: "",
        organization: "",
        invitation: "",
        country_id: "",
        equiloNewsletter: false,
        selectedOrganizationType: "",
        languagePreference: "English",
        termsAgreement: false,
        url: "",
      },
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "Minimum 8 characters required.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        {
          message: "One special character required.",
          regex: /(?=.*[!@#$%^&*])/,
        },
        {
          message: "Spaces are not allowed in the password.",
          regex: /^(?!.*\s).*$/,
        },
        { message: "Maximum 15 characters allowed.", regex: /^.{0,15}$/ },
      ],
      rewardfulReferralId: "",
      rewardfulCouponId: "",
      errors: [],
      env,
    };
  },
  methods: {
    downloadItem(label) {
      let link = this.$refs["download-link"],
        url = link.getAttribute("href");
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    acceptTerms() {
      this.termsAgreement = true;
      this.user.termsAgreement = true;
      this.closeAcceptTerms();
      this.termsAccepted = true;
    },
    showAcceptTermsModal(val) {
      if (val === "checkbox") {
        if (this.termsAccepted) {
          event.target.checked = !event.target.checked;
          this.user.termsAgreement = !this.user.termsAgreement;
          this.termsAgreement = !this.termsAgreement;
        } else {
          if (event.target.checked) {
            event.target.checked = false;
          }
          this.acceptTermsModal = true;
        }
      } else if (val === "text") {
        this.acceptTermsModal = true;
      }
    },
    closeAcceptTerms() {
      this.acceptTermsModal = false;
    },
    uniqueId(event) {
      console.log(event);
    },

    selectLanguage(event) {
      this.user.languagePreference = event.name;
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },

    createAccount() {
      const _self = this;

      let country_name = "";
      if (this.user.country_id) {
        let filter_country = this.countryList.filter(
          (item) => item._id == this.user.country_id
        );
        country_name = filter_country[0].name;
      }

      if (!this.isPasswordValid()) return;

      if (
        this.user.password &&
        this.user.firstname &&
        this.user.organization &&
        this.user.lastname &&
        this.user.languagePreference &&
        this.user.email &&
        this.user.termsAgreement
      ) {
        let validate = this.validEmail(this.user.email);
        if (validate) {
          const existingDevicetoken = localStorage.getItem("deviceToken");
          axios
            .post(env.apiUrl + "/organizations/create", {
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              email: this.user.email.trim(),
              organization: this.user.organization
                ? this.user.organization
                : "",
              password: this.user.password,
              invitation: this.user.invitation,
              languagePreference: this.user.languagePreference,
              existingDevicetoken,

              country_id: this.user.country_id ? this.user.country_id : "",
              country_name: country_name,
              equiloNewsletter: this.equiloNewsletter,
              organizationType: "Other",
              rewardfulReferralId: this.rewardfulReferralId,
              rewardfulCouponId: this.rewardfulCouponId,
              termsAgreement: this.user.termsAgreement,
              url: this.user.url,
            })
            .then(async function (res) {
              if (res.data.error) {
                if (
                  res.data.error ==
                  "You already have an account, please reset your password."
                ) {
                  await _self.$swal.fire({
                    icon: "info",
                    text: res.data.error,
                    showConfirmButton: true,
                  });
                  _self.$router.replace({ path: "/reset-password" });
                } else {
                  _self.$swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.data.error,
                  });
                }
              } else {
                try {
                  if (analyticsService) {
                    // Analytics for signup sent to Segment

                    const formattedDate = moment().format("MM/DD/YYYY");
                    const userData = {
                      email: _self.user.email,
                      firstname: _self.user.firstname,
                      lastname: _self.user.lastname,
                      organization: _self.user.organization,
                      country: country_name,
                      languagePreference: _self.user.languagePreference,
                      equiloNewsletter: _self.equiloNewsletter,
                      url: _self.user.url,
                      accountCreationDate: formattedDate,
                    };

                    analyticsService.track("User Signed Up", userData);
                  } else {
                    console.error("Analytics object is not available.");
                  }
                } catch (error) {
                  console.error("Error sending analytics data:", error);
                }
                localStorage.setItem("passwordPolicyModal", true);
                if (res.data.deviceId != "") {
                  await _self.$store.commit(
                    "setLocalStorage",
                    res.data.deviceId
                  );
                }
                await _self.$swal.fire({
                  icon: "success",
                  text: "You can now log in",
                  showConfirmButton: true,
                });

                _self.$router.replace({ path: "/login" });
              }
            })
            .catch(async (err) => {
              if (err.response.data.message) {
                await _self.$swal.fire({
                  icon: "info",
                  text: err.response.data.message,
                  showConfirmButton: true,
                });
                _self.$router.replace({ path: "/reset-password" });
              }
            });
        } else {
          _self.$swal.fire({
            icon: "error",
            title: "Invalid Email Address",
            text: "Please enter a valid email address.",
            showConfirmButton: true,
          });
        }
      } else {
        _self.$swal.fire({
          icon: "error",
          title: "Incomplete Information",
          text: "Please fill in all required fields.",
          showConfirmButton: true,
        });
      }
    },
    getOrganization: function () {
      const _self = this;
      return new Promise(function () {
        axios
          .get(
            env.apiUrl +
              "/organizations/getorg/" +
              _self.$route.params.invitationId,
            {}
          )
          .then(function (res) {
            _self.user.organization = res.data.data.orgname;
            _self.user.email = res.data.data.email;
            document.getElementById("organisation").disabled = true;
            document.getElementById("email").disabled = true;
          });
      });
    },
    isPasswordValid() {
      this.errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.user.password)) {
          this.errors.push(condition.message);
        }
      }
      return this.errors.length == 0;
    },
    getCountry() {
      return axios
        .get(env.apiUrl + "/countries", {
          headers: {
            Authorization: env.staticToken,
          },
        })
        .then((res) => {
          const uniqueCountry = [
            ...new Map(res.data.data.map((m) => [m.id, m])).values(),
          ];
          this.countryList = uniqueCountry;

          let langList = res.data.languagePreference;
          langList.map((item) => {
            this.LanguageOptions.push({ name: item });
          });
        });
    },
    getUniqueOrganization() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/uniqueOrg/list", {
            headers: {
              Authorization: env.staticToken,
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  async created() {
    axios
      .get(env.apiUrl + "/users/get-terms/userTerms", {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
      .then((response) => {
        this.termsText = response.data.terms.markup;
        this.downloadTermsPdfUrl = response.data.terms.downloadURL;
        this.termsName = response.data.terms.termsName;
      });
    if (typeof this.$route.params.invitationId != "undefined") {
      this.user.invitation = this.$route.params.invitationId;
      await this.getOrganization();
    }
  },
  async mounted() {
    await this.getCountry();

    this.orgList = await this.getUniqueOrganization();

    this.prevUser = await auth.me().catch(() => {});
    if (this.prevUser && this.prevUser._id) {
      this.$store.commit("setUser", this.prevUser);
      this.$router.replace({ path: "/" });
    }

    const _self = this;
    window.rewardful("ready", function () {
      if (window.Rewardful.referral)
        _self.rewardfulReferralId = window.Rewardful.referral;
      if (window.Rewardful.coupon)
        _self.rewardfulCouponId = window.Rewardful.coupon.id;
    });
  },
};
</script>

<style lang="scss" scoped>
.w-8.accept {
  max-width: 27px;
  width: 100%;
}
.terms-checkbox {
  h1 {
    cursor: pointer;
  }
}
[data-modal="accept-terms"] {
  .wr {
    padding: 80px 0 40px 0;
    .content-wr {
      > div {
        > ol {
          > li {
            margin-top: 0;
          }
          > ol {
            margin-top: 10px;
          }
        }
      }
    }
    li {
      display: block;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .wr::-webkit-scrollbar-thumb {
    background-color: rgba(32, 23, 71, 0.5);
    border: 4px solid transparent;
    border-radius: 30px;
    background-clip: padding-box;
  }
  .wr::-webkit-scrollbar {
    width: 20px;
  }
}

.language-dropdown {
  width: 300px !important;
  margin-right: 25px;
  margin-top: 10px;
}

input[type="checkbox"] {
  accent-color: rgb(32, 23, 71);
}

.TooltipImgText {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    padding-left: 8px;
    line-height: 22px;
    color: #ffff;
  }
  .tooltiptext {
    max-width: 360px;
    top: 20px;
    left: -124px;
    background-color: #201747 !important;
    text-align: inherit;
    &::after {
      top: -10px;
      right: 0;
      height: 0;
      width: 0;
      left: 0;
      margin: 0 auto;
      border-color: transparent transparent #201747 transparent;
    }
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}
.pwd-image {
  width: 100%;
  position: relative;
}
.pwd-text {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 15px;
  padding-right: 1px;
}
.pwd-input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 5px;
  border-radius: 2px;
}
</style>
