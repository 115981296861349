<template>
  <div class="w-[300px]" :id="`chart-${this.id}`">
    <h3 v-if="title" class="text-[16px] font-bold mt-1 mb-2">{{ title }}</h3>
  </div>
</template>

<script>
import { createBarChart } from "@/components/D3-Charts/generateCharts";

export default {
  name: "plot-bar-chart",
  props: {
    data: {
      type: Object,
      required: true,
    },
    colorSchema: {
      type: Array,
      required: false,
    },
    orientation: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      id: `${this.data.uniqueId}`,
    };
  },
  mounted() {
    const transformedData = this.transformData();
    createBarChart(`chart-${this.id}`, transformedData, this.colorSchema, this.orientation, 400, '');
  },
  methods: {
    transformData() {
      return Object.values(this.data.periods).map(({period, answer,date}) => ({
        period: period,
        value: answer,
        label: date,
      }));
    },
  },
};
</script>
