<template>
  <div class="static assementPageStyle">
    <div v-if="endofcreated">
      <div
        class="container px-6"
        v-if="
          (isRedirectFromTSP ||
            (user.access2x && user.sophisticatedInvestor) ||
            user?.organization?._id == $route.params.investorOrg) &&
          user?.membership2xData
        "
      >
        <div class="PoweredbyTitle_logo" v-if="endofcreated && investorOrgData">
          <div class="PoweredbyTitleWrepper">
            <img
              v-if="
                investorOrgData?.logo?.[0]?.url ||
                investorOrgData?.logo?.[0]?.content
              "
              class="h-16 print:h-auto print:w-64"
              :src="
                investorOrgData.logo[0].url
                  ? investorOrgData.logo[0].url
                  : investorOrgData.logo[0].content
              "
              style="
                width: 80px;
                height: 80px !important;
                display: block;
                border-radius: 50px;
                object-fit: contain;
              "
            />
            <h1 v-if="investorOrgData?.member2xc">
              {{ investorOrgData.member2xc }}
            </h1>
          </div>
          <div>
            <div class="Poweredby_logoImg">
              <span class="mr-3">Powered by:</span>
              <img
                src="@/assets/images/2X.png"
                alt="background-image"
                style="width: 80px"
              />
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
        </div>
        <div class="flex" v-if="investorOrgData?.sophisticatedInvestor">
          <img
            style="display: block; margin-left: 100px !important; width: 25px"
            src="@/assets/images/sophisticated_star.svg"
          />
          <span style="color: #0077c8; font-weight: 700"
            >SOPHISTICATED INVESTOR</span
          >
        </div>
        <transition-group name="fadeIn" mode="out-in">
          <div
            id="loaded"
            v-if="
              endofcreated &&
              investorOrgData &&
              (consent == 'Yes' || this.isMyOrg)
            "
            key="content"
          >
            <!-- Assement Page Wrepper -->

            <div class="AssementPageWrepper">
              <div
                class="my-profile-button mb-5"
                v-if="user.organization._id == $route.params.investorOrg"
              >
                <button
                  class="text-white text-sm font-medium"
                  @click="editMyProfile()"
                >
                  Edit My Profile
                </button>
              </div>
              <div class="Profile_Criteria_Wrepper">
                <!-- profile Block -->
                <div class="Profile_CountryWrepper">
                  <div class="Profile_Block flex">
                    <!-- Left side -->
                    <div class="left_side">
                      <div class="Profile_blockListText">
                        <div class="OverviewTitle">
                          <h2>Overview</h2>
                          <span></span>
                        </div>
                        <strong>
                          <h2>About the Organization</h2>
                        </strong>
                        <ul class="pb-8" style="display: block">
                          <li
                            class="profile-description"
                            v-if="investorOrgData.description"
                          >
                            <!-- <b>Organization Description:</b> -->
                            {{ investorOrgData.description }}
                          </li>
                          <li>
                            <div class="investorOrgProfile">
                              <div>
                                <div
                                  class="About_CompanyListText"
                                  style="display: flex"
                                >
                                  <a
                                    :href="investorOrgData.website"
                                    target="_blank"
                                    :class="{
                                      disabled: !investorOrgData.website,
                                    }"
                                  >
                                    <img
                                      src="../../assets/images/Earth.png"
                                      alt="Earth"
                                    />
                                  </a>
                                  <a
                                    :href="investorOrgData.linkedin"
                                    target="_blank"
                                    :class="{
                                      disabled: !investorOrgData.linkedin,
                                    }"
                                  >
                                    <img
                                      src="../../assets/images/linkedin.png"
                                      alt="Linkedin"
                                    />
                                  </a>
                                  <a
                                    :href="investorOrgData.twitterHandle"
                                    target="_blank"
                                    :class="{
                                      disabled: !investorOrgData.twitterHandle,
                                    }"
                                  >
                                    <img
                                      src="../../assets/images/twitter.png"
                                      alt="Twitter"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <strong>
                          <h2>Organization Type:</h2>
                        </strong>
                        <div v-if="investorOrgData?.organizationType?.length">
                          <span
                            v-for="type in investorOrgData.organizationType"
                            :key="type"
                          >
                            {{ "- " + type }} <br
                          /></span>
                        </div>
                        <span v-else>
                          {{ investorOrgData.organizationType }}
                        </span>
                      </div>
                      <div class="Profile_blockListText mt-5">
                        <div class="OverviewTitle">
                          <h2>Primary Contact</h2>
                          <span></span>
                        </div>
                        <ul class="removeBorderBottom">
                          <li v-if="investorOrgData.primaryContactName">
                            <b>Name:</b>
                            {{ investorOrgData.primaryContactName }}
                          </li>
                          <li v-if="investorOrgData.primaryContactTitle">
                            <b>Title:</b>
                            {{ investorOrgData.primaryContactTitle }}
                          </li>
                        </ul>

                        <br />

                        <div>
                          <div class="OverviewTitle">
                            <h2>Secondary Contact</h2>
                            <span></span>
                          </div>
                          <ul class="removeBorderBottom">
                            <li v-if="investorOrgData.secondaryContactName">
                              <b>Name:</b>
                              {{ investorOrgData.secondaryContactName }}
                            </li>
                            <li v-if="investorOrgData.secondaryContactTitle">
                              <b>Title:</b>
                              {{ investorOrgData.secondaryContactTitle }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="bg-gray-200 rounded-lg mt-5">
                        <div class="p-10">
                          <div class="OverviewTitle">
                            <h2>Enhance Your Experience</h2>
                          </div>
                          <div class="mb-10">
                            Don't miss out on our exclusive tools.
                            <router-link :to="'/upgrade'"
                              ><span class="text-blue-500 font-bold"
                                >Check out subscriptions</span
                              ></router-link
                            >
                            today and unlock a world of opportunities!
                          </div>
                          <router-link :to="'/upgrade'">
                            <button
                              class="border-2 border-gray-700 font-bold py-2 px-4 rounded-lg mt-5"
                            >
                              View Subscriptions
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <!-- Right side -->
                    <div class="right_side ml-5">
                      <div class="Profile_blockListText">
                        <div class="OverviewTitle">
                          <h2>Interests</h2>
                          <span></span>
                        </div>
                        <div v-if="investorOrgData.memberInterest">
                          <strong>
                            <h2>Member Interest</h2>
                          </strong>
                          <span
                            class="inline-block color_block greybtn"
                            v-for="(
                              member, memberIndex
                            ) in investorOrgData.memberInterest"
                            :key="memberIndex"
                            >{{ member }}</span
                          >
                        </div>
                        <br v-if="investorOrgData.memberInterest" />
                        <div v-if="investorOrgData.otherMemberInterests">
                          <strong>
                            <h2>Other Member Interests</h2>
                          </strong>
                          {{ investorOrgData.otherMemberInterests }}
                        </div>
                        <br v-if="investorOrgData.otherMemberInterests" />
                        <div v-if="investorOrgData.geographiesOfInterest">
                          <strong>
                            <h2>Geographies of Interests</h2>
                          </strong>
                          <br />
                          <TwoXCMap
                            :data="investorOrgData.geographiesOfInterest"
                          />
                        </div>
                        <br />

                        <div v-if="investorOrgData.sectorsOfInterest">
                          <strong>
                            <h2>Sectors of Interest</h2>
                          </strong>
                          <span
                            class="inline-block color_block"
                            :class="colorClass[sectorIndex]"
                            v-for="(
                              sector, sectorIndex
                            ) in investorOrgData.sectorsOfInterest"
                            :key="sectorIndex"
                            >{{ sector }}</span
                          >
                        </div>

                        <br />

                        <div v-if="investorOrgData.otherSectors">
                          <strong>
                            <h2>Other Sectors of Interest</h2>
                          </strong>
                          {{ investorOrgData.otherSectors }}
                        </div>

                        <br />

                        <div v-if="investorOrgData.themesOfInterest">
                          <strong>
                            <h2>Themes of Interest</h2>
                          </strong>
                          <span
                            class="inline-block color_block"
                            :class="colorClass[themeIndex]"
                            v-for="(
                              theme, themeIndex
                            ) in investorOrgData.themesOfInterest"
                            :key="themeIndex"
                            >{{ theme }}</span
                          >
                        </div>

                        <br />

                        <div v-if="investorOrgData.otherThemes">
                          <strong>
                            <h2>Other Themes of Interest</h2>
                          </strong>
                          {{ investorOrgData.otherThemes }}
                        </div>

                        <br />

                        <div
                          v-if="
                            !isTechnicalServiceProvider &&
                            investorOrgData.countryIncomeGroupFocus
                          "
                        >
                          <strong>
                            <h2>Income Group of Focus</h2>
                          </strong>
                          <span
                            class="inline-block color_block greybtn"
                            v-for="(
                              income, incomeIndex
                            ) in investorOrgData.countryIncomeGroupFocus"
                            :key="incomeIndex"
                            >{{ income }}</span
                          >
                        </div>

                        <br />

                        <div
                          v-if="
                            !isTechnicalServiceProvider &&
                            investorOrgData.geographiesOfInterestGrants
                          "
                          class="geographies_of_interest_for_grant"
                        >
                          <strong>
                            <h2>Geographies of Interest - For Grant</h2>
                          </strong>
                          <TwoXCMap
                            :data="investorOrgData.geographiesOfInterestGrants"
                          />
                        </div>
                      </div>
                      <div class="Profile_blockListText profile_offerings mt-5">
                        <div class="OverviewTitle">
                          <h2>Offerings</h2>
                          <span></span>
                        </div>
                        <div class="flex mb-8">
                          <div
                            class="products_offered"
                            v-if="
                              !isTechnicalServiceProvider &&
                              investorOrgData.productsOffered
                            "
                          >
                            <strong>
                              <h2>Products Offered</h2>
                            </strong>
                            <div
                              class="flex"
                              v-for="pro in investorOrgData.productsOffered"
                              :key="pro"
                            >
                              <img src="@/assets/images/check_black.svg" />
                              {{ pro }}
                            </div>
                          </div>
                          <div
                            class="support_and_services_offered"
                            v-if="investorOrgData.whatKindOfSupportDoYouOffer"
                          >
                            <strong>
                              <h2>Support and Services Offered</h2>
                            </strong>
                            <div
                              class="flex"
                              v-for="support in investorOrgData.whatKindOfSupportDoYouOffer"
                              :key="support"
                            >
                              <img src="@/assets/images/check_black.svg" />
                              {{ support }}
                            </div>
                          </div>
                          <div
                            class="offerings_to_support_gender_smart"
                            v-if="
                              isTechnicalServiceProvider &&
                              investorOrgData.taServicesOffered
                            "
                          >
                            <strong>
                              <h2>
                                Offerings to Support Gender Smart Investments
                                and Governance
                              </h2>
                            </strong>
                            <div
                              class="flex"
                              v-for="ta in investorOrgData.taServicesOffered"
                              :key="ta"
                            >
                              <img src="@/assets/images/check_black.svg" />
                              {{ ta }}
                            </div>
                          </div>
                        </div>
                        <div class="flex mb-8">
                          <div
                            class="offerings_to_support_gender_smart"
                            v-if="
                              !isTechnicalServiceProvider &&
                              investorOrgData.taServicesOffered
                            "
                          >
                            <strong>
                              <h2>
                                Offerings to Support Gender Smart Investments
                                and Governance
                              </h2>
                            </strong>
                            <div
                              class="flex"
                              v-for="ta in investorOrgData.taServicesOffered"
                              :key="ta"
                            >
                              <img src="@/assets/images/check_black.svg" />
                              {{ ta }}
                            </div>
                          </div>
                          <div
                            class="primary_investee_type"
                            v-if="
                              !isTechnicalServiceProvider &&
                              investorOrgData.investeeTypeRoutesToMarket
                            "
                          >
                            <strong>
                              <h2>Primary Investee Types/Routes</h2>
                            </strong>
                            <div
                              class="flex"
                              v-for="investee in investorOrgData.investeeTypeRoutesToMarket"
                              :key="investee"
                            >
                              <img src="@/assets/images/check_black.svg" />
                              {{ investee }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="ticket_size_range mb-8"
                          v-if="
                            !isTechnicalServiceProvider &&
                            investorOrgData.ticketSizeRange
                          "
                        >
                          <strong>
                            <h2>Ticket Sizes Funded</h2>
                          </strong>
                          <span
                            class="inline-block color_block greybtn"
                            v-for="(
                              ticket, ticketIndex
                            ) in investorOrgData.ticketSizeRange"
                            :key="ticketIndex"
                            >{{ ticket }}</span
                          >
                        </div>
                        <div
                          class="ticket_size_rang mb-8"
                          v-if="
                            !isTechnicalServiceProvider &&
                            investorOrgData.ticketSizeRangeGrants
                          "
                        >
                          <strong>
                            <h2>Grant Sizes Funded</h2>
                          </strong>
                          <span
                            class="inline-block color_block greybtn"
                            v-for="(
                              ticket, ticketIndex
                            ) in investorOrgData.ticketSizeRangeGrants"
                            :key="ticketIndex"
                            >{{ ticket }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else key="else">
            <div class="flex flex-row justify-center items-center ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <h1
                    class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
                  >
                    No Data Found
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <ecosystem-redirect-popup v-else />
    </div>
    <transition-group name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
    </transition-group>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import ecosystemRedirectPopup from "@/components/2XEcosystemRedirectPopup.vue";
import TwoXCMap from "@/components/2XCMapComponent.vue";
import { useUserStore } from "@/stores/user";

export default {
  name: "TwoXCMembership",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  data: function () {
    return {
      endofcreated: false,
      env,
      user: [],
      investorOrgId: null,
      investorOrgData: null,
      colorClass: [
        "greenbtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "greybtn",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greenbtn",
        "greybtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greybtn",
      ],
      isTechnicalServiceProvider: false,
      hidePopuponTSP: false,
      isMyOrg: false,
    };
  },
  components: {
    pageLoader,
    ecosystemRedirectPopup,
    TwoXCMap,
  },
  async created() {
    this.setBreadCrumbs();
    await this.getUserData();
    await this.get2XCMemberData();
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
  },
  methods: {
    setBreadCrumbs() {
      const defaultCrumb = {
        name: "My profile",
        url: `/2X-Ecosystem/2XC-Membership/${this.$route.params.investorOrg}`,
      };

      const breadcrumbs = this.$store.getters.showChallengeInBreadcrumb
        ? [
            {
              name: "2X Challenge",
              url: "/2X-Challenge",
            },
            defaultCrumb,
          ]
        : [defaultCrumb];

      this.$store.commit("setBreadcrumbs", breadcrumbs);
    },
    async get2XCMemberData() {
      try {
        const url = this.isMyOrg
          ? `/assesment2x/get-investee-interests?investorOrg`
          : `/assesment2x/get-investee-interests?investorOrg=${this.$route.params.investorOrg}`;
        const getInvestorOrgData = await axios.get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        this.investorOrgData = this.isMyOrg
          ? getInvestorOrgData?.data?.data?.data?.[0]
          : getInvestorOrgData?.data?.data?.data;

        this.isRedirectFromTSP
          ? (this.isTechnicalServiceProvider = true)
          : (this.isTechnicalServiceProvider = false);

        this.endofcreated = true;
      } catch (error) {
        console.log("get2XCMemberData Error: ", error);
        this.endofcreated = true;
      }
    },
    async getUserData() {
      try {
        this.user = this.userStore.user;
        if (!this.user) {
          this.user = await this.userStore.fetchUser(false, true);
        }
        if (this.$route?.params?.investorOrg == this?.user?.organization?._id) {
          this.isMyOrg = true;
        }
      } catch (error) {
        console.log("getUserData Error:", error);
      }
    },
    editMyProfile() {
      this.$router.push({ name: "TwoXC" });
    },
    beforeWindowUnload() {
      window.onbeforeunload = () => {
        sessionStorage.removeItem("fromTechnicalService");
      };
    },
  },
  filters: {
    changeArrayIntoString: function (value) {
      if (!value || value.length == 0) return "";
      return value.join(", ");
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
    sessionStorage.removeItem("fromTechnicalService");
  },
  computed: {
    consent() {
      return sessionStorage.getItem("fromTechnicalService")
        ? this.investorOrgData.taServiceProviderConsent
        : this.investorOrgData.consent;
    },
    isRedirectFromTSP() {
      return sessionStorage.getItem("fromTechnicalService");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep ol {
  list-style-type: decimal;
}
.greenbtn {
  background: #71ddc3;
  border-radius: 10px;
  padding: 2px 10px 2px 8px;
}

.greybtn {
  padding: 2px 10px 2px 8px;
  background: rgba(208, 208, 208, 0.5);
  border-radius: 10px;
}

.lightblue {
  background: #cfdfff;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.powerBlue {
  background: #d0f0fd;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.lightGreen {
  background: #c2f4e8;
  border-radius: 10px;
  padding: 2px 10px;
}

.lomengreen {
  background: #d1f7c3;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.skyblue {
  background: #9bc6ff;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.yellolight {
  background: #ffe9b6;
  border-radius: 10px;
  padding: 2px 10px 2px 7px;
}

.pinklight {
  background: #fee2d5;
  border-radius: 10px;
  padding: 2px 10px;
}

.glassgreen {
  background: #d1f7c3;
  border-radius: 10px;
  padding: 2px 10px 2px 7px;
}

.pink {
  background-color: #ffdce4;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.purple {
  background-color: #cdb0ff;
  border-radius: 10px;
  padding: 2px 10px 2px 8px;
}

.babypink {
  background-color: #ffdaf6;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.assementPageStyle {
  background: #f5f6f9;
  padding-top: 10em;

  .container {
    margin: 0 auto;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .AssementPageWrepper {
    margin-top: 15px;
    padding-bottom: 60px;

    .my-profile-button {
      padding-bottom: 36px;
      padding-right: 14px;
    }

    .my-profile-button button {
      float: right;
      background: #fff;
      color: #1b1b1b;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }

    .my-profile-button button:hover {
      background: #201747;
      color: #fff;
    }

    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      .Profile_CountryWrepper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .Profile_Block {
          .left_side {
            width: 40%;
          }

          .right_side {
            width: 60%;
          }

          // background: #ffffff;
          // border-radius: 10px;
          // padding: 40px;
          margin-bottom: 18px;
          width: 100%;

          .Profile_blockListText {
            background: #ffffff;
            padding: 40px;
            border-radius: 10px;

            .geographies_of_interest_for_grant {
              border-top: 1px solid #d0d0d0;
              padding-top: 2rem;
              margin-top: 2rem;
            }

            .color_block {
              margin: 0 1.5px 5px;
              padding: 2px 10px;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              font-weight: 400;
              border-radius: 10px;
            }

            .products_offered {
              width: 50%;
            }

            .support_and_services_offered {
              width: 50%;
            }

            .offerings_to_support_gender_smart {
              width: 50%;
            }

            .primary_investee_type {
              width: 50%;
            }

            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-top: 10px;

              b {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }

              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }

              a.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
            }

            ul {
              border-bottom: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 40px;
              // display: flex;
              // flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;

              li {
                // width: 33%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }

                &.profile-description {
                  flex: 1 1 auto;
                }
              }
            }

            .DownloadBtnDeck {
              display: flex;

              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;

                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }

                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }

            .removeBorderBottom {
              border: none;
            }
          }
        }
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: auto;
        flex: 1 0 auto;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }
  }
}

img {
  margin: 0 !important;
  margin-right: 15px !important;
}

.investorOrgProfile {
  display: flex;
  align-items: center;
}

.investorOrgProfile img {
  width: 100px;
  max-width: 100px;
  flex: 0 0 100px;
  margin-right: 10px;
}

.investorOrgProfile .profile-details {
  flex: 1 1 auto;
}

.ticket-block-row {
  display: flex;
  align-items: center;
}
</style>
