<template>
  <div class="step3-section">
    <div class="">
      <p
        class="font-sans text-2xl font-bold leading-[28px] text-left text-[#181818]"
      >
        Select all modules you want your portfolio companies to report on, and
        indicate the preferred frequency for receiving updated data.
      </p>
    </div>
    <div class="levels-section">
      <div
        class="levels-list"
        v-for="(level, index) in levels"
        :key="level.heading"
      >
        <div class="flex justify-between">
          <div class="flex level-drodown">
            <div class="level-title">
              <p>{{ level.heading }}</p>
            </div>
            <img
              @click="openSection(index)"
              class="cursor-pointer"
              src="./../../../assets/images/IMM/arrow-down-grey.svg"
              v-if="!level.isOpened"
            />
            <img
              @click="openSection(index)"
              class="cursor-pointer"
              src="./../../../assets/images/IMM/arrow-up-grey.svg"
              v-if="level.isOpened"
            />
          </div>
          <div class="radio-button">
            <input
              type="radio"
              :id="index"
              name="level"
              v-model="selectedLevel"
              :value="level.heading"
              @click="levelChecked(index)"
            />
          </div>
        </div>
        <div class="dropdown-content" v-if="level.isOpened">
          <div class="text">
            <p>{{ level.text }}</p>
          </div>
          <div
            class="highlighted-text"
            :style="
              'background:' +
              level.background +
              '; border: 1px solid' +
              level.border
            "
          >
            <p v-html="level.highlightedText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Step3",
  props: ["fieldValue"],
  data() {
    return {
      levels: [
        {
          heading: "Compliance",
          text: "Assess the extent to which the investment target or portfolio aligns with minimum standards or regulations",
          icon: "checklist.svg",
          background: "#FFECE8",
          border: "#CB2E00",
          isSelected: false,
          isOpened: false,
          highlightedText: `Is it time to elevate your standards and dive deeper? <br/> Choose "Risk" as your level!`,
        },
        {
          heading: "Risk",
          text: "Assess the risks associated with the investment target or portfolio including but not limited to the negative externalities caused",
          icon: "filled.svg",
          background: "#FFF8E0",
          border: "#E8AD17",
          isSelected: false,
          isOpened: false,
          highlightedText: `Mitigating risks is a commendable start of your impact journey. Enhance your investment strategy and strive for optimizing excellence by choosing "Performance" as your level!`,
        },
        {
          heading: "Performance",
          text: "Assess how well an investment target or portfolio performs based on it's actions, policies, strategies, targets, practices or initiatives ",
          icon: "arrow-up.svg",
          background: "#E5FAFA",
          border: "#13A094",
          isSelected: false,
          isOpened: false,
          highlightedText: `At this level, your investments are assessed based on their performance, but why stop there? Propel to the next level by comitting to positive change and choosing "Impact"`,
        },
        {
          heading: "Impact",
          text: "Assess whether an investment target or portfolio has achieved the intended long-term effects or benefits brought about for beneficiaries, stakeholders or the environment",
          icon: "target.svg",
          background: "#EAF6FF",
          border: "#2177C1",
          isSelected: false,
          isOpened: false,
          highlightedText: `The "Impact" level supports you in your journey towards bringing about lasting benefits for beneficiaries, stakeholders, or the environment.`,
        },
      ],
      selectedLevel: "",
    };
  },
  methods: {
    openSection(index) {
      this.levels[index].isOpened = !this.levels[index].isOpened;
    },
    levelChecked(index) {
      this.levels[index].isOpened = true;
      this.selectedLevel = this.levels[index].heading;
      this.levels.map((data) => {
        data.isSelected = false;
        if (data.heading === this.selectedLevel) {
          data.isSelected = true;
        }
      });
      if (this.selectedLevel) {
        this.emitValue();
      }
    },
    emitValue() {
      this.$emit("input-typed", {
        isStepActive: true,
        selectedLevel: this.selectedLevel,
      });
    },
  },
  mounted() {
    if (this.fieldValue && this.fieldValue.selectedLevel) {
      this.selectedLevel = this.fieldValue.selectedLevel;
      this.$emit("input-typed", {
        isStepActive: true,
        selectedLevel: this.selectedLevel,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.step3-section {
  margin-bottom: 90px;
  .label {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #181818;
  }
  .levels-section {
    margin-top: 16px;
    .levels-list {
      width: 616px;
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #9ea4ba;
      margin-bottom: 16px;
      .level-drodown {
        gap: 8px;
        .level-title {
          p {
            font-family: Arial;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #2c2e35;
          }
        }
      }
      .radio-button {
        input {
          width: 20px;
          height: 20px;
        }
      }
    }
    .dropdown-content {
      margin-top: 8px;
      .text {
        p {
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #717686;
        }
      }
      .highlighted-text {
        margin-top: 24px;
        width: 568px;
        padding: 16px;
        border-radius: 8px;
        gap: 16px;
        p {
          font-family: Arial;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #2c2e35;
        }
      }
    }
  }
}
</style>
