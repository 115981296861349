import { analyticsService } from "@/services/analyticsService";

export const clickTracking = {
  bind(el, binding, vnode) {
    el.addEventListener("click", () => {
      let trackingData = {};

      if (typeof binding.value === "string") {
        trackingData.elementName = binding.value;
      } else if (typeof binding.value === "object") {
        trackingData = { ...binding.value };
      } else {
        console.warn(
          "v-track-click directive requires a string or object value"
        );
        return;
      }

      // Add component-level data
      if (vnode.context && vnode.context.$options.trackingData) {
        const componentTrackingData =
          typeof vnode.context.$options.trackingData === "function"
            ? vnode.context.$options.trackingData.call(vnode.context)
            : vnode.context.$options.trackingData;
        trackingData = { ...componentTrackingData, ...trackingData };
      }

      // Add some default properties
      trackingData.elementType = el.tagName.toLowerCase();
      trackingData.currentRoute = window.location.pathname;
      trackingData.userId = window.app.$store.state.user.id;
      trackingData.userEmail = window.app.$store.state.user.email;
      trackingData.buttonName = el.innerText;

      analyticsService.trackClick(
        trackingData.elementName || "Unnamed Element",
        trackingData,
        trackingData.elementType
      );
    });
  },
  unbind(el) {
    el.removeEventListener("click", el._clickHandler);
    delete el._clickHandler;
  },
};
