<template>
  <div class="dashboard-container">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="dashboard-section container mx-auto px-4 lg:px-0 pb-4"
      v-if="endofcreated"
    >
      <div
        class="dashboard-heading-section mt-6 text[#666461] flex justify-between items-end"
      >
        <div class="flex items-end gap-3">
          <div class="flex flex-col justify-center gap-1">
            <span class="text-base text-gray-700 font-semibold"
              >From Date:</span
            >
            <input
              type="date"
              v-model="startDate"
              @change="fetchinProgressAssessmentsData"
              class="input-select px-3 p-2 text-sm font-medium focus:outline-none focus:shadow-outline"
            />
          </div>
          <div class="sort-section">
            <div class="sort-option-section">
              <select
                name="sortOptions"
                id="sortOptions"
                v-model="statusFilter"
                @change="fetchinProgressAssessmentsData"
              >
                <option value="" selected>Select Status</option>
                <!-- <option value="Certification Awarded">
                  Certification Awarded
                </option> -->
                <option value="New Request In Progress">
                  Self-Assessment in progress
                </option>
                <option :value="is2xChallenge ? 'Min Survey Submitted' : 'Full Survey Submitted'">
                  Self-Assessment Submitted
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <TwoXUserStatistics :certificationData="certificationData" />
      <EquiloPagination v-if="certificationData?.count"
        :total-items="parseInt(certificationData?.count)"
        :items-per-page="parseInt(10)"
        :current-page="parseInt(assessmentsPage)"
        @page-change="changeAssessmentsPage"
      />
      <TwoXSurveyStatistics :surveyData="surveyStatistics" />
      <EquiloPagination v-if="surveyStatistics?.count"
        :total-items="parseInt(surveyStatistics?.count)"
        :items-per-page="parseInt(10)"
        :current-page="parseInt(surveysPage)"
        @page-change="changeSurveysDatasPage"
      />
      <div class="flex gap-5 mt-8">
        <div
          @click="$router.replace({ path: is2xChallenge ? '/2x-global/2X-Challenge/deals-investor-disclosures' : '/2x-global/2X-Certification' })"
          class="bg-white shadow-sm rounded-lg p-6 border border-gray-500 cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg text-gray-700 hover:text-[#0077C8] w-64"
        >
          <p class="font-bold">{{ is2xChallenge ? '2X Challenge Deals and Investor Disclosures' : '2X Certification Self-Assessments Results' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import apiService from "@/helpers/apiServices.js";
import TwoXUserStatistics from "@/pages/towxGlobalPortal/2xUserStatistics.vue";
import TwoXSurveyStatistics from "@/pages/towxGlobalPortal/2xSurveyStatistics.vue";
import { useUserStore } from "@/stores/user";
import EquiloPagination from '@/components/common/EquiloPagination.vue';

export default {
  name: "TwoxGlobCertificationInProgressPage",
  data() {
    return {
      user: [],
      statusType: "",
      endofcreated: false,
      entityType: "",
      statusFilter: "",
      updateKey: 0,
      certificationData: [],
      surveyStatistics: [],
      startDate: "",
      assessmentsPage: 1,
      surveysPage: 1,
    };
  },
  components: {
    pageLoader,
    TwoXUserStatistics,
    TwoXSurveyStatistics,
    EquiloPagination,
  },
  async mounted() {
    if (this.is2xChallenge) {
      this.$store.commit("setBreadcrumbs", [
        { name: "2X Global Portal", url: "/2x-global/Home" },
        { name: "2X Challenge", url: "/2x-global/2X-Challenge" },
        { name: "2X Challenge Self-Assessment In Progress", url: "/2x-global/2X-Challenge/2X-Challenge-Self-Assessment-In-Progress" },
      ]);
    }
    else {
      this.$store.commit("setBreadcrumbs", [
        { name: "2X Global Portal", url: "/2x-global/Home" },
        { name: "2X Certification", url: "/2x-global/2X-Certification" },
        { name: "2X Certification Self-Assessment In Progress", url: "/2x-global/2X-Certification/2X-Certification-Self-Assessment-In-Progress" },
      ]);
    }

    const userStore = useUserStore();
    if (
      userStore.user.hasOwnProperty("twoXGlobal") &&
      userStore.user.twoXGlobal.length === 0
    ) {
      await this.$router.push({ name: "homePage" });
    }
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.fetchinProgressAssessmentsData();
    await this.fetchinProgressSurveysData();
    this.endofcreated = true;
  },
  methods: {
    async changeSurveysDatasPage(page) {
      this.surveysPage = page;
      await this.fetchinProgressSurveysData();
    },
    async changeAssessmentsPage(page) {
      this.assessmentsPage = page;
      await this.fetchinProgressAssessmentsData();
    },
    async fetchinProgressAssessmentsData() {
      const response = await apiService.fetchinProgressAssessments(this.assessmentsPage, 10, this.is2xChallenge ? 'Challenge' : 'Certification', this.startDate, this.statusFilter);
      this.certificationData = response?.data?.data;
      // this.surveyStatistics = response.data?.data?.aggregatedSurveyAnswers;
    },
    async fetchinProgressSurveysData() {
      const response = await apiService.fetchinProgressSurveys(this.surveysPage, 10, this.is2xChallenge ? 'Challenge' : 'Certification');
      this.surveyStatistics = response.data?.data;
    },
  },
  computed: {
    is2xChallenge() {
      return this.$route.path.includes('2X-Challenge');
    }
  },
};
</script>
<style lang="scss" scoped>
.dashboard-container {
  background-color: #f4f1ed;
  // color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 7em;

  .dashboard-section {
    .breadcrumb {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .dashboard-heading-section {
      font-family: Montserrat;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }

    .input-sort-section {
      .search-section {
        .input-select {
          border: 1px solid #e1e3e9;
          padding: 12px 24px 12px 64px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        border-radius: 8px 0px 0px 0px;
        border: 1px 0px 0px 0px;
      }

      .sort-section {
        .sort-option-section {
          select {
            border-radius: 8px;
            border: 1px solid #e1e3e9;
            gap: 8px;
            color: #2c2e35;
            border-radius: 10px;
          }
        }
      }
    }

    .company-list-section {
      .table-section {
        .document-upload-table-section {
          border-radius: 16px;
          border: 1px solid #666461;

          table {
            .no-match-found-text {
              border-bottom: none;
              height: 68px;
              padding: 22px 24px;
              gap: 1px;
              font-family: Arial;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              color: #2c2e35;
              text-align: right;
              margin-right: 5%;
            }

            th,
            td {
              border: 1px solid #666461;
            }

            .table-heading-row {
              th {
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                  linear-gradient(0deg, #c2beba, #c2beba);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2c2e35;
              }

              th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
              }

              th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
              }
            }

            .table-content-row {
              img {
                width: 24px;
                height: 24px;
              }

              td {
                border-bottom: none;
                height: 68px;
                padding: 22px 24px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;
              }

              td:first-child {
                border-left: none;
              }

              td:last-child {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
