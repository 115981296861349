import * as d3 from 'd3';
import * as Plot from "@observablehq/plot";
import { EQUILO_COLOR_SCHEME } from "@/helpers/constants";

export const createBarChart = (containerId, data, color = EQUILO_COLOR_SCHEME, orientation = "vertical", height = 400, title='') => {
  const container = document.getElementById(containerId);
  if (!container) {
    console.error(`Container with ID ${containerId} not found`);
    return;
  }

  if (data.length === 0) {
    container.style.position = "relative";
    const noDataParagraph = document.createElement("p", );
    noDataParagraph.style.fontSize = "16px";
    noDataParagraph.style.position = "absolute";
    noDataParagraph.style.top = "48%";
    noDataParagraph.style.left = "50%";
    noDataParagraph.style.transform = "translate(-50%, -50%)";
    noDataParagraph.textContent = "No data available";
    container.appendChild(noDataParagraph);
    return;
  }

  let colorConfig = { scheme: color, legend: true, legendPosition: "bottom" };
  if (color !== "spectral") {
    colorConfig = { range: color, legend: true, fill: "stack" };
  }
  let chart;

  if (orientation === "vertical") {
    chart = Plot.plot({
      height: height,
      x: { axis: null },
      y: { tickFormat: "s", grid: true },
      color: colorConfig,
      marks: [
        Plot.barY(data, {
          x: "period",
          y: "value",
          fill: "period",
          fx: "",
          fy: ""
        }),
        Plot.ruleY([0])
      ],
      title: title,
      marginTop: 50,
      style: {
        fontSize: "20px"
      }
    });
  } else {
    chart = Plot.plot({
      height: height,
      y: { axis: null },
      x: { tickFormat: "s", grid: true },
      color: colorConfig,
      marks: [
        Plot.barX(data, {
          y: "period",
          x: "value",
          fill: "period",
          fy: "",
          fx: ""
        }),
        Plot.ruleX([0])
      ],
      title: title,
      marginTop: 50,
      style: {
        fontSize: "20px"
      }
    });
  }

  // Append the chart to the DOM
  container.appendChild(chart);
};

export const createStackedBarChart = (data, color = EQUILO_COLOR_SCHEME, orientation = "vertical", height = 400) => {
  let colorConfig = { scheme: color, legend: true };
  if (color !== "spectral") {
    colorConfig = { range: color, legend: true, fill: "stack" };
  }

  if (orientation === "vertical") {
    return Plot.plot({
      height: height,
      x: {
        axis: true, // Show the x-axis
        label: "Period", // Optional: Add a label to the x-axis
      },
      y: { tickFormat: "s", grid: true },
      color: colorConfig,
      marks: [
        Plot.barY(data, {
          x: "period",
          y: "value",
          fill: "stack", // Ensure your data has a 'stack' field for stacking
        }),
        Plot.ruleY([0]),
      ],
    });
  }

  return Plot.plot({
    height: height,
    y: {
      axis: true,
      label: "Period",
      padding: 0.25,
    },
    x: { tickFormat: "s", grid: true },
    color: colorConfig,
    marks: [
      Plot.barX(data, {
        y: "period",
        x: "value",
        fill: "stack",
        width: 100,
      }),
      Plot.ruleX([0]),
    ],
  });
};

export const createLineChart = (data) => {
  return Plot.plot({
    marks: [
      Plot.ruleY([0]),
      Plot.lineY(data, {
        x: "label",
        y: "value",
        stroke: "period",
        marker: true,
      }),
    ],
  });
};

export const createPieChart = (
  container, data, width = 400, height = 400, colorScheme = EQUILO_COLOR_SCHEME, title = ""
) => {
  const radius = Math.min(width, height) / 2;

  const svg = d3.select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height + 100); // Adjust height to accommodate legend and title

  // Add title
  if (title) {
    svg.append('text')
      .attr('x', 10) // Position the title at the top-left corner
      .attr('y', 20)
      .attr('text-anchor', 'start')
      .style('font-size', '16px')
      .style('font-weight', 'bold')
      .text(title);
  }

  // Add text if it's empty
  if (data.length === 0) {
    svg.append('text')
      .attr('transform', `translate(${width / 2}, ${(height / 2) + 50})`) // Centered vertically and horizontally
      .attr('text-anchor', 'middle') // Center the text horizontally
      .style('font-size', '16px')
      .style('font-weight', 'regular')
      .text('No data available');
  }

  const chartGroup = svg.append('g')
    .attr('transform', `translate(${width / 2}, ${(height / 2) + 30})`); // Adjust to place below the title

  const color = d3.scaleOrdinal()
    .domain(data.map(d => d.label))
    .range(colorScheme);

  const pie = d3.pie()
    .value(d => d.value);

  const arcGenerator = d3.arc()
    .innerRadius(0)
    .outerRadius(radius);

  chartGroup.selectAll('path')
    .data(pie(data))
    .enter()
    .append('path')
    .attr('d', arcGenerator)
    .attr('fill', d => color(d.data.label))
    .attr('stroke', 'white')
    .attr('stroke-width', 2);

  chartGroup.selectAll('text')
    .data(pie(data))
    .enter()
    .append('text')
    .text(d => d.data.value)
    .attr('transform', d => `translate(${arcGenerator.centroid(d)})`)
    .style('text-anchor', 'middle')
    .style('font-size', '14px');

  const legend = chartGroup.append('g')
    .attr('transform', `translate(${-width / 2}, ${height / 2 + 10})`);

  const legendItemWidth = 100; // Width to allow per legend item including text
  legend.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', (d, i) => (i % Math.floor(width / legendItemWidth)) * legendItemWidth)
    .attr('y', (d, i) => Math.floor(i / Math.floor(width / legendItemWidth)) * 20 + 5)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', d => color(d.label));

  const maxLabelLength = 9; // Max length for the visible label
  legend.selectAll('text')
    .data(data)
    .enter()
    .append('text')
    .attr('x', (d, i) => (i % Math.floor(width / legendItemWidth)) * legendItemWidth + 12)
    .attr('y', (d, i) => Math.floor(i / Math.floor(width / legendItemWidth)) * 20 + 10)
    .text(d => {
      return d.label.length > maxLabelLength
        ? d.label.slice(0, maxLabelLength) + "..."
        : d.label;
    })
    .attr('title', d => d.label) // Full label on hover
    .style('font-size', '12px')
    .style('alignment-baseline', 'middle')
    .append('title') // Adding 'title' for tooltip
    .text(d => d.label);
};

export const createDonutChart = (
  container, data, width = 400, height = 400, colorScheme = EQUILO_COLOR_SCHEME, title = ""
) => {
  const radius = Math.min(width, height) / 2;

  const svg = d3.select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height + 100); // Adjust height to accommodate legend and title

  // Add title
  if (title) {
    svg.append('text')
      .attr('x', 10) // Position the title at the top-left corner
      .attr('y', 20)
      .attr('text-anchor', 'start')
      .style('font-size', '16px')
      .style('font-weight', 'bold')
      .text(title);
  }

  // Add text if it's empty
  if (data.length === 0) {
    svg.append('text')
      .attr('transform', `translate(${width / 2}, ${(height / 2) + 50})`) // Centered vertically and horizontally
      .attr('text-anchor', 'middle') // Center the text horizontally
      .style('font-size', '16px')
      .style('font-weight', 'regular')
      .text('No data available');
  }

  const chartGroup = svg.append('g')
    .attr('transform', `translate(${width / 2}, ${(height / 2) + 30})`); // Adjust to place below the title

  const color = d3.scaleOrdinal()
    .domain(data.map(d => d.label))
    .range(colorScheme);

  const pie = d3.pie()
    .value(d => d.value);

  const arcGenerator = d3.arc()
    .innerRadius(radius * 0.7)
    .outerRadius(radius);

  chartGroup.selectAll('path')
    .data(pie(data))
    .enter()
    .append('path')
    .attr('d', arcGenerator)
    .attr('fill', d => color(d.data.label))
    .attr('stroke', 'white')
    .attr('stroke-width', 2);

  chartGroup.selectAll('text')
    .data(pie(data))
    .enter()
    .append('text')
    .text(d => d.data.value)
    .attr('transform', d => `translate(${arcGenerator.centroid(d)})`)
    .style('text-anchor', 'middle')
    .style('font-size', '14px');

  // Legend
  const legend = chartGroup.append('g')
    .attr('transform', `translate(${-width / 2}, ${height / 2 + 10})`);

  const legendItemWidth = 100; // Width to allow per legend item including text
  legend.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', (d, i) => (i % Math.floor(width / legendItemWidth)) * legendItemWidth)
    .attr('y', (d, i) => Math.floor(i / Math.floor(width / legendItemWidth)) * 20 + 5)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', d => color(d.label));

  const maxLabelLength = 9; // Max length for the visible label
  legend.selectAll('text')
    .data(data)
    .enter()
    .append('text')
    .attr('x', (d, i) => (i % Math.floor(width / legendItemWidth)) * legendItemWidth + 12)
    .attr('y', (d, i) => Math.floor(i / Math.floor(width / legendItemWidth)) * 20 + 10)
    .text(d => {
      return d.label.length > maxLabelLength
        ? d.label.slice(0, maxLabelLength) + "..."
        : d.label;
    })
    .attr('title', d => d.label) // Full label on hover
    .style('font-size', '12px')
    .style('alignment-baseline', 'middle')
    .append('title') // Adding 'title' for tooltip
    .text(d => d.label);
};
