<template>
  <div class="container mx-auto py-6">
    <h2 class="text-2xl font-bold mb-4 text-gray-800">2X {{ is2xChallenge ? 'Challenge' : 'Certification' }} Self-Assessment Modules Status</h2>
    <div class="shadow-md rounded-lg overflow-hidden">
      <div class="max-h-[80vh] overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-[#201747] sticky top-0 z-30">
            <tr class="divide-x divide-gray-200">
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap sticky left-0 z-30 bg-[#201747]">
                Entity Name
              </th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Email ID
              </th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Modules Completed
              </th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Modules Pending
              </th>
              <th class="px-4 py-3 text-center text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap" :colspan="Object.keys(surveyMap).length">
                Survey Module Details
              </th>
            </tr>
            <tr class="divide-x divide-gray-200">
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap sticky left-0 bg-[#201747] z-30"></th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap"></th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap"></th>
              <th class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap"></th>
              <th v-for="surveyId in Object.keys(surveyMap)" :key="surveyId" class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider whitespace-nowrap border-t">
                {{ surveyMap[surveyId] }}
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="item in surveyData?.aggregatedSurveyAnswers" :key="item._id" class="hover:bg-gray-50 divide-x divide-gray-200">
              <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 sticky left-0 bg-white z-20">
                {{ item?.organizationId?.name ? item?.organizationId?.name.slice(0, 10) : 'N/A' }}
              </td>
              <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                {{ item.email ? item.email : 'N/A' }}
              </td>
              <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {{ item.completedSurveys || '0' }}
                </span>
              </td>
              <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                  {{ item.inProgressSurveys || '0' }}
                </span>
              </td>
              <td v-for="surveyId in Object.keys(surveyMap)" :key="surveyId" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                <template v-if="getSurveyStatus(item, surveyId) !== null">
                  <img v-if="getSurveyStatus(item, surveyId)" src="@/assets/images/Rightcircle.svg" class="w-5 h-5" />
                  <img v-else src="@/assets/images/Crosscircle.svg" alt="error" class="w-4 h-4" />
                </template>
                <img v-else src="@/assets/images/Crosscircle.svg" alt="error" class="w-4 h-4" />
              </td>
            </tr>
            <tr v-if="!surveyData.count" class="text-center">
              <td colspan="100%">No data found!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoXSurveyStatistics",
  props: {
    surveyData: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      surveyMap: {
        "2x-intake": "Intake",
        "2x-governance": "Governance",
        "2x-esg": "ESG",
        "2x-entrepreneurship": "Entrepreneurship",
        "2x-lead": "Leadership",
        "2x-employment": "Employment",
        "2x-supply-chain": "Supply Chain",
        "2x-products-services": "Products & Services",
        "2x-commitment": "Commitments",
        "2x-portfolio": "Portfolio",
      }
    };
  },
  methods: {
    getSurveyStatus(item, surveyId) {
      const survey = item.surveyDetail ? item.surveyDetail.find(s => s.surveyId === surveyId) : null;
      return survey ? survey.isComplete : null;
    },
  },
  computed: {
    is2xChallenge() {
      return this.$route.path.includes('2X-Challenge');
    }
  }
};
</script>