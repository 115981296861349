const ORGANIZATION_TYPES = [
  {
    name: "Investor making investments",
    code: "IVM",
  },
  {
    name: "Financial institution lending to businesses",
    code: "FILB"
  },
  {
    name: "Enterprise Support Organization",
    code: "ESO"
  },
  {
    name: "Professional in international development",
    code: "PID",
  },
  {
    name: "Humanitarian actor",
    code: "HA",
  },
  {
    name: "Associate of company producing goods services not targeting international development",
    code: "ACPGSNTID",
  },
  {
    name: "Academic / researcher",
    code: "AR",
  },
  {
    name: "Not sure / Something else",
    code: "BASIC",
  },

];

const EQUILO_COLOR_SCHEME = ['#FF501C', '#4DC9BE', '#FFDE60', '#191B45', '#43467C', '#2177C1',
  '#4E99DB', '#4DC9BE', '#13A094', '#E8AD17'];

export { ORGANIZATION_TYPES, EQUILO_COLOR_SCHEME };
