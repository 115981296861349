import axios from "axios";
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import Sectors from "@/helpers/sectors";

const BASE_URL = env.apiUrl;

export default {
  fetchPlanList() {
    return axios.get(`${BASE_URL}/subscriptions/get-planlist/equilo`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchUserSurveys(type) {
    return axios.get(
      `${BASE_URL}/survey-answers/user-answer?surveyType=${type}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchRoiUserSurvey(type) {
    return axios.get(
      `${BASE_URL}/survey-answers/user-answer?surveyType=${type}&handleMultipleDuplicate=true`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetch2xUserSurvey(type, teamid) {
    let url;
    if (teamid) {
      url = `${BASE_URL}/survey-answers/user-answer?surveyType=${type}&handleMultipleDuplicate=true&teamId=${teamid}`;
    } else {
      url = `${BASE_URL}/survey-answers/user-answer?surveyType=${type}&handleMultipleDuplicate=true`;
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2xCertRequest(teamid) {
    return axios.get(
      `${BASE_URL}/certify2x/2x-certification-verification-request?teamId=${teamid}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  sendRequestForMatchmakingAccess() {
    return axios.post(`${BASE_URL}/twox-match-making-requests`, null, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2xModuleSelection() {
    return axios.get(`${BASE_URL}/surveys/user-survey-modules?type=2x`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  post2xModuleSelection(modules) {
    return axios.post(
      `${BASE_URL}/surveys/user-survey-modules`,
      {
        type: "2x",
        modules,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchVerifierRequests(teamid, filterParams, pageDetails) {
    let url;
    if (teamid) {
      url = `${BASE_URL}/verifiers/request/${teamid}`;
    } else {
      url = `${BASE_URL}/verifiers/requests`;
    }

    if (filterParams) {
      url += `?${new URLSearchParams(filterParams).toString()}`;
    }
    if (pageDetails) {
      url += `?${new URLSearchParams(pageDetails).toString()}`;
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  assignVerifierToRequest(verifierId, requestId) {
    return axios.put(
      `${BASE_URL}/verifiers/request/assignee`,
      {
        assignee: verifierId,
        requestId,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchAllVerifiers() {
    return axios.get(`${BASE_URL}/verifiers/team-members`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchGlobalRequests(filterParams, pageDetails, teamid) {
    let url;
    if (teamid) {
      url = `${BASE_URL}/verifiers/requests/2XGlobal/${teamid}`;
    } else {
      url = `${BASE_URL}/verifiers/requests/2XGlobal`;
    }
    if (filterParams) {
      url += `?${new URLSearchParams(filterParams).toString()}`;
    }
    if (pageDetails) {
      url += `?${new URLSearchParams(pageDetails).toString()}`;
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2xCertDataPageWise(page, entity, teamId) {
    let url;
    if(teamId) {
      url = `/twox-framework/fetch-data?teamId=${teamId}`
    } else {
      url = `/twox-framework/fetch-data`
    }
    return axios.post(
      env.apiUrl + url,
      {
        page: page,
        entity: entity,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  updateAssessStatus(teamId, status, remarks) {
    let body;
    if (status == "Rejected") {
      body = {
        teamId: teamId,
        status: status,
        remarks: remarks,
      };
    } else {
      body = {
        teamId: teamId,
        status: status,
      };
    }
    return axios.put(`${BASE_URL}/verifiers/status`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  updateGlobalStatus(teamId, status, remarks) {
    let body;
    if (status == "Rejected") {
      body = {
        teamId: teamId,
        status: status,
        remarks: remarks,
      };
    } else {
      body = {
        teamId: teamId,
        status: status,
      };
    }
    return axios.put(`${BASE_URL}/verifiers/status/2XGlobal`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchCountryData(iso) {
    return axios.get(`${BASE_URL}/countries/${iso}/sectors/country`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchSectorData(iso, sectorName) {
    const sectorAry = Sectors.sectors;
    let sectorValue = "Other";
    let sectorData = sectorAry.find((sector) => sector.name === sectorName);
    if (sectorData) {
      sectorValue = sectorData.label;
    }

    if (sectorName == "funds") {
      sectorValue = "2X_FNCE";
    }
    return axios.get(`${BASE_URL}/sectors/${sectorValue}`, {
      params: { iso: iso },
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  postVerifiersComments(data, teamId) {
    return axios.post(
      `${BASE_URL}/verifiers/comments`,
      {
        comment: data,
        teamId: teamId,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchVerifiersComments(teamId) {
    return axios.get(`${BASE_URL}/verifiers/comments?teamId=${teamId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  putVerifiersComments(data, id) {
    return axios.put(
      `${BASE_URL}/verifiers/comments/${id}`,
      {
        comment: data,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  removeVerifiersComments(id) {
    return axios.delete(`${BASE_URL}/verifiers/comments/${id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchVerifiedStatus(teamId) {
    return axios.get(
      `${BASE_URL}/verifiers/verified-data-points?teamId=${teamId}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  putVerifiedStatus(teamId, questionId, status) {
    return axios.put(
      `${BASE_URL}/verifiers/verify-data-point`,
      {
        teamId,
        dataPoint: questionId,
        status,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchEditableQuestionAnswer(questionId, entity) {
    return axios.get(
      `${BASE_URL}/verifiers/data-point-question?questionId=${questionId}&entityType=${entity}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  updateQuestionAnswer(teamId, questionId, answer, uuid) {
    return axios.put(
      `${BASE_URL}/verifiers/data-point`,
      {
        teamId,
        questionId,
        answer,
        uuid,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchThirdPartyPartnerList() {
    return axios.get(`${BASE_URL}/users/verifiers`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2xCertificationData(entityChosen, teamId, assessmentType) {
    let entity = entityChosen;
    if (entityChosen == "financial-institution") {
      entity = "Financial Institution";
    }
    if (entityChosen == "fund-manager") {
      entity = "Fund Manager";
    }
    if (entityChosen == "start-up") {
      entity = "Start-up";
    }
    if (entityChosen == "sme") {
      entity = "SME";
    }
    if (entityChosen == "corporate") {
      entity = "Corporate";
    }
    let url = "";
    if (teamId) {
      url = `${BASE_URL}/certify2x/2x-certification-result?entityType=${entity}&teamId=${teamId}&assessmentType=${assessmentType}`;
    } else {
      url = `${BASE_URL}/certify2x/2x-certification-result?entityType=${entity}&assessmentType=${assessmentType}`;
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchSummaryReportData(teamId) {
    return axios.get(`${BASE_URL}/verifiers/summary-report?teamId=${teamId}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchUploadDocumentsData(teamId) {
    return axios.get(
      `${BASE_URL}/certify2x/verification-documents?teamId=${teamId}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  sendRequestToThirdPartyPartner(verifierId, userNotes) {
    const payload = { userNotes };

    if (verifierId !== "Unknown") {
      payload.verifierId = verifierId;
    }

    return axios.post(
      `${BASE_URL}/certify2x/2x-certification-verifier`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`,
        },
      }
    );
  },
  putVerificationRecommendsStatusByVerifier(
    teamId,
    data,
    status,
    reason = null
  ) {
    let body;
    if (status == "Rejected") {
      body = {
        teamId,
        validationRecommendation: {
          [data.variable]: {
            status,
            reason,
          },
        },
      };
    } else {
      body = {
        teamId,
        validationRecommendation: {
          [data.variable]: {
            status,
          },
        },
      };
    }
    return axios.put(
      `${BASE_URL}/verifiers/verification-recommendation-status`,
      body,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  putDocumentStatusByVerifier(data, id, status) {
    let body;
    if (status == "Rejected") {
      body = {
        status: status,
        comment: data,
      };
    } else {
      body = {
        status: status,
      };
    }
    return axios.put(`${BASE_URL}/certify2x/status/${id}`, body, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2XPortfolioInvestees(investeeId) {
    let url;
    if (investeeId) {
      url = `${BASE_URL}/2x-invites/2x-investees/${investeeId}`;
    } else {
      url = `${BASE_URL}/2x-invites/2x-investees`;
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetch2xPortfolioData(orgID) {
    return axios.get(
      `${BASE_URL}/2x-invites/2x-investees/aggregated/${orgID}?portfolioType=Alumni`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetch2xPortfolioCsvData(orgID) {
    return axios.get(
      `${BASE_URL}/2x-invites/2x-investees/csv-data/${orgID}?portfolioType=Alumni`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchThresholdsData(recordId, sector) {
    return axios.get(
      `${BASE_URL}/thresholds2x/benchmark-details?iso=${recordId}&sector=${sector.replace(
        "&",
        "%26"
      )}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchCompanyProfile(companyId) {
    const url = companyId
      ? `${BASE_URL}/imm/company-profile?companyId=${companyId}`
      : `${BASE_URL}/imm/company-profile`;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  postFeedback(rating, comment, type) {
    return axios.post(
      `${BASE_URL}/feedbacks`,
      {
        rating,
        comment,
        type,
      },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
  fetchinProgressAssessments(page, pageSize, journeyType, startDate, status) {
    let url = '';
    if(startDate && status) {
      url = `${BASE_URL}/certify2x/in-progress-requests?page=${page}&pageSize=${pageSize}&journeyType=${journeyType}&startDate=${startDate}&status=${status}`
    }
    if(!startDate && !status) {
      url = `${BASE_URL}/certify2x/in-progress-requests?page=${page}&pageSize=${pageSize}&journeyType=${journeyType}`
    }
    if(startDate && !status) {
      url = `${BASE_URL}/certify2x/in-progress-requests?page=${page}&pageSize=${pageSize}&journeyType=${journeyType}&startDate=${startDate}`
    }
    if(!startDate && status) {
      url = `${BASE_URL}/certify2x/in-progress-requests?page=${page}&pageSize=${pageSize}&journeyType=${journeyType}&status=${status}`
    }
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchinProgressSurveys(page, pageSize, journeyType) {
    return axios.get(`${BASE_URL}/certify2x/in-progress-surveys?page=${page}&pageSize=${pageSize}&journeyType=${journeyType}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
  },
  fetchAnsweredSurveyModules(surveyType, uuid) {
    return axios.get(
      `${BASE_URL}/survey-answers/user-title-answer?type=${surveyType}&uuid=${uuid}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
  },
};
