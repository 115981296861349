<template>
  <div v-if="values">
    <div v-for="(value, k) in values" :key="k">
      <div v-if="value.questions" class="table-container">
        <table class="inner-table">
          <tr class="table-heading-row">
            <th></th>
            <th><span>2024</span></th>
            <th
              v-if="
                $route.path.includes('/admin') &&
                value?.questions?.some(
                  (data) => data?.surveyQType != 'Calculated'
                )
              "
            ></th>
          </tr>
          <tr
            class="table-content-row"
            v-for="data in value.questions"
            :key="data.title"
          >
            <td>
              <span>{{ data.title }}</span>
            </td>
            <td class="text-center">
              <img
                v-if="data.value == 'Yes'"
                src="@/assets/images/rightsign-2x-challenge.svg"
              />
              <img
                v-if="data.value == 'No'"
                src="@/assets/images/Crosscircle-2x-challenge.svg"
              />
            </td>
            <td
              v-if="
                $route.path.includes('/admin') &&
                data?.surveyQType != 'Calculated'
              "
            >
              <div
                class="flex gap-2 transition-all items-center duration-300 ease-in-out m-auto"
              >
                <div class="flex items-center gap-3 justify-end">
                  <img
                    src="@/assets/images/edit-dark.svg"
                    alt="edit-icon"
                    class="h-6 w-6 cursor-pointer"
                    @click="openEditModal(data)"
                  />
                  <verification-dropdown :value="data" />
                  <div>
                    <MLVerifierValue
                      :value="data"
                      :teamId="$route.query?.teamId"
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <span
          v-if="value.textForChartInfo"
          class="rounded-md p-2 text-md text-center max-w-[25rem]"
          v-html="createInteractiveText(value)"
          @click="handleSpanClick"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import { createInteractiveText, handleSpanClick } from "./helpers";

export default {
  name: "CustomTableView",
  data: function () {
    return {
      updateKey: 0,
    };
  },
  components: { VerificationDropdown, MLVerifierValue },
  props: { values: Array },
  methods: {
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
    createInteractiveText(value) {
      return createInteractiveText(value);
    },
    handleSpanClick(event) {
      handleSpanClick(event, this.openEditModal);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  // gap: 20px;
  border-radius: 16px;
  border: 1px solid #e1e3e9;
  .inner-table {
    width: 100%;
    th,
    td {
      border: 1px solid #e1e3e9;
    }
    .table-heading-row {
      th {
        padding: 16px;
        // gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Montserrat, Arial;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }
      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }
      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }
    .table-content-row {
      img {
        width: 24px;
        // height: 24px;
        margin: auto;
      }
      td {
        border-bottom: none;
        // height: 68px;
        padding: 16px 12px 16px 12px;
        // gap: 1px;
        font-family: Montserrat, Arial;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2c2e35;
      }
      td:first-child {
        border-left: none;
      }
      td:last-child {
        border-right: none;
      }
    }
  }
  .red {
    color: #cb2e00;
  }
  .green {
    color: #13a094;
  }
}
</style>
