<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ excludedActivities || 0 }}</span>
      </div>
      <span class="text-sm font-semibold text-gray-500"
        >Excluded Activities</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ExclusionModuleCardContent",
  props: {
    excludedActivities: Number, // Source: ESG_Exclusion_100
    aggregatedView: Boolean,
  },
  mounted() {
    // TODO: Add logic to calculate impact rating and energy emission
  },
};
</script>
