<template>
  <div class="dashboard-container">
    <div id="notLoaded" v-if="isLoading" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="dashboard-section container mx-auto px-4 lg:px-0 pb-32"
      v-if="!isLoading"
    >
      <div class="breadcrumb">2X Verifier Dashboard</div>
      <div
        class="dashboard-heading-section flex mt-6 text[#666461] justify-between items-center"
      >
        <div class="flex items-center gap-4">
          <div class="font-bold text-[#191b45]">
            2X Certification Verification Portal
          </div>
          <!-- <div :class="{
            'font-bold text-[#191b45] cursor-auto': $route.path == '/admin',
          }" @click="$router.push({ path: '/admin' })">
            Admin
          </div>
          <div :class="{
            'font-bold text-[#191b45] cursor-auto': $route.path == '/',
          }" @click="$router.push({ path: '/' })">
            Equilo Tools
          </div> -->
        </div>
        <div>
          <EquiloEmailInviteForm :portal="'Verifier'" />
        </div>
      </div>
      <div class="input-sort-section mt-6 flex justify-between items-center">
        <div class="search-section w-1/4">
          <div
            class="text-sm leading-5 w-full relative flex flex-row items-center"
          >
            <svg
              class="absolute left-0 ml-4 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Artboard 329</title>
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <input
              type="text"
              class="input-select rounded-lg px-3 p-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              v-model="searchCompanyQuery"
              placeholder="Search by company name"
            />
          </div>
        </div>
        <div class="flex justify-center items-center gap-2">
          <div class="sort-section">
            <div class="sort-option-section">
              <select
                name="sortOptions"
                class="cursor-pointer"
                id="sortOptions"
                v-model="entityType"
              >
                <option value="" selected>All Entity Types</option>
                <option value="start-up">Start-up</option>
                <option value="sme">SME</option>
                <option value="corporate">Corporate</option>
                <option value="fund-manager">Fund Manager</option>
                <option value="financial-institution">
                  Financial Institution
                </option>
              </select>
            </div>
          </div>
          <div class="sort-section">
            <div class="sort-option-section">
              <select
                name="sortOptions"
                class="cursor-pointer"
                id="sortOptions"
                v-model="statusType"
              >
                <option value="" selected>All statuses</option>
                <option value="In Progress">In Progress</option>
                <option value="Validated">Verified</option>
                <option value="Rejected">Rejected</option>
                <option value="Certified">Certified</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="company-list-section mt-6">
        <div class="table-section">
          <div
            class="relative overflow-x-auto document-upload-table-section rounded-lg"
            v-cloak
          >
            <table
              class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
              :key="'table' + updateKey"
            >
              <thead
                class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base"
              >
                <tr class="table-heading-row">
                  <th scope="col" class="px-6 py-4">Entity Name</th>
                  <th scope="col" class="px-6 py-4">
                    Date of assessment submission
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Date of verification request
                  </th>
                  <th scope="col" class="px-6 py-4">Progress %</th>
                  <th scope="col" class="px-6 py-4">Status</th>
                  <th scope="col" class="px-6 py-4">Assigned to</th>
                  <th scope="col" class="px-6 py-4"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-content-row dark:bg-gray-800 text-base"
                  v-for="(company, i) in filterCompanyList"
                  v-show="companyList?.length > 0"
                  :key="i"
                >
                  <td
                    @click="
                      $router.push({
                        path: '/admin/assessment',
                        query: { teamId: company?.teamId?._id },
                      })
                    "
                    scope="row"
                    class="font-medium cursor-pointer text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ company?.teamId?.name }}
                  </td>
                  <td
                    class="font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{
                      moment(
                        company?.certificationReqId?.fullSurveySubmittedDate
                      ).format("DD MMMM, YYYY")
                    }}
                  </td>
                  <td
                    class="font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ moment(company?.createdAt).format("DD MMMM, YYYY") }}
                  </td>
                  <td
                    class="font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{
                      company?.lastCompletedMilestone
                        ? Math.round(company?.lastCompletedMilestone * 100) /
                          100
                        : 0
                    }}%
                  </td>
                  <td>
                    <div class="flex items-center justify-center w-[100px]">
                      <div
                        class="flex text-white text-sm items-center px-2 py-1 font-bold bg-[#fcc30b] rounded-lg"
                        v-if="company?.status === 'In Progress'"
                      >
                        <img
                          src="@/assets/images/2x_certify/verification-eye.svg"
                        />
                        <span class="ml-2">Pending</span>
                      </div>
                      <div
                        class="flex text-white text-sm items-center px-2 py-1 font-bold bg-[#ff3a21] rounded-lg"
                        v-if="company?.status === 'Rejected'"
                      >
                        <img
                          src="@/assets/images/2x_certify/verification-close.svg"
                        />
                        <span class="ml-2">Rejected</span>
                      </div>
                      <div
                        class="flex text-white text-sm items-center px-2 py-1 font-bold bg-[#395aff] rounded-lg"
                        v-if="company?.status === 'Validated'"
                      >
                        <img src="@/assets/images/2x_certify/check.svg" />
                        <span class="ml-2">Verified</span>
                      </div>
                      <div
                        class="flex flex-grow text-white text-sm items-center px-2 py-1 font-bold bg-[#4c9f38] rounded-lg"
                        v-if="company?.status === 'Certified'"
                      >
                        <img src="@/assets/images/2x_certify/check.svg" />
                        <span class="ml-1">Certified</span>
                      </div>
                    </div>
                  </td>
                  <td class="w-[200px]">
                    <select
                      v-if="
                        company?.verifierId?._id === user?.organization?._id &&
                        user?.admin &&
                        company.status === 'In Progress'
                      "
                      v-model="company.assignedTo._id"
                      @change="updateVerifier(company)"
                      :class="{ 'pointer-events-none': !user.admin }"
                      style="padding-right: 26px"
                      class="mt-1 cursor-pointer block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:border-blue-500 sm:text-sm"
                    >
                      <option value="" disabled>Select a team member</option>
                      <option
                        v-for="verifier in verifiers"
                        :key="verifier._id"
                        :value="verifier._id"
                      >
                        {{ verifier.firstname }} {{ verifier.lastname }}
                      </option>
                    </select>
                    <div v-else>
                      <span
                        v-if="
                          company?.assignedTo?.firstname ||
                          company?.assignedTo?.lastname
                        "
                      >
                        {{ company?.assignedTo?.firstname }}
                        {{ company?.assignedTo?.lastname }}
                      </span>
                      <span v-else>Not assigned yet</span>
                    </div>
                  </td>
                  <td
                    v-if="
                      user.email.split('@')[1].includes('equilo.') ||
                      user.email.includes('2xglobal.org')
                    "
                  >
                    <button
                      class="[ flex items-center justify-center gap-2 ][ w-32 ][ text-white ][ border-2 border-transparent rounded-md ][ bg-orange-100 ][ py-2 px-4 ][ hover:bg-orange-80 ]"
                      @click="
                        $router.push({
                          path: '/admin/assessment',
                          query: { teamId: company?.teamId?._id },
                        })
                      "
                    >
                      <img
                        src="@/assets/images/edit-white.svg"
                        alt="notebook-pencil"
                      />
                      <span class="text-sm">Verify</span>
                    </button>
                  </td>
                  <td
                    v-else
                    :class="{
                      'pointer-events-none':
                        company?.verifierId?._id !== user?.organization?._id ||
                        company?.assignedTo?._id !== user?._id,
                    }"
                  >
                    <button
                      v-if="company?.paymentStatus === 'Completed'"
                      class="[ flex items-center justify-center gap-2 ][ w-32 ][ text-white ][ border-2 border-transparent rounded-md ][ bg-orange-100 ][ py-2 px-4 ][ hover:bg-orange-80 ]"
                      @click="
                        $router.push({
                          path: '/admin/assessment',
                          query: { teamId: company?.teamId?._id },
                        })
                      "
                    >
                      <img
                        src="@/assets/images/edit-white.svg"
                        alt="notebook-pencil"
                      />
                      <span class="text-sm">Verify</span>
                    </button>
                    <button
                      v-else
                      class="[ flex items-center justify-center gap-2 ][ w-32 ][ text-white ][ border-2 border-transparent rounded-md ][ bg-grey-50 ][ py-2 px-4 ][ hover:bg-orange-100 ]"
                      @click="openPaywall(company)"
                      :disabled="isLoading"
                    >
                      <img
                        src="@/assets/images/lock_white.svg"
                        alt="lock-white"
                      />
                      <span class="text-sm">Upgrade</span>
                    </button>
                  </td>
                </tr>
                <div
                  v-if="filterCompanyList?.length === 0"
                  class="no-match-found-text"
                >
                  No match found!
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <EquiloPagination
        :total-items="parseInt(verifiersData?.totalCount)"
        :items-per-page="parseInt(verifiersData?.pageSize)"
        :current-page="parseInt(verifiersData?.pageNumber)"
        @page-change="changeVerifierPage"
      />
    </div>
    <Modal
      v-if="isPaywallOpen"
      @on-close="(isPaywallOpen = false), (selectedCompany = null)"
    >
      <template>
        <clip-loader
          v-if="isLoadingProducts"
          :size="'80px'"
          :color="'#2BD4C4'"
        />
        <div v-if="!isLoadingProducts && errorProducts" class="[ text-center ]">
          There was an error getting the products, please try it again later.
        </div>
        <Paywall
          v-if="!isLoadingProducts && !errorProducts"
          title="Continue your 2X Certification journey now!"
          subtitle="Take the next step towards certification and/or advance to the next level with our additional product offering."
          :items="products"
          @on-cancel="isPaywallOpen = false"
          @on-buy="handleOnBuyProducts"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import apiService from "@/helpers/apiServices.js";
import EquiloEmailInviteForm from "@/components/common/EquiloEmailInviteForm.vue";
import axios from "axios";
import env from "../../../env";
import Cookies from "@/helpers/cookies";
import Paywall from "@/components/Paywall/index.vue";
import Modal from "@/components/basics/Modal.vue";
import ClipLoader from "vue-spinner/src/ClipLoader";
import EquiloPagination from "@/components/common/EquiloPagination.vue";
import SubscriptionAPIs from "@/api/SubscriptionAPIs";

export default {
  name: "VerifierDashboard",
  data() {
    return {
      user: [],
      companyList: [
        {
          _id: "663b107af3938b15f54bf27c",
          key: "prod/Dev-User-Team-Acct-recVrOfR4ivt6ox7d}/61fa76ed852d0d0a4c863be0-1715146873391-2X Challenge Website Guide for Equilo.pptx",
          name: "2X Challenge Website Guide for Equilo.pptx",
          status: "In Review",
          uploadedBy: {
            _id: "61fa76ed852d0d0a4c863be0",
            email: "trishant.k@simformsolutions.com",
            firstname: "Tony",
            lastname: "Stark",
            organization: "603a83ea018751220f8c677e",
          },
          certificationRequestId: "657046ce12ad9842c5ded95a",
          __v: 0,
          createdAt: "2024-05-08T05:41:14.014Z",
          updatedAt: "2024-05-08T05:41:14.014Z",
          recordId: "reciJKUQRchNHPjwE",
          url: "",
        },
      ],
      searchCompanyQuery: "",
      statusType: "",
      portal: "Verifier",
      email: "",
      verifiers: [],
      selectedVerifier: "",
      debounceTimeout: null,
      entityType: "",
      isLoading: true,
      isLoadingProducts: false,
      errorProducts: "",
      selectedCompany: null,
      isPaywallOpen: false,
      products: [],
      stripeVerifierProductId: null,
      updateKey: 0,
      verifiersData: {},
      company: null,
    };
  },
  components: {
    pageLoader,
    EquiloEmailInviteForm,
    Paywall,
    Modal,
    ClipLoader,
    EquiloPagination,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.getStripeProducts();
    await this.getVerifierData();
    await this.getAllVerifiers();
    this.isLoading = false;
  },
  methods: {
    async getVerifierData(filterParams = null, pageDetails = null) {
      apiService
        .fetchVerifierRequests(null, filterParams, pageDetails)
        .then(async (response) => {
          this.verifiersData = response.data.data;
          this.companyList = response.data.data?.requests;
          this.companyList.forEach((data) => {
            if (
              data.status !== "Rejected" &&
              data.status !== "Certified" &&
              data.status !== "In Progress" &&
              data.status !== ""
            ) {
              data.status = "Validated";
            }
            if (!data.assignedTo) {
              data.assignedTo = { _id: "" };
            }
          });
        });
    },
    changeVerifierPage(page) {
      this.getVerifierData(null, {
        pageNumber: page,
        pageSize: this.verifiersData.pageSize,
      });
    },
    debouncedSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.applyFilters();
      }, 300);
    },
    applyFilters() {
      let queryParams = {};
      if (this.searchCompanyQuery) {
        queryParams.search = this.searchCompanyQuery;
      }
      if (this.entityType) {
        queryParams.entityType = this.entityType;
      }
      this.getVerifierData(queryParams);
    },
    async getAllVerifiers() {
      try {
        const response = await apiService.fetchAllVerifiers();
        this.verifiers = response.data.message;
      } catch (error) {
        console.log(error);
      }
    },
    async updateVerifier(company) {
      try {
        if (company.assignedTo._id !== "") {
          await apiService.assignVerifierToRequest(
            company.assignedTo._id,
            company._id
          );
          await this.getVerifierData();
          this.updateKey++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getStripeProducts() {
      this.isLoadingProducts = true;
      this.errorProducts = "";
      try {
        const response = await axios.get(
          `${env.apiUrl}/subscriptions/twox-products?type=Verifier`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.products = response.data.data;
      } catch (error) {
        console.log(error);
        this.errorProducts = error;
        setTimeout(() => {
          this.errorProducts = "";
        }, 3000);
      } finally {
        this.isLoadingProducts = false;
      }
    },
    openPaywall(company) {
      this.selectedCompany = company;
      this.isPaywallOpen = true;

      this.getStripeProducts();
    },
    async getStripeLink(priceIds) {
      const { paymentStatus, teamId } = this.selectedCompany;
      if (paymentStatus !== "Completed" && teamId?._id) {
        try {
          this.isLoading = true;
          const subscriptionAPI = new SubscriptionAPIs();
          const assessmentURL = `/admin/assessment?teamId=${this.selectedCompany?.teamId?._id}`;
          const encodedAssessmentURL = encodeURIComponent(assessmentURL);
          const redirectURL = `${window.location.origin}/#/check-session?type=Verifier&teamId=${this.user?.organization?._id}&redirectURL=${encodedAssessmentURL}`;
          const response = await subscriptionAPI.createCheckoutSession(
            "Global",
            {
              type: "Verifier",
              teamId: teamId?._id,
              redirectUrl: redirectURL,
              priceIds: priceIds,
            }
          );
          window.location.href = response.data.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleOnBuyProducts(products) {
      const priceIds = products.map((product) => product.stripePriceId);
      this.getStripeLink(priceIds);
    },
  },
  computed: {
    filterCompanyList() {
      return this.companyList.filter((item) => {
        return this.statusType ? item.status === this.statusType : true;
      });
    },
  },
  watch: {
    searchCompanyQuery(value) {
      if (value !== "") {
        this.debouncedSearch();
      } else {
        this.applyFilters();
      }
    },
    entityType() {
      this.applyFilters();
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-container {
  background-color: #f4f1ed;
  // color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 7em;

  .dashboard-section {
    .breadcrumb {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .dashboard-heading-section {
      font-family: Montserrat;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }

    .input-sort-section {
      .search-section {
        .input-select {
          border: 1px solid #e1e3e9;
          padding: 12px 24px 12px 64px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        border-radius: 8px 0px 0px 0px;
        border: 1px 0px 0px 0px;
      }

      .sort-section {
        .sort-option-section {
          select {
            border-radius: 8px;
            border: 1px solid #e1e3e9;
            gap: 8px;
            color: #2c2e35;
          }
        }
      }
    }

    .company-list-section {
      .table-section {
        .document-upload-table-section {
          border: 1px solid #666461;

          table {
            .no-match-found-text {
              border-bottom: none;
              height: 68px;
              padding: 22px 24px;
              gap: 1px;
              font-family: Arial;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              color: #2c2e35;
              text-align: right;
              margin-right: 5%;
            }

            th,
            td {
              border: 1px solid #666461;
            }

            .table-heading-row {
              th {
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                  linear-gradient(0deg, #c2beba, #c2beba);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #2c2e35;
              }

              th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
              }

              th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
              }
            }

            .table-content-row {
              img {
                width: 24px;
                height: 24px;
              }

              td {
                border-bottom: none;
                height: 68px;
                padding: 22px 24px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;
                text-align: center;
              }

              td:first-child {
                border-left: none;
              }

              td:last-child {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
