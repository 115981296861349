<template>
  <div class="dashboard-container">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div class="dashboard-section container mx-auto px-4 lg:px-0 pb-4" v-if="endofcreated">
      <div @click="$router.push({ path: '/2x-global/Home' })" class="breadcrumb">2X Global Certification Dashboard</div>
      <div class="dashboard-heading-section  mt-6 text[#666461] flex justify-between items-center">
          <div></div>
         <EquiloEmailInviteForm :portal="'Verifier'" />
      </div>
      <div class="input-sort-section mt-6 flex justify-between items-center">
        <div class="search-section w-1/4">
          <div class="text-sm leading-5 w-full relative flex flex-row items-center">
            <svg class="absolute left-0 ml-4 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>Artboard 329</title>
              <circle cx="11.059" cy="11.059" r="7.059" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.5" />
              <line x1="20" y1="20" x2="16.05" y2="16.05" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.5" />
              <rect width="24" height="24" fill="none" />
            </svg>
            <input type="text"
              class="input-select rounded-lg px-3 p-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              v-model="searchCompanyQuery" placeholder="Search by company name" />
          </div>
        </div>
        <div class="flex justify-center items-center gap-2">
          <div class="sort-section">
            <div class="sort-option-section">
              <select name="sortOptions" id="sortOptions" v-model="entityType">
                <option value="" selected>All Entity Types</option>
                <option value="start-up">Start-up</option>
                <option value="sme">SME</option>
                <option value="corporate">Corporate</option>
                <option value="fund-manager">Fund Manager</option>
                <option value="financial-institution">Financial Institution</option>
              </select>
            </div>
          </div>
          <div class="sort-section">
            <div class="sort-option-section">
              <select name="sortOptions" id="sortOptions" v-model="statusType">
                <option value="" selected>All statuses</option>
                <option value="In Progress">Pending</option>
                <option value="Validated">Verified</option>
                <option value="Rejected">Rejected</option>
                <option value="Certified">Certified</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="company-list-section mt-6">
        <div class="table-section">
          <div class="relative overflow-x-auto document-upload-table-section" v-cloak>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" :key="'table'+updateKey">
              <thead class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base">
                <tr class="table-heading-row">
                  <th scope="col" class="px-6 py-4 relative">
                    <span class="inline-flex items-center">
                      <span>Entity Name</span>
                      <span class="ml-2 text-gray-500 cursor-pointer group relative">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M12 4a8 8 0 100 16 8 8 0 000-16z" />
                        </svg>
                        <!-- Tooltip on hover -->
                        <div
                          class="absolute top-[-20px] left-7 mt-2 w-72 p-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10"
                        >
                          By clicking on the entity name you will be able to access the entity's 2X Certification results and verification summary report
                        </div>
                      </span>
                    </span>
                  </th>
                  <th scope="col" class="px-6 py-4">Date of assessment submission</th>
                  <th scope="col" class="px-6 py-4">Date of verification request</th>
                  <th scope="col" class="px-6 py-4">Verification Progress (%)</th>
                  <th scope="col" class="px-6 py-4">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-content-row dark:bg-gray-800 text-base" v-for="(company, i) in filterCompanyList"
                  v-show="companyList?.length > 0" :key="i">
                  <td @click="$router.push({ path: '/2x-global/admin/assessment', query: { teamId: company?.teamId?._id } })"
                    scope="row"
                    class="font-medium cursor-pointer text-gray-900 whitespace-nowrap dark:text-white entity-name">
                    {{ company?.teamId?.name }}
                  </td>
                  <td class="font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ company?.certificationReqId?.fullSurveySubmittedDate ? moment(company?.certificationReqId?.fullSurveySubmittedDate).format("DD MMMM, YYYY") : "NA" }}
                  </td>
                  <td class="font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ company?.createdAt ? moment(company?.createdAt).format("DD MMMM, YYYY") : "NA" }}
                  </td>
                  <td class="font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ company?.lastCompletedMilestone ? Math.round(company?.lastCompletedMilestone * 100) / 100 : 0 }}%
                  </td>
                  <td class="">
                    <div class="flex items-center justify-center">
                      <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                        v-if="company?.status === 'In Progress'" style="border-radius: 20px; background-color: #fcc30b">
                        <img src="@/assets/images/2x_certify/verification-eye.svg" />
                        <span class="ml-2">Pending verification</span>
                      </div>
                      <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                        v-if="company?.status === 'Rejected'" style="border-radius: 20px; background-color: #FF3A21">
                        <img src="@/assets/images/2x_certify/verification-close.svg" />
                        <span class="ml-2">Rejected</span>
                      </div>
                      <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                        v-if="company?.status === 'Validated'" style="border-radius: 20px; background-color: #395AFF">
                        <img src="@/assets/images/2x_certify/check.svg" />
                        <span class="ml-2">Verified</span>
                      </div>
                      <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                        v-if="company?.status === 'Certified'" style="border-radius: 20px; background-color: #4C9F38">
                        <img src="@/assets/images/2x_certify/check.svg" />
                        <span class="ml-2">Certified</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="filterCompanyList?.length === 0" class="no-match-found-text">
                  <td colspan="100%" style="border: none; text-align: center;">
                    No match found!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <EquiloPagination
        :total-items="parseInt(globalRequests.totalCount)"
        :items-per-page="parseInt(globalRequests.pageSize)"
        :current-page="parseInt(globalRequests.pageNumber)"
        @page-change="changeVerifierPage"
      />
      <div class="mt-8">
      <div 
        @click="redirectToCertificationInProgress()"
        class="
          bg-white shadow-sm rounded-lg p-6 
          border border-gray-500 cursor-pointer 
          transition-all duration-300 ease-in-out
          hover:shadow-lg text-gray-700
          hover:text-[#0077C8] w-64
        "
      >
        <p class="font-bold">
          2X Certification Self-Assessments in Progress
        </p>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import apiService from '@/helpers/apiServices.js';
import EquiloEmailInviteForm from '@/components/common/EquiloEmailInviteForm.vue';
import EquiloPagination from '@/components/common/EquiloPagination.vue';
import { useUserStore } from "@/stores/user";


export default {
  name: "TwoxGlobdashboardPage",
  data() {
    return {
      user: [],
      companyList: [
        {
          _id: "663b107af3938b15f54bf27c",
          key: "prod/Dev-User-Team-Acct-recVrOfR4ivt6ox7d}/61fa76ed852d0d0a4c863be0-1715146873391-2X Challenge Website Guide for Equilo.pptx",
          name: "2X Challenge Website Guide for Equilo.pptx",
          status: "In Review",
          uploadedBy: {
            _id: "61fa76ed852d0d0a4c863be0",
            email: "trishant.k@simformsolutions.com",
            firstname: "Tony",
            lastname: "Stark",
            organization: "603a83ea018751220f8c677e",
          },
          certificationRequestId: "657046ce12ad9842c5ded95a",
          __v: 0,
          createdAt: "2024-05-08T05:41:14.014Z",
          updatedAt: "2024-05-08T05:41:14.014Z",
          recordId: "reciJKUQRchNHPjwE",
          url: "",
        },
      ],
      searchCompanyQuery: "",
      statusType: "",
      endofcreated: false,
      entityType: '',
      updateKey : 0,
    };
  },
  components: {
    pageLoader,
    EquiloEmailInviteForm,
    EquiloPagination,
  },
  async mounted() {
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Global Portal", url: "/2x-global/Home" },
      { name: "2X Certification", url: "/2x-global/2X-Certification" },
    ]);
    const userStore = useUserStore();
      if(userStore.user.hasOwnProperty("twoXGlobal") && userStore.user.twoXGlobal.length === 0)
      {
        await this.$router.push({ name: 'homePage' });
      }
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.getVerifierData();
    this.endofcreated = true;
  },
  methods: {
    async getVerifierData(filterParams = null, pageDetails = null) {
      await apiService.fetchGlobalRequests(filterParams, pageDetails)
        .then(async response => {
          this.globalRequests = response.data.data;
          this.companyList = response?.data?.data?.requests;
          this.companyList.forEach(data => {
            if (data.status !== 'Rejected' && data.status !== 'Certified' && data.status !== 'In Progress' && data.status !== '') {
              data.status = 'Validated'
            }
          })
        })
    },
    changeVerifierPage(page) {      
      this.getVerifierData(null, { pageNumber: page, pageSize: this.globalRequests.pageSize });
    },
    applyFilters() {
      let queryParams = {};
      if (this.searchCompanyQuery) {
        queryParams.search = this.searchCompanyQuery;
      }
      if (this.entityType) {
        queryParams.entityType = this.entityType;
      }
      this.getVerifierData(queryParams);
    },
    redirectToCertificationInProgress() {
      this.$router.replace({ name: 'TwoXCertificationInProgress'});
    }
  },
  watch: {
    entityType() {
      this.applyFilters();
    },
    searchCompanyQuery() {
      this.applyFilters();
    }
  },
  computed: {
    filterCompanyList() {
      return this.companyList.filter(item => {
        return this.statusType ? item.status === this.statusType : true;
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.dashboard-container {
  background-color: #f4f1ed;
  // color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 7em;

  .dashboard-section {
    .breadcrumb {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .dashboard-heading-section {
      font-family: Montserrat;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }

    .input-sort-section {
      .search-section {
        .input-select {
          border: 1px solid #e1e3e9;
          padding: 12px 24px 12px 64px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }

        border-radius: 8px 0px 0px 0px;
        border: 1px 0px 0px 0px;
      }

      .sort-section {
        .sort-option-section {
          select {
            border-radius: 8px;
            border: 1px solid #e1e3e9;
            gap: 8px;
            color: #2c2e35;
          }
        }
      }
    }

    .company-list-section {
      .table-section {
        .document-upload-table-section {
          border-radius: 16px;
          border: 1px solid #666461;

          table {
            .no-match-found-text {
              border-bottom: none;
              height: 68px;
              padding: 22px 24px;
              gap: 1px;
              font-family: Arial;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              color: #2c2e35;
              text-align: right;
              margin-right: 5%;
            }

            th,
            td {
              border: 1px solid #666461;
            }

            .table-heading-row {
              th {
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                  linear-gradient(0deg, #c2beba, #c2beba);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2c2e35;
              }

              th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
              }

              th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
              }
            }

            .table-content-row {
              img {
                width: 24px;
                height: 24px;
              }

              td {
                border-bottom: none;
                height: 68px;
                padding: 22px 24px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;
              }

              td:first-child {
                border-left: none;
              }

              td:last-child {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}

.entity-name:hover {
  color: #0077C8 !important;
  text-decoration: underline;
  transition: all 0.3s ease;
}
</style>
