<template>
  <div class="get-started-container" :style="'background:' + bgColor">
    <div class="inner-get-started-container flex justify-between">
      <div class="content-section">
        <div class="text-content-heading" v-if="false">
          Set up your preferences in less than 5 minutes!
        </div>
        <div class="text-content-paragraph">{{ text }}</div>
      </div>
      <div class="button-section">
        <button
          class="flex justify-center"
          @click="$router.push({ path: '/impact-compass/setup-preferences' })"
        >
          Get started
          <img src="./../../assets/images/IMM/Vector.svg" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GetStarted",
  props: {
    text: String,
    bgColor: String,
  },
};
</script>
<style lang="scss" scoped>
.get-started-container {
  padding: 16px 24px 16px 24px;
  border-radius: 16px;
  .inner-get-started-container {
    .content-section {
      width: 804px;
      height: 60px;
      margin: 8px 0;
      gap: 8px;
      .text-content-heading {
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }
      .text-content-paragraph {
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .button-section {
      margin: 8px 0;
      gap: 8px;
      button {
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        width: 202px;
        height: 48px;
        background: #191b45;
        color: #ffffff;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
      img {
        margin-top: 8px;
        margin-left: 8px;
      }
    }
  }
}
</style>
