<script>
import { Bar } from "vue-chartjs";
// import env from "@/../env.js";

export default {
    name: "roiTurnoverPillarBarChart",
    extends: Bar,
    props: [
        "chartLabels",
        "chartsData",
        "created",
        "name"
    ],
    data: function () {
        return {
            max: 100,
            beginAtZero: true,
            labels: [],
            datasets: [],
            hoverLabels: [],
            hoverScores: [],
            chartData: []
        };
    },

    options: {
        maintainAspectRatio: false,
    },

    mounted() {
        this.renderMyChart();
    },
    methods: {
        renderMyChart() {
            this.labels = [];
            this.labels = this.chartLabels;
            this.chartData = JSON.parse(JSON.stringify(this.chartsData));

            this.chartData.forEach(data => {
                if(data.Oldscore != '') {
                    data.Oldscore = (Number(data.Oldscore)*100).toFixed(0);
                }
            });

            if(this.chartData[0].Oldscore == '') {
                this.hoverScores.push('No women worked at the enterprise in the past 12 months');
            }
            else if(this.chartData[0].Oldscore == '0' || this.chartData[0].Oldscore == '0.0') {
                this.hoverScores.push('No women left the enterprise in the past 12 months');
            }
            else {
            this.hoverScores.push(this.chartData[0].Oldscore)
            }

            if(this.chartData[1].Oldscore == '') {
                this.hoverScores.push('No men worked at the enterprise in the past 12 months');
            }
            else if(this.chartData[1].Oldscore == '0' || this.chartData[1].Oldscore == '0.0') {
                this.hoverScores.push('No women left the enterprise in the past 12 months');
            }
            else {
            this.hoverScores.push(this.chartData[1].Oldscore)
            }

            this.chartData.map(data => {
                if(data.Oldscore == '' || data.Oldscore == '0' || data.Oldscore == '0.0') {
                    data.Oldscore = 2;
                }
            });

            this.labels = ["Women", "Men"];
            this.datasets = [
                {
                    label: "Women",
                    borderColor: '#FE5000',
                    backgroundColor: '#FE5000',
                    data: [Number(this.chartData[0].Oldscore)],
                    barPercentage: 0.20,
                },
                {
                    label: "Men",
                    borderColor: '#0077C8',
                    backgroundColor: '#0077C8',
                    data: [Number(this.chartData[1].Oldscore)],
                    barPercentage: 0.20,
                },
            ]
            const _self = this;
            let year = this.created[1].split(" ");
            this.renderChart(
                {
                    labels: [year[0] + ' ' + year[1]],
                    datasets: this.datasets,
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    fontFamily: "basier",
                    legend: {
                        position: "bottom",
                        display: true,
                        labels: {
                            filter: function (item) {
                                // Logic to remove a particular legend item goes here
                                return !item.text.includes("hide");
                            },
                            fontSize: 13,
                            fontFamily: "basier",
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    max: this.max,
                                    min: 0,
                                    stepSize: this.max / 5,
                                    callback: function (value) {
                                        var numFormat = new Intl.NumberFormat("en-US");
                                        return (_self._props?.chartData?.[0]?.surveyQType == 'Currency') ? '$' + (numFormat.format(value)) : '' + (numFormat.format(value))+'%';
                                    }
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: "",
                                    fontStyle: "bold",
                                    fontSize: "16",
                                    fontFamily: "basier",
                                },
                                beginAtZero: this.beginAtZero,
                                fontFamily: "basier",
                                // stacked: true,
                                fontSize: 16,
                            },
                        ],
                        xAxes: [
                            {
                                fontSize: 13,
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                                fontFamily: "basier",
                                // stacked: true,

                            },
                        ],
                    },
                    tooltips: {
                        enabled: false
                    },
                    hover: {
                        onHover: function (e) {
                            // return false;
                            var point = this.getElementAtEvent(e);
                            e.target.style.cursor = 'pointer';
                            var numFormat = new Intl.NumberFormat("en-US");
                            // Prevent from flashing.
                            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
                                if (point[0]._index !== _self.hoverIndex + "_" + point[0]._datasetIndex) {
                                    _self.hoverIndex = point[0]._index + "_" + point[0]._datasetIndex;
                                    document.getElementById("intersection-info").position = "fixed";
                                    document.getElementById("intersection-info").style.zIndex = "99999";
                                    document.getElementById("intersection-info").style.top = e.pageY + "px";
                                    document.getElementById("intersection-info").style.left = e.pageX + "px";
                                    document.getElementById("intersection-info").style.display = "block";
                                    document.getElementById("intersection-name").style.textTransform = 'none';
                                    document.getElementById("intersection-name").innerText = (_self._props?.chartData?.[0]?.surveyQType == 'Currency' ? '$' : '') + ((_self.hoverScores[point[0]._datasetIndex] != 0 && _self.hoverScores[point[0]._datasetIndex].includes('in the past 12 months')) ? _self.hoverScores[point[0]._datasetIndex] : (numFormat.format(Math.round(_self.hoverScores[point[0]._datasetIndex])) + '% ' + _self.labels[point[0]._datasetIndex]));
                                    document.getElementById("intersection-domain").style.display = "none";
                                    document.getElementById("div-intersection-country").style.display = "none";
                                    document.getElementById("div-intersection-region").style.display = "none";
                                    document.getElementById("div-intersection-income").style.display = "none";
                                    document.getElementById("div-intersection-country-four").style.display = "none";
                                    document.getElementById("div-intersection-country-five").style.display = "none";
                                    document.getElementById("lmic-avg").style.display = "none";
                                    document.getElementById("div-intersection-validity-power").style.display = "none";
                                    document.getElementById("intersection-theme-link").style.display = "none";
                                    document.getElementById("intersection-domain-link").style.display = "none";
                                }
                            } else {
                                document.getElementById('intersection-info').style.display = 'none';
                                e.target.style.cursor = 'default';
                            }
                        }
                    },

                },
            );
        },
    },
    watch: {
        totalValue: function () {
            this.renderMyChart();
        },
    },
};
</script>
<style scoped>
.chartjs-render-monitor {
    height: 270px !important;
}
</style>