<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <!-- financial metrics section -->
    <div class="flex flex-col gap-2">
      <div class="flex items-center justify-between gap-2">
        <span class="text-2xl font-bold text-[#2c2e35]"
          >${{ revenue.toLocaleString() }}</span
        >
        <span class="text-large font-semibold text-[#4dc9be]"
          >↗ {{ revenueIncrease }}% increase</span
        >
      </div>
      <span class="text-sm font-semibold text-gray-500">Revenue</span>
    </div>

    <!-- financial breakdown -->
    <div class="flex flex-col gap-2 text-[#2c2e35]">
      <div class="flex flex-col gap-1">
        <div class="flex flex-col justify-between">
          <span class="text-sm">Gross Profit</span>
          <span class="font-semibold">${{ netProfit.toLocaleString() }}</span>
        </div>
        <div class="flex flex-col justify-between">
          <span class="text-sm">EBITDA</span>
          <span class="font-semibold">${{ ebitda.toLocaleString() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinanceModuleCardContent",
  props: {
    revenue: {
      type: Number,
      default: 25523,
    },
    revenueIncrease: {
      type: Number,
      default: 5,
    },
    netProfit: {
      type: Number,
      default: 25523,
    },
    ebitda: {
      type: Number,
      default: 25523,
    },
  },
};
</script>
