<template>
    <div class="static">
        <div style="display: flex; justify-content: space-between">
            <p class="object-contain no-print" style="height: 6rem; margin-top: 72px; margin-left: 285px"></p>
            <img src="@/assets/images/logo-navy.png" alt="background-image" class="object-contain no-print"
                style="height: 6rem; margin-top: 72px; margin-right: 120px" />
        </div>
        <transition name="fadeIn" mode="out-in">
            <div id="loaded" key="content">
                <div class="flex flex-col items-center justify-center w-full">
                    <div class="container">
                        <div data-modal="user-terms" class="flex flex-row justify-center items-center mt-3 ml-20">
                            <div class="wr">
                                <div class="content-wr mb-10" v-html="termsText"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
import axios from "axios";
export default {
    name: "EquiloUserTerms",
    data: function () {
        return {
            termsText: "",
            downloadTermsPdfUrl: "",
            termsName: "",
        };
    },
    components: {
    },
    async mounted() {
        this.$store.commit("setBreadcrumbs", [
            {
                name: "Cookies Policy",
                url: "/cookies-policy",
            },
        ]);
        await this.fetchUserTerms();
    },
    methods: {
        fetchUserTerms() {
            axios
                .get(env.apiUrl + "/users/get-terms/userTerms", {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("session"),
                    },
                })
                .then((response) => {
                    this.termsText = response.data.terms.markup;
                    this.downloadTermsPdfUrl = response.data.terms.downloadURL;
                    this.termsName = response.data.terms.termsName;
                });
        }
    }
};
</script>

<style lang="scss">
[data-modal="user-terms"] {
    .wr {
        .content-wr {
            li {
                font-size: 16px;
                line-height: 19.5px;
                font-weight: 500;
                color: #4b5563;
                margin-top: 10px;
                text-align: justify;
            }

            >ol {
                >li {
                    margin-top: 0;
                }

                >li:before {
                    font-size: 18px;
                    line-height: 21.94px;
                }

                h2 {
                    margin-top: 20px;
                }

                p {
                    margin-top: 10px;
                }

                >ol {
                    margin-top: 10px;
                }
            }

            h1 {
                font-size: 3.75rem;
                letter-spacing: -0.05em;
                font-weight: 500;
                text-align: center;
                margin-left: 1.25rem;
                margin-bottom: 2rem;
            }

            h2 {
                font-size: 18px;
                font-weight: 500;
                display: inline-block;
                margin-top: 20px;
            }

            p {
                color: #4b5563;
                font-weight: 500;
                font-size: 16px;
                margin-top: 1rem;
            }

            ol {
                list-style: auto;
                counter-reset: item;
            }

            li {
                display: block;
            }

            li:before {
                content: counters(item, ".") ". ";
                counter-increment: item;
                color: #4b5563;
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}
</style>