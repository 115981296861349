<template>
  <div class="w-full overflow-x-auto table-container">
    <table class="min-w-full border-collapse inner-table">
      <!-- Parent row headers -->
      <thead class="bg-gray-100">
        <tr class="table-heading-row">
          <th class="p-3 text-left w-1/12"></th>
          <th class="p-3 text-left w-6/12">Indicator</th>
          <th class="p-3 text-left w-5/12">Sub Domain</th>
        </tr>
      </thead>

      <tbody>
        <!-- Parent row -->
        <tr v-for="(section, sectionKey) in tableData.data" :key="sectionKey" v-show="tableData.data"
          class="hover:bg-gray-50 cursor-pointer border-b min-w-full table-content-row"
          @click="toggleSection(sectionKey)">
          <template v-if="!expandedSections[sectionKey]">
            <td class="p-3 w-1/12">
              <button class="w-6 h-6 flex items-center justify-center">
                <span class="transform transition-transform duration-200"
                  :class="{ 'rotate-90': expandedSections[sectionKey] }">
                  ▶
                </span>
              </button>
            </td>
            <td class="p-3 font-medium w-6/12">{{ section.indicator }}</td>
            <td class="p-3 w-5/12">{{ section.dimension }}</td>
          </template>
          <template v-else>
            <td colspan="3" style="padding: 0">
              <table class="w-full">
                <tbody>
                  <tr class="hover:bg-gray-50 cursor-pointer border-b min-w-full table-content-row">
                    <td class="p-3 w-1/12">
                      <button class="w-6 h-6 flex items-center justify-center">
                        <span class="transform transition-transform duration-200"
                          :class="{ 'rotate-90': expandedSections[sectionKey] }">
                          ▶
                        </span>
                      </button>
                    </td>
                    <td class="p-3 font-medium w-6/12">{{ section.indicator }}</td>
                    <td class="p-3 w-5/12">{{ section.dimension }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="w-full">
                <!-- Expanded row headers -->
                <thead>
                  <tr class="bg-gray-50 table-heading-row" :key="sectionKey + '_expanded'">
                    <th class="p-3 w-1/12"></th>
                    <th class="p-3 text-left w-3/12">Question</th>
                    <th class="p-3 text-left w-2/12">Changed From</th>
                    <th class="p-3 text-left w-2/12">Changed To</th>
                    <th class="p-3 text-center w-2/12">Updated Date</th>
                    <th class="p-3 text-center w-2/12">Verification Status</th>
                  </tr>
                </thead>
                <!-- Expanded row items -->
                <tbody>
                  <tr v-for="(item, index) in section.items" :key="index"
                    class="border-b bg-white hover:bg-gray-50 table-content-row">
                    <td class="p-3"></td>
                    <td class="p-3">{{ item.question }}</td>
                    <td class="p-3">
                      <template v-if="Array.isArray(item.changedFrom)">
                        <ul class="list-disc pl-4 text-red-600">
                          <li v-for="change in item.changedFrom" :key="change">
                            {{ change }}
                          </li>
                        </ul>
                      </template>
                      <span v-else class="text-red-600">{{ item.changedFrom }}</span>
                    </td>
                    <td class="p-3">
                      <template v-if="Array.isArray(item.changedTo)">
                        <ul class="list-disc pl-4 text-green-600">
                          <li v-for="change in item.changedTo" :key="change">
                            {{ change }}
                          </li>
                        </ul>
                      </template>
                      <span v-else class="text-green-600">{{ item.changedTo }}</span>
                    </td>
                    <td class="p-3 text-left">{{ formatDate(item.latestUpdatedDate) }}</td>
                    <td class="p-3">
                      <div class="flex justify-center items-center gap-2">
                        <template v-if="item.verificationStatus === true">
                          <img style="margin: 0px;" src="@/assets/images/Rightcircle.svg" alt="background-image" />
                          <span>Verified</span>
                        </template>
                        <template v-else-if="item.verificationStatus === false">
                          <img style="margin: 0px;" src="@/assets/images/Crosscircle.svg" alt="background-image" />
                          <span>Unverified</span>
                        </template>
                        <template v-else>
                          <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
                          <span>Pending</span>
                        </template>
                        <span v-if="item.verificationDate" class="font-bold">
                          (on {{ formatDate(item.verificationDate) }})
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </template>
        </tr>
        <tr v-if="!tableData.data || !tableData.data.length" class="font-medium text-lg">
          <td colspan="3" class="p-3 text-center">No data is available!</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SummaryReportData',
  props: {
    tableData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expandedSections: {}
    }
  },
  methods: {
    toggleSection(sectionKey) {
      this.expandedSections[sectionKey] = !this.expandedSections[sectionKey]
      this.$forceUpdate() // Ensure reactive update
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #e1e3e9;

  .red-button {
    font-weight: bold;
    font-size: 14px;
    color: #ff3a21;
    border-style: solid;
    border-color: #ff3a21;
    border-width: 1px;
  }

  .green-button {
    font-weight: bold;
    font-size: 14px;
    color: #3f7e44;
    border-style: solid;
    border-color: #3f7e44;
    border-width: 1px;
  }

  .disabled {
    border-color: rgb(204, 204, 204);
    color: rgb(204, 204, 204);
    cursor: text;
  }

  .rejected-status {
    color: #ff3a21;
    padding: 5px 8px;
    border: 1px solid #ff3a21;
    border-radius: 7px;
    cursor: default;
    font-size: 14px;
    font-weight: bold;
  }

  .accepted-status {
    color: #3f7e44;
    padding: 5px 8px;
    border: 1px solid #3f7e44;
    border-radius: 7px;
    cursor: default;
    font-size: 14px;
    font-weight: bold;
  }

  .inner-table {
    width: 100%;

    th,
    td {
      border: 1px solid #e1e3e9;
    }

    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }

      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row {
      img {
        width: 24px;
        height: 24px;
        margin: auto;
      }

      td {
        border-bottom: none;
        height: 85px;
        padding: 16px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2c2e35;
      }

      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }
    }
  }

  .red {
    color: #cb2e00;
  }

  .green {
    color: #13a094;
  }
}
</style>
