import { render, staticRenderFns } from "./2XCertSurveyModulesDashboard.vue?vue&type=template&id=bc95d486&scoped=true"
import script from "./2XCertSurveyModulesDashboard.vue?vue&type=script&lang=js"
export * from "./2XCertSurveyModulesDashboard.vue?vue&type=script&lang=js"
import style0 from "./2XCertSurveyModulesDashboard.vue?vue&type=style&index=0&id=bc95d486&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc95d486",
  null
  
)

export default component.exports