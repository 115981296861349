<template>
  <div class="mb-40">
    <div>
      <p
        class="font-sans text-2xl font-bold leading-[28px] text-left text-[#181818]"
      >
        Invite your investment targets or portfolio companies to the Equilo IMM
        platform to start the collection of gender, ESG, climate, impact and
        financial data.
      </p>
    </div>
    <div class="mt-4">
      <div class="grid grid-cols-3 gap-4">
        <div>
          <p class="text-lg text-left text-gray-900">Investee company name</p>
          <input
            type="text"
            placeholder="Type investee company name"
            v-model="companyName"
            class="mt-2 w-60 h-12 p-3 rounded-lg border border-gray-300"
          />
        </div>
        <div>
          <p class="text-lg text-left text-gray-900">E-mail address</p>
          <input
            type="text"
            placeholder="Type e-mail address"
            v-model="emailAddress"
            class="mt-2 w-60 h-12 p-3 rounded-lg border border-gray-300"
          />
        </div>
        <div>
          <p class="text-lg text-left text-gray-9000">Portfolio (Optional)</p>
          <select
            v-model="portfolio"
            class="mt-2 w-60 h-12 p-3 rounded-lg border border-gray-300"
          >
            <option v-for="portfolio in portfolioList" :key="portfolio">
              {{ portfolio }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-4 text-right">
        <button
          :class="{
            'bg-gray-300 cursor-not-allowed': !companyName || !emailAddress,
            'bg-blue-600': companyName && emailAddress,
          }"
          :disabled="!companyName || !emailAddress"
          @click="
            sendInviteToCompany(
              companyName,
              emailAddress,
              portfolio,
              portfolioName
            )
          "
          class="px-4 py-2 rounded-lg text-white font-bold"
        >
          Send invite
        </button>
      </div>
    </div>
    <div class="mt-6">
      <div class="w-154 h-8">
        <h2 class="font-bold text-2xl text-left text-gray-800">
          Investees ({{ companyList.length }})
        </h2>
      </div>
      <div class="mt-4">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Type company’s name"
          @input="debounceGetCompanyList"
          class="w-60 h-12 p-3 rounded-lg border border-gray-300"
        />
      </div>
      <div class="mt-4 w-158 max-h-92 overflow-auto" id="scrollbar_style">
        <div
          class="w-154 h-16 p-4 rounded-lg border border-gray-300 mb-3 flex justify-between"
          v-for="(company, index) in companyList"
          :key="index"
          :class="{
            'bg-yellow-100': company.status == 'Request sent',
            'bg-green-100': company.status == 'Request accepted',
          }"
        >
          <div class="flex gap-3">
            <div>
              <img
                src="./../../../assets/images/IMM/yellow-circle.svg"
                v-if="company.status == 'Request sent'"
              />
              <img
                src="./../../../assets/images/IMM/temp-company-image.png"
                v-else
              />
            </div>
            <div class="text-left text-gray-800">{{ company.companyName }}</div>
          </div>
          <div class="text-left text-gray-800">
            {{ company.portfolioType }}
          </div>
          <div v-if="company.status == 'Request sent'">
            <button
              class="w-34 h-8 p-1.5 rounded-full bg-yellow-500 text-white font-bold flex gap-1"
            >
              <img src="./../../../assets/images/IMM/yellow-eye.svg" />
              Request sent
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!companyList.length"
      class="mt-5 w-154 h-32 p-6 rounded-lg bg-gray-100"
    >
      <div>
        <img src="./../../../assets/images/IMM/box.svg" class="block m-auto" />
      </div>
      <p class="text-center text-gray-600 mt-4">Your investees list is empty</p>
    </div>
    <div
      v-if="isInviteSuccess"
      class="w-68 h-14 p-4 rounded-lg bg-teal-100 m-auto mt-19 flex gap-2"
    >
      <img src="@/assets/images/2x_certify/filled.svg" />
      <p class="text-left text-gray-800">Invitation was sent via e-mail</p>
    </div>
  </div>
</template>
<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
export default {
  name: "Step4",
  props: {
    portfolioName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      companyList: [],
      companyName: "",
      emailAddress: "",
      isInviteSuccess: false,
      searchQuery: "",
      portfolio: "",
      portfolioList: ["Portfolio"],
      debounceGetCompanyList: null,
    };
  },
  methods: {
    async sendInviteToCompany(c_name, c_email, portfolio, portfolioName) {
      let validate = this.validEmail(c_email);
      if (validate) {
        const payload = {
          emails: [c_email],
          companyName: c_name,
          portfolioName: portfolioName,
        };

        if (portfolio) {
          payload.portfolioType = portfolio;
        }

        try {
          const response = await axios.post(
            `${env.apiUrl}/imm-preferences/investee-invites`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
            }
          );
          if (response.data.data) {
            this.$swal.fire({
              icon: "success",
              text: "Success! Your invitation was sent!",
              showConfirmButton: true,
            });
            this.companyName = "";
            this.emailAddress = "";
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Please try again!",
              showConfirmButton: true,
            });
          }
          await this.getCompanyList();
          return response.data.data;
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text:
              error?.response?.data?.error || error?.response?.data?.message,
            showConfirmButton: true,
          });
          throw error;
        }
      } else {
        alert("Please enter valid email address!");
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    async getCompanyList() {
      try {
        const url = this.searchQuery
          ? `${
              env.apiUrl
            }/imm-preferences/investee-invites?search=${encodeURIComponent(
              this.searchQuery
            )}`
          : `${env.apiUrl}/imm-preferences/investee-invites`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        });
        this.companyList = response.data.data;
      } catch (error) {
        this.companyList = [];
        console.error("Failed to search companies:", error);
      }
    },
  },
  mounted() {
    this.debounceGetCompanyList = this.debounce(
      () => this.getCompanyList(),
      400
    );
    this.debounceGetCompanyList();
  },
};
</script>
<style lang="scss" scoped>
#scrollbar_style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e1e3e9;
  border-radius: 10px;
  background-color: #e1e3e9;
}
#scrollbar_style::-webkit-scrollbar {
  width: 6px;
  padding-right: 20px;
}
#scrollbar_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #717686;
  background-color: #717686;
}
</style>
